import React, { FC, useContext } from "react"
import { DealsContext } from "../../components/app/context/DealsContext"
import PageLayout from "../../components/layout/PageLayout"
import { Deals } from "../../components/pages/deals"
import { AddColumnModal } from "../../components/pages/deals/ui/AddColumnModal"
import { AddDealModal } from "../../components/pages/deals/ui/AddDealModal"
import { DeleteColumnModal } from "../../components/pages/deals/ui/DeleteColumnModal"
import { DeleteDealModal } from "../../components/pages/deals/ui/DeleteDealModal"
import { EditColumnModal } from "../../components/pages/deals/ui/EditColumnModal"
import { EditDealModal } from "../../components/pages/deals/ui/EditDealModal"
import { ConfirmButtons } from "../../components/ui"
import { useBreakpoints } from "../../helpers/useBreakpoints"
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal"

interface DealsPageProps {
  isTourStep?: boolean
}

const DealsPage: FC<DealsPageProps> = ({ isTourStep }) => {
  const {
    isAddColumnModalOpened,
    openAddColumnModal,
    closeAddColumnModal,
    isEditColumnModalOpened,
    closeEditColumnModal,
    isDeleteColumnModalOpened,
    closeDeleteColumnModal,
    isAddDealModalOpened,
    closeAddDealModal,
    isEditDealModalOpened,
    closeEditDealModal,
    isDeleteDealModalOpened,
    closeDeleteDealModal,
  } = useContext(DealsContext)
  const { isDesktop } = useBreakpoints()

  return isDesktop ? (
    <>
      <PageLayout
        pageTitle={"Deals"}
        rightHeaderContent={
          <ConfirmButtons
            rightButtonTitle={"Add Column"}
            width={"fit-content"}
            style={{ gap: 18 }}
            handleSubmit={openAddColumnModal}
          />
        }
        isTableContent={false}
      >
        <Deals isTourStep={isTourStep} />
      </PageLayout>
      {!isTourStep && (
        <>
          {isAddColumnModalOpened && <AddColumnModal onClose={closeAddColumnModal} />}
          {isEditColumnModalOpened && <EditColumnModal onClose={closeEditColumnModal} />}
          {isDeleteColumnModalOpened && <DeleteColumnModal onClose={closeDeleteColumnModal} />}
          {isAddDealModalOpened && <AddDealModal onClose={closeAddDealModal} />}
          {isEditDealModalOpened && <EditDealModal onClose={closeEditDealModal} />}
          {isDeleteDealModalOpened && <DeleteDealModal onClose={closeDeleteDealModal} />}
        </>
      )}
    </>
  ) : (
    <GoToDesktopModal />
  )
}

export default DealsPage
