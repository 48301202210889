/* eslint-disable react/jsx-fragments */
import { Button, Typography } from "antd"
import React, { FC, useState } from "react"
import { Collapse, ContentCard, CustomLabel } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { AddVoicemailModal } from "../AddVoicemailModal"
import { CollapseProps } from "antd/lib"

export const PhoneCall: FC = () => {
  const [openModal, setOpenModal] = useState(false)

  const showModal = () => {
    setOpenModal(true)
  }
  const onCancel = () => {
    setOpenModal(false)
  }

   const items: CollapseProps["items"] =[
    {
      key: "1",
      label: "What happens after the call?",
      children:
        <>
           <div style={{color:'blueviolet',fontWeight:600}}>Interested call:</div> Taken out of the sequence
      <div style={{color:'orange',fontWeight:600}}>Missed calls:</div> Next step of a Templated or Personalized Ai email will automatically mention you tried to call them to try and get a demo booked that way
      </>
    },
    {
      key: "2",
      label: "Does Ai talk for me?",
      children:
        <>
         Ai voice calling is not legal in most countries. Meetz will autodial when you click on start power dialing in the campaign but you will talk with the first person that answers, not Ai. <br /><br />Ai will however give you a researched intro line on any prospect that answers your call. <i>f.e: I saw you uploaded on LinkedIn a post discussing how Ai will shape the future of sales outreach so I thought it would make sense to reach out and offer our services that do just that.</i>
      </>
    },
    {
      key: "3",
      label: "What phone number will be used if the prospect has a few different numbers?",
      children:
        <>
          
           Every time the prospect is called in the sequence they will be called again with a different number they have in the next phone call step (Rotates mobile/direct/company number to try and reach them)
      </>
    },
    {
      key: "4",
      label: "What if more than 2 people answer?",
      children:
        <>
        Meetz listens to hear if a voicemail or a human answered using Ai. If a human answers you will get that call and in a split second the rest of the calls are  
      </>
    }
  ]

  return (
    <ContentCard headerIcon={<SvgIcon type={"phoneCall"} />} cardTitle={"Phone Call"}>
      <Typography.Title level={3}>How does the phone call step work?</Typography.Title>

      <br /><br /><b>1.</b> Simply click start dialing in the activated campaign<br /><b>2.</b> Meetz Power dialer will call up to 15 people at the same time <br /><b>3.</b> Once someone answers you'll a pop up with all of your prospects LinkedIn/Companies information scraped in real time. <br /><br /><b>Extra:</b> Our Ai will provide a researched intro line you can say to make your pitch very interesting for your prospect<br /><br />
     
      {/* <CustomLabel title={"Voicemail"} />
      <Button className={"link"} onClick={showModal}>
        Add TODOF add voicemail later
      </Button> */}
     <br /><br /> FAQ:<br /><br />
      <Collapse items={items}/>
      <AddVoicemailModal open={openModal} onCancel={onCancel} />
    </ContentCard>
  )
}
