import { Button, Row } from "antd";
import React, { FC, useState } from "react";
import { CustomSelect } from "../../../ui";
import { DashboardCard } from "../ui/DashboardCard";
import { OverviewChart } from "./OverviewChart";
import { OverviewEmptyData } from "./OverviewEmptyData";
import "./index.less";
import { SvgIcon } from "src/shared/icons";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { Loader } from "src/components/layout";
import { getEmailGraphAnalytics } from "src/store/outreach-campaigns/asyncRequests";
import { AppDispatch } from "src/store";

interface OverviewCardProps {
  // data?: any;
}

export const periodOptions = [
  {
    label: "Last 7 days",
    value: "Last 7 days",
  },
  {
    label: "Last Month",
    value: "Last Month",
  },
];

const overviewItemsOptions = [
  {
    label: "Emails",
    value: "Emails",
  },
  {
    label: "Phone and SMS",
    value: "Phone and SMS",
  },
];

export const OverviewCard: FC<OverviewCardProps> = () => {
  // const [periodOfTime, setPeriodOfTime] = useState("Emails");
  const [periodOfTime, setPeriodOfTime] = useState("Last 7 days");
  const { emailAnalyticsGraphData, loadingGraphAnalytics } = useSelector(outreachCampaignSelector);

  const dispatch = useDispatch<AppDispatch>();
  const getAnalytics = (timeframe?: string) => {
    dispatch(getEmailGraphAnalytics({ timeframe: timeframe || periodOfTime }));
  };

  const handleChangeTimeframe = (val) => {
    // console.log(val, "VALasvsdav");
    setPeriodOfTime(val);
    getAnalytics(val);
  };

  // const handleChange = (value: string) => {
  //   setSelectedChartType(value);
  // };
  // console.log(emailAnalyticsGraphData, "emailAnalyticsGraphData");
  const headerRightContent = (
    <Row wrap={false} style={{ gap: 18 }}>
      {/* <CustomSelect
        customClassName={"dashboard-select"}
        options={overviewItemsOptions}
        onChange={handleChange}
        defaultValue={"Emails"}
        popupClassName={"dashboard-select-dropdown"}
        style={{ width: 138, height: 26 }}
      /> */}
      <Button className="link" onClick={() => !loadingGraphAnalytics && getAnalytics()}>
        {loadingGraphAnalytics ? <Loader size={"small"} /> : <SvgIcon type={"refresh"} width={12} height={12} />}
        Refresh
      </Button>
      <CustomSelect
        options={periodOptions}
        value={periodOfTime}
        popupClassName={"dashboard-select-dropdown"}
        style={{ width: 130, height: 26 }}
        isSearchSelect={false}
        onSelect={handleChangeTimeframe}
      />
    </Row>
  );

  return (
    <DashboardCard customClassName={"overview-card-wrapper"} title={"Email Analytics Overview"} headerRightContent={headerRightContent}>
      {emailAnalyticsGraphData?.length ? <OverviewChart data={emailAnalyticsGraphData} /> : <OverviewEmptyData />}
    </DashboardCard>
  );
};
