export const getTagStyles = (color: string) => {
  switch (color) {
    case "Red": {
      return {
        color: "#AF1EAA",
        backgroundColor: "rgba(175, 30, 170, 0.10)",
      }
    }
    case "Green": {
      return {
        color: "#096766",
        backgroundColor: "#D0EED0",
      }
    }
    case "Light Green": {
      return {
        color: "#37B9A8",
        backgroundColor: "rgba(55, 185, 168, 0.10)",
      }
    }
    case "Blue": {
      return {
        color: "#00ADEE",
        backgroundColor: "rgba(0, 173, 238, 0.10)",
      }
    }
    case "Purple": {
      return {
        color: "#6048F3",
        backgroundColor: "rgba(96, 72, 243, 0.10)",
      }
    }
    default: {
      return {
        color: "#096766",
        backgroundColor: "#D0EED0",
      }
    }
  }
}
