import { FC, useCallback, useContext, useMemo, useState } from "react"
import { ConfirmButtons, CustomSelect, Modal } from "../../../ui"
import { Flex, Typography } from "antd"
import { CommonContext } from "../../../app/context/CommonContext"
import { SvgIcon } from "../../../../shared/icons"
import "./index.less"

interface ViewModalProps {
  onClose: () => void
}

const fakeTeams = [
  {
    id: "1",
    name: "Team 1",
    type: "team",
    members: [
      {
        id: "1.1",
        name: "John Snow",
        type: "member",
      },
      {
        id: "1.2",
        name: "Tom Smith",
        type: "member",
      },
      {
        id: "1.3",
        name: "Elena Gilbert",
        type: "member",
      },
      {
        id: "1.4",
        name: "Ann Salvatore",
        type: "member",
      },
      {
        id: "1.5",
        name: "Mike Butko",
        type: "member",
      },
      {
        id: "1.6",
        name: "Sam Smith",
        type: "member",
      },
    ]
  },
  {
    id: "2",
    name: "Team 2",
    type: "team",
    members: [
      {
        id: "2.1",
        name: "Anna Golibenko",
        type: "member",
      },
      {
        id: "2.2",
        name: "Alec Smith",
        type: "member",
      },
      {
        id: "2.3",
        name: "Elena Gilbert",
        type: "member",
      },
      {
        id: "2.4",
        name: "Ann Salvatore",
        type: "member",
      },
      {
        id: "2.5",
        name: "Mike Butko",
        type: "member",
      },
      {
        id: "2.6",
        name: "Sam Smith",
        type: "member",
      },
    ]
  }
]

export const ViewModal: FC<ViewModalProps> = ({ onClose }) => {
  const { currentView, setCurrentView, currentViewEntity, setCurrentViewEntity, setIsViewingProcess } = useContext(CommonContext)
  const [selectedOption, setSelectedOption] = useState(currentView === "Personal" || currentView === "All" ? currentView : currentViewEntity.id)

  const handleSelectedViewChange = (value: any) => {
    setSelectedOption(value)
  }

  const handleSubmitBtnClick = () => {
    if (selectedOption === "Personal" || selectedOption === "All") {
      setCurrentView(selectedOption);
    } else {
      const team = fakeTeams.find(team => team.id === selectedOption);
      if (team) {
        setCurrentView("Team");
        setCurrentViewEntity(team);
      } else {
        fakeTeams.forEach(team => {
          const member = team.members.find(member => member.id === selectedOption);
          if (member) {
            setCurrentView("Custom");
            setCurrentViewEntity(member);
          }
        });
      }
    }

    setIsViewingProcess(true);
    onClose();
  };

  const teamOptions = useMemo(() =>
      fakeTeams.map(team => ({
        label: team.name,
        value: team.id,
      })),
    [fakeTeams]
  );

  const customOptions = useCallback(() => {
    const membersMap = new Map();

    fakeTeams.forEach(team => {
      team.members.forEach(member => {
        if (!membersMap.has(member.id)) {
          membersMap.set(member.id, member);
        }
      });
    });

    return useMemo(() =>
        Array.from(membersMap.values()).map(member => ({
          label: member.name,
          value: member.id,
        })),
      [membersMap]
    );
  }, [fakeTeams]);

  const viewOptions = [
    {
      label: "Personal",
      value: "Personal",
    },
    {
      label: "All",
      value: "All",
    },
    {
      label: (
        <Flex gap={8} align={"center"}>
          <Flex justify={"center"} align={"center"} className={"option-wrapper"}>
            <SvgIcon type={"arrowDownTriangle"} width={12} height={12} />
          </Flex>
          <Typography.Paragraph>Teams</Typography.Paragraph>
        </Flex>
      ),
      value: "Team",
      options: teamOptions,
    },
    {
      label: (
        <Flex gap={8} align={"center"}>
          <Flex justify={"center"} align={"center"} className={"option-wrapper"}>
            <SvgIcon type={"arrowDownTriangle"} width={12} height={12} />
          </Flex>
          <Typography.Paragraph>Custom</Typography.Paragraph>
        </Flex>
      ),
      value: "Custom",
      options: customOptions(),
    },
  ];

  return (
    <Modal customClassName={"view-modal"} open={true} title={"Select View"} onCancel={onClose}>
      <Flex vertical={true} gap={8}>
        <Typography.Paragraph className={"label"}>View</Typography.Paragraph>
        <CustomSelect className={"view-select"} options={viewOptions} defaultValue={selectedOption} onChange={handleSelectedViewChange} />
      </Flex>
      <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Back"} rightButtonTitle={"Select"} handleSubmit={handleSubmitBtnClick} onCancel={onClose} />
    </Modal>
  )
}