/* eslint-disable no-unused-vars */

import { createAsyncThunk } from "@reduxjs/toolkit"
import { apiHandler, apiLinkHandler } from "../helpers/apiHandler"

export const getavail = createAsyncThunk(
  "reactive-links/getavail",
  async (body: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers
    const res = await apiHandler({
      iframeJwt,
      url: "/reactive-links/getavail",
      method: "post",
      body,
    })

    return res
  }
)
export const countEmailClick = createAsyncThunk(
  "reactive-links/countEmailClick",
  async (body: any, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers
    const res = await apiHandler({
      iframeJwt,
      url: "/aiSales/countEmailClick",
      method: "post",
      body,
    })

    return res
  }
)
export const cancelGuestMeeting = createAsyncThunk("reactive-links/cancelGuestMeeting", async (body: any) => {
  const res = await apiLinkHandler({
    url: "/reactive-links/cancelMeeting",
    method: "post",
    body,
  })

  return res
})
export const reschGuestMeeting = createAsyncThunk("reactive-links/reschGuestMeeting", async (body: any) => {
  const res = await apiLinkHandler({
    url: "/reactive-links/reschMeeting",
    method: "post",
    body,
  })
  return res
})
export const getavailAndGuest = createAsyncThunk("reactive-links/getavailAndGuest", async (body: any) => {
  const res = await apiLinkHandler({
    url: "/reactive-links/getavailAndGuest",
    method: "post",
    body,
  })

  return res
})

export const gettimezones = createAsyncThunk(
  "reactive-links/gettimezones",
  async (_, { getState }: { getState: any }) => {
    const { iframeJwt } = getState().helpers
    const res = await apiHandler({
      iframeJwt,
      url: "/reactive-links/gettimezones",
      method: "get",
    })

    return res
  }
)
