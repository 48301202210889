import { Button, Col, Flex, Popover, Row, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons } from "src/components/ui";
import { CustomTag } from "src/components/ui/CustomTag";
import { SvgIcon } from "src/shared/icons";
import { CallNotesModal } from "../CallNotesModal";
import { numberToDial } from "src/store/dialer";
import { useDispatch } from "react-redux";

const phoneButtons = [
  {
    value: "1",
    letters: "",
  },
  {
    value: "2",
    letters: "ABC",
  },
  {
    value: "3",
    letters: "DEF",
  },
  {
    value: "4",
    letters: "GHI",
  },
  {
    value: "5",
    letters: "JKL",
  },
  {
    value: "6",
    letters: "MNO",
  },
  {
    value: "7",
    letters: "PQRS",
  },
  {
    value: "8",
    letters: "TUV",
  },
  {
    value: "9",
    letters: "WXYZ",
  },
  {
    value: "*",
    letters: "",
  },
  {
    value: "0",
    letters: "",
  },
  {
    value: "#",
    letters: "",
  },
];

export const DialerButtonsFooter: FC<{
  closeModal: VoidFunction;
  onHangUp: VoidFunction;
  onHangUpAndClose: VoidFunction;
  callBackFunction: VoidFunction;
  callSeconds: number;
}> = ({ callBackFunction, closeModal, onHangUp, callSeconds, onHangUpAndClose }) => {
  const dispatch = useDispatch();
  const [openPopover, setOpenPopover] = useState(false);
  const [openCallNotesModal, setOpenCallNotesModal] = useState(false);
  const handleNumberClick = (number) => {
    console.log(number, "numberToDialOnCall2");
    dispatch(numberToDial(number));
  };
  const popoverContent = (
    <Row className={"phone-numbers-wrapper"} gutter={[0, 16]}>
      {phoneButtons.map((item, index) => {
        return (
          <Col key={`${item.value}-${index}`} span={8} style={{ textAlign: "center" }}>
            <Flex className={"phone-numbers-button"} vertical align={"center"} gap={4} onClick={() => handleNumberClick(item.value)}>
              <Typography.Paragraph className={"phone-number"}>{item.value}</Typography.Paragraph>
              {item.letters ? <Typography.Paragraph className={"phone-letters"}>{item.letters}</Typography.Paragraph> : null}
            </Flex>
          </Col>
        );
      })}
    </Row>
  );

  const showCallNotesModal = () => {
    setOpenCallNotesModal(true);
  };
  const closeCallNotesModal = () => {
    setOpenCallNotesModal(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenPopover(newOpen);
  };
  const secondsToCallTime = (seconds) => {
    const minutes = Math.floor((seconds || 0) / 60);
    const remainingSeconds = seconds % 60;
    return `Call Time: ${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <Flex justify={"space-between"} align={"center"} style={{ marginTop: 28 }}>
        <Button className={"link"} onClick={showCallNotesModal}>
          Add notes
        </Button>
        <Row style={{ marginLeft: "11%", width: "fit-content" }}>
          {/* {phoneCalling ? ( */}
          {/* <Tooltip title={``}> */}
          {/* {" "} */}
          <CustomTag text={secondsToCallTime(callSeconds)} color={"#00ADEE"} backgroundColor={"#00ADEE1A"} style={{ marginRight: "30px" }} />
          {/* <CustomTag text={"Call Time: 00:28"} color={"#00ADEE"} backgroundColor={"#00ADEE1A"} style={{ marginRight: "30px" }} /> */}
          {/* </Tooltip> */}
          {/* ) : (
            <CustomTag text={"Call Time: 00:28"} color={"#00ADEE"} backgroundColor={"#00ADEE1A"} style={{ marginRight: "30px" }} />
          )} */}
          <Popover content={popoverContent} trigger={"click"} open={openPopover} arrow={false} style={{ width: 300 }} onOpenChange={handleOpenChange}>
            <SvgIcon type={"phoneDialer"} onClick={() => setOpenPopover(true)} style={{ cursor: "pointer" }} />
          </Popover>
        </Row>
        <ConfirmButtons width={200} rightButtonTitle={"Call Back"} leftButtonTitle={"End Call"} onCancel={onHangUp} handleSubmit={callBackFunction} />
      </Flex>
      <CallNotesModal open={openCallNotesModal} onCancel={closeCallNotesModal} hangup={onHangUpAndClose} />
    </>
  );
};
