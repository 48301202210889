import { Form, Input, Radio, Space, Tooltip } from "antd";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmButtons, CustomSelect, Modal } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { dialerSelector } from "src/store/dialer";
import { updateCallNotes } from "src/store/dialer/asyncRequests";

type CallNotesModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  hangup: VoidFunction;
};

const callOutcomeOptions = [
  {
    label: "interested",
    value: "interested",
  },
  {
    label: "Not Interested",
    value: "Not Interested",
  },
  {
    label: "No Answer",
    value: "No Answer",
  },
  {
    label: "Follow Up",
    value: "Follow Up",
  },
  {
    label: "Voicemail",
    value: "Voicemail",
  },
];

export const CallNotesModal: FC<CallNotesModalProps> = ({ open, onCancel, hangup }) => {
  const { dialerInfo, activeProspectOnPhoneNowId, dialerPowerDialingOnInfo } = useSelector(dialerSelector);
  const [form] = Form.useForm();
  const closeModal = () => {
    // form.resetFields();
    onCancel();
  };
  const dispatch = useDispatch();
  const handleFormSubmit = () => {
    const values = form.getFieldsValue();
    const callNotes = { activeProspectOnPhoneNowId, dialerId: dialerPowerDialingOnInfo?.overview?.campId, ...values };
    dispatch(updateCallNotes(callNotes)); // HAS to hang up otherwise the notes wont be connected to a call
    console.log(callNotes, "valuestest");
    hangup();
    // form.resetFields();
  };

  const callOutcomeOptions = (dialerInfo?.call_outcomes_new || [])?.map((out) => ({
    label: (
      <>
        {out?.friendly_name} -{" "}
        {out?.status === "good" ? (
          <span style={{ color: "green" }}> Good </span>
        ) : out?.status === "neutral" ? (
          <span style={{ color: "blue" }}> Neutral </span>
        ) : (
          <span style={{ color: "red" }}> Bad </span>
        )}
      </>
    ),
    value: out?.value,
  }));

  console.log(callOutcomeOptions, "callOutcomeOptions");
  return (
    <Modal width={530} open={open} title={"Call Notes"} onCancel={closeModal}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Form.Item
          name={"callOutcome"}
          label={
            <>
              Call Outcome{" "}
              <Tooltip
                placement={"topLeft"}
                title={
                  <>
                    "Good" and "Bad" statuses will remove the prospect from this campaign from getting further steps. If you want to stop prospects from ever
                    getting called again in any campaign in your account mark them as "Do not call"
                  </>
                }
              >
                <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
              </Tooltip>
            </>
          }
          rules={[{ required: true, message: "Select outcome" }]}
        >
          <CustomSelect options={callOutcomeOptions} />
        </Form.Item>
        <Form.Item name={"remove"} label={"Mark as do not call"}>
          <Radio.Group defaultValue={false}>
            <Space direction={"vertical"}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={"notes"} label={"Call Notes"}>
          <Input.TextArea
            placeholder={"Enter Text"}
            style={{
              resize: "vertical",
              overflow: "auto", // Ensure scrollbars work as needed
              border: "1px solid #d9d9d9", // Match Ant Design default border
              padding: "10px", // Adjust padding if needed
              position: "relative",
            }}
          />
        </Form.Item>
        <ConfirmButtons rightButtonTitle={"Save and Hangup"} leftButtonTitle={"Minimize"} onCancel={closeModal} />
      </Form>
    </Modal>
  );
};
