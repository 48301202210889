import { Button, message } from "antd";
import React, { FC, RefObject, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageLayout from "../../../layout/PageLayout";
import { CampaignsTable } from "../CampaignsTable";
import { CreateCampaignsModal } from "../ui";
import { AIGeneratedSequence, FromSaved, FromScratch } from "./ui";
import { TestAiSettings } from "./ui/AIGenerated/ui/StepThree/ui/AiPersonalizedEmail/ui/TestAiSettings";
import { outreachCampaignSelector, resetOutcome } from "src/store/outreach-campaigns";
import { useDispatch, useSelector } from "react-redux";

interface CreateCampaignProps {
  isTourStep?: boolean;
  createCampaignRef?: RefObject<HTMLButtonElement>;
}

export const CreateCampaign: FC<CreateCampaignProps> = ({ createCampaignRef, isTourStep }) => {
  const [openModal, setOpenModal] = useState(false);
  const { pathname } = useLocation();

  const showModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const rightHeaderContent = (
    <Button onClick={showModal} className={"primary"} ref={createCampaignRef}>
      Create Campaign
    </Button>
  );

  const { successfullUpdateMsg, failedUpdateMsg, infoUpdateMsg } = useSelector(outreachCampaignSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (successfullUpdateMsg) {
      message.destroy();
      message.success(successfullUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [successfullUpdateMsg]);
  useEffect(() => {
    if (failedUpdateMsg) {
      message.destroy();
      message.error(failedUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [failedUpdateMsg]);
  useEffect(() => {
    if (infoUpdateMsg) {
      message.destroy();
      message.info(infoUpdateMsg);
      dispatch(resetOutcome());
    }
  }, [infoUpdateMsg]);

  switch (true) {
    case pathname.endsWith("ai-generated"):
      return <AIGeneratedSequence />;
    case pathname.endsWith("from-saved"):
      return <FromSaved />;
    case pathname.endsWith("from-scratch"):
      return <FromScratch />;
    // case pathname.endsWith("test-ai-settings"):
    //   return <TestAiSettings />
    default:
      return (
        <PageLayout pageTitle={"Campaigns"} rightHeaderContent={rightHeaderContent}>
          <CreateCampaignsModal onCancel={closeModal} open={openModal} />
          <CampaignsTable isTourStep={isTourStep} showModal={showModal} />
        </PageLayout>
      );
  }
};
