/* eslint-disable react/jsx-fragments */
import { Button, Flex, MenuProps, Row, Tooltip, Typography } from "antd";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { App } from "src/types";
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext";
import { prospectDataSelector, resetSelectedRows, setPageNum, setSelectedRowKeys } from "src/store/prospect_data";
import { prospectData } from "../../info";
import { ActivateForAccessModal, ExportProspectsModal, PhonesCell } from "src/components/pages/prospect-data";
import { Table } from "src/components/ui";
import { getHeadcountRange, getRevenueRange } from "src/helpers";
import { SvgIcon } from "src/shared/icons";
import { EmptyFilterData } from "./ui/EmptyFilterData";
import { HeaderTagsBar } from "./ui/HeaderTagsBar";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "src/store";
import { fullUserSelector, userSelector } from "src/store/user";
import Speedometer from "./ui/Speedometer";

export const items: MenuProps["items"] = [
  {
    key: "1",
    label: "View details",
  },
  {
    key: "2",
    label: "Add member",
  },
  {
    key: "3",
    danger: true,
    label: "Delete",
  },
];

interface ExploreTableProps {
  isTourStep?: boolean;
}

export const ExploreTable: FC<ExploreTableProps> = ({ isTourStep }) => {
  // const [loading, setLoading] = useState(false)
  let { prospect_data_credits, new_payment_status, split_between_prosp_to_personalize, new_prospectdata_or_personalized_email_permonth, trial_credits } =
    useSelector(userSelector);
  const isTrial = new_payment_status === "trial";

  if (isTrial) {
    const credUsed = (1 - split_between_prosp_to_personalize / 100) * new_prospectdata_or_personalized_email_permonth - prospect_data_credits;
    prospect_data_credits = trial_credits - credUsed;
    if (prospect_data_credits < 0) {
      prospect_data_credits = 0;
    }
  }
  const [openActivateModal, setOpenActivateModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [selectProspects, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [tableData, setTableData] = useState<App.ProspectEntity[]>(prospectData);
  const [tourStepTableData, setTourStepTableData] = useState<App.ProspectEntity[]>(prospectData);

  const isObjectEmpty = (obj: any) => {
    return Object.keys(obj).every((key) => Array.isArray(obj[key]) && obj[key].length === 0);
  };
  // const isFilterSelected = filterFormData && !isObjectEmpty(filterFormData);

  const { totalProspectsAvailable, loading, prospOnCurPage, totalProspPages, curProspPages, filterFormData, selectProspects } =
    useSelector(prospectDataSelector);

  useEffect(() => {
    setTableData(prospOnCurPage);
  }, [prospOnCurPage]);

  const onSelectChange = (selectedRowKeys) => {
    // console.log(selectProspects,'selectProspects',selectedRowKeys);
    dispatch(
      setSelectedRowKeys({
        ...selectProspects,
        [curProspPages]: selectedRowKeys,
      })
    );
  };

  // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
  //   console.log(newSelectedRowKeys,'newSelectedRowKeys');
  //   // setSelectedRowKeys(newSelectedRowKeys);
  //   dispatch(setSelectedRowKeys(newSelectedRowKeys));
  // };

  // const handleSelectAll = () => {
  //   if (prospectData && Array.isArray(prospectData)) {
  //     const selectedKeys = prospectData.map(item => item["ruid"]);
  //     rowSelection.onChange(selectedKeys);
  //   }
  // }
  // const handleSelectAll = useCallback(() => {
  //   if (prospectData && Array.isArray(prospectData)) {
  //     const selectedKeys = prospectData.map(item => item["id"]);
  //     rowSelection.onChange(selectedKeys);
  //   }
  // }, [prospectData]);

  const handleDeselectAll = () => {
    setSelectedRowKeys([]);
  };

  //   const getSelectedRowKeys = () => {
  //   return Object.values(selectedRowKeysByPage).flat();
  // };
  const [lastMeasureTotPros, setLastMeasureTotPros] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [speedometerVal, setSpeedometerVal] = useState(1);
  const showTable = (totalProspectsAvailable || loading) && totalProspectsAvailable !== "0";
  useEffect(() => {
    let isChanged = false;
    if (!showTable || loading) {
      return;
    }
    isChanged = totalProspectsAvailable !== lastMeasureTotPros;
    setLastMeasureTotPros(totalProspectsAvailable);
    if (totalProspectsAvailable > 150000000) {
      setSpeedometerVal(1);
    } else if (totalProspectsAvailable >= 12000) {
      let normalizedValue = (totalProspectsAvailable - 12000) / (150000000 - 12000);
      // math pow so that it doesnt get close to green unless very close to number needed
      // const newSpeedVal=
      setSpeedometerVal(0.3 + Math.pow(normalizedValue, 0.3) * 0.7);
    } else if (totalProspectsAvailable >= 1000) {
      // Scale between 1,000 and 12,000
      setSpeedometerVal(0.1 + (totalProspectsAvailable - 1000) * (0.2 / (12000 - 1000)));
    } else {
      // Scale between 0 and 1,000
      setSpeedometerVal((totalProspectsAvailable || 1) * (0.1 / 1000));
    }
    if (isChanged) {
      setAnimate(true);
      setTimeout(() => {
        setAnimate(false);
      }, 200);
    }
  }, [totalProspectsAvailable]);

  // const rowSelection = {
  //   selectedRowKeys: selectProspects[curProspPages] || [],
  //   onChange: onSelectChange,
  // };
  const [selectedAmountCnt, setSelectedAmountCnt] = useState(0);
  const [rowSelection, setRowSelection] = useState({ selectedRowKeys: [], onChange: onSelectChange });

  useEffect(() => {
    setRowSelection({ selectedRowKeys: selectProspects[curProspPages] || [], onChange: onSelectChange });
    setSelectedAmountCnt(Object.values(selectProspects).reduce((total, keys) => total + (keys?.length || 0), 0));
  }, [selectProspects, curProspPages]);

  // const rowSelection = {
  //   selectProspects,
  //   onChange: onSelectChange,
  // };
  // console.log(rowSelection,'rowSelection');
  const showUpgrade = () => {
    setOpenActivateModal(true);
  };
  const showModal = () => {
    setOpenModal(true);
  };

  const onCancel = () => {
    setOpenModal(false);
    setOpenActivateModal(false);
  };

  // TODO update data and add renders for new columns + url image

  const columns: ColumnsType<App.ProspectEntity> = [
    {
      title: "Contact",
      dataIndex: "firstName",
      fixed: "left",
      key: "firstName",
      width: "150px",
      render: (text, record) => (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <span style={{ fontWeight: 600 }}> {record.contactFullName} </span>
          {record.contactTitle ? (
            <>
              <br /> {record.contactTitle}
            </>
          ) : (
            ``
          )}
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "company",
      fixed: "left",
      width: "150px",
      key: "company",
      render: (text, record) => (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          {record.companyName ? (
            <div>
              {" "}
              <span style={{ fontWeight: 600 }}> {record.companyName} </span>
            </div>
          ) : (
            ``
          )}
        </div>
      ),
    },
    {
      title: "Email",
      // width: "auto",
      dataIndex: "contactEmail",
      // render: (text,record) => (value ? <SvgIcon type={"checked"} /> : <SvgIcon type={"minus"} />),
      render: (text, record) => (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          {record.contactEmail ? (
            <>
              {" "}
              <span style={{ fontWeight: 600 }}>B: </span> {record.contactEmail}
            </>
          ) : (
            ``
          )}
          {record.personalEmail ? (
            <>
              <br /> <span style={{ fontWeight: 600 }}>P: </span>
              {record.personalEmail}
            </>
          ) : (
            ``
          )}
        </div>
      ),
      width: "200px",
      key: "contactEmail",
    },
    {
      title: "Phone Numbers Available",
      dataIndex: "phoneNumbers",
      render: (text, record) => <PhonesCell phonesData={[record?.hasCompanyPhone, record?.hasDirectDial, record?.hasContactPhone]} />,

      width: "150px",
      key: "phoneNumbers",
    },
    {
      title: "Seniority",
      // width: "auto",
      dataIndex: "contactTitleLevel",

      width: "150px",
      key: "contactTitleLevel",
    },
    {
      title: "Contacts Location",
      // width: "auto",
      dataIndex: "contactLocation",

      width: "150px",
      key: "contactLocation",
    },
    // {
    //   title: "LinkedIn",
    //   // width: "auto",
    //   dataIndex: "linkedin",
    //   render: value => (value ? <SvgIcon type={"checked"} /> : <SvgIcon type={"minus"} />),

    //   width:'150px',
    //   key: "linkedin",
    // },
    // {
    //   title: "Twitter",
    //   // width: "auto",
    //   dataIndex: "twitter",
    //   render: value => (value ? <SvgIcon type={"checked"} /> : <SvgIcon type={"minus"} />),

    //   width:'150px',
    //   key: "twitter",
    // },
    // {
    //   title: "Industry",
    //   dataIndex: "industry",

    //   // width:'150px',
    //   key: "industry",
    // },
    // {
    //   title: "Company's Location",
    //   className: "wrapped-header-cell",
    //   dataIndex: "location",

    //   width:'150px',
    //   key: "location",
    // },
    // {
    //   title: "Company Headcount",
    //   className: "wrapped-header-cell",
    //   dataIndex: "headcount",
    //   render: value => getHeadcountRange(value),

    //   width:'150px',
    //   key: "headcount",
    // },
    // {
    //   title: "Company Revenue",
    //   className: "wrapped-header-cell",
    //   dataIndex: "revenue",
    //   render: value => getRevenueRange(value),

    //   width:'150px',
    //   key: "revenue",
    // },
  ];

  const leftTableHeaderContent = (
    <Row style={{ gap: 16 }}>
      <Typography.Paragraph className={"explore-table-title"}>Prospects</Typography.Paragraph>
      {/* {isFilterSelected && (
        <Button className={"link"} onClick={selectProspects.length > 0 ? handleDeselectAll : handleSelectAll}>
          {selectProspects.length > 0 ? "Deselect all" : "Select all"}
        </Button>
      )} */}
    </Row>
  );

  const rightTableHeaderContent = (
    <Row style={{ gap: 20 }}>
      {!prospOnCurPage?.length && !selectProspects?.length ? (
        <Tooltip title={"Apply filters to save prospects found."} placement={"bottomRight"} rootClassName={"tooltip"} color={"#ECFBFC"}>
          {" "}
          <Row align={"middle"} style={{ gap: 8 }}>
            <SvgIcon type={"save"} />
            <Typography.Paragraph> Save List </Typography.Paragraph>
          </Row>
        </Tooltip>
      ) : (
        <Row align={"middle"} style={{ gap: 8, cursor: "pointer" }} onClick={prospect_data_credits ? showModal : showUpgrade}>
          <SvgIcon type={"save"} />
          <Typography.Paragraph> Save List </Typography.Paragraph>
        </Row>
      )}
    </Row>
  );

  const dispatch = useDispatch<AppDispatch>();
  console.log(filterFormData, "filterFormData123");
  const [isValidatedSearch, setIsValidatedSearch] = useState(false);
  useEffect(() => {
    setIsValidatedSearch((filterFormData?.information || [])?.includes("Validated Email"));
  }, [loading]);

  const bottomTableHeaderContent = (
    <Flex>
      <HeaderTagsBar
        customTotalStr={`Filtered${isValidatedSearch ? ` & Recently Validated` : ""} Prospects`}
        totalAmount={totalProspectsAvailable ? totalProspectsAvailable : 0}
        selectedAmount={selectedAmountCnt}
        {...(selectedAmountCnt !== 0 && {
          removeSelectedPros: () => {
            dispatch(resetSelectedRows());
          },
        })}
        // removeSelectedPros={selectedAmountCnt ?? rstRows}
      />

      <Speedometer value={speedometerVal} animate={animate} />
    </Flex>
  );

  const memoizedTourStepTableData = useMemo(() => tourStepTableData, [tourStepTableData]);

  const setCurrentPage = (pageNum) => {
    // dispatch(resetSelectedRows())
    dispatch(setPageNum({ isSynced: false, pageNum }));
  };

  const { sdBarCollapsed } = useSelector(fullUserSelector);

  return (
    <div className={"explore-wrapper"}>
      <Flex vertical gap={16} style={{ marginBottom: 12 }}>
        <Row justify={"space-between"}>
          {leftTableHeaderContent}
          {rightTableHeaderContent}
        </Row>
        {bottomTableHeaderContent}
      </Flex>
      <Flex vertical={showTable || isTourStep} justify={showTable || isTourStep ? "unset" : "center"} align={showTable || isTourStep ? "unset" : "center"}>
        {isTourStep ? (
          <Table
            columns={columns}
            dataSource={memoizedTourStepTableData}
            // scroll={{ x: "calc(1200px + 50%)" }}
            // scroll={{ x: "200px",y:'480px' }}

            scroll={{ x: "100%" }}
            divWidth={sdBarCollapsed ? `calc(100vw - 600px)` : "calc(100vw - 770px)"}
            rowSelection={{ ...rowSelection }}
            pageSize={25}
            totalPages={totalProspPages}
            totalAmnt={25}
            currentPage={1}
            setCurrentPage={setCurrentPage}
            keyCustom={"ruid"}
            skeletonSelection
          />
        ) : showTable ? (
          <Table
            loading={loading || !tableData?.length}
            keyCustom={"ruid"}
            columns={columns}
            dataSource={tableData}
            scroll={{ x: "100%" }}
            divWidth={sdBarCollapsed ? `calc(100vw - 600px)` : "calc(100vw - 770px)"}
            // divWidth={ "calc(200px + 60%)" }
            // divWidth={ "50vw" }
            // divWidth={ "calc(200px + 70%)" }
            // BUG https://stackoverflow.com/questions/55457311/ant-design-table-with-vertical-and-horizontal-scroll
            // scroll={{ x: "200px",y:'480px' }}
            rowSelection={{ ...rowSelection }}
            pageSize={25}
            totalAmnt={+(totalProspectsAvailable || 0)}
            totalPages={totalProspPages}
            currentPage={curProspPages}
            setCurrentPage={setCurrentPage}
            skeletonSelection
          />
        ) : (
          <EmptyFilterData noProspectsMatchFil={totalProspectsAvailable === "0"} />
        )}
        {!isTourStep && (
          <>
            <ExportProspectsModal
              open={openModal}
              onCancel={onCancel}
              totalSelectedProspects={selectProspects.length}
              setTotalSelectedProspects={setSelectedRowKeys}
              selectedRowKeys={selectProspects}
            />
            <ActivateForAccessModal open={openActivateModal} onCancel={onCancel} />
          </>
        )}
      </Flex>
    </div>
  );
};
