import { Flex } from "antd";
import { FC, useEffect, useState } from "react";
import { InfoItem } from "../InfoItem";
const moment = require("moment-timezone");

export const ContactCardContent: FC<{ data: any }> = ({ data }) => {
  const [timezone, setTimezone] = useState(null);

  useEffect(() => {
    let legitTz = null;
    // console.log(data.timezone_guessed, "data.timezone_guessed", !!moment.tz.zone(data.timezone_guessed));
    if (!!moment.tz.zone(data.timezone_guessed)) {
      legitTz = data.timezone_guessed;
    }

    setTimezone(legitTz);
  }, [data.timezone_guessed]);

  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    let interval;

    if (timezone) {
      // Update the time every second
      interval = setInterval(() => {
        const time = moment().tz(timezone).format(`[Date]: MMM-Do [Time]: h:mm:ss A`);
        setCurrentTime(time + ` ${timezone}`);
      }, 1000);
    } else {
      setCurrentTime("-");
    }

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [timezone]);

  return (
    <Flex vertical gap={16} style={{ height: 328, overflowY: "auto", width: "100%" }}>
      <InfoItem label={"Name"} value={`${data.first_name} ${data.last_name}`} />
      <InfoItem label={"Job Title"} value={data.title} />
      <InfoItem label={"Company Name"} value={data.company_name} />
      {timezone && <InfoItem label={"Their Time Now "} value={currentTime} />}
      <InfoItem label={"Upload Name"} value={data.upload_name} />
      <InfoItem label={"Email"} value={data.email} />
      {data?.mobile_phone?.length > 5 && (
        <>
          <InfoItem label={"Mobile Number"} value={data?.mobile_phone} />
        </>
      )}
      {data?.direct_phone?.length > 5 && (
        <>
          {" "}
          <InfoItem label={"Direct Number"} value={data?.direct_phone} />
        </>
      )}
      {data?.business_number?.length > 5 && (
        <>
          {" "}
          <InfoItem label={"Company Number"} value={data?.business_number} />
        </>
      )}
    </Flex>
  );
};
