/* eslint-disable import/no-unresolved */
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client";
import { ConfigProvider } from "antd";
import { createContext, memo, useContext, type FC, type PropsWithChildren } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom";
import store from "src/store";
import { version } from "../../../package.json";
import introspection from "../../graphql";
import routes from "../../pages";
import theme from "../../themes";

import AuthRoutes from "./AuthRoutes";
import { CampaignsContextProvider, MeetingLinksContextProvider, ProspectDataContextProvider, TeamMembersContextProvider } from "./context";
import { CommonContextProvider } from "./context/CommonContext";
import { DealsContextProvider } from "./context/DealsContext";
// import { CommonContextProvider } from "./context"
import ErrorBoundary from "../layout";
import "./index.less";

type ContextProps = {
  app: { version: string };
};

const app: ContextProps["app"] = { version };

const Context = createContext({ app });

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  return <Context.Provider value={{ ...props }}>{children}</Context.Provider>;
};

const useApp: () => ContextProps = () => useContext(Context);

const client = new ApolloClient({
  // link: createHttpLink({
  //   uri: "/graphql",
  //   credentials: "same-origin",
  // }),
  connectToDevTools: false,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: true,
    possibleTypes: introspection.possibleTypes,
  }),
});

// const router = createHashRouter(routes);
const router = createBrowserRouter(routes);

const App: FC = memo(() => (
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <AuthRoutes>
          <DndProvider backend={HTML5Backend}>
            <CommonContextProvider>
              <CampaignsContextProvider>
                <ProspectDataContextProvider>
                  <MeetingLinksContextProvider>
                    <TeamMembersContextProvider>
                      <DealsContextProvider>
                        <ContextProvider app={app}>
                          <ConfigProvider theme={theme}>
                            <RouterProvider router={router} />
                          </ConfigProvider>
                        </ContextProvider>
                      </DealsContextProvider>
                    </TeamMembersContextProvider>
                  </MeetingLinksContextProvider>
                </ProspectDataContextProvider>
              </CampaignsContextProvider>
            </CommonContextProvider>
          </DndProvider>
        </AuthRoutes>
      </Provider>
    </ApolloProvider>
  </ErrorBoundary>
));

export { useApp };

export default App;
