
/* eslint-disable react/jsx-fragments */
import { Avatar, Col, Form, Row, TimePicker, Typography } from "antd"
import dayjs from "dayjs"
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker"
import React, { FC, useState } from "react"
import _ from 'lodash';
import { SvgIcon } from "src/shared/icons"

interface WeekTimePlannerProps {
  formItemName?: string[]
  className?: string
  onChange?: (value: any) => void
  timeFormat?: string
  initialAvail:any
  setInitialAvail:(value:any)=>void // is custom implementation since this is a hard issue
  scheduling?:boolean
}

const daysList = [
  {
    value: "monday",
    label: "M",
  },
  {
    value: "tuesday",
    label: "T",
  },
  {
    value: "wednesday",
    label: "W",
  },
  {
    value: "thursday",
    label: "T",
  },
  {
    value: "friday",
    label: "F",
  },
  {
    value: "saturday",
    label: "S",
  },
  {
    value: "sunday",
    label: "S",
  },
]

export const WeekTimePlanner: FC<WeekTimePlannerProps> = ({
  formItemName = [],
  className,
  onChange,
  timeFormat,initialAvail,setInitialAvail,scheduling,
  ...props
}) => {

  

 

  const handleTimeChange = (value: NoUndefinedRangeValueType<dayjs.Dayjs> ,weekDay:any, index: any) => {
    if(value?.length===2){
 
      console.log(value,'valuevalues');
  // @ts-ignore
  // changed from H::MM to HH:MM in the first one
const timesToUpd = {start:value[0].format("HH:mm"),end:value[1].format("HH:mm"),}
const deepCopiedSched = _.cloneDeep(initialAvail);

 deepCopiedSched[weekDay][index]=timesToUpd
 
  setInitialAvail(deepCopiedSched) 

}
 // Update the specific time range
    // const newTimeRanges = [...timeRanges];
    // newTimeRanges[index] = {
    //   start: value[0],
    //   end: value[1]
    // };
    // setTimeRanges(newTimeRanges);
  };

//   const [selectedWeekdays, setSelectedWeekdays] = useState<{ [key: string]: any }>({
//   Monday: [],
//   Tuesday: [],
//   Wednesday: [],
//   Thursday: [],
//   Friday: [],
// });

const sortDaysByWeekday = (weekdays: { [key: string]: any }): { [key: string]: any } => {
  const sortedWeekdays: { [key: string]: any } = {};
  Object.keys(weekdays).sort((a, b) => daysList.findIndex(day => day.value === a) - daysList.findIndex(day => day.value === b))
    .forEach(key => {
      sortedWeekdays[key] = weekdays[key];
    });
  return sortedWeekdays;
};

const handleWeekdayClick = (weekdayValue: string) => {
  let updatedSelectedWeekdays: { [key: string]: any };
  if (initialAvail[weekdayValue]?.length > 0) {
    updatedSelectedWeekdays = { ...initialAvail, [weekdayValue]: [] };
  } else {

updatedSelectedWeekdays= _.cloneDeep(initialAvail);
updatedSelectedWeekdays[weekdayValue]= [{start:'9:00',end:"16:00"}] 
  
  }
  setInitialAvail(updatedSelectedWeekdays);
};

const isDaySelected = (day: string): boolean => {
  return initialAvail[day]?.length > 0;
};

const remTime = (weekDay: string) =>{
  if(initialAvail[weekDay]?.length>1){
   const deepCopiedSched = _.cloneDeep(initialAvail);
  deepCopiedSched[weekDay]?.pop()
  setInitialAvail(deepCopiedSched) 

}else{
setInitialAvail({ ...initialAvail, [weekDay]: [] });
}
}


const addTime = (weekDay: string) =>{
 const deepCopiedSched = _.cloneDeep(initialAvail);
 deepCopiedSched[weekDay]=[...deepCopiedSched[weekDay],{start:"", end:""}]
  setInitialAvail(deepCopiedSched)  

}



  return (
    <Col className={className ? `week-time-planner ${className}` : "week-time-planner"} {...props}>
      <Row justify={"space-between"} style={{ padding: "6px 0", marginBottom: 8 }}>
        {daysList.map((weekday, index) => (
          <Avatar
            key={`${index}-${weekday.value}`}
            style={{ backgroundColor: isDaySelected(weekday.value) ? "#01AEEF" : "#01AEEF4D", cursor: "pointer" }}
            onClick={() => {
              handleWeekdayClick(weekday.value)
            }}
          >
            {weekday.label}
          </Avatar>
        ))}
      </Row>
      {Object.keys(initialAvail).map((weekDay, index) => 
        {
          

          // selectedWeekdays[weekDay]?.map((timerange)=>{

          // })
       if(!initialAvail[weekDay]?.length) {return }
   return (<Row
          key={`${index}-${weekDay}`}
          justify={"space-between"}
          align={"top"}
          style={{ marginBottom: 8 }}
          wrap={false}
        >
          <Typography.Paragraph style={{ color: "#757A89", lineHeight: 1.43 ,marginTop:'3px'}}>{ weekDay?.charAt(0)?.toUpperCase() + weekDay?.slice(1)}</Typography.Paragraph>
          <div>
        
          <React.Fragment >
             <div >
           {initialAvail[weekDay]?.map((timerange: any,index)=> {
            console.log(timerange,'timerangetimerange',weekDay,index);
            return (<Form.Item
          key={index}
            rules={[{required:true,message:'Please select both start and end times.'}]}
            // preserve
            // name={[...(formItemName || []), weekDay.toLowerCase()]} BUG name throws off this logic do not reintroduce
           
            style={{ marginBottom: index!==initialAvail[weekDay]?.length-1 ? 1:7,display:'flex',alignItems:'center',justifyContent:'center' }}>
         
            <TimePicker.RangePicker
             key={index}
           
             defaultValue={[initialAvail[weekDay][index][0],initialAvail[weekDay][index][1]]}
   minuteStep={15} format={timeFormat || "HH:mm"} 
                allowClear={false} needConfirm={false} 
            onChange={(newRange) => handleTimeChange(newRange,weekDay, index)} /> 
  
          </Form.Item>  )})}
   {!!scheduling &&(<> <SvgIcon type={"plus"} style={{ margin:'auto',marginTop:'4px', cursor: "pointer" }} onClick={()=>{addTime(weekDay)}} />
   
    <SvgIcon type={"closeTag"} style={{ margin:'auto',marginTop:'4px', cursor: "pointer" }} onClick={()=>{remTime(weekDay)}}  /></>)}
        </div>
          </React.Fragment>
          </div>
        </Row>
      )})}
    </Col>
  )
}
