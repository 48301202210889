import React, { createContext, useState } from "react"
import { robinMeetingLinks } from "../../pages/team-members/roundRobinMeetingLinksData"
import { teams as initialTeams } from "../../pages/team-members/teamsData"

interface MeetingLinksContextProps {
  children: React.ReactNode
}

interface TeamTag {
  text: string
  color: string
}

interface TeamLink {
  text: string
}

interface Relationships {
  id: string
  name: string
  shouldAddGroupMembersToMeeting: boolean
  shouldAddInitiatorToMeeting: boolean
  shouldOnlyOfferTimesInitiatorIsAlsoAvailable: boolean
  shouldOnlyOfferTimesWhereAllTaggedUsersAvailable: boolean
}

interface TeamMembers {
  id: string
  firstName: string
  role: string
  email: string
  teamSchedulingGroup: string
}

interface Team {
  id: string
  name: string
  description: string
  tags: TeamTag[]
  links: TeamLink[]
  isOutlined: boolean
  relationships: Relationships[]
  members: TeamMembers[]
}

interface Tag {
  text: string
  color: string
}

interface Link {
  text: string
  href: string
}

interface RoundRobinMeetingLink {
  id: string
  name: string
  description: string
  tags: Tag[]
  links: Link[]
  isOutlined: boolean
}

export const TeamMembersContext = createContext<{
  teams: Team[]
  setTeams: React.Dispatch<React.SetStateAction<Team[]>>
  selectedTeamId: string | null
  tempTeamData: Team | null
  setSelectedTeamId: React.Dispatch<React.SetStateAction<string | null>>
  setTempTeamData: React.Dispatch<React.SetStateAction<Team | null>>
  addTeam: (newTeam: Team) => void
  editTeam: (updatedTeam: Team) => void
  deleteTeam: () => void
  addRoundRobinMeetingLink: (newLink: RoundRobinMeetingLink) => void
  editRoundRobinMeetingLink: (updatedLink: RoundRobinMeetingLink) => void
  deleteRoundRobinMeetingLink: () => void
  isEditTeamModalOpened: boolean
  openEditTeamModal: () => void
  closeEditTeamModal: () => void
  isDeleteTeamModalOpened: boolean
  openDeleteTeamModal: () => void
  closeDeleteTeamModal: () => void
  isAddTeamMembersModalOpened: boolean
  openAddTeamMembersModal: () => void
  closeAddTeamMembersModal: () => void
  isAddMembersManuallyModalOpened: boolean
  openAddMembersManuallyModal: () => void
  closeAddMembersManuallyModal: () => void
  isCreateTeamModalOpened: boolean
  openCreateTeamModal: () => void
  closeCreateTeamModal: () => void
  isDefineRelationshipsModalOpened: boolean
  openDefineRelationshipsModal: () => void
  closeDefineRelationshipsModal: () => void
  isMoveSelectedMembersModalOpened: boolean
  openMoveSelectedMembersModal: () => void
  closeMoveSelectedMembersModal: () => void
  isDeleteMemberModalOpened: boolean
  openDeleteMemberModal: () => void
  closeDeleteMemberModal: () => void

  roundRobinMeetingLinks: RoundRobinMeetingLink[]
  setRoundRobinMeetingLinks: React.Dispatch<React.SetStateAction<RoundRobinMeetingLink[]>>
  selectedRoundRobinMeetingLinkId: string | null
  setSelectedRoundRobinMeetingLinkId: React.Dispatch<React.SetStateAction<string | null>>
  isAddRoundRobinMeetingLinkDrawer: boolean
  openAddRoundRobinMeetingDrawer: () => void
  closeAddRoundRobinMeetingDrawer: () => void
  isEditRoundRobinMeetingDrawerOpened: boolean
  openEditRoundRobinMeetingDrawer: () => void
  closeEditRoundRobinMeetingDrawer: () => void
  isDeleteRoundRobinMeetingModalOpened: boolean
  openDeleteRoundRobinMeetingModal: () => void
  closeDeleteRoundRobinMeetingModal: () => void
  isRoundRobinAdditionalAvailabilityDrawerOpened: boolean
  openRoundRobinAdditionalAvailabilityModal: () => void
  closeRoundRobinAdditionalAvailabilityModal: () => void
}>({
  teams: [],
  setTeams: () => {},
  selectedTeamId: null,
  tempTeamData: null,
  setSelectedTeamId: () => {},
  setTempTeamData: () => {},
  addTeam: () => {},
  editTeam: () => {},
  deleteTeam: () => {},
  addRoundRobinMeetingLink: () => {},
  editRoundRobinMeetingLink: () => {},
  deleteRoundRobinMeetingLink: () => {},
  isEditTeamModalOpened: false,
  openEditTeamModal: () => {},
  closeEditTeamModal: () => {},
  isDeleteTeamModalOpened: false,
  openDeleteTeamModal: () => {},
  closeDeleteTeamModal: () => {},
  isAddTeamMembersModalOpened: false,
  openAddTeamMembersModal: () => {},
  closeAddTeamMembersModal: () => {},
  isAddMembersManuallyModalOpened: false,
  openAddMembersManuallyModal: () => {},
  closeAddMembersManuallyModal: () => {},
  isCreateTeamModalOpened: false,
  openCreateTeamModal: () => {},
  closeCreateTeamModal: () => {},
  isDefineRelationshipsModalOpened: false,
  openDefineRelationshipsModal: () => {},
  closeDefineRelationshipsModal: () => {},
  isMoveSelectedMembersModalOpened: false,
  openMoveSelectedMembersModal: () => {},
  closeMoveSelectedMembersModal: () => {},
  isDeleteMemberModalOpened: false,
  openDeleteMemberModal: () => {},
  closeDeleteMemberModal: () => {},

  roundRobinMeetingLinks: [],
  setRoundRobinMeetingLinks: () => {},
  selectedRoundRobinMeetingLinkId: null,
  setSelectedRoundRobinMeetingLinkId: () => {},
  isAddRoundRobinMeetingLinkDrawer: false,
  openAddRoundRobinMeetingDrawer: () => {},
  closeAddRoundRobinMeetingDrawer: () => {},
  isEditRoundRobinMeetingDrawerOpened: false,
  openEditRoundRobinMeetingDrawer: () => {},
  closeEditRoundRobinMeetingDrawer: () => {},
  isDeleteRoundRobinMeetingModalOpened: false,
  openDeleteRoundRobinMeetingModal: () => {},
  closeDeleteRoundRobinMeetingModal: () => {},
  isRoundRobinAdditionalAvailabilityDrawerOpened: false,
  openRoundRobinAdditionalAvailabilityModal: () => {},
  closeRoundRobinAdditionalAvailabilityModal: () => {},
})

export const TeamMembersContextProvider: React.FC<MeetingLinksContextProps> = ({ children }) => {
  const [teams, setTeams] = useState<Team[]>(initialTeams)
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null)
  const [isEditTeamModalOpened, setIsEditTeamModalOpened] = useState(false)
  const [isDeleteTeamModalOpened, setIsDeleteTeamModalOpened] = useState(false)
  const [isDeleteMemberModalOpened, setIsDeleteMemberModalOpened] = useState<boolean>(false)
  const [isDefineRelationshipsModalOpened, setIsDefineRelationshipsModalOpened] = useState(false)
  const [isAddTeamMembersModalOpened, setIsAddTeamMembersModalOpened] = useState(false)
  const [isAddMembersManuallyModalOpened, setIsAddMembersManuallyModalOpened] = useState(false)
  const [isCreateTeamModalOpened, setIsCreateTeamModalOpened] = useState(false)
  const [isMoveSelectedMembersModalOpened, setIsMoveSelectedMembersModalOpened] = useState(false)
  const [tempTeamData, setTempTeamData] = useState<Team | null>(null)

  const [roundRobinMeetingLinks, setRoundRobinMeetingLinks] = useState<RoundRobinMeetingLink[]>(robinMeetingLinks)
  const [selectedRoundRobinMeetingLinkId, setSelectedRoundRobinMeetingLinkId] = useState<string | null>(null)
  const [isAddRoundRobinMeetingLinkDrawer, setIsAddRoundRobinMeetingLinkDrawer] = useState(false)
  const [isEditRoundRobinMeetingDrawerOpened, setIsEditRoundRobinMeetingDrawerOpened] = useState(false)
  const [isDeleteRoundRobinMeetingModalOpened, setIsDeleteRoundRobinMeetingModalOpened] = useState(false)
  const [isRoundRobinAdditionalAvailabilityDrawerOpened, setIsRoundRobinAdditionalAvailabilityDrawerOpened] =
    useState(false)

  const addTeam = (newTeam: Team) => {
    const updatedTeams = [...teams, newTeam]
    setTeams(updatedTeams)
  }

  const editTeam = (updatedTeam: Team) => {
    const updatedTeams = teams.map(team => (team.id === selectedTeamId ? updatedTeam : team))
    setTeams(updatedTeams)
  }

  const deleteTeam = () => {
    const updatedTeams = teams.filter(team => team.id !== selectedTeamId)
    setTeams(updatedTeams)
  }

  const addRoundRobinMeetingLink = (newLink: RoundRobinMeetingLink) => {
    setRoundRobinMeetingLinks(prevLinks => [...prevLinks, newLink])
  }

  const editRoundRobinMeetingLink = (updatedLink: RoundRobinMeetingLink) => {
    setRoundRobinMeetingLinks(prevLinks =>
      prevLinks.map(link => (link.id === selectedRoundRobinMeetingLinkId ? updatedLink : link))
    )
  }

  const deleteRoundRobinMeetingLink = () => {
    setRoundRobinMeetingLinks(prevLinks => prevLinks.filter(link => link.id !== selectedRoundRobinMeetingLinkId))
    setSelectedRoundRobinMeetingLinkId(null)
  }

  const openDeleteMemberModal = () => {
    setIsDeleteMemberModalOpened(true)
  }

  const closeDeleteMemberModal = () => {
    setIsDeleteMemberModalOpened(false)
  }

  const openMoveSelectedMembersModal = () => {
    setIsMoveSelectedMembersModalOpened(true)
  }

  const closeMoveSelectedMembersModal = () => {
    setIsMoveSelectedMembersModalOpened(false)
  }

  const openDefineRelationshipsModal = () => {
    setIsDefineRelationshipsModalOpened(true)
  }

  const closeDefineRelationshipsModal = () => {
    setIsDefineRelationshipsModalOpened(false)
  }

  const openEditTeamModal = () => {
    setIsEditTeamModalOpened(true)
  }

  const closeEditTeamModal = () => {
    setIsEditTeamModalOpened(false)
  }

  const openDeleteTeamModal = () => {
    setIsDeleteTeamModalOpened(true)
  }
  const closeDeleteTeamModal = () => {
    setIsDeleteTeamModalOpened(false)
  }

  const openAddTeamMembersModal = () => {
    setIsAddTeamMembersModalOpened(true)
  }
  const closeAddTeamMembersModal = () => {
    setIsAddTeamMembersModalOpened(false)
  }

  const openAddMembersManuallyModal = () => {
    setIsAddMembersManuallyModalOpened(true)
  }
  const closeAddMembersManuallyModal = () => {
    setIsAddMembersManuallyModalOpened(false)
  }

  const openCreateTeamModal = () => {
    setIsCreateTeamModalOpened(true)
  }

  const closeCreateTeamModal = () => {
    setIsCreateTeamModalOpened(false)
  }

  const openAddRoundRobinMeetingDrawer = () => {
    setIsAddRoundRobinMeetingLinkDrawer(true)
  }

  const closeAddRoundRobinMeetingDrawer = () => {
    setIsAddRoundRobinMeetingLinkDrawer(false)
  }

  const openEditRoundRobinMeetingDrawer = () => {
    setIsEditRoundRobinMeetingDrawerOpened(true)
  }

  const closeEditRoundRobinMeetingDrawer = () => {
    setIsEditRoundRobinMeetingDrawerOpened(false)
  }

  const openDeleteRoundRobinMeetingModal = () => {
    setIsDeleteRoundRobinMeetingModalOpened(true)
  }

  const closeDeleteRoundRobinMeetingModal = () => {
    setIsDeleteRoundRobinMeetingModalOpened(false)
  }

  const openRoundRobinAdditionalAvailabilityModal = () => {
    setIsRoundRobinAdditionalAvailabilityDrawerOpened(true)
  }

  const closeRoundRobinAdditionalAvailabilityModal = () => {
    setIsRoundRobinAdditionalAvailabilityDrawerOpened(false)
  }

  const contextValue = {
    teams,
    setTeams,
    selectedTeamId,
    setSelectedTeamId,
    tempTeamData,
    setTempTeamData,
    editTeam,
    deleteTeam,
    addTeam,
    addRoundRobinMeetingLink,
    editRoundRobinMeetingLink,
    deleteRoundRobinMeetingLink,
    isEditTeamModalOpened,
    openEditTeamModal,
    closeEditTeamModal,
    isDeleteTeamModalOpened,
    openDeleteTeamModal,
    closeDeleteTeamModal,
    isAddTeamMembersModalOpened,
    openAddTeamMembersModal,
    closeAddTeamMembersModal,
    isAddMembersManuallyModalOpened,
    openAddMembersManuallyModal,
    closeAddMembersManuallyModal,
    isCreateTeamModalOpened,
    openCreateTeamModal,
    closeCreateTeamModal,
    isDefineRelationshipsModalOpened,
    openDefineRelationshipsModal,
    closeDefineRelationshipsModal,
    isMoveSelectedMembersModalOpened,
    openMoveSelectedMembersModal,
    closeMoveSelectedMembersModal,
    isDeleteMemberModalOpened,
    openDeleteMemberModal,
    closeDeleteMemberModal,

    roundRobinMeetingLinks,
    setRoundRobinMeetingLinks,
    selectedRoundRobinMeetingLinkId,
    setSelectedRoundRobinMeetingLinkId,
    isAddRoundRobinMeetingLinkDrawer,
    openAddRoundRobinMeetingDrawer,
    closeAddRoundRobinMeetingDrawer,
    isEditRoundRobinMeetingDrawerOpened,
    openEditRoundRobinMeetingDrawer,
    closeEditRoundRobinMeetingDrawer,
    isDeleteRoundRobinMeetingModalOpened,
    openDeleteRoundRobinMeetingModal,
    closeDeleteRoundRobinMeetingModal,
    isRoundRobinAdditionalAvailabilityDrawerOpened,
    openRoundRobinAdditionalAvailabilityModal,
    closeRoundRobinAdditionalAvailabilityModal,
  }

  return <TeamMembersContext.Provider value={contextValue}>{children}</TeamMembersContext.Provider>
}
