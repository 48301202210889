import { Flex, FlexProps, Row, Typography } from "antd"
import { FC, PropsWithChildren, ReactNode } from "react"
import "./index.less"

interface DashboardCardProps extends FlexProps {
  title?: string
  subtitle?: string
  customClassName?: string
  headerRightContent?: ReactNode
}

export const DashboardCard: FC<PropsWithChildren<DashboardCardProps>> = ({
  children,
  title,
  subtitle,
  customClassName,
  headerRightContent,
  ...props
}) => {
  return (
    <Flex className={`card-wrapper ${customClassName}`} vertical {...props}>
      <Row className={"title-row"} justify={"space-between"} wrap={false}>
        <Typography.Title level={4}>{title}</Typography.Title>
        {headerRightContent && headerRightContent}
      </Row>
      {subtitle && <Typography.Paragraph className={"dashboard-card-subtitle"}>{subtitle}</Typography.Paragraph>}
      {children}
    </Flex>
  )
}
