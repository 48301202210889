import { Form, Input } from "antd"
import React, { FC } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"

type CreateTaskModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const CreateTaskModal: FC<CreateTaskModalProps> = ({ open, onCancel }) => {
  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    console.log("Created")
    onCancel()
  }

  return (
    <Modal width={530} open={open} title={"New Task"} onCancel={closeModal}>
      <Form.Item name={"description"} label={"Description"}>
        <Input placeholder={"Enter text"} />
      </Form.Item>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Create"}
        onCancel={closeModal}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  )
}
