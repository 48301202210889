import { Row, Space, SpaceProps, Typography } from "antd";
import React, { FC, useState } from "react";
import { SvgIcon } from "src/shared/icons";
import { SMTPConnectionModal } from "./SMTPConnectionModal";

interface SignInWithButtonsProps extends SpaceProps {
  title: string;
  onGoogleClick?: VoidFunction;
  smtp?: boolean;
  onMicrosoftClick?: VoidFunction;
  openSMTPSetUp?: any;
  setOpenSMTPSetUp?: any;
}

export const SignInWithButtons: FC<SignInWithButtonsProps> = ({ title, onGoogleClick, onMicrosoftClick, smtp, openSMTPSetUp, setOpenSMTPSetUp }) => {
  return (
    <Space direction={"vertical"}>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Row align={"middle"} onClick={onGoogleClick} style={{ gap: 16, cursor: "pointer" }}>
        <SvgIcon type={"google"} />
        <Typography.Paragraph>Sign in with Google</Typography.Paragraph>
      </Row>
      <Row align={"middle"} onClick={onMicrosoftClick} style={{ gap: 16, cursor: "pointer" }}>
        <SvgIcon type={"microsoft"} />
        <Typography.Paragraph>Sign in with Microsoft</Typography.Paragraph>
      </Row>
      {!!smtp && (
        <Row align={"middle"} onClick={() => setOpenSMTPSetUp(true)} style={{ marginLeft: "3px", marginTop: "3px", gap: 16, cursor: "pointer" }}>
          <SvgIcon type={"largeEmail"} />
          <Typography.Paragraph style={{ marginLeft: "3px" }}>Sign in with SMTP/IMAP</Typography.Paragraph>
        </Row>
      )}
      <SMTPConnectionModal open={!!openSMTPSetUp} informationOfEditing={openSMTPSetUp} onCancel={() => setOpenSMTPSetUp(false)} />
    </Space>
  );
};
