/* eslint-disable react/jsx-fragments */
import { Button, Card, CardProps, Col, Divider, MenuProps, Row, Typography } from "antd";
import React, { FC, forwardRef } from "react";
import { getTagStyles } from "../../../helpers";
import { DotsMenuDropdown } from "../DotsMenuDropdown";
import { CardTag } from "./ui/CardTag";
import "./index.less";

interface Tag {
  text: string;
  color: string;
}

interface Link {
  text: string;
  href?: string;
}

interface CustomCardProps extends CardProps {
  id: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  dropdownMenuItems: MenuProps["items"];
  width?: string | number;
  tags?: Tag[];
  links?: Link[];
  isOutlined?: boolean;
  footerContent?: React.ReactNode;
  onDropdownClick?: () => any;
  onLinkClick?: (href: string) => any;
  ref?: any;
}

export const CustomCard: FC<CustomCardProps> = forwardRef<HTMLDivElement, CustomCardProps>(
  ({ id, title, description, width, tags, links, isOutlined, footerContent, dropdownMenuItems, onDropdownClick, onLinkClick, children, ...props }, ref) => {
    return (
      <Card
        ref={ref}
        title={`Meeting Title: ${title}`}
        extra={<DotsMenuDropdown menuItems={dropdownMenuItems} onDropdownClick={onDropdownClick} />}
        className={"custom-card"}
        style={{ width: width ? width : "100%", border: isOutlined ? "1px solid #01AEEF" : "1px solid #FFFFFF" }}
        {...props}
      >
        <Typography.Paragraph className={"card-menu-title"}> {!description ? "" : <>Meeting Description: {description || ""}</>}</Typography.Paragraph>
        {children}
        <Divider className={"horizontal-divider"} />
        <Row justify={"space-between"}>
          {!footerContent && (
            <>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row justify={"start"}>
                  {tags &&
                    tags.map((tag, index) => (
                      <CardTag key={index} value={tag.text} color={getTagStyles(tag.color).color} backgroundColor={getTagStyles(tag.color).backgroundColor} />
                    ))}
                </Row>
              </Col>
              <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Row justify={"end"} align={"middle"} style={{ height: "100%" }}>
                  {links &&
                    links.map((link, index) => (
                      <React.Fragment key={index}>
                        <Button className={"link card-link"} onClick={() => onLinkClick && onLinkClick(link.href || id)}>
                          {link.text}
                        </Button>
                        {index !== links.length - 1 && <Divider type={"vertical"} className={"vertical-divider"} />}
                      </React.Fragment>
                    ))}
                </Row>
              </Col>
            </>
          )}
          {footerContent && footerContent}
        </Row>
      </Card>
    );
  }
);
