import { App } from "../../../types";
import { StatisticItem } from "../../ui/StatisticsBar";

export const phoneCallsData: App.PhoneCallsEntity[] = [
  {
    id: "1",
    firstName: "Aron",
    lastName: "Adams",
    lastTimeContacted: "2 Days Ago",
    contents: "3 Min",
    company: {
      id: "1",
      name: "Summer Sunshine",
      step: 1,
    },
    totalTimesCalled: 3,
    totalIncomingCalls: 3,
    status: ["Phone calls incoming"],
  },
  {
    id: "2",
    firstName: "Olivia",
    lastName: "Johnson",
    lastTimeContacted: "2 Days Ago",
    contents: "20 Min",
    company: {
      id: "1",
      name: "Summer Sunshine",
      step: 2,
    },
    totalTimesCalled: 3,
    totalIncomingCalls: 3,
    status: ["Phone calls incoming"],
  },
  {
    id: "3",
    firstName: "Liam",
    lastName: "Williams",
    lastTimeContacted: "2 Days Ago",
    contents:
      "Hi, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest? Warm regards, Joan",
    company: {
      id: "1",
      name: "Summer Sunshine",
      step: 2,
    },
    totalTimesCalled: 3,
    totalIncomingCalls: 3,
    status: ["SMS Sent"],
  },
  {
    id: "4",
    firstName: "Liam",
    lastName: "Ok",
    lastTimeContacted: "1 Week Ago",
    contents: "20 Min",
    company: {
      id: "1",
      name: "Summer Sunshine",
      step: 2,
    },
    totalTimesCalled: 3,
    totalIncomingCalls: 3,
    status: ["Phone calls incoming"],
  },
  {
    id: "5",
    firstName: "Olga",
    lastName: "Filatova",
    lastTimeContacted: "2 Days Ago",
    contents: "Not connected",
    company: {
      id: "1",
      name: "Summer Sunshine",
      step: 2,
    },
    totalTimesCalled: 3,
    totalIncomingCalls: 3,
    status: ["Calls attempted", "Voicemail"],
  },
  {
    id: "6",
    firstName: "Liam",
    lastName: "Williams",
    lastTimeContacted: "2 Days Ago",
    contents:
      "Hi, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest? Warm regards, Joan",
    company: {
      id: "1",
      name: "Summer Sunshine",
      step: 2,
    },
    totalTimesCalled: 3,
    totalIncomingCalls: 3,
    status: ["Sms Incoming"],
  },
];

export const phonesAvailable = ["(585) 689-7689", "(585) 689-7688", "(585) 689-7687", "(585) 689-7686", "(585) 689-7685", "(585) 689-7684", "(585) 689-7683"];

export const addressesAvailable = [
  "New York, NY, USA",
  "New York State, USA",
  "New York-New York Hotel & Casino, South Las Vegas",
  "Boulevard, Las Vegas, NV, USA",
  "Kyiv, Ukraine",
  "Obukhiv, Kyiv region, Ukraine",
  "New Deli, India",
];

export const phoneCallsStatisticData: StatisticItem[] = [
  { id: 1, title: "Total", value: 31 },
  { id: 2, title: "Calls attempted", value: 1 },
  { id: 3, title: "Phone calls incoming", value: 23 },
  { id: 4, title: "SMS Sent", value: 3 },
  { id: 5, title: "SMS Incoming", value: 4 },
];
