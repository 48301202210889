import { Flex, Image, Row, Typography } from "antd"
import { Link } from "react-router-dom"
import React, { FC } from "react"
import FailureImg from "../../../../../shared/images/failure.webp"
import { SvgIcon } from "../../../../../shared/icons"
import { ConfirmButtons, Modal } from "../../../../ui"
import { useBreakpoints } from "../../../../../helpers/useBreakpoints"
import "./index.less"

interface PaymentIssueModalProps {
  contactSupport: () => void
  checkPaymentDetails: () => void
  onClose: () => void
}

export const PaymentIssueModal: FC<PaymentIssueModalProps> = ({ contactSupport, checkPaymentDetails, onClose }) => {
  const { isDesktop } = useBreakpoints()

  return isDesktop ? (
    <Modal rootClassName={"payment-issue-modal"} open={true} onCancel={onClose}>
      <Flex vertical={true} align={"center"}>
        <SvgIcon type={"cancel"} />
        <Typography.Title className={"title"} level={2}>
          Your payment can't be completed
        </Typography.Title>
        <Typography.Paragraph className={"description"}>
          Please review your payment details and try again, or contact Support.
        </Typography.Paragraph>
        <ConfirmButtons
          className={"confirm-buttons"}
          leftButtonTitle={"Contact Support"}
          rightButtonTitle={"Check Payment Details "}
          onCancel={contactSupport}
          handleSubmit={checkPaymentDetails}
        />
      </Flex>
    </Modal>
  ) : (
    <Flex className={"payment-issue-page-wrapper"} vertical={true}>
      <Row className={"mobile-gradient1"} />
      <Row className={"mobile-gradient2"} />
      <Flex className={"box"} vertical={true} style={{ padding: "32px 18px" }}>
        <Flex vertical={true} justify={"center"} align={"center"} className={"result-wrapper"} style={{ position: "static", transform: "unset" }}>
          <Image
            src={FailureImg}
            alt={"Failure"}
            preview={false}
            width={280}
            height={206}
            className={"result-img"}
          />
          <Typography.Title level={2} className={"result-title"}>Your payment can't be completed</Typography.Title>
          <Typography.Paragraph className={"result-description"}>
            Please review your payment details and try again, or contact Support.
          </Typography.Paragraph>
          <ConfirmButtons
            leftButtonTitle={"Contact Support "}
            rightButtonTitle={"Check Payment Details"}
            className={"confirm-buttons"}
            onCancel={contactSupport}
            handleSubmit={checkPaymentDetails}
          />
        </Flex>
      </Flex>
      <Link to={"/"} className={"meetz-link"}>Meetz.ai</Link>
    </Flex>
  )
}
