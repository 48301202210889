import { CSSProperties, FC } from "react";
import { StatusTag } from "src/components/ui";

export const EmailsStatus: FC<{ status: string; key?: string; count?: number; style?: CSSProperties }> = ({ status, count, ...props }) => {
  switch (status) {
    case "SENT": // change to sent
      return <StatusTag color={"purple"} value={"Sent"} {...props} />;
    // case "PENDING":
    //   return <StatusTag color={"magenta"} value={"Pending"} {...props} />
    case "LIKELY FORWARDED":
      return <StatusTag color={"purple"} value={"Likely Forwarded"} {...props} />;
    // return <StatusTag color={"gold"} value={"Bounced"} {...props} />;
    case "OPENED":
      return <StatusTag color={"processing"} value={"Opened"} count={count} {...props} />;
    case "REPLIED":
      return <StatusTag color={"success"} value={"Replied"} count={count} {...props} />;
    case "CLICKED":
      return <StatusTag color={"cyan"} value={"Clicked"} count={count} {...props} />;
    // default:
    case "BOOKED":
      return <StatusTag color={"gold"} value={"Booked Meetings"} count={count} {...props} />;
  }
};
