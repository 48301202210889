import { Segmented } from "antd";
import { FC } from "react";
import "./index.less";

interface TabsSwitch {
  firstTab: string;
  secondTab: string;
  thirdTab?: string;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  isMediumSize?: boolean;
  className?: string;
}

export const TabsSwitch: FC<TabsSwitch> = ({ activeTab, setActiveTab, firstTab, secondTab, thirdTab, isMediumSize = false, className, ...props }) => {
  const options = [firstTab, secondTab, thirdTab].filter((tab) => tab);
  // console.log(options, "options", activeTab);
  return (
    <Segmented
      className={className ? (isMediumSize ? `medium-switcher ${className}` : className) : ""}
      defaultValue={options?.[0]}
      onChange={(value) => setActiveTab(value)}
      // options={thirdTab ? [firstTab, secondTab, thirdTab] : [firstTab, secondTab]}
      options={options}
      // style={{ fontSize: 12 }}
      {...props}
    />
  );
};
