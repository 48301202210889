import { Checkbox, Flex, Typography } from "antd";
import { FC } from "react";
import { CustomLabel, CustomSelect, Drawer } from "src/components/ui";
import "./index.less";

interface ProspectsFilterProps {
  onClose: () => void;
  uplNameOptions: string[];
  uploadNameToSearch: string;
  recentlyUpdatedToSearch: string;
  includeInCampaignToSearch: string;
  includeContactedAlreadyToSearch: string;
  addProsToCamp: boolean;
  setUploadNameToSearch: () => void;
  setRecentlyUpdatedToSearch: () => void;
  setIncludeInCampaignToSearch: () => void;
  setIncludeContactedAlreadyToSearch: () => void;
  submitFilters: () => void;
}

const { Text } = Typography;

export const ProspectsFilter: FC<ProspectsFilterProps> = ({
  onClose,
  uplNameOptions,
  uploadNameToSearch,
  setUploadNameToSearch,
  setRecentlyUpdatedToSearch,
  setIncludeInCampaignToSearch,
  setIncludeContactedAlreadyToSearch,
  recentlyUpdatedToSearch,
  includeInCampaignToSearch,
  includeContactedAlreadyToSearch,
  submitFilters,
  addProsToCamp,
}) => {
  return (
    <Drawer
      open={true}
      title={"Filter Uploaded Prospects"}
      leftButtonTitle={"Close"}
      rightButtonTitle={"Search"}
      onBtnPrimaryClick={submitFilters}
      // rightButtonDis={!selectedMeetingTool}
      onBtnSecondaryClick={onClose}
      onCancel={onClose}
      className={"filter-drawer"}
    >
      <div className={"filter-drawer-inside"}>
        <Flex vertical gap={8}>
          <Typography.Paragraph className={"label"}>Upload name</Typography.Paragraph>

          <CustomSelect options={uplNameOptions} value={uploadNameToSearch} onChange={setUploadNameToSearch} />
        </Flex>
        <br />
        <br />
        <Flex vertical gap={8}>
          <CustomLabel title={"Additional Filters"} />
          <Checkbox checked={recentlyUpdatedToSearch} onChange={setRecentlyUpdatedToSearch}>
            Keep only most recently uploaded prospects
          </Checkbox>
        </Flex>
        <br />
        <Flex vertical gap={8}>
          <Checkbox checked={includeInCampaignToSearch} onChange={setIncludeInCampaignToSearch}>
            Also include prospects that are now in a campaign
          </Checkbox>
        </Flex>
        <br />
        <Flex vertical gap={8}>
          <Checkbox checked={includeContactedAlreadyToSearch} onChange={setIncludeContactedAlreadyToSearch}>
            Also include prospects that you emailed/called
          </Checkbox>
        </Flex>
        <br />
        <Text className={"content-footnote"}>
          *Prospect's that unsusbcribe or are marked as "Do Not Call" by you are automatically not included in the results
          {!!addProsToCamp && ". Also any prospect in this campaign will be excluded automatically."}
        </Text>
      </div>
    </Drawer>
  );
};
