import { Flex, Typography } from "antd";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmButtons, Loader } from "src/components/ui";
import { overviewChart } from "src/shared/images";
import "./index.less";

export const LockedOverviewCard: FC<{ campCount: "loading" | number }> = ({ campCount }) => {
  const navigate = useNavigate();
  return (
    // <div className={"blur-mask"}>
    <Flex className={"locked-overview-card"} gap={14}>
      {/* <div className={"blur-mask"}> */}
      <img src={overviewChart} alt={"overview chart"} width={298} />
      {/* </div> */}
      <Flex vertical gap={8} style={{ padding: "20px 0" }}>
        {campCount !== 0 ? (
          <>
            {" "}
            <Typography.Paragraph className={"locked-title"}>Loading </Typography.Paragraph>
            <Typography.Paragraph className={"locked-subtitle"} style={{ filter: "none !important" }}>
              Getting campaign analytics
            </Typography.Paragraph>
            <br />
            <Loader />
          </>
        ) : (
          <>
            {" "}
            <Typography.Paragraph className={"locked-title"}>No campaigns created yet </Typography.Paragraph>
            <Typography.Paragraph className={"locked-subtitle"} style={{ filter: "none !important" }}>
              Create and activate a campaign to start seeing analytics here about the performance.
            </Typography.Paragraph>
          </>
        )}
        {/* <ConfirmButtons
          rightButtonTitle={"Update Plan"}
          leftButtonTitle={"Activate trial"}
          // onCancel={() => setIsDashboardLocked(false)}
          // handleSubmit={() => navigate("/settings")} these are nice buttons but most likely no need for them
        /> */}
      </Flex>
    </Flex>
    // </div>
  );
};
