import { generateRandomDigits } from "../../../helpers"

export const robinMeetingLinks: any[] = [
  {
    id: "1",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [
      {
        text: "30 min",
        color: "Green",
      },
    ],
    links: [
      {
        text: "Teams Link",
        href: "#",
      },
      {
        text: "Your Team Link",
        href: "#",
      },
    ],
    reminders: {},
    meetingLength: "30 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isOutlined: false,
    teamMembersConnectedToLink: [
      {
        id: generateRandomDigits(10),
        name: "Anna",
      },
    ],
  },
  {
    id: "2",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [
      {
        text: "35 min",
        color: "Green",
      },
    ],
    links: [
      {
        text: "Teams Link",
        href: "#",
      },
      {
        text: "Your Team Link",
        href: "#",
      },
    ],
    reminders: {},
    meetingLength: "35 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isOutlined: false,
    teamMembersConnectedToLink: [
      {
        id: generateRandomDigits(10),
        name: "Anna",
      },
    ],
  },
  {
    id: "3",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [
      {
        text: "20 min",
        color: "Green",
      },
    ],
    links: [
      {
        text: "Teams Link",
        href: "#",
      },
      {
        text: "Your Team Link",
        href: "#",
      },
    ],
    reminders: {},
    meetingLength: "20 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isOutlined: false,
    teamMembersConnectedToLink: [
      {
        id: generateRandomDigits(10),
        name: "Anna",
      },
    ],
  },
  {
    id: "4",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [
      {
        text: "10 min",
        color: "Green",
      },
    ],
    links: [
      {
        text: "Teams Link",
        href: "#",
      },
      {
        text: "Your Team Link",
        href: "#",
      },
    ],
    reminders: {},
    meetingLength: "10 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isOutlined: false,
    teamMembersConnectedToLink: [
      {
        id: generateRandomDigits(10),
        name: "Anna",
      },
    ],
  },
  {
    id: "5",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [
      {
        text: "40 min",
        color: "Green",
      },
    ],
    links: [
      {
        text: "Teams Link",
        href: "#",
      },
      {
        text: "Your Team Link",
        href: "#",
      },
    ],
    reminders: {},
    meetingLength: "40 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isOutlined: false,
    teamMembersConnectedToLink: [
      {
        id: generateRandomDigits(10),
        name: "Anna",
      },
    ],
  },
  {
    id: "6",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [
      {
        text: "60 min",
        color: "Green",
      },
    ],
    links: [
      {
        text: "Teams Link",
        href: "#",
      },
      {
        text: "Your Team Link",
        href: "#",
      },
    ],
    reminders: {},
    meetingLength: "60 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isOutlined: false,
    teamMembersConnectedToLink: [
      {
        id: generateRandomDigits(10),
        name: "Anna",
      },
    ],
  },
]
