import { Drawer as AntDrawer, DrawerProps, Tabs, TabsProps } from "antd"
import React, { FC, PropsWithChildren } from "react"
import { SvgIcon } from "src/shared/icons"
import { ConfirmButtons } from "../ConfirmButtons"
import "./index.less"

interface CustomDrawerProps extends DrawerProps {
  leftButtonTitle: string
  rightButtonTitle: string
  // leftButtonDis?: boolean
  defaultActiveKey?: string
  rightButtonDis?: boolean
  tabItems?: TabsProps["items"]
  onBtnPrimaryClick?: () => void
  onBtnSecondaryClick?: () => void
  onCancel: () => void
  customClassName?: string
}

export const Drawer: FC<PropsWithChildren<CustomDrawerProps>> = ({
  leftButtonTitle,
  rightButtonTitle,
  tabItems,
  onBtnPrimaryClick,
  onBtnSecondaryClick,
  onCancel,
  customClassName,
  rightButtonDis = false,
  defaultActiveKey='1',
  ...props
}) => {
  const footer = (
    <ConfirmButtons
      leftButtonTitle={leftButtonTitle}
      rightButtonTitle={rightButtonTitle}
      handleSubmit={onBtnPrimaryClick}
      onCancel={onBtnSecondaryClick}
      disabled={rightButtonDis}
      // onCancel={props.onClose as VoidFunction}
    />
  )

  const drawerSettings = {
    styles: {
      mask: { backgroundColor: "#00000073" },
    },
    width: "476px",
    maskClosable: true,
    title: props.title,
    closeIcon: <SvgIcon type={"close"} />,
    forceRender: true,
    className: `custom-drawer-wrapper ${customClassName}`,
    footer: footer,
    onClose: onCancel,
  }
  const mergedProps = { ...drawerSettings, ...props }

  return (
    <AntDrawer {...mergedProps}>
      {tabItems ? <Tabs defaultActiveKey={defaultActiveKey} items={tabItems} /> : null}
      {props.children}
    </AntDrawer>
  )
}
