import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./components/app";
import "core-js/stable";
import "regenerator-runtime/runtime";
// import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-N4JJMC4", // Replace with your GTM ID
};

TagManager.initialize(tagManagerArgs);

const rootElement = document.querySelector("#root") as HTMLElement;
// ReactGA.initialize("AW-630038230");
// removed strict mode
createRoot(rootElement).render(
  <>
    <App />
  </>
);
