/* eslint-disable react/jsx-fragments */
import { Checkbox, Col, Form, FormInstance, FormItemProps, Row } from "antd";
import React, { FC } from "react";
import { FilterTagsList } from "src/components/ui/FilterTagsList";

interface CheckboxGroupFilterItem extends FormItemProps {
  className?: string;
  data: string[];
  form: FormInstance;
  fieldName: string;
  checkboxList: string[] | { value: string; label: string }[];
}
export const CheckboxGroupFilterItem: FC<CheckboxGroupFilterItem> = ({ className, data, form, fieldName, checkboxList }) => {
  return (
    <>
      <Form.Item className={className} name={fieldName}>
        <Checkbox.Group style={{ width: "100%" }}>
          <Row gutter={[12, 12]}>
            {checkboxList.map((checkbox, index) => (
              <Col key={`${index}-${checkbox}`} span={className ? 16 : 12}>
                <Checkbox disabled={(checkbox.value || checkbox) === "Business Email Address"} value={checkbox?.value || checkbox}>
                  {checkbox?.label || checkbox}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <FilterTagsList data={data} form={form} fieldToUpdate={fieldName} />
    </>
  );
};
