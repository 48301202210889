import { Skeleton, SkeletonProps, Table } from "antd"
import SkeletonAvatar from "antd/es/skeleton/Avatar"
import { ColumnsType } from "antd/lib/table"
import { ReactElement } from "react"

export type SkeletonTableColumnsType = {
  key: string
  title?: string
  dataIndex?: string
  className?: string
  render?: (text: string, record: any, index: number) => ReactElement
}

type SkeletonTableProps = SkeletonProps & {
  columns: ColumnsType<SkeletonTableColumnsType>
  rowCount?: number
  rowSelection?: boolean
}

export default function SkeletonTable({
  loading = false,
  active = true,
  columns,
  rowCount = 10,
  children,
  rowSelection,
}: SkeletonTableProps): ReactElement {
  return loading ? (
    <Table
      className={"custom-table"}
      rowKey={"key"}
      pagination={false}
      rowSelection={
        rowSelection
          ? {
              renderCell: () => <SkeletonAvatar active={active} size={20} shape={"circle"} />,
            }
          : undefined
      }
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map(column => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return <Skeleton key={column.key} title active={active} paragraph={false} className={"skeleton"} />
          },
        }
      })}
    />
  ) : (
    <>{children}</>
  )
}
