import { Typography, message } from "antd"
import React, { FC } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"

type DeleteSavedFilterModalProps = {
  open: boolean
  onCancel: VoidFunction
  setSavedFilterData: React.Dispatch<any>
  itemId: string
}

const info = () => {
  message.success("Filter Deleted!")
}

export const DeleteSavedFilterModal: FC<DeleteSavedFilterModalProps> = ({
  open,
  onCancel,
  setSavedFilterData,
  itemId,
}) => {
  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    setSavedFilterData((prevData: any) => prevData.filter((item: any) => item.id !== itemId))
    info()
    onCancel()
  }

  return (
    <Modal width={530} open={open} title={"Are you sure you want to delete this saved filters?"} onCancel={closeModal}>
      <Typography.Paragraph className={"empty-data"}>
        This action will permanently remove all associated data, and the process cannot be undone.
      </Typography.Paragraph>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Delete"}
        onCancel={closeModal}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  )
}
