import { FC } from "react"
import { getStepSequenceIconType } from "src/helpers"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

export const StepSequenceIcon: FC<{ mode: string }> = ({ mode }) => {
  return (
    <div className={"steps-icon-wrapper"}>
      <SvgIcon type={getStepSequenceIconType(mode)} />
    </div>
  )
}
