import { Button, Table } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC } from "react"
import { Modal, StatusTag } from "src/components/ui"
import { statusInfo } from "../../campaignsData"
import { statusColors } from "../StepsSequence"

type TagStatusInfoProps = {
  open: boolean
  onCancel: VoidFunction
  status?: string
}

export const TagStatusInfoModal: FC<TagStatusInfoProps> = ({ open, onCancel, status }) => {
  const closeModal = () => {
    onCancel()
  }

  const selectedStatusData = status && statusInfo[0][status]

  const columns: ColumnsType<any> = [
    {
      title: "Prospect",
      dataIndex: "prospect",
      key: "prospect",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: value =>
        status !== "finished" ? (
          <StatusTag value={value} color={status && statusColors[status]} />
        ) : (
          <Button className={"link"}>{value}</Button>
        ),
      key: "status",
    },
  ]

  return (
    <Modal
      customClassName={"table-modal"}
      width={"723px"}
      open={open}
      title={`${status && status.charAt(0).toUpperCase() + status.substring(1)}: ${selectedStatusData.length}`}
      onCancel={closeModal}
    >
      <Table rowKey={"id"} columns={columns} dataSource={selectedStatusData} pagination={false} />
    </Modal>
  )
}
