/* eslint-disable react/jsx-fragments */
import React, { useContext, useEffect, useRef, useState } from "react"
import { Button, Flex, Layout, Row, Tour, TourProps, Typography } from "antd"
import { ConfirmButtons } from "../../../ui"
import { CommonContext } from "../../../app/context/CommonContext"
import { DashboardLayout } from "../../../pages/dashboard/DashboardLayout"
import { SideBarMenu } from "../index"
import { HowToUseCard } from "../HowToUseCard"
import { DefaultHeader } from "../../DefaultHeader"
import { CreateCampaign } from "../../../pages/campaigns"
import ProspectData from "../../../../pages/prospect-data"
import DealsPage from "../../../../pages/deals"
import Emails from "../../../../pages/emails-data"
import MeetingLinksPage from "../../../../pages/meeting-links"
import "./index.less"

const { Sider, Header } = Layout

export const WelcomeTour = () => {
  const { currentStep, setCurrentStep, setIsTourOpened } = useContext(CommonContext)
  const [collapsed, setCollapsed] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] = useState("")

  const sidebarRef = useRef(null);
  const prospectDataFiltersRef = useRef(null)
  const createCampaignRef = useRef(null)
  const meetingLinksCardRef = useRef(null)
  const meetingLinksAddLinkRef = useRef(null)
  const meetingLinksAdditionalAvailabilityRef = useRef(null)

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setSelectedMenuItem("dashboard");
        break;
      case 2:
        setSelectedMenuItem("deals");
        break;
      case 3:
        setSelectedMenuItem("prospectData");
        break;
      case 4:
        setSelectedMenuItem("campaigns");
        break;
      case 5 :
        setSelectedMenuItem("emails");
        break;
      case 6 :
        setSelectedMenuItem("meetingLinks");
        break;
      case 7 :
        setSelectedMenuItem("meetingLinks");
        break;
      case 8 :
        setSelectedMenuItem("meetingLinks");
        break;
      default:
        setSelectedMenuItem("");
        break;
    }
  }, [currentStep]);

  const handlePrevStepClick = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleNextStepClick = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleCloseTour = () => {
    setIsTourOpened(false)
  }

  const steps: TourProps['steps'] = [
    {
      title: false,
      description: (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"step-title"}>Dashboard</Typography.Paragraph>
            <Typography.Paragraph className={"step-counter"}>1 of 8</Typography.Paragraph>
          </Flex>
          <Typography.Paragraph className={"step-description"}>This tab is where you'll find the analytics for all of your outreach efforts.</Typography.Paragraph>
          <Flex justify={"space-between"} align={"center"} className={"step-footer"}>
            <Button className={"link"} onClick={handleCloseTour}>Dismiss</Button>
            <ConfirmButtons className={"confirm-buttons"} rightButtonTitle={"Next"} handleSubmit={handleNextStepClick} />
          </Flex>
        </>
      ),
      arrow: false,
      closeIcon: false,
      placement: "right",
      target: () => sidebarRef.current,
    },
    {
      title: false,
      description: (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"step-title"}>Deals</Typography.Paragraph>
            <Typography.Paragraph className={"step-counter"}>2 of 8</Typography.Paragraph>
          </Flex>
          <Typography.Paragraph className={"step-description"}>Track your deal flow and manage associated tasks in this tab.</Typography.Paragraph>
          <Flex justify={"space-between"} align={"center"} className={"step-footer"}>
            <Button className={"link"} onClick={handleCloseTour}>Dismiss</Button>
            <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Previous"} rightButtonTitle={"Next"} handleSubmit={handleNextStepClick} onCancel={handlePrevStepClick} />
          </Flex>
        </>
      ),
      arrow: false,
      closeIcon: false,
      placement: "right",
      target: () => sidebarRef.current,
    },
    {
      title: false,
      description: (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"step-title"}>Prospects Filtering</Typography.Paragraph>
            <Typography.Paragraph className={"step-counter"}>3 of 8</Typography.Paragraph>
          </Flex>
          <Typography.Paragraph className={"step-description"}>Use filters to refine your prospect list based on specific criteria and preferences.</Typography.Paragraph>
          <Flex justify={"space-between"} align={"center"} className={"step-footer"}>
            <Button className={"link"} onClick={handleCloseTour}>Dismiss</Button>
            <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Previous"} rightButtonTitle={"Next"} handleSubmit={handleNextStepClick} onCancel={handlePrevStepClick} />
          </Flex>
        </>
      ),
      arrow: false,
      closeIcon: false,
      placement: "right",
      target: () => prospectDataFiltersRef.current,
    },
    {
      title: false,
      description: (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"step-title"}>Create campaigns</Typography.Paragraph>
            <Typography.Paragraph className={"step-counter"}>4 of 8</Typography.Paragraph>
          </Flex>
          <Typography.Paragraph className={"step-description"}>Initiate Meetz outreach campaigns to engage with potential leads. Customize your campaign steps, identify leads, create compelling messages, and take action to generate new leads and grow your business.</Typography.Paragraph>
          <Flex justify={"space-between"} align={"center"} className={"step-footer"}>
            <Button className={"link"} onClick={handleCloseTour}>Dismiss</Button>
            <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Previous"} rightButtonTitle={"Next"} handleSubmit={handleNextStepClick} onCancel={handlePrevStepClick} />
          </Flex>
        </>
      ),
      arrow: false,
      closeIcon: false,
      placement: "bottom",
      target: () => createCampaignRef.current,
    },
    {
      title: false,
      description: (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"step-title"}>Emails and Phone calls</Typography.Paragraph>
            <Typography.Paragraph className={"step-counter"}>5 of 8</Typography.Paragraph>
          </Flex>
          <Typography.Paragraph className={"step-description"}>These two tabs are to check all of your phone or email analytics across all of your campaigns.</Typography.Paragraph>
          <Flex justify={"space-between"} align={"center"} className={"step-footer"}>
            <Button className={"link"} onClick={handleCloseTour}>Dismiss</Button>
            <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Previous"} rightButtonTitle={"Next"} handleSubmit={handleNextStepClick} onCancel={handlePrevStepClick} />
          </Flex>
        </>
      ),
      arrow: false,
      closeIcon: false,
      placement: "right",
      target: () => sidebarRef.current,
    },
    {
      title: false,
      description: (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"step-title"}>AI Scheduler Overview</Typography.Paragraph>
            <Typography.Paragraph className={"step-counter"}>6 of 8</Typography.Paragraph>
          </Flex>
          <Typography.Paragraph className={"step-description"}>AI Scheduler automates and optimizes meeting scheduling by analyzing availability and preferences, streamlining the process for more efficient and convenient appointments.</Typography.Paragraph>
          <Flex justify={"space-between"} align={"center"} className={"step-footer"}>
            <Button className={"link"} onClick={handleCloseTour}>Dismiss</Button>
            <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Previous"} rightButtonTitle={"Next"} handleSubmit={handleNextStepClick} onCancel={handlePrevStepClick} />
          </Flex>
        </>
      ),
      arrow: false,
      closeIcon: false,
      placement: "right",
      target: () => meetingLinksCardRef.current,
    },
    {
      title: false,
      description: (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"step-title"}>Add Additional Links</Typography.Paragraph>
            <Typography.Paragraph className={"step-counter"}>7 of 8</Typography.Paragraph>
          </Flex>
          <Typography.Paragraph className={"step-description"}>Using this button, you can add more meeting links.</Typography.Paragraph>
          <Flex justify={"space-between"} align={"center"} className={"step-footer"}>
            <Button className={"link"} onClick={handleCloseTour}>Dismiss</Button>
            <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Previous"} rightButtonTitle={"Next"} handleSubmit={handleNextStepClick} onCancel={handlePrevStepClick} />
          </Flex>
        </>
      ),
      arrow: false,
      closeIcon: false,
      placement: "left",
      target: () => meetingLinksAddLinkRef.current,
    },
    {
      title: false,
      description: (
        <>
          <Flex justify={"space-between"} align={"center"}>
            <Typography.Paragraph className={"step-title"}>Connect and Integrate Platforms</Typography.Paragraph>
            <Typography.Paragraph className={"step-counter"}>8 of 8</Typography.Paragraph>
          </Flex>
          <Typography.Paragraph className={"step-description"}>This feature allows you to seamlessly connect and integrate your preferred platforms, enabling smooth communication and collaboration during meetings.</Typography.Paragraph>
          <Flex justify={"space-between"} align={"center"} className={"step-footer"}>
            <Button className={"link"} onClick={handleCloseTour}>Dismiss</Button>
            <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Previous"} rightButtonTitle={"Finish"} handleSubmit={handleCloseTour} onCancel={handlePrevStepClick} />
          </Flex>
        </>
      ),
      arrow: false,
      closeIcon: false,
      placement: "left",
      target: () => meetingLinksAdditionalAvailabilityRef.current,
    },
  ];

  return (
    <Row className={`tour-page-wrapper step-${currentStep}`}>
      <Layout hasSider style={{ position: "relative", minHeight: "100vh" }}>
        <Sider
          ref={sidebarRef}
          collapsible
          className={"authenticated-slider guid-sider"}
          collapsed={collapsed}
          onCollapse={value => setCollapsed(value)}
          width={280}
          style={{ padding: collapsed ? "28px 14px" : "37px 28px", height: "auto", borderRight: "1px solid #EDEFF3" }}
        >
          <SideBarMenu selectedItem={selectedMenuItem} isLogoLetter={collapsed} />
          <HowToUseCard isCollapsed={collapsed} />
        </Sider>
        <Layout.Content style={{ width: "100%", padding: "0 auto" }}>
          <Header style={{ height: 80 }}>
            <DefaultHeader />
          </Header>
          {currentStep === 1 ? (
            <DashboardLayout isTourStep={true} />
          ) : currentStep === 2 ? (
            <DealsPage isTourStep={true} />
          ) : currentStep === 3 ? (
            <ProspectData ref={prospectDataFiltersRef} isTourStep={true} />
          ) : currentStep === 4 ? (
            <CreateCampaign isTourStep={true} createCampaignRef={createCampaignRef} />
          ) : currentStep === 5 ? (
            <Emails isTourStep={true} />
          ) : currentStep === 6 || currentStep === 7 || currentStep === 8 ? (
            <MeetingLinksPage
              isTourStep={true}
              meetingLinksCardRef={meetingLinksCardRef}
              meetingLinksAddLinkRef={meetingLinksAddLinkRef}
              meetingLinksAdditionalAvailabilityRef={meetingLinksAdditionalAvailabilityRef}
            />
          ) : (
            <></>
          )}
        </Layout.Content>
      </Layout>
      <Tour
        open={true}
        current={currentStep - 1}
        steps={steps}
        mask={{color: "rgba(237, 239, 243, 0.60)"}}
        disabledInteraction={true}
        onClose={handleCloseTour}
      />
    </Row>
  )
}