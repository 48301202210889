import { Button, Divider, Row, Typography } from "antd";
import React, { FC } from "react";
import { ConfirmButtons } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";

interface ProspectFilterHeaderProps {
  openSaveModal: VoidFunction;
  openFromSavedModal: VoidFunction;
  totalTagsLength: any;
}

export const ProspectFilterHeader: FC<ProspectFilterHeaderProps> = ({
  openSaveModal,
  openFromSavedModal,
  totalTagsLength,
  filterFormData,
  handleClearAll,
  getProspects,
  loading,
}) => {
  return (
    <Row className={"prospect-filter-header"} justify={"space-between"}>
      <Row align={"middle"} style={{ gap: 8 }}>
        <SvgIcon type={"filter"} />
        <Typography.Paragraph className={"filter-header-title"}>Filters</Typography.Paragraph>
      </Row>
      <Row align={"middle"}>
        {/* {totalTagsLength !== 0 && (
          <>
            <Button className={"link"} onClick={openSaveModal}>
              Save
            </Button>
            <Divider type={"vertical"} />
          </>
        )} */}
        {/* <Button className={"link"} onClick={openFromSavedModal}>
          Apply From Saved
        </Button> */}
        <ConfirmButtons
          disabled={!totalTagsLength || JSON.stringify(filterFormData) === JSON.stringify({ information: ["Exclude My Saved Contacts"] })}
          rightButtonTitle={"Search"}
          // leftButtonTitle={"Reset all"}
          onCancel={handleClearAll}
          noEnterKeyboardSubmit
          handleSubmit={getProspects}
          loading={loading}
        />
      </Row>
      {/* <Row align={"middle"}>
        {totalTagsLength !== 0 && (
          <>
            <Button className={"link"} onClick={openSaveModal}>
              Save
            </Button>
            <Divider type={"vertical"} />
          </>
        )}
        <Button className={"link"} onClick={openFromSavedModal}>
          Apply From Saved
        </Button>
      </Row> 
      TODOF */}
      {/* TODOF PROB ADD IN THE ALL SELECTED FJILTERS add ai recommendation = use value propostion component and give 3 suggestions with explanation why each onei s good give ai all filters except for location and other useless filers */}
    </Row>
  );
};
