import React, { useContext } from "react";
import { Button, Flex, List, MenuProps, Typography } from "antd";
import { DealsContext } from "../../../../app/context/DealsContext";
import { DealCard } from "../DealCard";
import { useDrop } from 'react-dnd';
import { DotsMenuDropdown } from "../../../../ui"
import "./index.less"

export const ItemTypes = {
  CARD: 'card',
};

export const Group = ({ group }: any) => {
  const { setSelectedGroupId, openEditColumnModal, openDeleteColumnModal, openAddDealModal, dealsGroups, setDealsGroups } = useContext(DealsContext);

  const calculateTotalPrice = (deals: any[]) => {
    return deals.reduce((total: number, deal: any) => total + deal.price, 0);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item: any) => {
      const { id: draggedId, index: draggedIndex, groupId: sourceGroupId } = item;
      const { index: hoverIndex, groupId: targetGroupId } = { index: group.index, groupId: group.id };

      if (sourceGroupId === targetGroupId) {
        const newDeals = Array.from(dealsGroups.find(group => group.id === sourceGroupId)?.deals || []);
        const [draggedItem] = newDeals.splice(draggedIndex, 1);
        newDeals.splice(hoverIndex, 0, draggedItem);

        setDealsGroups(dealsGroups.map(group => group.id === sourceGroupId ? { ...group, deals: newDeals, totalPrice: calculateTotalPrice(newDeals) } : group));
      } else {
        const sourceGroupIndex = dealsGroups.findIndex(group => group.id === sourceGroupId);
        const targetGroupIndex = dealsGroups.findIndex(group => group.id === targetGroupId);

        const sourceGroup = dealsGroups[sourceGroupIndex];
        const targetGroup = dealsGroups[targetGroupIndex];

        const newSourceDeals = Array.from(sourceGroup.deals);
        const newTargetDeals = Array.from(targetGroup.deals);

        const [draggedItem] = newSourceDeals.splice(draggedIndex, 1);
        newTargetDeals.splice(hoverIndex, 0, draggedItem);

        const updatedSourceGroup = { ...sourceGroup, deals: newSourceDeals, totalPrice: calculateTotalPrice(newSourceDeals) };
        const updatedTargetGroup = { ...targetGroup, deals: newTargetDeals, totalPrice: calculateTotalPrice(newTargetDeals) };

        const updatedGroups = [...dealsGroups];
        updatedGroups[sourceGroupIndex] = updatedSourceGroup;
        updatedGroups[targetGroupIndex] = updatedTargetGroup;

        setDealsGroups(updatedGroups);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleAddDealClick = () => {
    setSelectedGroupId(group.id);
    openAddDealModal();
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      onClick: openEditColumnModal,
    },
    {
      key: "2",
      label: "Delete",
      onClick: openDeleteColumnModal,
    },
  ];

  return (
    <div ref={drop}>
      <Flex vertical={true} className={"group"} key={group?.id}>
        <Flex className={"group-header"} justify={"space-between"}>
          <Flex vertical={true} gap={8}>
            <Typography.Title level={2} className={"group-header-title"}>{group?.name} ({group?.deals.length})</Typography.Title>
            <Typography.Paragraph className={"group-header-total-price"}>${group?.totalPrice}</Typography.Paragraph>
          </Flex>
          <DotsMenuDropdown menuItems={items} onDropdownClick={() => setSelectedGroupId(group?.id)} />
        </Flex>
        <List
          dataSource={group?.deals || []}
          renderItem={(item: any, index: number) => (
            <List.Item key={item?.id}>
              <DealCard deal={item} index={index} groupId={group.id} />
            </List.Item>
          )}
          className={"deals-list"}
        />
        <Button className={"link"} onClick={handleAddDealClick}>Add Deal</Button>
      </Flex>
    </div>
  );
};