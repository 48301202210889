/* eslint-disable react/jsx-fragments */
import { Typography } from "antd"
import React, { FC } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"

type DeleteStepModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const DeleteStepModal: FC<DeleteStepModalProps> = ({ open, onCancel, isphone,step,handleDelete }) => {
  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    handleDelete(step)
    onCancel()
  }
  

  return (
    <Modal width={530} open={!!open}  title={"Are you sure you want to delete this step?"}  onCancel={closeModal}>


        {isphone ?<> 
        <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginBottom: 26 }}>
  *Make sure that if you delete this phone step you adjust the email templates after it if they talk about "I tried calling you earlier today".
        </Typography.Paragraph></>
        :''}
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Delete"}
        onCancel={closeModal}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  )
}
