/* eslint-disable no-unused-vars */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiHandler } from '../helpers/apiHandler';
 /*
export const checkIfProspIsGenerating = createAsyncThunk('prospectdata/checkIfProspIsGenerating', async (body,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,

    url: '/prospectdata/checkIfProspIsGenerating',
    method: 'post',
    body,
  });
  return res;
});

export const updateProsCredits = createAsyncThunk('prospectdata/doNotSell', async (body,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/prospectdata/updateProsCredits',
    method: 'post',
    body,
  });
  return res;
});

export const doNotSell = createAsyncThunk('prospectdata/doNotSell', async (body,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/prospectdata/doNotSell',
    method: 'post',
    body,
  });

  return res;
});


export const searchForAvailProsp = createAsyncThunk('prospectdata/searchForAvailProsp', async (body,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,
    url: '/prospectdata/searchProspIfAvail',
    method: 'post',
    body,
  });

  return res;
});
*/

export const renameFileName = createAsyncThunk('prospectdata/renameFileName', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,

    url: '/prospectdata/renameFileName',
    method: 'post',
    body,
  });

  return res;
});

export const eraseProsDataFile = createAsyncThunk('prospectdata/eraseProsDataFile', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,

    url: '/prospectdata/eraseProsDataFile',
    method: 'post',
    body,
  });

  return res;
});

export const downloadProspects = createAsyncThunk('prospectdata/downloadProspects', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  const res = await apiHandler({
    iframeJwt,

    url: '/prospectdata/extractOrBuyProspectsById',
    method: 'post',
    body,
    downloadFileName: `${body?.extract?.name}.xlsx`,
  });

  return res;
});

export const getProsFiles = createAsyncThunk('prospectdata/getProsFiles', async (body,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  // console.log(body, 'selectProspects');
  const res = await apiHandler({
    iframeJwt,
    url: '/prospectdata/getProsFiles',
    method: 'get',
  });

  return res;
});
export const exportProspects = createAsyncThunk('prospectdata/exportProspects', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  // console.log(body, 'selectProspects');
  const res = await apiHandler({
    iframeJwt,
    url: '/prospectdata/exportProspects',
    method: 'post',
    body,
  });

  return res;
});

export const getFilterProspects = createAsyncThunk('prospectdata/getFilterProspects', async (body:any,  { getState }:{getState:any}) => {
  const { iframeJwt } = getState().helpers;
  // console.log(body, 'selectProspects');
  const res = await apiHandler({
    iframeJwt,
    url: '/prospectdata/getFilterProspects',
    method: 'post',
    body,
  });

  return res;
});

// export const searchProsp = createAsyncThunk('prospectdata/search', async (body,  { getState }:{getState:any}) => {
//   const { iframeJwt } = getState().helpers;
//   const res = await apiHandler({
//     iframeJwt,
//     url: '/prospectdata/search',
//     method: 'post',
//     body,
//   });

//   return res;
// });
