import { Mark, mergeAttributes } from '@tiptap/core';
import { Tooltip } from 'antd';
import React from 'react';

const LinkWithTooltip = Mark.create({
  name: 'link',

  addOptions() {
    return {
      openOnClick: true,
      HTMLAttributes: {
        class: 'link',
      },
    };
  },

  inclusive: false,

  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: '_blank',
      },
    };
  },
// TOTOC later add when hovering over link option to se that link in the text - except for unsub link
  parseHTML() {
    return [
      { tag: 'a[href]' },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['a', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  renderText({ node, mark, HTMLAttributes }) {
    return (
      <Tooltip title={mark.attrs.href}>
        <a href={mark.attrs.href} target={mark.attrs.target} rel="noopener noreferrer" {...HTMLAttributes}>
          {node.text}
        </a>
      </Tooltip>
    );
  },

  addCommands() {
    return {
      setLink: attributes => ({ commands }) => {
        return commands.setMark(this.name, attributes);
      },
      toggleLink: attributes => ({ commands }) => {
        return commands.toggleMark(this.name, attributes);
      },
      unsetLink: () => ({ commands }) => {
        return commands.unsetMark(this.name);
      },
    };
  },
});

export default LinkWithTooltip;
