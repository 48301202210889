/* eslint-disable react/jsx-fragments */
import { Button, Typography } from "antd";
import { FC, useState } from "react";

import { SvgIcon } from "src/shared/icons";
import "./index.less";
import { ConnectSpotifyModal } from "../ConnectSpotifyModal";
import { userSelector } from "src/store/user";
import { useSelector } from "react-redux";

export const ConnectWithSpotifyButton: FC<{ handleClick?: (e?: any) => void; integrate?: boolean }> = ({ handleClick, integrate }) => {
  const [isSpotifyModalOpen, setIsSpotifyModalOpen] = useState(false);
  const { spotify } = useSelector(userSelector);
  const handleMenuClick = (e: any) => {
    e.stopPropagation();
  };
  const showSpotifyModal = (e) => {
    e.stopPropagation();
    setIsSpotifyModalOpen(true);
  };
  const handleSpotifyModalClose = (e) => {
    e.stopPropagation();
    setIsSpotifyModalOpen(false);
  };

  return (
    <>
      <Button
        type={"link"}
        className={"connect-btn"}
        onClick={(e) => {
          integrate ? handleClick && handleClick() : setIsSpotifyModalOpen(true);
        }}
      >
        <Typography.Text>
          {!integrate && spotify?.access_token ? "You are connected to" : integrate && spotify?.access_token ? "Connect to a different" : "Connect your"}
        </Typography.Text>
        <SvgIcon type={"spotifyLong"} />
        {isSpotifyModalOpen && <ConnectSpotifyModal open={isSpotifyModalOpen} onCancel={handleSpotifyModalClose} />}
      </Button>
    </>
  );
};
