import { FC } from "react"
import { StatusTag } from "src/components/ui"
import { App } from "src/types/index"

export const PhoneCallsStatus: FC<{ status: App.PhoneCallsStatus }> = ({ status }) => {
  switch (status) {
    case "Phone calls incoming":
      return <StatusTag color={"cyan"} value={status} />
    case "Calls attempted":
      return <StatusTag color={"red"} value={status} />
    case "SMS Sent":
      return <StatusTag color={"green"} value={status} />
    case "Sms Incoming":
      return <StatusTag color={"purple"} value={status} />
    case "Voicemail":
      return <StatusTag color={"geekblue"} value={status} />
    default:
      return <StatusTag color={"success"} value={status} />
  }
}