import { FC } from "react"
import { Flex, Typography } from "antd"
import { ConfirmButtons, Modal } from "../../../../../../ui"
import { SvgIcon } from "../../../../../../../shared/icons"
import "./index.less"

interface ChangeStepModalProps {
  onSubmit: () => void
  onCancel: () => void
  onClose: () => void
}

export const ChangeStepModal: FC<ChangeStepModalProps> = ({ onSubmit, onCancel, onClose }) => {
  return (
    <Modal
      open={true}
      rootClassName={"change-step-modal-root"}
      closeIcon={false}
      onCancel={onClose}
    >
      <Flex vertical={true} align={"center"}>
        <SvgIcon type={"alarm"} width={24} height={24} />
        <Typography.Title level={2} className={"title"}>Changing steps in sequence will reset analytics</Typography.Title>
        <Typography.Paragraph className={"description"}>Are you sure you want to submit these changes? Please note that submitting changes will reset analytics associated with the steps you've modified.</Typography.Paragraph>
      </Flex>
      <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Cancel"} rightButtonTitle={"Submit Changes"} handleSubmit={onSubmit} onCancel={onCancel} />
    </Modal>
  )
}