import React, { FC, useEffect, useState } from "react";
import { Input, Button } from "antd";
import { EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import MenuBar from "./MenuBar";
import "./index.less";
import { ConfirmButtons } from "../ConfirmButtons";
import { Modal } from "../Modal";
import LinkWithTooltip from "./LinkWithToolTip";
import BlueVariables from "./BlueVariables";
// import { ConfirmButtons } from '../ConfirmButtons';
// import { Modal } from '../Modal';

export const TextEditor: FC<{
  content: any;
  isMenuBar?: boolean;
  unsubSentence?: boolean;
  isMultiLined?: boolean;
  placeholderText?: string;
  onContentChange: (content: any) => void;
  readOnly?: boolean;
  isMaxHeight: any;
}> = ({
  content, // only if this changes will what shows up in the text box change. so either handle it or leave it alone if no need. (***For highlighting values it does have to be handled)
  isMenuBar = true,
  isMultiLined = true,
  readOnly = false,
  unsubSentence,
  placeholderText = "",
  onContentChange,
  isMaxHeight = false,
}) => {
  const extensions = [
    Underline,
    TextStyle,
    ListItem,
    // BlueVariables(/{{\s*var:\s*.*?;\s*alt:\s*.*?;\s*nat:\s*.*?;}}/g), TODOC later fix not critical
    Paragraph,
    Text,
    TextAlign.configure({
      types: ["heading", "paragraph"],
    }),
    LinkWithTooltip.configure({
      openOnClick: false,
      autolink: false,
    }),
    Link.configure({
      openOnClick: false,
      autolink: false,
    }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false,
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false,
      },
    }),
    Placeholder.configure({
      // not working for some reason ( try fix later)
      placeholder: placeholderText,
    }),
  ];

  if (unsubSentence) {
    isMultiLined = false;
  }
  const variableRegex = /{{\s*var:\s*.*?;\s*alt:\s*.*?;\s*nat:\s*.*?;}}/g;

  const parseString = (inputString) => {
    return inputString?.replace(variableRegex, (match) => {
      // return `<span class="highlightVariable">${match}</span>`;
      return `<b>${match}</b>`;
    });
  };

  const removeStyling = (inputString) => {
    return inputString?.replace(/<span class="highlightVariable">|<\/span>/g, "");
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditable, setIsEditable] = useState(!readOnly);
  const editor = useEditor({
    extensions,
    content: parseString(content),
    onUpdate: ({ editor, transaction }) => {
      const html = editor.getHTML();

      onContentChange && onContentChange(removeStyling(html));
    },
    editable: isEditable,
  });
  // Update editor content programmatically when content prop changes
  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(removeStyling(content), false);
      // editor.commands.setContent(removeStyling(content), true);
    }
  }, [content, editor]);
  // Toggle editable state
  useEffect(() => {
    if (editor) {
      setIsEditable(!readOnly);
      editor.setOptions({ editable: !readOnly });
    }
  }, [readOnly, editor]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (editor) {
      editor.chain().focus().extendMarkRange("link").setLink({ href: linkUrl }).run();
    }
    setIsModalVisible(false);
    setLinkUrl("");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setLinkUrl("");
  };

  return (
    <div>
      <EditorContent
        editor={editor}
        className={`${isMaxHeight ? "verylong" : isMultiLined ? "long" : ""} ${readOnly ? "readonly" : ""}`}
        style={{ cursor: readOnly ? "default" : "auto" }}
      />
      {!readOnly && isMenuBar && <MenuBar editor={editor} unsubSentence={unsubSentence} />}
    </div>
  );
};
