import { Dayjs } from "dayjs"
import React, { createContext, useEffect, useState } from "react"
import { meetingLinksData } from "../../pages/meeting-links/meetingLinksData"
import { useDispatch, useSelector } from "react-redux"
import { reactiveLinksSelector } from "src/store/reactive-links-settings"
import { AppDispatch } from "src/store"
import { removeLink } from "src/store/reactive-links-settings/asyncRequests"

interface MeetingLinksContextProps {
  children: React.ReactNode
}

interface Tag {
  text: string
  color: string
  type: string
}

interface Link {
  text: string
  href: string
}

interface Reminder {
  time: Dayjs
  text: string
}

interface Availability {
  [day: string]: string[]
}

interface MeetingCardType {
  id: string
  name: any
  description: string
  tags: Tag[]
  links: Link[]
  reminders: { [key: string]: Reminder }
  meetingLength: string
  timezone: string
  availability: { [key: string]: Availability }
  maximumDaysToScheduleForward: string
  minimumTimeToBookInAdvance: string
  bufferTimeBetweenMeetings: string
  isAiGenerated: boolean
}

export const MeetingLinksContext = createContext<{
  // meetingLinks: MeetingCardType[]
  meetingLinks: any[]
  setMeetingLinks: React.Dispatch<React.SetStateAction<MeetingCardType[]>>
  selectedMeetingLinkId: string | null
  setSelectedMeetingLinkId: React.Dispatch<React.SetStateAction<string | null>>
  deleteMeetingLink: () => void
  editMeetingLink: (updatedLink: MeetingCardType) => void
  addMeetingLink: (newLink: MeetingCardType) => void
}>({
  meetingLinks: [],
  setMeetingLinks: () => {},
  selectedMeetingLinkId: null,
  setSelectedMeetingLinkId: () => {},
  deleteMeetingLink: () => {},
  editMeetingLink: () => {},
  addMeetingLink: () => {},
})

export const MeetingLinksContextProvider: React.FC<MeetingLinksContextProps> = ({ children }) => {
  // const [meetingLinks, setMeetingLinks] = useState(meetingLinksData)
  const [meetingLinks, setMeetingLinks] = useState<any>([])
  const [loadedFirstTme, setLoadedFirstTme] = useState<any>(false)
  const {schedulingFormated} = useSelector(reactiveLinksSelector)

  useEffect(() => {
    if( schedulingFormated?.length){
  // setLoadedFirstTme(true)
    setMeetingLinks(schedulingFormated)}
  }, [schedulingFormated]);

  const [selectedMeetingLinkId, setSelectedMeetingLinkId] = useState<string | null>(null)

  const addMeetingLink = (newLink: MeetingCardType) => {
    setMeetingLinks(prevLinks => [...prevLinks, newLink])
  }

  const editMeetingLink = (updatedLink: MeetingCardType) => {
    setMeetingLinks(prevLinks => prevLinks.map(link => (link.id === selectedMeetingLinkId ? updatedLink : link)))
  }
const dispatch = useDispatch<AppDispatch>()
  const deleteMeetingLink = () => {
dispatch(removeLink({linkId:selectedMeetingLinkId}))
    setMeetingLinks(prevLinks => prevLinks.filter(link => link.id !== selectedMeetingLinkId))
    setSelectedMeetingLinkId(null)
  }

  const contextValue = {
    meetingLinks,
    setMeetingLinks,
    selectedMeetingLinkId,
    setSelectedMeetingLinkId,
    deleteMeetingLink,
    editMeetingLink,
    addMeetingLink,
  }

  return <MeetingLinksContext.Provider value={contextValue}>{children}</MeetingLinksContext.Provider>
}
