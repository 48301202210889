import React, { FC, useState } from "react";
import { Col, Row } from "antd";
import { ActionsForTodayCard, StepsSequence } from "../../../ui";
import { allActionsData, sequenceDataWithStatus } from "../../../campaignsData";
import { App } from "src/types";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { useSelector } from "react-redux";

export const OverviewTab: FC = ({
  frmtdSeq,
  payingClient, // this is also trial counts
}) => {
  const [mode, setMode] = useState<string>("");
  const filteredActions: { [key: string]: { title: string } } = {};

  for (const item of allActionsData) {
    if (item && item.title) {
      filteredActions[item.title] = item;
    }
  }

  const actions = [
    { key: "manual", data: filteredActions["Emails"] },
    { key: "ai", data: filteredActions["Emails"] },
    { key: "task", data: filteredActions["Tasks"] },
    { key: "sms", data: filteredActions["SMS"] },
    { key: "phone", data: filteredActions["Phone Call"] },
  ];

  console.log(frmtdSeq, "frmtdSeq");

  const { loadingCampLookingAt } = useSelector(outreachCampaignSelector);

  const action = actions?.find((item) => item?.key === mode)?.data as App.AllAction;
  return (
    <Row gutter={[24, 0]}>
      <Col span={12}>
        <StepsSequence
          data={frmtdSeq}
          // setMode={setMode} removed for now.
          loading={loadingCampLookingAt}
          finalSeq
          payingClient={payingClient}
        />
      </Col>
      <Col span={12}>
        {!loadingCampLookingAt && frmtdSeq && (
          <ActionsForTodayCard
            loading={loadingCampLookingAt}
            //  data={mode && action ? [action] : allActionsData}
            frmtdSeq={frmtdSeq}
            payingClient={payingClient}
          />
        )}
      </Col>
    </Row>
  );
};
