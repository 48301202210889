/* eslint-disable react/jsx-fragments */
import { Flex, Radio, Row, Typography, message } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, Modal, DotsMenuDropdown } from "src/components/ui"
import { SaveValueModal } from "../SaveValueModal"
import { MenuProps } from "antd/lib"
import { valuePropUpd } from "src/store/outreach-campaigns/asyncRequests"
import { useDispatch } from "react-redux"

type SelectFromSavedModalProps = {
  open: boolean
  onCancel: VoidFunction
  setSelectedValProp: Function
  data: any
}



export const SelectFromSavedModal: FC<SelectFromSavedModalProps> = ({data, open, onCancel ,setSelectedValProp}) => {
  const [openSaveValueModal, setOpenSaveValueModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState<any>()

  const showSaveValueModal=(indexToEdit)=> {
    setSelectedValue(indexToEdit)
    // console.log(indexToEdit,'indexToEdit');
    setOpenSaveValueModal(true)
  }
  const dispatch = useDispatch()

  const deleteItem=(indexToDel)=> {
          message.info(`Deleting value proposition...`,20)
        
        dispatch(valuePropUpd({action:'delete',indexToEdit:indexToDel}))
        setSelectedValue(null)
  }

  const items = (index) => [
    {
      key: "1",
      label: "Edit",
      onClick: ()=> showSaveValueModal(index),
      },
      {
        key: "2",
        label: "Delete",
        onClick: () => deleteItem(index),
    }
    // {
    //   key: "3",
    //   label: "Duplicate",
    // },
  ]  
  const closeModal = () => {
    onCancel()
  }



  const handleCloseSaveValueModal = () => {
    setOpenSaveValueModal(false)
  }

  const handleFormSubmit = () => {
    console.log(selectedValue,'selcva');
    if(data?.length >selectedValue){
      setSelectedValProp('')
      setTimeout(() => {
        // timeout to reset state if the same one is selected
        setSelectedValProp(data[selectedValue]?.value_prop)
      }, 200);
    }
    onCancel()
  }

  const handleRadioBtnChange = (index) => {
    setSelectedValue(index)
  }

  return (
  <>
  <Modal width={530} open={open} title={"Select From Saved Value Proposition"} onCancel={closeModal}>
   {data?.length ?  <Radio.Group
      name={"value"}
      value={selectedValue}
      // onChange={handleRadioBtnChange}
      style={{ width: "100%"}}

    >
      <Flex vertical gap={16} style={{marginBottom:28}}>
        {data.map((item: any,i)=> (
          <Flex key={`${item.title||''}${i}`} vertical gap={8} >
            <Row justify={"space-between"} align={"middle"} wrap={false}>
          <Radio value={i} onClick={()=>handleRadioBtnChange(i)} key={`${item.title||''}${i}`}>
                <Typography.Paragraph className={"value-name"}>{item.title}</Typography.Paragraph>
          </Radio>
              <DotsMenuDropdown menuItems={items(i)}/>
            </Row>
          <Typography.Paragraph ellipsis style={{maxWidth:435, marginLeft: 24, color: "#757A89"}}>{item?.value_prop}</Typography.Paragraph>
         </Flex>
        ))}
      </Flex>
    </Radio.Group>:<div style={{marginBottom:'30px'}}>No value propositions saved</div>}
    <ConfirmButtons rightButtonTitle={"Select"} leftButtonTitle={"Back"} disabled={!data?.length} onCancel={closeModal} handleSubmit={handleFormSubmit}/>
  </Modal>
   <SaveValueModal editId={selectedValue} open={openSaveValueModal} onCancel={handleCloseSaveValueModal} defaultVal={data?.length >selectedValue && data[selectedValue]?.value_prop} defaultTitle={data?.length >selectedValue && data[selectedValue]?.title} />
  </>)
}
