/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/jsx-fragments */
import {
  CardElement,
  Elements,
  // LinkAuthenticationElement,
  PaymentElement,
  // linkAuthenticationElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Flex, Row, Col, Typography, message } from "antd";
import Reeact, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "src/components/layout";
import { SvgIcon } from "src/shared/icons";
import { AppDispatch } from "src/store";
import { resetPayIntent, stripeSelector } from "src/store/stripe";
import { createPayIntent, createSubIntentToPayForCli, newActivatePaidClient, newActivateTrialClient } from "src/store/stripe/asyncRequests";
import { userSelector } from "src/store/user";
import { ConfirmButtons, Modal } from "../../../../ui";
import "./index.less";

import { Link } from "react-router-dom";
import { useBreakpoints } from "../../../../../helpers/useBreakpoints";

export function CheckoutForm({
  // checkout,
  // payIntentId,
  closeCheckoutShowPP,
  backStr = "Back to Plans",
  proceedStr = "Get Started",
  markIsSuccess,
  submit,

  // noteInBottom = `*You will only be charged for the subscription at the end of your free trial. To confirm your payment method, a temporary
  //       authorization of 50 cents will be processed, this amount will be immediately refunded to you.`,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const { email, first_name, last_name } = useSelector(userSelector);
  const { loading_intent } = useSelector(stripeSelector);
  const [succMessage, setSuccMessage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [paymentError, setPaymentError] = useState<any>(null);

  const [messageApi, contextHolder] = message.useMessage();
  const handleSubmit = async (event) => {
    setSuccMessage("");
    setPaymentError("");
    event?.preventDefault(); // might not need
    elements?.submit();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading("Validating Card");

    const cardElement = elements.getElement(CardElement);
    const paymentElement = elements.getElement(PaymentElement);

    // Use your server to create a SetupIntent and get its client_secret

    const result = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });
    console.log(result, "error, paymentMethod");

    if (result.error) {
      setIsLoading("");
      // console.error('Payment Error:', result?.error?.message);
      // setPaymentError(result?.error?.message || 'Error In Setting Up Card');
      messageApi.error(result?.error?.message || "Error In Setting Up Card", 6.5);
      // .then(() => {message.error(result?.error?.message || 'Error In Setting Up Card',5.5)
      //   message.success("Exporting finished", 5.5)
      // })
      // Handle payment errors
    } else {
      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        console.log("Payment successful:", result);
        // messageApi
        // .success("Success", 5.5)

        setIsLoading("");
        // setSuccMessage('✅ Success');
        markIsSuccess(result?.paymentIntent?.payment_method);
        // Payment was successful, you can retrieve the amount from result.paymentIntent
      }
    }
  };

  useEffect(() => {
    if (submit) {
      handleSubmit();
    }
  }, [submit]);

  return (
    <form>
      {contextHolder}
      {/* <CardElement /> */}
      {/* <LinkAuthenticationElement /> */}
      <PaymentElement
        // Optional prop for prefilling customer information
        options={{
          defaultValues: {
            billingDetails: {
              name: `${first_name || ""} ${last_name || ""}`?.trim(),
              email, // check on Prod if the LINK is avaiable if not erase this
              // phone: '888-888-8888',
            },
          },
        }}
      />
      {/* <button type="submit">Pay Now</button> */}
      {paymentError ? (
        <div style={{ color: "red", fontWeight: 600, fontSize: "18px", marginTop: "10px" }}>Error: {paymentError}</div>
      ) : succMessage ? (
        <div style={{ color: "green", fontWeight: 600, fontSize: "18px", marginTop: "10px" }}>{succMessage}</div>
      ) : isLoading ? (
        <>
          <Flex vertical={true} align={"center"} gap={24} className={"loader-wrapper"} style={{ margin: "35px", marginBottom: "55px" }}>
            {" "}
            <Loader size="large" />{" "}
          </Flex>
        </>
      ) : (
        ""
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "space-between",
          justifyContent: "center",
          margin: "15px",
          marginLeft: "-5px",
        }}
      ></div>
    </form>
  );
}
interface PaymentMethodModalProps {
  onClose: () => void;
  payment?: boolean;
  successfulPayment?: any;
  checkout?: string;
  isTrial?: boolean;
  isGenericBillingFailed?: boolean;
  failedPayment?: boolean;
  failedPaymentSkipTrial?: boolean;
  paymentIntentToUse?: boolean;
  forceSubtitle?: string;
  forceTitle?: string;
  discountType?: null | "price" | "extrCreds";
}

export const PaymentMethodModal: FC<PaymentMethodModalProps> = ({
  checkout,
  onClose,
  payment,
  discountType,
  successfulPayment,
  isTrial,
  failedPayment,
  failedPaymentSkipTrial,
  isGenericBillingFailed,
  paymentIntentToUse,
  forceTitle,
  forceSubtitle,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [lastCheckedId, setlastCheckedId] = useState<any>("");
  const [submit, setSubmit] = useState(false);

  const submitPayment = () => {
    setSubmit((prev) => !prev); // Toggles the state
  };
  useEffect(() => {
    if (submit) {
      setTimeout(() => {
        setSubmit(false);
      }, 300);
    }
  }, [submit]);
  // useEffect(() => {

  // }, [isTrial,payment]);
  useEffect(() => {
    if (isGenericBillingFailed) {
      return;
    }
    if (payment) {
      if (isTrial) {
        dispatch(createPayIntent({}));
      } else {
        dispatch(createSubIntentToPayForCli({ plan: checkout, priceDiscount: discountType === "price" }));
      }
    } else {
      // for 50 cent small charge to change card
      // setlastCheckedId(checkout)
      dispatch(createPayIntent({}));
    }
  }, []);
  const markIsSuccess = (payMethodId) => {
    successfulPayment();
    // console.log(payMethodId, 'payMethodIdpayMethodId');
    // if(!payment){
    if (isGenericBillingFailed) {
      return;
    }
    if (payment) {
      // for update card and trial to refund their 50 cent
      if (isTrial) {
        dispatch(newActivateTrialClient({ checkout, payIntentIdentification, payMethodId, discountType }));
      } else {
      }
    } else {
    }
  };
  // setFadeOut(true)
  // setTimeout(() => {
  //   setSuccessFullyMadeClient(true)
  //   // add call for backedn to create the accoutn and perhaps refresh page with use effect or show the end client as being premium or whatever
  // }, 200)

  const { isDesktop } = useBreakpoints();
  const handleCls = () => {
    if (!isGenericBillingFailed) {
      dispatch(resetPayIntent());
    }
    onClose();
  };
  const stripe = loadStripe(import.meta.env.VITE_REACT_APP_STRIPE_PK);
  let { payIntentId, payIntentIdentification } = useSelector(stripeSelector);
  const client = useSelector(userSelector);

  console.log(payIntentId, "payIntentId");
  payIntentId = paymentIntentToUse || payIntentId;
  // failedPayment;
  // failedPaymentSkipTrial;

  return isDesktop ? (
    <Modal
      rootClassName={"payment-method-modal"}
      title={
        forceTitle ? (
          forceTitle
        ) : payment ? (
          failedPayment ? (
            `Your payment has failed`
          ) : (
            <div style={{ fontSize: "18px", fontWeight: 600 }}>
              {isTrial ? "Trial Summary - " : "Plan Summary - "}
              {checkout === "scheduler"
                ? "Ai Email Scheduling "
                : checkout === "starter"
                ? "Starter Plan "
                : checkout === "growth"
                ? "Growth Plan "
                : "Ultimate Plan "}
            </div>
          )
        ) : (
          "Update Invoice - Meetz"
        )
      }
      open={true}
      onCancel={handleCls}
      closeIcon={!failedPayment}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
        {forceSubtitle ? (
          <div style={{ marginBottom: "30px" }}> {forceSubtitle}</div>
        ) : (
          !isTrial && (
            <div style={{ marginBottom: "30px" }}>
              {failedPayment ? (
                <>
                  Fix your payment below to unlock Meetz {!failedPaymentSkipTrial ? `and start getting leads!` : `to resume your outreach!`}
                  <br />{" "}
                  {checkout === "scheduler"
                    ? "Ai Email Scheduling "
                    : checkout === "starter"
                    ? "Starter Plan "
                    : checkout === "growth"
                    ? "Growth Plan "
                    : "Ultimate Plan "}
                </>
              ) : (
                `Activate Meetz - `
              )}
              <span
                style={{
                  color: "#757a89",
                  textDecoration: "line-through",
                }}
              >
                {discountType === "price" ? (
                  <> {checkout === "scheduler" ? "$49" : checkout === "starter" ? "$49" : checkout === "growth" ? "$99" : "$199"}</>
                ) : (
                  ""
                )}
              </span>{" "}
              {discountType === "price" ? (
                <span style={{ color: "#0fca7a" }}>
                  {checkout === "scheduler" ? "$39" : checkout === "starter" ? "$39" : checkout === "growth" ? "$79" : "$159"}
                </span>
              ) : (
                <>{checkout === "scheduler" ? "$49" : checkout === "starter" ? "$49" : checkout === "growth" ? "$99" : "$199"}</>
              )}
              <br />
              {discountType === "extrCreds" ? <span style={{ color: "#0fca7a" }}>20% More Credits for the first 12 months</span> : ""}
            </div>
          )
        )}
        <div>
          {!!isTrial && (
            <div style={{ marginBottom: "15px" }}>
              {" "}
              This is to verify your account,<span style={{ fontWeight: 500 }}> you will not be charged during the 14 day free trial.</span>
            </div>
          )}
          {payIntentId ? (
            // <>
            <Elements stripe={stripe} options={{ clientSecret: payIntentId, loader: "auto", paymentMethodCreation: "manual" }}>
              <CheckoutForm
                // payIntentId={payIntentId}
                markIsSuccess={markIsSuccess}
                closeCheckoutShowPP={handleCls}
                submit={submit}
                // checkout={checkout}
              />
            </Elements>
          ) : (
            // </>
            <Flex vertical={true} align={"center"} gap={24} className={"loader-wrapper"} style={{ margin: "35px", marginBottom: "55px" }}>
              {" "}
              <Loader size="large" />{" "}
            </Flex>
          )}
        </div>
        <div>
          <ConfirmButtons
            leftButtonTitle={!failedPayment && "Back to Plans"}
            rightButtonTitle={isTrial ? "Get Started" : "Activate Meetz"}
            handleSubmit={submitPayment}
            onCancel={handleCls}
          />
        </div>
        <span style={{ fontSize: "12px", color: "grey", marginTop: "35px" }}>
          {isTrial
            ? `*You will only be charged for the subscription at the end of your free trial. To confirm your payment method, a temporary authorization of 50 cents will be processed, this amount will be immediately refunded to you`
            : `Cancel anytime, no commitment necessary `}
          {discountType ? "- Checkout now to lock in your discount" : ""}
        </span>
      </div>
    </Modal>
  ) : (
    <Flex className={"payment-method-page-wrapper"} vertical={true}>
      <Row className={"mobile-gradient1"} />
      <Row className={"mobile-gradient2"} />
      <Col className={"box"} style={{ padding: "32px 18px" }}>
        {" "}
        {failedPayment ? (
          <div style={{ fontSize: "18px", fontWeight: 600 }}>Your payment has failed</div>
        ) : (
          <div style={{ fontSize: "18px", fontWeight: 600 }}>
            {isTrial ? "Trial Summary - " : "Plan Summary - "}
            {checkout === "scheduler"
              ? "Ai Email Scheduling "
              : checkout === "starter"
              ? "Starter Plan "
              : checkout === "growth"
              ? "Growth Plan "
              : "Ultimate Plan "}
          </div>
        )}
        <br />
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "fit-content" }}>
          {!isTrial && (
            <div style={{ marginBottom: "30px" }}>
              {failedPayment ? (
                <>
                  Fix your payment below to unlock Meetz {!failedPaymentSkipTrial ? `and start getting leads!` : `to resume your outreach!`}
                  <br />{" "}
                  {checkout === "scheduler"
                    ? "Ai Email Scheduling "
                    : checkout === "starter"
                    ? "Starter Plan "
                    : checkout === "growth"
                    ? "Growth Plan "
                    : "Ultimate Plan "}
                </>
              ) : (
                `Activate Meetz - `
              )}
              <span
                style={{
                  color: "#757a89",
                  textDecoration: "line-through",
                }}
              >
                {discountType === "price" ? (
                  <> {checkout === "scheduler" ? "$49" : checkout === "starter" ? "$49" : checkout === "growth" ? "$99" : "$199"}</>
                ) : (
                  ""
                )}
              </span>{" "}
              {discountType === "price" ? (
                <span style={{ color: "#0fca7a" }}>
                  {checkout === "scheduler" ? "$39" : checkout === "starter" ? "$39" : checkout === "growth" ? "$79" : "$159"}
                </span>
              ) : (
                <>{checkout === "scheduler" ? "$49" : checkout === "starter" ? "$49" : checkout === "growth" ? "$99" : "$199"}</>
              )}
              <br />
              {discountType === "extrCreds" ? <span style={{ color: "#0fca7a" }}>20% More Credits for the first 12 months</span> : ""}
            </div>
          )}
          <div>
            {!!isTrial && (
              <div style={{ marginBottom: "15px" }}>
                {" "}
                This is to verify your account,<span style={{ fontWeight: 500 }}> you will not be charged during the 14 day free trial.</span>
              </div>
            )}
            {payIntentId ? (
              // <>
              <Elements stripe={stripe} options={{ clientSecret: payIntentId, loader: "auto", paymentMethodCreation: "manual" }}>
                <CheckoutForm
                  // payIntentId={payIntentId}
                  markIsSuccess={markIsSuccess}
                  closeCheckoutShowPP={handleCls}
                  submit={submit}
                  // checkout={checkout}
                />
              </Elements>
            ) : (
              // </>
              <Flex vertical={true} align={"center"} gap={24} className={"loader-wrapper"} style={{ margin: "35px", marginBottom: "55px" }}>
                {" "}
                <Loader size="large" />{" "}
              </Flex>
            )}
          </div>
          <div>
            <ConfirmButtons
              leftButtonTitle={!failedPayment && "Back to Plans"}
              rightButtonTitle={isTrial ? "Get Started" : "Activate Meetz"}
              handleSubmit={submitPayment}
              onCancel={!failedPayment && handleCls}
            />
          </div>
          <span style={{ fontSize: "12px", color: "grey", marginTop: "35px" }}>
            {isTrial
              ? `*You will only be charged for the subscription at the end of your free trial. To confirm your payment method, a temporary authorization of 50 cents will be processed, this amount will be immediately refunded to you`
              : `Cancel anytime, no commitment necessary `}
            {discountType ? "- Checkout now to lock in your discount" : ""}
          </span>
        </div>
      </Col>
      {/* <Link to={"/"} className={"meetz-link"}>
        Meetz.ai
      </Link> */}
    </Flex>
  );
};
