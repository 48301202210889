export const getInteractionsOperation = (item: string) => {
  switch (true) {
    case item === "SMS":
      return "sent you SMS"
    case item === "phone":
      return "called you"
    default:
      return "sent you email"
  }
}
