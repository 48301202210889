/* eslint-disable no-unused-vars */

import { createSlice, createSelector } from "@reduxjs/toolkit";

import {
  addDialerCampaignBe,
  callMultiplePeople,
  changeInboundPage,
  changePage,
  frwdNumChange,
  getDialer,
  getFocusedCampInfo,
  getSpotify,
  getTwilioVoiceToken,
  heartbeatToShowOnlineAndGetStatus,
  listPhoneNums,
  loadPowerDialer,
  loadPowerDialerFirstTime,
  lookupProspect,
  saveEmailTemplate,
  saveOrUpdateEmailTemplate,
  updateCallNotes,
  updateDialerCampaignProp,
  whoIsActiveContact,
  whoIsActiveContactCompany,
  whoIsActiveContactLinkedIn,
  updateEmailTemplate,
  checkAddress,
  getAddressInformation,
  stopMultiDialingActive,
  purchaseBalance,
  getRealTimePrices,
} from "./asyncRequests";

import _ from "lodash";
import { initiateAndGetAllUserInfo } from "../user/asyncRequests";

const dialerSlice = createSlice({
  name: "dialer",
  initialState: {
    dialerInfo: {},
    dialerPowerDialingOnInfo: {},
    dialerFocusedOnInfo: {},
    prospects: [],
    activity: [],
    isUnknownProsActivity: false,
    isKnownProsActivity: false,
    totalActivity: 0,
    isIncomingLastPage: false,
    loadingInbound: false,
    totalProspectCount: 0,
    isLastPage: true,
    loading: false,
    loadingNums: false,
    loadingSaveOrUpdateEmailTemplate: false,
    loadingCampaigns: false,
    loadingFocCamp: false,
    frwdNumSuccess: false,
    dialerLoaded: false,
    loadingNewCampaign: false,
    newCampaignId: null,
    listAvailPhoneNums: [],
    max_times_to_call: 6,
    time_between_last_call: 24,
    start_day_min_to_call_hour: 9,
    end_day_max_to_call_hour: 16,
    campaign_name: "",
    whats_special_about_prospects: "",
    removeLeadsAlreadyUpl: false,
    calls_at_the_same_time: 10,
    prosp_called_now: 0,
    amount_of_prosp_to_call_now: 0,
    loadingTokenCompletedFirstTime: false,
    callingInProgress: false,
    toggleOnTheConfirmationPopUp: false,
    callIsLiveNow: false,
    finishedWithProspectsInDialer: false,
    multiCallingFullyPaused: false,
    predictiveDialerLoadProsp: false,
    predictiveDialerOn: false, // this is the command to open it
    isPopUpOpen: false, // this is if it is actually open or not (if completed could be open this is true while predictiveDialerOn is false)
    dialerToNum: "",
    dialerToProsp: "",
    // interchanging so there is no dial delay
    spotifyFirstLoad: false,
    spotify: {},
    voiceToken: "",
    readyToDial: true,
    loadingcheckAddress: false,
    addressComplete: {},
    guessedAddresses: [],
    prospectsSearched: [],
    stopDialing: false,
    loadingProspSearch: false,
    prospectAnsweredNowSid: "",
    mute: "auto", // auto off on (auto means turning off when they answer)
    speakerDefault: "",
    microphoneDefault: "",
    actNotifications: 0,
    // this id should not be taken off until it's manually done so upon the client closing the window or something else
    credits: null,
    ignoreProspId: null,
    activeProspectOnPhoneNowId: null as null | number,
    prospectOnPhoneDetails: {},
    callInMultiNum: "",
    callInMultiNumExt: "",
    hangUpOnMultiProspect: false,
    numberToDialOnCall: null,
    whoIsActiveContactCurrentlyOnCall: null,
    loadingWhoIsActiveContact: false,
    whoIsActiveContactCompanyCurrentlyOnCall: null,
    loadingWhoIsActiveContactCompany: false,
    whoIsActiveContactLinkedInCurrentlyOnCall: null,
    loadingWhoIsActiveContactLinkedIn: false,
    multiRingingStatus: "", // '' 'ringing'

    // adding credits
    successfullUpdateMsgBil: false as boolean | string,
    infoUpdateMsgBil: false as boolean | string,
    failedUpdateMsgBil: false as boolean | string,
    sentPaymentRequest: false,
    newDialerAmntAdd: 0,
    statusOfPaymentBilling: "no_activity_now" as "no_activity_now" | "successfully_added" | string, // string if is a payment intent (use this to pay and fix if needed)
    amountPPM: "Add to and from countries to check" as string | number, // display price per minute of to and from
    callingBalance: "-" as "-" | number,
    prosCalledNow: { prospectsCanCallNowAmnt: 0, amountCalledNow: 0 },
    finishedReason: null as null | "no_prospects_left" | "no_prospects_to_begin_with" | "no_money_left" | "something_went_wrong",
  },
  reducers: {
    resetMultiDialerPros: (state, action) => {
      // console.log("here333");

      const dontResetProsOnlyDialer = action.payload;
      state.whoIsActiveContactCurrentlyOnCall = null;
      state.activeProspectOnPhoneNowId = null;
      state.whoIsActiveContactCompanyCurrentlyOnCall = null;
      // console.log("hello1213B");
      state.whoIsActiveContactLinkedInCurrentlyOnCall = null;
      state.prosCalledNow = { prospectsCanCallNowAmnt: 0, amountCalledNow: 0 };
    },
    setFinishedReason: (state, action) => {
      state.finishedReason = action.payload;
    },
    setProsCalled: (state, action) => {
      state.prosCalledNow = action.payload;
    },
    setWhoIsActCompanyOnCall: (state, action) => {
      state.whoIsActiveContactCompanyCurrentlyOnCall = action.payload;
    },
    setWhoIsActLinkedInOnCall: (state, action) => {
      console.log("hello1213A", action.payload);
      state.whoIsActiveContactLinkedInCurrentlyOnCall = action.payload;
    },
    resetPaybBill: (state) => {
      state.statusOfPaymentBilling = "no_activity_now";
    },
    resetOutcomeBil: (state) => {
      state.successfullUpdateMsgBil = false;
      state.infoUpdateMsgBil = false;
      state.failedUpdateMsgBil = false;
    },
    resetVoiceToken: (state) => {
      state.voiceToken = "";
    },
    openDialer: (state, action) => {
      if (action.payload.num) {
        state.dialerToNum = action.payload.num;
      }
      if (action.payload.prosp) {
        state.dialerToProsp = action.payload.prosp;
      }
    },
    resetOpenDialer: (state, action) => {
      state.dialerToNum = "";
      state.dialerToProsp = "";
    },
    multiCallBackRingingStatus: (state, action) => {
      state.multiRingingStatus = action.payload.status;
    },
    numberToCallInMulti: (state, action) => {
      state.callInMultiNum = action.payload.num;
      state.callInMultiNumExt = action.payload.ext;
    },
    // this is for numbers like if they need to click 1 2 or 3 or to dial by name when they are actively on a call now
    numberToDial: (state, action) => {
      state.numberToDialOnCall = action.payload;
    },
    setIsPopUpOpen: (state, action) => {
      state.isPopUpOpen = action.payload;
    },
    setStopDialing: (state, action) => {
      state.stopDialing = action.payload;
    },
    hangUpOnMultiProspectChange: (state, action) => {
      state.hangUpOnMultiProspect = action.payload;
    },
    readyToDialSwitch: (state, action) => {
      state.readyToDial = action.payload;
    },
    microphoneDefaultChange: (state, action) => {
      state.microphoneDefault = action.payload;
    },
    speakerDefaultChange: (state, action) => {
      state.speakerDefault = action.payload;
    },
    changeDefEmail: (state, action) => {
      const dialer = _.cloneDeep(state.dialerInfo);

      const emailToFind = action.payload === "none" ? { id: "none" } : dialer?.dialer_email_templates?.find((em) => em.id === action.payload);

      // dialer.dialer_email_templates = action.payload;
      dialer.email_default = emailToFind;

      state.dialerInfo = dialer;
    },
    turnOffLoadSuc: (state, action) => {
      const turnOff = action.payload;
      if (turnOff === "frwd") {
        state.frwdNumSuccess = false;
      }
    },
    setFocusedCampaignToPowerDialing: (state, action) => {
      console.log(action.payload, "phone_num2");
      state.dialerPowerDialingOnInfo = action.payload;
    },
    resetDialerStatus: (state, action) => {
      console.log("here5555");
      // console.log('wentintorestart');
      state.ignoreProspId = state.activeProspectOnPhoneNowId; // heartbeat initially stil shows the old id no way to time it differently.
      state.activeProspectOnPhoneNowId = null;
      state.prosCalledNow = { prospectsCanCallNowAmnt: 0, amountCalledNow: 0 };

      state.prosp_called_now = 0;
      state.amount_of_prosp_to_call_now = 0;
      // state.finishedWithProspectsInDialer = false;
      // state.multiCallingFullyPaused = false;
      // state.voiceToken = '';
    },
    openStopDialingWindow: (state) => {
      state.toggleOnTheConfirmationPopUp = !state.toggleOnTheConfirmationPopUp;
    },
    setPowerDialer: (state, action) => {
      state.predictiveDialerOn = action.payload;
    },
    togRemoveLeadsAlreadyUpl: (state) => {
      state.removeLeadsAlreadyUpl = !state.removeLeadsAlreadyUpl;
    },
    resetProspectsSearched: (state) => {
      state.prospectsSearched = [];
    },
    resetActiveCallId: (state) => {
      console.log("here123");
      state.activeProspectOnPhoneNowId = null;
    },
    resetDialerFocused: (state) => {
      state.dialerFocusedOnInfo = {};
    },
    changeWhatsSpecial: (state, action) => {
      state.whats_special_about_prospects = action.payload;
    },
    newCampaignIdToNull: (state) => {
      state.newCampaignId = null;
    },
    changeNewCampaignName: (state, action) => {
      state.campaign_name = action.payload;
    },
    activelyOnCall: (state, action) => {
      console.log(action.payload, "activelyOnCallactivelyOnCall");
      state.whoIsActiveContactCurrentlyOnCall = action.payload;
      state.activeProspectOnPhoneNowId = action.payload?.id;
    },
    changeTheDialerCampaignSet: (state, action) => {
      const obj = action.payload;
      const propNames = Object.keys(obj)[0];
      const firstProperty = obj[propNames];
      // console.log(firstProperty, '-----', propNames, '-----', obj);
      // @ts-ignore
      state[propNames] = firstProperty;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {
        state.spotify = action.payload?.spotify;
        state.statusOfPaymentBilling = action.payload?.phoneNumbersInfo?.dialer_charging_balance_status || "no_activity_now";
        state.dialerInfo = action.payload?.dialer;
        state.callingBalance = parseFloat((action.payload?.phoneNumbersInfo?.dialer_balance_remaining || 0).toFixed(2));
      })
      .addCase(stopMultiDialingActive.fulfilled, (state) => {
        state.readyToDial = true;
      })
      .addCase(stopMultiDialingActive.rejected, (state) => {
        state.readyToDial = true;
      })
      .addCase(stopMultiDialingActive.pending, (state) => {
        state.readyToDial = false;
      })
      .addCase(getRealTimePrices.fulfilled, (state, action) => {
        // state.readyToDial = true;
        console.log(action.payload, "getRealTimePrices");
        state.amountPPM = `$${parseFloat((action.payload?.pricingPerMinute || 0).toFixed(2))} USD`;
      })
      .addCase(getRealTimePrices.rejected, (state) => {
        // state.readyToDial = true;
        state.amountPPM = "Error";
      })
      .addCase(getRealTimePrices.pending, (state) => {
        state.amountPPM = "loading";
      })
      .addCase(purchaseBalance.fulfilled, (state, action) => {
        state.sentPaymentRequest = false;
        const status = action?.payload?.status;
        if (status === "error") {
          state.failedUpdateMsgBil = "Something went wrong, check your internet and refresh the page if needed. If this persists contact support.";
          return;
        }
        state.newDialerAmntAdd = action?.payload?.newDialerAmntAdd;
        state.statusOfPaymentBilling = status;
        state.callingBalance = parseFloat(action?.payload?.newAmount.toFixed(2));
      })
      .addCase(purchaseBalance.rejected, (state) => {
        state.sentPaymentRequest = false;
        state.failedUpdateMsgBil = "Something went wrong, check your internet and refresh the page if needed.";
      })
      .addCase(purchaseBalance.pending, (state) => {
        // state.readyToDial = false;
        state.sentPaymentRequest = true;
      })
      .addCase(getAddressInformation.pending, (state) => {
        if (!state.loadingcheckAddress) {
          state.loadingcheckAddress = true;
        }
      })
      .addCase(getAddressInformation.fulfilled, (state, action) => {
        if (state.loadingcheckAddress) {
          state.loadingcheckAddress = false;
        }
        console.log(action.payload, "addressComplete1");
        state.addressComplete = action.payload;
      })
      .addCase(getAddressInformation.rejected, (state, action) => {
        console.log(action.payload, "addressComplete2");
        if (state.loadingcheckAddress) {
          state.loadingcheckAddress = false;
        }
      })
      .addCase(checkAddress.pending, (state) => {
        if (!state.loadingcheckAddress) {
          state.loadingcheckAddress = true;
        }
      })
      .addCase(checkAddress.fulfilled, (state, action) => {
        if (state.loadingcheckAddress) {
          state.loadingcheckAddress = false;
        }
        state.guessedAddresses = action.payload.places;
      })
      .addCase(checkAddress.rejected, (state, action) => {
        if (state.loadingcheckAddress) {
          state.loadingcheckAddress = false;
        }
        state.guessedAddresses = [];
      })
      .addCase(updateCallNotes.pending, (state) => {
        // if (!state.loadingupdateCallNotes) {
        //   state.loadingupdateCallNotes = true;
        // }
      })
      .addCase(updateCallNotes.fulfilled, (state, action) => {
        // if (state.loadingupdateCallNotes) {
        //   state.loadingupdateCallNotes = false;
        // }
        // state.activeProspectOnPhoneNowId = null;
      })
      .addCase(updateCallNotes.rejected, (state, action) => {
        // if (state.loadingupdateCallNotes) {
        //   state.loadingupdateCallNotes = false;
        // }
      })
      .addCase(saveOrUpdateEmailTemplate.pending, (state) => {
        if (!state.loadingSaveOrUpdateEmailTemplate) {
          state.loadingSaveOrUpdateEmailTemplate = true;
        }
      })
      .addCase(saveOrUpdateEmailTemplate.fulfilled, (state, action) => {
        if (state.loadingSaveOrUpdateEmailTemplate) {
          state.loadingSaveOrUpdateEmailTemplate = false;
        }
        // state.saveOrUpdateEmailTemplateCurrentlyOnCall = action.payload;
      })
      .addCase(saveOrUpdateEmailTemplate.rejected, (state, action) => {
        if (state.loadingSaveOrUpdateEmailTemplate) {
          state.loadingSaveOrUpdateEmailTemplate = false;
        }
      })
      .addCase(whoIsActiveContact.pending, (state) => {
        if (!state.loadingWhoIsActiveContact) {
          state.loadingWhoIsActiveContact = true;
        }
      })
      .addCase(whoIsActiveContact.fulfilled, (state, action) => {
        if (state.loadingWhoIsActiveContact) {
          state.loadingWhoIsActiveContact = false;
        }
        console.log(action.payload, "state.whoIsActiveContactCurrentlyOnCall");
        // state.whoIsActiveContactCurrentlyOnCall = action.payload;
      })
      .addCase(whoIsActiveContact.rejected, (state, action) => {
        if (state.loadingWhoIsActiveContact) {
          state.loadingWhoIsActiveContact = false;
        }
      })
      // .addCase(whoIsActiveContactCompany.pending, (state) => {
      //   state.loadingWhoIsActiveContactCompany = true;
      // })
      // .addCase(whoIsActiveContactCompany.fulfilled, (state, action) => {
      //   state.loadingWhoIsActiveContactCompany = false;
      //   state.whoIsActiveContactCompanyCurrentlyOnCall = action.payload;
      // })
      // .addCase(whoIsActiveContactCompany.rejected, (state, action) => {
      //   state.loadingWhoIsActiveContactCompany = false;
      // })
      .addCase(whoIsActiveContactLinkedIn.pending, (state) => {
        state.loadingWhoIsActiveContactLinkedIn = true;
      })
      .addCase(whoIsActiveContactLinkedIn.fulfilled, (state, action) => {
        state.loadingWhoIsActiveContactLinkedIn = false;
        console.log("hello1213C", action.payload);
        state.whoIsActiveContactLinkedInCurrentlyOnCall = action.payload;
      })
      .addCase(whoIsActiveContactLinkedIn.rejected, (state, action) => {
        state.loadingWhoIsActiveContactLinkedIn = false;
      })
      .addCase(loadPowerDialer.pending, (state) => {
        // if (!state.loadPowerDialer) {
        //   state.loadPowerDialer = true;
        // }
      })
      .addCase(loadPowerDialer.fulfilled, (state, action) => {
        // if (state.loadPowerDialer) {
        //   state.loadPowerDialer = false;
        // }
        // state.predictiveDialerProspectsToCall = action.payload?.prospectData;
        // const ids = action.payload?.idstoremovefromcallarr;
        // const filteredIds = state.predictiveDialerIdsToCall?.filter((id) => !ids.includes(id));
        // state.predictiveDialerIdsToCall = filteredIds;
      })
      .addCase(loadPowerDialer.rejected, (state, action) => {
        // if (state.loadPowerDialer) {
        //   state.loadPowerDialer = false;
        // }
        // state.predictiveDialerProspectsToCall = [];
        // state.predictiveDialerIdsToCall = [];
      })
      // .addCase(heartbeatToShowOnlineAndGetStatus.pending, (state) => {
      //   // if (!state.heartbeatToShowOnlineAndGetStatus) {
      //   //   state.heartbeatToShowOnlineAndGetStatus = true;
      //   // }
      // })
      // .addCase(heartbeatToShowOnlineAndGetStatus.fulfilled, (state, action) => {
      //   // if (state.heartbeatToShowOnlineAndGetStatus) {
      //   //   state.heartbeatToShowOnlineAndGetStatus = false;
      //   // }
      //   const heartbeatRes = action.payload;
      //   // console.log(
      //   //   heartbeatRes?.resultArray,
      //   //   heartbeatRes?.queueMembers,
      //   //   heartbeatRes?.is_multi_dialing_active,
      //   //   heartbeatRes?.is_multi_dialing_queue_active,
      //   //   'Heartbeat REs'
      //   // );
      //   // state.multiCallingFullyPaused = !heartbeatRes?.is_multi_dialing_queue_active;
      //   state.callingInProgress = heartbeatRes?.is_multi_dialing_active;
      //   state.finishedWithProspectsInDialer = heartbeatRes?.is_multi_dialing_ran_out_off_prosp;
      //   state.prosp_called_now = heartbeatRes?.prosp_called_now;
      //   state.amount_of_prosp_to_call_now = heartbeatRes?.amount_of_prosp_to_call_now;
      //   state.credits = heartbeatRes?.creditsLeft;
      //   state.finishedReason = heartbeatRes?.finishedReason;
      //   // Keep it like this otherwise it will disconnect and not allow to "call back" in the popup after the first call is done
      //   if (state.callIsLiveNow !== heartbeatRes?.callIsLiveNow) {
      //     // console.log(state.callIsLiveNow, heartbeatRes?.callIsLiveNow, 'state.callIsLiveNow !== heartbeatRes?.callIsLiveNow');
      //     state.callIsLiveNow = heartbeatRes?.callIsLiveNow;
      //   }
      //   // console.log(state.activeProspectOnPhoneNowId, 'issue>', heartbeatRes?.activeCallId);
      //   // console.log(heartbeatRes, 'heartbeatRes');
      //   // console.log(heartbeatRes?.activeCallId, state.activeProspectOnPhoneNowId, 'heartbeatRes');

      //   if (
      //     state.ignoreProspId !== +heartbeatRes?.activeCallId &&
      //     (state.activeProspectOnPhoneNowId || heartbeatRes?.activeCallId) &&
      //     state.activeProspectOnPhoneNowId !== +heartbeatRes?.activeCallId &&
      //     !state.stopDialing &&
      //     state.voiceToken
      //   ) {
      //     // console.log(
      //     //   state.activeProspectOnPhoneNowId,
      //     //   heartbeatRes?.activeCallId,
      //     //   'uipdating the actprod',
      //     //   heartbeatRes,
      //     //   'heartbeat',
      //     //   (state.activeProspectOnPhoneNowId || heartbeatRes?.activeCallId) &&
      //     //     state.activeProspectOnPhoneNowId !== +heartbeatRes?.activeCallId
      //     // );
      //     state.callInMultiNum = "";
      //     state.callInMultiNumExt = "";
      //     state.whoIsActiveContactCurrentlyOnCall = heartbeatRes?.prospectInfo;
      //     state.whoIsActiveContactCompanyCurrentlyOnCall = {};
      //     state.whoIsActiveContactLinkedInCurrentlyOnCall = {};
      //     state.hangUpOnMultiProspect = false;
      //     state.activeProspectOnPhoneNowId = +heartbeatRes?.activeCallId ? +heartbeatRes?.activeCallId : null;
      //   }
      // })
      // .addCase(heartbeatToShowOnlineAndGetStatus.rejected, (state, action) => {
      //   // if (state.heartbeatToShowOnlineAndGetStatus) {
      //   //   state.heartbeatToShowOnlineAndGetStatus = false;
      //   // }
      // })
      .addCase(loadPowerDialerFirstTime.pending, (state) => {
        // if (!state.loadPowerDialerFirstTime) {
        //   state.loadPowerDialerFirstTime = true;
        // }
      })
      .addCase(loadPowerDialerFirstTime.fulfilled, (state, action) => {
        // if (state.loadPowerDialerFirstTime) {
        //   state.loadPowerDialerFirstTime = false;
        // }
      })
      .addCase(
        loadPowerDialerFirstTime.rejected,
        (state, action) => {
          // if (state.loadPowerDialerFirstTime) {
          //   state.loadPowerDialerFirstTime = false;
          // }
        }
        // ).addCase(addCasecallMultiplePeople.pending, (state) => {
        //   // if (!state.callMultipleLeads) {
        //   //   state.callMultipleLeads = true;
        //   // }
        // }).addCase(callMultiplePeople.fulfilled,(state, action) => {
        //   // if (state.callMultipleLeads) {
        //   //   state.callMultipleLeads = false;
        //   // }
        //   // state.prospectAnsweredNowSid = action.payload.accountSid;
        // }).addCase(callMultiplePeople.rejected,(state, action) => {
        //   // if (state.callMultipleLeads) {
        //   //   state.callMultipleLeads = false;
        //   // }
        // }
      )
      .addCase(getTwilioVoiceToken.pending, (state) => {
        // if (!state.loadingTwilToken) {
        //   state.loadingTwilToken = true;
        // }
        state.voiceToken = "";
      })
      .addCase(getTwilioVoiceToken.fulfilled, (state, action) => {
        // if (state.loadingTwilToken) {
        //   state.loadingTwilToken = false;
        // }
        state.loadingTokenCompletedFirstTime = true;
        state.voiceToken = action.payload?.token;
      })
      .addCase(getTwilioVoiceToken.rejected, (state, action) => {
        // if (state.loadingTwilToken) {
        //   state.loadingTwilToken = false;
        // }
      })
      .addCase(getSpotify.pending, (state) => {
        // if (!state.loadingSpot) {
        //   state.loadingSpot = true;
        // }
      })
      .addCase(getSpotify.fulfilled, (state, action) => {
        // if (state.loadingSpot) {
        //   state.loadingSpot = false;
        // }
        state.spotifyFirstLoad = true;
        state.spotify = action.payload;
      })
      .addCase(getSpotify.rejected, (state, action) => {
        // if (state.loadingSpot) {
        //   state.loadingSpot = false;
        // }
      })
      .addCase(lookupProspect.pending, (state) => {
        if (!state.loadingProspSearch) {
          state.loadingProspSearch = true;
        }
      })
      .addCase(lookupProspect.fulfilled, (state, action) => {
        if (state.loadingProspSearch) {
          state.loadingProspSearch = false;
        }
        state.prospectsSearched = action.payload;
      })
      .addCase(lookupProspect.rejected, (state) => {
        if (state.loadingProspSearch) {
          state.loadingProspSearch = false;
        }
        state.prospectsSearched = [];
      })
      .addCase(changeInboundPage.pending, (state) => {
        if (!state.loadingInbound) {
          state.loadingInbound = true;
        }
      })
      .addCase(changeInboundPage.fulfilled, (state, action) => {
        if (state.loadingInbound) {
          state.loadingInbound = false;
        }

        // state.activities = action.payload.activities;
        state.totalActivity = action.payload.totalCount;
        state.isIncomingLastPage = action.payload.isLastPage;
        state.actNotifications = action.payload.actNotifications;
        state.isUnknownProsActivity = action.payload.isUnknownProspects;
        state.isKnownProsActivity = action.payload.isKnownProspects;
      })
      .addCase(changeInboundPage.rejected, (state) => {
        if (state.loadingInbound) {
          state.loadingInbound = false;
        }
      })
      .addCase(changePage.pending, (state) => {
        if (!state.loadingFocCamp) {
          state.loadingFocCamp = true;
        }
      })
      .addCase(changePage.fulfilled, (state, action) => {
        if (state.loadingFocCamp) {
          state.loadingFocCamp = false;
        }
        state.prospects = action.payload.prospects;
        state.totalProspectCount = action.payload.totalCount;
        state.isLastPage = action.payload.isLastPage;
      })
      .addCase(changePage.rejected, (state) => {
        if (state.loadingFocCamp) {
          state.loadingFocCamp = false;
        }
        // state.newCampaignId = -1;
      })
      .addCase(updateEmailTemplate.pending, (state) => {
        // if (!state.loadupdateEmailTemplate) {
        //   state.loadupdateEmailTemplate = true;
        // }
      })
      .addCase(updateEmailTemplate.fulfilled, (state, action) => {
        // if (state.loadupdateEmailTemplate) {
        //   state.loadupdateEmailTemplate = false;
        // }
        const dialer = _.cloneDeep(state.dialerInfo);
        dialer.dialer_email_templates = action.payload;

        state.dialerInfo = dialer;
      })
      .addCase(updateEmailTemplate.rejected, (state) => {
        // if (state.loadupdateEmailTemplate) {
        //   state.loadupdateEmailTemplate = false;
        // }
        // state.newCampaignId = -1;
      })
      .addCase(saveEmailTemplate.pending, (state) => {
        // if (!state.loadSaveEmailTemplate) {
        //   state.loadSaveEmailTemplate = true;
        // }
      })
      .addCase(saveEmailTemplate.fulfilled, (state, action) => {
        // if (state.loadSaveEmailTemplate) {
        //   state.loadSaveEmailTemplate = false;
        // }
        const dialer = _.cloneDeep(state.dialerInfo);
        dialer.dialer_email_templates = action.payload.email_temp_list;
        dialer.email_default = action.payload.email_default;
        state.dialerInfo = dialer;
      })
      .addCase(saveEmailTemplate.rejected, (state) => {
        // if (state.loadSaveEmailTemplate) {
        //   state.loadSaveEmailTemplate = false;
        // }
        // state.newCampaignId = -1;
      })
      .addCase(updateDialerCampaignProp.pending, (state) => {
        // if (!state.loadPatchDialer) {
        //   state.loadPatchDialer = true;
        // }
      })
      .addCase(updateDialerCampaignProp.fulfilled, (state, action) => {
        // if (state.loadPatchDialer) {
        //   state.loadPatchDialer = false;
        // }
        state.dialerFocusedOnInfo = action.payload;
      })
      .addCase(updateDialerCampaignProp.rejected, (state) => {
        // if (state.loadPatchDialer) {
        //   state.loadPatchDialer = false;
        // }
        // state.newCampaignId = -1;
      })
      .addCase(getFocusedCampInfo.pending, (state) => {
        if (!state.loadingFocCamp) {
          state.loadingFocCamp = true;
        }
      })
      .addCase(getFocusedCampInfo.fulfilled, (state, action) => {
        if (state.loadingFocCamp) {
          state.loadingFocCamp = false;
        }
        state.prospects = action.payload.prospects;
        state.dialerFocusedOnInfo = action.payload.dialerFocusedOnInfo;
      })
      .addCase(getFocusedCampInfo.rejected, (state) => {
        if (state.loadingFocCamp) {
          state.loadingFocCamp = false;
        }
        // state.newCampaignId = -1;
      })
      .addCase(addDialerCampaignBe.pending, (state) => {
        if (!state.loadingNewCampaign) {
          state.loadingNewCampaign = true;
        }
      })
      .addCase(addDialerCampaignBe.fulfilled, (state, action) => {
        if (state.loadingNewCampaign) {
          state.loadingNewCampaign = false;
        }
        state.newCampaignId = action.payload.id;
      })
      .addCase(addDialerCampaignBe.rejected, (state, action) => {
        if (state.loadingNewCampaign) {
          state.loadingNewCampaign = false;
        }
        // state.newCampaignId = -1;
      })
      .addCase(listPhoneNums.pending, (state) => {
        if (!state.loadingNums) {
          state.loadingNums = true;
        }
      })
      .addCase(listPhoneNums.fulfilled, (state, action) => {
        if (state.loadingNums) {
          state.loadingNums = false;
        }
        state.listAvailPhoneNums = action.payload;
      })
      .addCase(listPhoneNums.rejected, (state) => {
        if (state.loadingNums) {
          state.loadingNums = false;
          // state.loginError = action.error;
        }
      })
      .addCase(frwdNumChange.pending, (state) => {
        if (!state.loadingNums) {
          state.loadingNums = true;
        }
      })
      .addCase(frwdNumChange.fulfilled, (state, action) => {
        if (state.loadingNums) {
          state.loadingNums = false;
        }
        state.frwdNumSuccess = true;
      })
      .addCase(frwdNumChange.rejected, (state, action) => {
        if (state.loadingNums) {
          state.loadingNums = false;
          // state.loginError = action.error;
        }
      })
      .addCase(getDialer.pending, (state) => {
        if (!state.loadingCampaigns) {
          state.loadingCampaigns = true;
        }
      })
      .addCase(getDialer.fulfilled, (state, action) => {
        if (state.loadingCampaigns) {
          state.loadingCampaigns = false;
        }
        console.log(action.payload, "dialerrej acc");
        state.dialerInfo = action.payload;
        state.dialerLoaded = true;
      })
      .addCase(getDialer.rejected, (state, action) => {
        if (state.loadingCampaigns) {
          state.loadingCampaigns = false;
          // state.loginError = action.error;
        }
        console.log(action.error, "dialerrej rej");
      });
  },
});

const { reducer, actions } = dialerSlice;

export const {
  // logout,
  // setError,
  // changeTheMlResOfCurrThread,
  // changeTheTimezone,
  turnOffLoadSuc,
  resetDialerStatus,
  changeTheDialerCampaignSet,
  changeWhatsSpecial,
  changeNewCampaignName,
  newCampaignIdToNull,
  resetDialerFocused,
  resetProspectsSearched,
  togRemoveLeadsAlreadyUpl,
  setPowerDialer,
  setFocusedCampaignToPowerDialing,
  openStopDialingWindow,
  microphoneDefaultChange,
  speakerDefaultChange,
  hangUpOnMultiProspectChange,
  numberToDial,
  numberToCallInMulti,
  multiCallBackRingingStatus,
  resetPaybBill,
  openDialer,
  resetOpenDialer,
  changeDefEmail,
  readyToDialSwitch,
  setStopDialing,
  resetVoiceToken,
  resetOutcomeBil,
  setIsPopUpOpen,
  resetActiveCallId,
  activelyOnCall,
  setWhoIsActCompanyOnCall,
  setWhoIsActLinkedInOnCall,
  setFinishedReason,
  resetMultiDialerPros,
  setProsCalled,
} = actions;

// export const { setSearch, clearError } = actions;

export default reducer;

export const dialerSelector = createSelector(
  (state) => state.dialer,
  (dialer) => dialer
);
