/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-fragments */
import { Button, Divider, Dropdown, Flex, List, MenuProps, Row, Select, Space, Switch, Tooltip, Typography, message } from "antd";
import _ from "lodash";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { useLocation } from "react-router-dom";
import { CampaignsContext } from "src/components/app/context/CampaignsContext";
import { DeleteStepModal, SelectStepModal, StepSequenceIcon } from "src/components/pages/campaigns/ui/StepsSequence/ui";
import { useDragLayer } from "react-dnd";
import { ContentCard, Loader, StatusTag } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { TagStatusInfoModal } from "../TagStatusInfoModal";
import { ChangeStepModal } from "./ui/ChangeStepModal";
import { useNavigate } from "react-router-dom";
import { ViewAnalyticsModal } from "./ui/ViewAnalyticsModal";
import { DotsMenuDropdown } from "../../../../ui";
import "./index.less";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TestAiSettings } from "../../CreateCampaign/ui/AIGenerated/ui/StepThree/ui/AiPersonalizedEmail/ui/TestAiSettings";
import { SaveBeforeChangeModal } from "./ui/SaveBeforeChange";
import { useDispatch, useSelector } from "react-redux";
import { autosaveSequenceSteps, outreachCampaignSelector, setInvalidSteps, setOpenVariantFromAction, setitemIdSel } from "src/store/outreach-campaigns";

interface StepsSequenceProps {
  setMode?: React.Dispatch<React.SetStateAction<string>>;
  loading?: boolean;
  isEdit?: boolean;
  finalSeq?: boolean;
  isDraft?: boolean;
  data: any[];
  setData: any;
  setIDOfEditingEmail: any;
  madeChanges: any;
  setMadeChanges: any;
  payingClient: any;
  from: any;
}

export const statusColors: any = {
  success: "success",
  finished: "processing",
  completed: "purple",
  skipped: "magenta",
};

const stepOptions = [
  {
    label: "Immediately",
    value: "immediate",
  },
  {
    label: "Delay for 5 Minutes",
    value: 5,
  },
  {
    label: "Delay for 15 Minutes",
    value: 15,
  },
  {
    label: "Delay for 1 Hour",
    value: 60,
  },
  {
    label: "Delay for 3 Hours",
    value: 180,
  },
  {
    label: "Delay for 6 Hours",
    value: 360,
  },
  {
    label: "Delay for 1 Day",
    value: 1440,
  },
  {
    label: "Delay for 2 Days",
    value: 2880,
  },
  {
    label: "Delay for 3 Days",
    value: 4320,
  },
  {
    label: "Delay for 5 Days",
    value: 7200,
  },
  {
    label: "Delay for 10 Days",
    value: 14400,
  },
  {
    label: "Delay for 15 Days",
    value: 21600,
  },
];

export const StepsSequence: FC<StepsSequenceProps> = ({
  setMode,
  data,
  loading,
  isEdit,
  isDraft,
  setData,
  setIDOfEditingEmail,
  madeChanges,
  setMadeChanges,
  finalSeq,
  payingClient,
  from,
}) => {
  // console.log();
  // const [currentData, setToggleChange] = useState(data);
  const [currentData, setCurrentData] = useState(data);
  const [prevData, setPrevData] = useState(data);

  // useEffect(() => {
  //   dispatch(autosaveSequenceSteps({ from, formatedSeq: currentData }));
  // }, [currentData]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddStepModal, setOpenAddStepModal] = useState(false);
  const [openStatusTagInfoModal, setOpenStatusTagInfoModal] = useState(false);
  const [openChangeStepModal, setOpenChangeStepModal] = useState(false);
  const [openViewAnalyticsModal, setOpenViewAnalyticsModal] = useState({});

  const { aiGenProps, eachStepsAnalytics, itemIdSel, openVariantFromAction, invalidDraftStepsScratch, invalidDraftStepsAiGen, invalidDraftStepsEditCampaign } =
    useSelector(outreachCampaignSelector);

  useEffect(() => {
    if (openVariantFromAction) {
      dispatch(setOpenVariantFromAction(false));
      const item = currentData?.find((item) => item?.id === itemIdSel);
      setOpenViewAnalyticsModal(item);
    }
  }, [openVariantFromAction]);

  const [selectedStatusTag, setSelectedStatusTag] = useState("");
  const [saveFirst, setSaveFirst] = useState(false);
  // const { selectedStep } = useContext(CampaignsContext);
  const [deleteStep, setDeleteStep] = useState();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // console.log(eachStepsAnalytics,'eachStepsAnalytics');

  const handleAddStep = (type, override) => {
    if (madeChanges && !override) {
      setSaveFirst({ type: "addStep", params: type });
    } else {
      let mode;
      const id = Math.random().toString(36).substr(2, 9) + "newstep";
      let sequence = [...currentData];
      switch (type) {
        case "aiemail":
          mode = "ai";
          sequence = [
            {
              type: "aiemail",
              delayFromLatestStep: "immediate",
              id, // anyways only 1, if future want more than 1 should make this a random string
            },
            ...sequence,
          ];
          message.destroy();
          message.success({
            content: <>Successfully added "Ai Personalized Email" step to the start of the sequence! </>,
            duration: 9,
          });
          break;
        case "phonecall":
          mode = "phone";
          sequence = [
            ...sequence,
            {
              type: "phonecall",
              delayFromLatestStep: !sequence?.length ? "immediate" : 1440,
              id,
            },
          ];
          message.destroy();
          message.success({
            content: <>Added "Phone Call" step successfully! </>,
            duration: 9,
          });
          break;
        case "email":
          mode = "templated";
          sequence = [
            ...sequence,
            {
              type: "email",
              delayFromLatestStep: !sequence?.length ? "immediate" : 1440,
              emailVariantsArray: [{ subject: "", body: "" }],
              id,
            },
          ];
          message.destroy();
          message.info({
            content: (
              <>
                Added "Templated Email" step successfully! <b>Don't forget</b> to add the email template for the new step{" "}
              </>
            ),
            duration: 9,
          });
          break;
        // default:
        // mode='Template Email'
      }
      //  edit the step as soon as it loads
      dispatch(setitemIdSel(id));
      setIDOfEditingEmail && setIDOfEditingEmail(id);
      setMode && setMode(mode);

      // add step
      const formatedSeq = formatDataOfSequence(sequence);
      setCurrentData(formatedSeq);
      setPrevData(formatedSeq);
      setData && setData(formatedSeq);
      dispatch(autosaveSequenceSteps({ from, formatedSeq: formatedSeq }));
    }
  };

  const formatDataOfSequence = (dataToProcess) => {
    let totalMinutes = 0;

    // const autoStart = !!aiGenProps?.automations?.some((automations) => automations === "Auto Send Emails Upon Ai Generation");
    const autoStart = true;
    console.log(autoStart, "autoStart", aiGenProps?.automations);

    return dataToProcess?.map((obj, i) => {
      obj = _.cloneDeep(obj);
      if (!i) {
        obj.delayFromLatestStep = "immediate"; // makes first one immediate
      }
      totalMinutes += obj.delayFromLatestStep === "immediate" ? 0 : obj.delayFromLatestStep;
      const daysPassed = Math.floor(totalMinutes / (60 * 24)) + 1;

      let mode;
      switch (obj.type) {
        case "aiemail":
          mode = "Ai Personalized Email";
          break;
        case "phonecall":
          mode = "Phone Call";
          break;
        case "email":
          {
            mode = "Templated Email";
            // if(data?.length && )
            const dataOfObjWithUpToDateEmailTempllates = data?.find((data) => data?.id === obj?.id);
            // console.log(dataOfObjWithUpToDateEmailTempllates, "emailobject", obj);
            if (dataOfObjWithUpToDateEmailTempllates) {
              obj.emailVariantsArray = dataOfObjWithUpToDateEmailTempllates?.emailVariantsArray || [];
            }
          }
          break;
        // default:
      }

      // redundant but keep it for now
      const autoStartFix = obj?.autoStart === false || obj?.autoStart ? obj?.autoStart : autoStart;

      return { ...obj, day: daysPassed, mode, notUpdatedComp: false, autoStart: autoStartFix };
    });
  };

  useEffect(() => {
    if (data?.length && data[0]?.notUpdatedComp) {
      const formatedSeq = formatDataOfSequence(data);
      setCurrentData(formatedSeq);
      setPrevData(formatedSeq);
      setData && setData(formatedSeq);
      dispatch(autosaveSequenceSteps({ from, formatedSeq: formatedSeq }));
    }
    if (data?.length && data[0]?.updFromTemplates) {
      // saves the text to the auto save
      const formatedSeq = formatDataOfSequence(data);
      dispatch(autosaveSequenceSteps({ from, formatedSeq: formatedSeq }));
    }
    //notUpdatedComp
  }, [data]);

  // const [saveFirst, setSaveFirst] = useState({type:'delete'});

  const onDiscard = () => {
    if (saveFirst?.type === "addStep") {
      handleAddStep(saveFirst.params, true);
    }
    if (saveFirst?.type === "delete") {
      handleDelete(saveFirst.params, true);
    }
    if (saveFirst?.type === "delay") {
      handleChangeDelay(saveFirst.params.e, saveFirst.params.id, true);
    }
    if (saveFirst?.type === "autoStart") {
      onChangAutoStart(saveFirst.params.e, saveFirst.params.id, true);
    }
    if (saveFirst?.type === "changeStep") {
      handleChangeStepEdit(saveFirst.params, true);
    }
    setSaveFirst(false);

    // this comes after in order to not trigger the "save in the template"
    setMadeChanges && setMadeChanges(false);
  };

  // needdeed because it changes the main draft
  const saveTemplates = () => {
    setData(data?.map((step) => ({ ...(step || {}), updFromTemplates: false })));
    //  it makes it so FIRST save the text and then save the other sequence changes
    if (saveFirst?.type === "delete") {
      // this triggers save first, the 500 timeout is so that theres time for it to save
      setMadeChanges && setMadeChanges(false);
      setSaveFirst(false);
      setTimeout(() => {
        handleDelete(saveFirst.params, true);
      }, 500);
    }
    if (saveFirst?.type === "addStep") {
      // this triggers save first, the 500 timeout is so that theres time for it to save
      setMadeChanges && setMadeChanges(false);
      setSaveFirst(false);
      setTimeout(() => {
        handleAddStep(saveFirst.params, true);
      }, 500);
    }
    if (saveFirst?.type === "delay") {
      setMadeChanges && setMadeChanges(false);
      setSaveFirst(false);
      setTimeout(() => {
        handleChangeDelay(saveFirst.params.e, saveFirst.params.id, true);
      }, 500);
    }
    if (saveFirst?.type === "autoStart") {
      setMadeChanges && setMadeChanges(false);
      setSaveFirst(false);
      setTimeout(() => {
        onChangAutoStart(saveFirst.params.e, saveFirst.params.id, true);
      }, 500);
    }
    if (saveFirst?.type === "changeStep") {
      setMadeChanges && setMadeChanges(false);
      setSaveFirst(false);
      setTimeout(() => {
        handleChangeStepEdit(saveFirst.params, true);
      }, 500);
    }
  };
  const dispatch = useDispatch();
  // const [itemIdSel, setitemIdSel] = useState();

  const handleChangeStepEdit = (item, override) => {
    // console.log('itemitem',item);
    if (madeChanges && !override) {
      setSaveFirst({ type: "changeStep", params: item });
    } else {
      dispatch(setitemIdSel(item.id));
      setIDOfEditingEmail && setIDOfEditingEmail(item.id);
      setMode && setMode(item.mode.split(" ")[0].toLowerCase());
    }
  };

  const handleDelete = (delStep, override) => {
    if (madeChanges && !override) {
      setSaveFirst({ type: "delete", params: delStep });
    } else {
      // delete any errors in step
      const id = delStep?.mode === "Ai Personalized Email" ? "aiEmail" : delStep?.id;
      const invalidSteps = from === "ai" ? invalidDraftStepsAiGen : from === "scratch" ? invalidDraftStepsScratch : invalidDraftStepsEditCampaign;
      console.log(invalidSteps, id, "id of deleeted ai step");
      const inInvalidInd = (invalidSteps as any[])?.findIndex((inv) => inv?.id === id);
      const copInvalidSteps = [...(invalidSteps || [])];
      if (inInvalidInd !== -1) {
        copInvalidSteps.splice(inInvalidInd, 1);
        dispatch(setInvalidSteps({ copInvalidSteps, from }));
      }

      // deleting step
      const updatedData = currentData.filter((step) => step.id !== delStep.id);
      setMode && setMode("default");
      const formatedSeq = formatDataOfSequence(updatedData);
      setCurrentData(formatedSeq);
      setPrevData(formatedSeq);
      setData && setData(formatedSeq);
      dispatch(autosaveSequenceSteps({ from, formatedSeq: formatedSeq }));
    }
  };

  const handleChangeDelay = (e, id, override) => {
    if (madeChanges && !override) {
      setSaveFirst({ type: "delay", params: { e, id } });
    } else {
      console.log(e, id, "handleChangeDelay");
      const updatedData = currentData.map((step) => {
        if (step.id === id) {
          return { ...step, delayFromLatestStep: e };
        }
        return step;
      });
      const formatedSeq = formatDataOfSequence(updatedData);
      setCurrentData(formatedSeq);
      setPrevData(formatedSeq);
      // console.log(setData, "formatedSeqformatedSeq", formatedSeq);
      setData && setData(formatedSeq);
      dispatch(autosaveSequenceSteps({ from, formatedSeq: formatedSeq }));
    }
  };

  const showModal = (item) => {
    setDeleteStep(item);
    setOpenDeleteModal(true);
  };

  const showAddStepModal = () => {
    setOpenAddStepModal(true);
  };

  const showChangeStepModal = () => {
    setOpenChangeStepModal(true);
  };

  const closeChangeStepModal = () => {
    setOpenChangeStepModal(false);
  };

  const showViewAnalyticsModal = (item) => {
    setOpenViewAnalyticsModal(item);
  };

  const closeViewAnalyticsModal = () => {
    setOpenViewAnalyticsModal({});
  };

  const showStatusModal = (key: string) => {
    setOpenStatusTagInfoModal(true);
    setSelectedStatusTag(key);
  };

  const onCancel = () => {
    setDeleteStep();
    setOpenDeleteModal(false);
    setOpenAddStepModal(false);
    setOpenStatusTagInfoModal(false);
  };

  const updateStepNumbers = (steps: any) => {
    steps.forEach((step: any, index: number) => {
      step.stepNumber = index + 1;
    });
  };

  const handleChangeStepConfirmed = () => {
    setPrevData(currentData);
    setData && setData(currentData);
    setOpenChangeStepModal(false);
  };

  const handleChangeStepRejected = () => {
    setCurrentData(prevData);
    dispatch(autosaveSequenceSteps({ from, formatedSeq: prevData }));
    updateStepNumbers(prevData);
    setOpenChangeStepModal(false);
  };

  const handleEditSequenceBtnClick = () => {
    navigate(`${pathname}/edit-sequence`);
  };

  const createCampItems: MenuProps["items"] = (step) => [
    {
      key: "0",
      label: "Delete step",
      onClick: () => showModal(step),
    },
  ];

  const items: MenuProps["items"] = (step) => [
    {
      key: "1",
      label: "View Analytics for Variations",
      onClick: () => showViewAnalyticsModal(step),
    },
  ];
  const itemsEdit: MenuProps["items"] = (step) => [
    {
      key: "1",
      label: "View Analytics for Variations",
      onClick: () => showViewAnalyticsModal(step),
    }, // TODOn only for manual emails and in the campaign itself
    {
      key: "2",
      label: "Delete step",
      onClick: () => showModal(step),
    },
  ];

  const onChangAutoStart = (e: boolean, id, override) => {
    if (madeChanges && !override) {
      setSaveFirst({ type: "autoStart", params: { e, id } });
    } else {
      // console.log(e,id,'handleChangeDelay');
      const updatedData = currentData.map((step) => {
        if (step.id === id) {
          return { ...step, autoStart: e };
        }
        return step;
      });

      const formatedSeq = formatDataOfSequence(updatedData);
      setCurrentData(formatedSeq);
      setPrevData(formatedSeq);
      setData && setData(formatedSeq);
      dispatch(autosaveSequenceSteps({ from, formatedSeq: formatedSeq }));
    }
  };

  const isPersonalizedAiUsed = (): boolean => {
    // if (pathname.endsWith("from-scratch") && selectedStep === "aiemail") {
    //   return true;
    // } else {
    return currentData && currentData.some((item: any) => item.mode === "Ai Personalized Email");
    // }
  };

  const CustomDragLayer: React.FC = () => {
    const { isDragging } = useDragLayer((monitor) => ({
      isDragging: monitor.isDragging(),
    }));

    if (!isDragging) {
      return null;
    }

    return (
      <div
        style={{
          position: "fixed",
          pointerEvents: "none",
          zIndex: 1000,
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      />
    );
  };

  const [idOfDragging, setIdOfDragging] = useState("");

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = currentData[dragIndex];
    const newData = [...currentData];
    console.log(newData, "newDatanewData");
    newData.splice(dragIndex, 1);
    newData.splice(hoverIndex, 0, draggedItem);
    newData.forEach((item, index) => {
      item = _.cloneDeep(item);
      item.stepNumber = index + 1;
    });

    setCurrentData(formatDataOfSequence(newData));
    dispatch(autosaveSequenceSteps({ from, formatedSeq: newData }));
  };

  const loadingSent = [
    "Analyzing your input",
    "Gathering market data",
    "Generating campaign strategy",
    "Creating A/B templates",
    "Finalizing details",
    "Almost ready",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const newIndex = prevIndex + 1;
          if (newIndex >= loadingSent.length) {
            clearInterval(interval);
            return prevIndex;
          } else {
            return newIndex;
          }
        });
      }, 12000);

      return () => clearInterval(interval);
    } else {
      setCurrentIndex(0);
    }
  }, [loading]);

  const DraggableItem: FC<any> = ({ item, index }) => {
    // console.log(index,'countingindex of step');
    const iconRef = useRef<HTMLDivElement>(null);
    const listItemRef = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
      type: "card",
      item: () => {
        return { id: item.id, item, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        opacity: monitor.isDragging() ? 0 : 1,
      }),
      canDrag: () => !madeChanges,
    });

    if (isDragging) {
      setIdOfDragging(item.id);
    }

    const [, drop] = useDrop({
      accept: "card",
      hover(item: any, monitor) {
        if (!listItemRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        const isOver = monitor.isOver({ shallow: true });

        if (dragIndex === hoverIndex || !isOver) {
          return;
        }

        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      drop(item: any, monitor) {
        const isOrderChanged = !currentData.every((item, index) => item === prevData[index]);
        setIdOfDragging("");

        if (isOrderChanged && isEdit && !isDraft) {
          showChangeStepModal();
        } else if (isOrderChanged && isDraft) {
          handleChangeStepConfirmed();
        }
      },
    });

    drag(iconRef);
    drop(listItemRef);
    if (index === 2) {
      console.log(item, "secitem");
    }

    const findLabel = (value) => {
      const option = stepOptions.find((option) => option.value === value);
      return option ? option.label : "Unknown";
    };

    // itemIdSel
    let reason = "booked";
    let bestVariantIndex = 0;
    let bestScore = 0;
    let explanation = "";
    // const [explanation, setexpl] = useState("");
    // const [bestScore, setBestScore] = useState(0);
    // const [bestVariantIndex, setBestVariantIndex] = useState(0);

    // useEffect(() => {
    if (item?.emailVariantsArray?.length) {
      // let curBestScroe = 0;
      // let curBestSttpNum = 0;
      for (let i = 0; i < item?.emailVariantsArray?.length; i++) {
        const variant = item.emailVariantsArray[i];
        const score =
          variant.email_analytic_opened * 0.2 +
          variant.email_analytic_clicked * 0.4 +
          variant.email_analytic_forwarded * 1.5 +
          variant.email_analytic_replied * 2.5 +
          variant.email_analytic_booked * 20;

        if (score > bestScore) {
          bestScore = score;
          bestVariantIndex = i;
        }
      }

      console.log(item.delayFromLatestStep, "item.delayFromLatestStep");
      // setBestVariantIndex(curBestSttpNum);
      // setBestScore(curBestScroe);
      const bestVariant = item.emailVariantsArray[bestVariantIndex];
      // console.log(bestVariantIndex, "bestVariantIndex", item.emailVariantsArray);
      let num;
      if (bestVariant.email_analytic_booked > 0) {
        num = bestVariant.email_analytic_booked;
        reason = "book";
      } else if (bestVariant.email_analytic_replied > 0) {
        num = bestVariant.email_analytic_replied;
        reason = "reply";
      } else if (bestVariant.email_analytic_forwarded > 0) {
        num = bestVariant.email_analytic_forwarded;
        reason = "forward";
      } else if (bestVariant.email_analytic_clicked > 0) {
        num = bestVariant.email_analytic_clicked;
        reason = "click";
      } else if (bestVariant.email_analytic_opened > 0) {
        num = bestVariant.email_analytic_opened;
        reason = "open";
      }

      explanation = `Best email variation found: Variation ${String.fromCharCode(
        65 + item?.emailVariantsArray[bestVariantIndex]?.variantNum
      )}, that has ${num?.toLocaleString()} ${reason}${num > 1 ? "s" : ""}.`;
      // console.log(curBestSttpNum, curBestScroe, reason, "Working???");
    }
    // }, [item]);

    console.log(itemIdSel, "itemIdSel123", item);
    const invalidSteps = from === "ai" ? invalidDraftStepsAiGen : from === "scratch" ? invalidDraftStepsScratch : invalidDraftStepsEditCampaign;

    return (
      <List.Item
        ref={listItemRef}
        key={item.id}
        className={`steps-sequence-item ${finalSeq ? "" : "draft-item"}`}
        style={{
          opacity: idOfDragging === item.id ? 0.5 : 1,
          boxShadow:
            idOfDragging === item.id
              ? "0 4px 8px rgba(0, 0, 0, 0.2)"
              : itemIdSel === item.id
              ? "0 0 10px 2px rgba(173, 216, 230, 0.6), 0 0 20px 5px rgba(173, 216, 230, 0.4), 0 0 30px 10px rgba(173, 216, 230, 0.2)"
              : "0 0 5px rgba(211, 211, 211, 0.5)",
          transform: idOfDragging === item.id ? "scale(1.05)" : "none",
          transition: "transform 0.2s, box-shadow 0.2s",
          zIndex: idOfDragging === item.id ? 1000 : "auto",
          position: idOfDragging === item.id ? "relative" : "static",
          margin: "15px 0",
          cursor: finalSeq ? "default" : "pointer",
          // pointerEvents: finalSeq ? "none" : "auto",
        }}
        onClick={() => {
          !finalSeq && handleChangeStepEdit(item);
        }}
      >
        <Row align={"middle"} justify={"space-between"} style={{ width: "100%" }} wrap={false}>
          <Space size={6}>
            <Row align={"top"} style={{ gap: 8 }}>
              {!finalSeq && (
                <div ref={iconRef} style={{ cursor: madeChanges ? "default" : idOfDragging === item.id ? "grabbing !important" : "grab" }}>
                  {madeChanges ? (
                    <Tooltip placement={"top"} title={`Save changes to the templates you edited before changing the order of the steps`}>
                      {" "}
                      <SvgIcon type={"stepPrefix"} />
                    </Tooltip>
                  ) : (
                    <SvgIcon type={"stepPrefix"} />
                  )}
                </div>
              )}

              {/* {isEdit && (
                <div ref={iconRef} style={{ cursor: "grab" }}>
                  <SvgIcon type={"stepPrefix"} />
                </div>
              )} */}

              <Typography.Paragraph className={"step-number-title"}>
                {idOfDragging?.length ? (
                  <Flex justify="center" align="center">
                    <div>Editing...</div>
                  </Flex>
                ) : (
                  `Step ${index + 1}`
                )}
              </Typography.Paragraph>
            </Row>
            {item.type !== "phonecall" ? (
              <Row align={"middle"} style={{ gap: 12, marginBottom: "1px" }}>
                {finalSeq ? (
                  <>
                    {" "}
                    <Typography.Paragraph style={{ marginBottom: "2px" }}>
                      {" "}
                      - {item.autoStart ? "Will " : "Will not "}Automatically Send Out
                      <Tooltip
                        title={
                          <>
                            {/* {item.autoStart */}
                            Once prospects reach this step Meetz will automatically send the email out without you needing to do anything futher.
                            {/* : `Once prospects reach this step you will have to manually approve every email before it's sent. You can change this so that Meetz automatically sends the email by editing the step.`}*/}{" "}
                            <br />
                            <br />
                            {/* *Edit steps by clicking on the above "Edit Sequence" */}
                          </>
                        }
                        placement={"topLeft"}
                        rootClassName={"tooltip"}
                        color={"#ECFBFC"}
                      >
                        <SvgIcon className={"info-tooltip"} type={"tooltip"} style={{ marginLeft: "6px" }} />
                      </Tooltip>
                    </Typography.Paragraph>
                    {/* <Switch value={item.autoStart} size={"small"}  onChange={(e)=>onChangAutoStart(e,item.id)} /> */}
                  </>
                ) : (
                  <>
                    {/*  this allows them to auto send or not it is probably redundany and confusing {" "}
                    <Typography.Paragraph>Automatically Send</Typography.Paragraph>
                    <Switch value={item.autoStart} size={"small"} onChange={(e) => onChangAutoStart(e, item.id)} /> */}
                  </>
                )}
              </Row>
            ) : null}
          </Space>
          {/* <DotsMenuDropdown menuItems={isEdit ? items : createCampItems} 
           /> */}
          {!finalSeq && (
            <Dropdown
              menu={{ items: createCampItems(item) }}
              //  overlay={<Menu items={getMenuItems(number)} />}
              trigger={["click"]}
              onClick={(event) => event.stopPropagation()}
            >
              <SvgIcon type={"action"} style={{ cursor: "pointer" }} />
            </Dropdown>
          )}
        </Row>
        <Space style={{ position: "relative" }}>
          <StepSequenceIcon mode={item.mode} />
          <Space
            direction={"vertical"}
            style={{ flex: 1, width: "100%" }}
            // size={4}
          >
            <Flex align={"center"}>
              <Typography.Paragraph>
                {idOfDragging?.length ? (
                  <Flex justify="center" align="center">
                    <div>Editing...</div>
                  </Flex>
                ) : (
                  `Day ${item.day}`
                )}
              </Typography.Paragraph>
              <SvgIcon type={"dividerDot"} style={{ margin: "0 6px" }} />
              {!index ? (
                <Typography.Paragraph className={"select-input-title"}>Outreach Start</Typography.Paragraph>
              ) : finalSeq ? (
                <Typography.Paragraph className={"select-input-title"}> {findLabel(item.delayFromLatestStep)}</Typography.Paragraph>
              ) : (
                <>
                  {" "}
                  <Select
                    defaultValue={item.delayFromLatestStep}
                    size={"small"}
                    options={stepOptions}
                    onClick={(event) => event.stopPropagation()}
                    onChange={(e) => handleChangeDelay(e, item.id)}
                    style={{ width: 139 }}
                  />
                  {index && (!item.delayFromLatestStep || item.delayFromLatestStep === "immediate") && (
                    <div style={{ color: "lightcoral", marginLeft: "15px", fontSize: "13px" }}>- No delay is not recommended</div>
                  )}
                </>
              )}
              {finalSeq ? (
                <React.Fragment>
                  <SvgIcon type={"dividerDot"} style={{ margin: "0 6px" }} />
                  {/* {item.mode !== "phonecall" && item?.additional?.length ? ( */}
                  {/* {item.additional.map((it: any, index: number) => { */}
                  {/* return ( */}
                  <Tooltip title={<>Prospects that are ready to get this step</>} placement={"top"} rootClassName={"tooltip"} color={"#ECFBFC"}>
                    <Row align={"middle"} style={{ marginRight: "6px" }}>
                      <Typography.Paragraph className={"step-additional-value"}>
                        {eachStepsAnalytics ? eachStepsAnalytics[item.stepNum]?.prospectReadyStepCount?.toLocaleString() || 0 : 0}
                      </Typography.Paragraph>{" "}
                      <SvgIcon type={"person"} style={{ marginLeft: "2px" }} />
                    </Row>
                  </Tooltip>
                  {item.stepNum !== 1 && (
                    <Tooltip
                      title={<>Prospects waiting in the delay phase and then will be ready to get this step</>}
                      placement={"top"}
                      rootClassName={"tooltip"}
                      color={"#ECFBFC"}
                    >
                      <Row align={"middle"} style={{ marginRight: "6px" }}>
                        <Typography.Paragraph className={"step-additional-value"}>
                          {eachStepsAnalytics ? eachStepsAnalytics[item.stepNum]?.prospectNotReadyYetCount?.toLocaleString() || 0 : 0}
                        </Typography.Paragraph>{" "}
                        <SvgIcon type={"watch"} style={{ marginLeft: "2px" }} />
                      </Row>
                    </Tooltip>
                  )}
                  {/* )
                  })} */}
                </React.Fragment>
              ) : null}
              {item.additional?.length ? (
                <React.Fragment>
                  <Typography.Paragraph className={"step-additional-value"}>{item.additional[0]}</Typography.Paragraph>
                  <SvgIcon type={"voiceMail"} />
                </React.Fragment>
              ) : null}
            </Flex>
            <Flex align="center" justify="space-between" style={{ width: "100%" }}>
              {/* <div style={{display:'flex',justifyContent:'space-between'}}> */}
              <Flex>
                <Typography.Paragraph
                  // can consider adding here logic to not show it IF PAYING and have the chill guy of "no actions for today" since when clicked it doesn't do anything
                  className={"step-mode-title"}
                >
                  {item.mode}
                </Typography.Paragraph>{" "}
                {!finalSeq &&
                ((item.mode === "Ai Personalized Email" && invalidSteps?.some((inv) => inv?.id === "aiEmail")) ||
                  invalidSteps?.some((inv) => inv?.id === item?.id)) ? (
                  item.mode === "Templated Email" ? (
                    <Tooltip placement={"top"} title={<>Missing email, edit this step then click "Save"</>}>
                      {" "}
                      <SvgIcon type={"warning"} style={{ height: "15px", width: "15px", marginLeft: "8px" }} />
                    </Tooltip>
                  ) : (
                    <Tooltip placement={"top"} title={<>Missing backup email, edit this step</>}>
                      {" "}
                      <SvgIcon type={"warning"} style={{ height: "15px", width: "15px", marginLeft: "8px" }} />
                    </Tooltip>
                  )
                ) : (
                  ""
                )}
              </Flex>
            </Flex>
            {/* </div> */}
          </Space>
          {itemIdSel === item.id && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                // color: "#01aeef",
                fontWeight: "400",
                fontSize: "14px",
                width: "auto",
                textAlign: "right",
                // padding: '5px 10px',
              }}
            >
              {finalSeq ? `Viewing This Step` : `Editing Step`}
            </div>
          )}
          {/* <Space style={{marginLeft:'auto'}}> */}
          {/* </Space> */}
        </Space>

        {finalSeq && (
          <>
            <Divider style={{ margin: "6px 0 2px 0" }} />
            <div>Analytics:</div>
            {item.type === "email" && (
              <>
                <Flex style={{ marginTop: "-8px", marginBottom: "2px" }}>
                  <div>Best email variation found so far:</div>{" "}
                  <Tooltip
                    title={
                      <>
                        {!bestScore ? (
                          `No best variation found for this step yet`
                        ) : (
                          <>
                            {explanation}
                            <br />
                            <br />
                            *Calculated with an aggregated score
                          </>
                        )}
                      </>
                    }
                    placement={"top"}
                    rootClassName={"tooltip"}
                    color={"#ECFBFC"}
                  >
                    {" "}
                    <StatusTag
                      // value={bestScore}
                      value={!bestScore ? `None yet` : <>Variation {String.fromCharCode(64 + item?.emailVariantsArray[bestVariantIndex]?.variantNum)}</>}
                      // color={'gold'}
                      color={!bestScore ? "gold" : "rgba(43, 143, 130, 0.10)"}
                      style={{
                        color: !bestScore ? "default" : "#2B8F82",
                        padding: "3px 14px 2px 14px",
                        margin: "2px 0 0 5px",
                        cursor: "default",
                      }}
                      onClick={() => item?.emailVariantsArray?.length && setOpenViewAnalyticsModal(item)}
                    />
                  </Tooltip>
                  <Button className="link" onClick={() => item?.emailVariantsArray?.length && setOpenViewAnalyticsModal(item)} style={{ marginLeft: "10px" }}>
                    View Templates
                  </Button>
                </Flex>
              </>
            )}
            <Row justify={"space-between"} style={{ width: "100%" }}>
              {/* {Object.entries(item.status).map(([key, value]) => (
                <StatusTag
                  key={key}
                  value={`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}
                  color={statusColors[key]}
                  style={{ padding: "7px 14px" }}
                  onClick={() => showStatusModal(key)}
                />
              ))} */}

              {item.type === "phonecall" ? (
                <>
                  <StatusTag
                    // key={key}
                    value={`Calls Made: ${item.phone_analytic_calls_attempted?.toLocaleString()}`}
                    color={"cyan"}
                    style={{ padding: "7px 14px" }}
                    // onClick={() => showStatusModal(key)} TODOF later add
                  />
                  <StatusTag
                    // key={key}
                    value={`Marked Not Interested: ${item.phone_analytic_with_bad_status?.toLocaleString()}`}
                    color={"magenta"}
                    style={{ padding: "7px 14px" }}
                    // onClick={() => showStatusModal(key)} TODOF later add
                  />
                  <StatusTag
                    // key={key}
                    value={`Marked Good: ${item.phone_analytic_with_good_status?.toLocaleString()}`}
                    color={"success"}
                    style={{ padding: "7px 14px" }}
                    // onClick={() => showStatusModal(key)} TODOF later add
                  />
                </>
              ) : (
                <>
                  <StatusTag
                    // key={key}
                    value={`Sent: ${item.total_email_analytic_sent?.toLocaleString()}`}
                    color={"cyan"}
                    style={{ padding: "7px 14px" }}
                    // onClick={() => showStatusModal(key)} TODOF later add
                  />
                  <StatusTag
                    // key={key}
                    value={`Opened: ${item.total_email_analytic_opened?.toLocaleString()}`}
                    color={"processing"}
                    style={{ padding: "7px 14px" }}
                    // onClick={() => showStatusModal(key)} TODOF later add
                  />
                  <StatusTag
                    // key={key}
                    value={`Forwarded: ${item.total_email_analytic_forwarded?.toLocaleString()}`}
                    color={"purple"}
                    style={{ padding: "7px 14px" }}
                    // onClick={() => showStatusModal(key)} TODOF later add
                  />
                  <StatusTag
                    // key={key}
                    value={`Replied: ${item.total_email_analytic_replied?.toLocaleString()}`}
                    color={"success"}
                    style={{ padding: "7px 14px" }}
                    // onClick={() => showStatusModal(key)} TODOF later add
                  />
                  {/* <StatusTag
                  // key={key}
                  value={`Sent: ${item.total_email_analytic_replied}`}
                  color={'success'}
                  style={{ padding: "7px 14px" }}
                  // onClick={() => showStatusModal(key)} TODOF later add
                />
               */}
                </>
              )}
            </Row>
          </>
        )}
      </List.Item>
    );
  };

  return (
    <ContentCard
      headerIcon={<SvgIcon type={"statistic"} />}
      cardTitle={"Sequence"}
      headerRightContent={
        !loading
          ? !isEdit && (
              <Button className={"link plus-btn"} onClick={handleEditSequenceBtnClick}>
                Edit Sequence
              </Button>
            )
          : null
      }
    >
      <Flex vertical className={"steps-sequence-container"}>
        {loading ? (
          <Loader
            className={"loader-wrapper"}
            tip={
              finalSeq ? (
                `Loading Campaign Sequence`
              ) : (
                <>
                  Loading... {loadingSent[currentIndex]}
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div style={{ fontSize: "12px", color: "#897575", width: "80%", margin: "0 auto" }}>
                    Meetz is now gathering information online and drafting the most optimized A/B tested campaign based on your accounts previous performance,
                    this process can take up to 1-3 minutes.
                  </div>
                </>
              )
            }
            style={{ marginTop: "130px" }}
          />
        ) : (
          <>
            <DndProvider backend={HTML5Backend}>
              <CustomDragLayer />
              <div className={"steps-sequence-wrapper"}>
                {currentData && currentData.map((item, index) => <DraggableItem key={item.id} item={item} index={index} />)}
              </div>
            </DndProvider>
            {/* TODOn add "finished step when its in teh real campaign" */}
            {!finalSeq && (
              <Button className={"link"} onClick={showAddStepModal}>
                Add Step
              </Button>
            )}
            {openDeleteModal && deleteStep && (
              <DeleteStepModal
                open={openDeleteModal}
                isphone={deleteStep?.type === "phonecall"}
                onCancel={onCancel}
                step={deleteStep}
                handleDelete={handleDelete}
              />
            )}
            {!!saveFirst && <SaveBeforeChangeModal onSubmit={saveTemplates} onCancel={onDiscard} />}
            {openAddStepModal && (
              <SelectStepModal open={openAddStepModal} onCancel={onCancel} handleAddStep={handleAddStep} isPersonalizedAiUsed={isPersonalizedAiUsed()} />
            )}
            {openStatusTagInfoModal && <TagStatusInfoModal open={openStatusTagInfoModal} onCancel={onCancel} status={selectedStatusTag} />}
            {openChangeStepModal && <ChangeStepModal onSubmit={handleChangeStepConfirmed} onCancel={handleChangeStepRejected} onClose={closeChangeStepModal} />}
            {openViewAnalyticsModal?.id && <ViewAnalyticsModal item={openViewAnalyticsModal} onClose={closeViewAnalyticsModal} />}
          </>
        )}
      </Flex>
    </ContentCard>
  );
};
