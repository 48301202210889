import { Flex, Typography } from "antd"
import React, { FC } from "react"

interface CustomLabelProps {
  title: string
  subtitle?: string
  isFontBold?: boolean
}

export const CustomLabel: FC<CustomLabelProps> = ({ title, subtitle, isFontBold = false }) => {
  return (
    <Flex vertical>
      <Typography.Paragraph
        className={"secondary-label-title"}
        style={{ marginBottom: subtitle ? 6 : 8, color: isFontBold ? "#2E2E2E" : undefined }}
      >
        {title}
      </Typography.Paragraph>
      {subtitle ? (
        <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginBottom: 16 }}>
          {subtitle}
        </Typography.Paragraph>
      ) : null}
    </Flex>
  )
}
