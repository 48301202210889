/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-fragments */
import { Button, Checkbox, Col, Divider, Flex, Form, Input, Radio, Row, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ContentCard, CustomLabel, CustomSelect } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { StepsTypesCheckboxes } from "./ui/StepsTypesCheckboxes";
import { SaveValueModal, SelectFromSavedModal } from "../StepThree/ui";
import { outreachCampaignSelector, setAiGenProp } from "src/store/outreach-campaigns";
import { useDispatch, useSelector } from "react-redux";
import { callToActionsOptions } from "../StepThree/ui/AiPersonalizedEmail/ui/TestAiSettings/infoData";
import { reactiveLinksSelector } from "src/store/reactive-links-settings";
import { autoSave } from "src/store/outreach-campaigns/asyncRequests";
import { AppDispatch } from "src/store";

const topLanguages = [
  // update on backedn if needed
  "English (US)",
  "Spanish",
  "French",
  "German",
  "Dutch",
  "Hebrew",
  "Mandarin Chinese",
  "Italian",
  "Hindi",
  "Arabic",
  "Ukrainian",
  "Bengali",
  "Greek",
  "English (UK)",
  "English (AU)",
  "English (CA)",
  "Czech",
  "Hungarian",
  "Danish",
  "Norwegian",
  "Finnish",
  "Bengali",
  "Bulgarian",
  "Russian",
  "Portuguese",
  "Swedish",
  "Japanese",
  "Punjabi",
  "Turkish",
  "Korean",
  "Vietnamese",
  "Polish",
];
const { Text } = Typography;

export const validateNoSpecialChars = (_, value) => {
  const regex = /^[^\{\}\[\]]*$/;
  if (!value || regex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("No special characters allowed"));
};

export const StepOne: FC = ({ onNext, toggleHandleNextStep, step, setToggleHandleNextStep }) => {
  const [form] = Form.useForm();
  const [openSaveValueModal, setOpenSaveValueModal] = useState(false);
  const [checkErr, setCheckErr] = useState(false);
  const [checkFailed, setCheckFailed] = useState(false);
  const [valueProp, setValueProp] = useState("");
  const [savedValueProposition, setSavedValueProposition] = useState([]);
  // const [jobTitle, setJobTitle] = useState('')
  // const [companyName, setCompanyName] = useState('')
  // const [testimonial, setTestimonial] = useState('')
  // const [testimonialsWhoSaidIt, setTestimonialsWhoSaidIt] = useState('')
  const [selectedValProp, setSelectedValProp] = useState("");
  const [cta, setCta] = useState<string[]>([""]);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [openSelectFromSavedModal, setOpenSelectFromSavedModal] = useState(false);
  const closeModal = () => {
    setOpenSaveValueModal(false);
    setOpenSelectFromSavedModal(false);
  };
  // console.log(cta,'ctaaaaaaaaaaaa');
  const addCta = () => {
    setCta([...cta, ""]);
  };
  const remCta = (index) => {
    const newCta = [...cta];
    newCta.splice(index, 1);
    const bookMetWithLin = form.getFieldValue("callToAction");
    // console.log(newCta,index,cta,'ctaaaaaa',bookMetWithLin);
    setCta(newCta);
  };

  const { aiSettings, autoSaveToggle } = useSelector(outreachCampaignSelector);
  const { schedulingFormated } = useSelector(reactiveLinksSelector);
  const sortedMeetingLinks = [...schedulingFormated].sort((a, b) => (a.isAiGenerated === b.isAiGenerated ? 0 : a.isAiGenerated ? -1 : 1));
  console.log(sortedMeetingLinks, "sortedMeetingLinks");
  const [autoSend, setAutoSend] = useState(true);

  //  do not change any of these values without checking other places it could affect in FE and BE
  const [checkedEmailSteps, setCheckedEmailSteps] = useState<CheckboxValueType[]>([]);
  const [checkedPersSteps, setCheckedPersSteps] = useState<CheckboxValueType[]>([]);
  const [checkedPhoneSteps, setCheckedPhoneSteps] = useState<CheckboxValueType[]>([]);
  const [automations, setAutomations] = useState<CheckboxValueType[]>(["Automated Booking", "Auto Send Emails Upon Ai Generation"]);
  const [useLinks, setUseLinks] = useState(false);

  useEffect(() => {
    // TODOFN fast add this to look at the Genprop if its there and use that one should be simple
    setSavedValueProposition(aiSettings?.value_props || []);
    if (aiSettings?.id && !loadedOnce) {
      // console.log(aiSettings, "aiSettings");
      setUseLinks(!!aiSettings?.link_in_email?.id);
      setCalToUse(aiSettings?.link_in_email?.id || "");
      setCta(aiSettings?.call_to_action_custom_list || [""]);

      // when i will separate the bare with phone and emails so this logic has to be implemented here
      //  for now iwll have phne step here too
      setCheckedEmailSteps(aiSettings?.sequence_actions_emails || []);
      setCheckedPersSteps(aiSettings?.sequence_personalization_options || []);
      // setCheckedPhoneSteps(aiSettings?.sequence_actions_phones || []);

      form.setFieldsValue({
        valueProposition: aiSettings?.current_value_prop || "",
        companyName: aiSettings?.seller_company_name || "",
        jobTitle: aiSettings?.job_title || "",
        testimonials: aiSettings?.testimonial_quote || "",
        testimonialsWhoSaidIt: aiSettings?.testimonial_quote_whosaidit || "",
        language: aiSettings?.language_in_email || "English (US)",
        bookMetWithLink: aiSettings?.link_in_email?.id || sortedMeetingLinks?.length > 1 ? sortedMeetingLinks[1]?.id : "",
        callToAction: aiSettings?.call_to_action_custom_list || [],
      });
      setLoadedOnce(true); // at bottom so that the auto save gets all the values
    }
  }, [aiSettings]);

  const [calToUse, setCalToUse] = useState();
  const handleCalChange = (met) => {
    // console.log(met.target.value,'meetet');
    setCalToUse(met.target.value);
    form.setFieldsValue({ bookMetWithLink: met.target.value });
  };

  useEffect(() => {
    if (loadedOnce && selectedValProp?.length) {
      form.setFieldsValue({
        valueProposition: selectedValProp || "",
      });
    }
  }, [selectedValProp]);
  // useEffect(() => {
  //   if(aiSettings?.id){
  //     setSavedValueProposition(aiSettings?.value_props||[])
  //     setSavedValueProposition(aiSettings?.current_value_prop||'')
  // }
  // }, [aiSettings]);

  useEffect(() => {
    if (checkFailed) {
      form.validateFields(["stepTypes"]);
    }
  }, [checkErr, checkFailed]);

  useEffect(() => {
    setTimeout(() => {
      setToggleHandleNextStep(false);
    }, 600);
    if (toggleHandleNextStep && !step) {
      form.submit();
    }
  }, [toggleHandleNextStep, form]);

  const dispatch = useDispatch<AppDispatch>();

  const onFinish = (vals) => {
    // const vals2= form.getFieldsValue()
    autoSaveInput();
    // console.log("Success:", vals, vals2);
    dispatch(
      setAiGenProp({
        ...(vals || {}),
        sequenceActionsEmail: checkedEmailSteps,
        // sequenceActionPhone: checkedPersSteps,
        sequencePersonalization: checkedPersSteps,
        automations,
        callToAction: cta,
      })
    );
    onNext();
  };

  const autoSaveInput = () => {
    if (loadedOnce && !step) {
      const vals = form.getFieldsValue();
      dispatch(
        autoSave({
          ...(vals || {}),
          sequenceActionsEmail: checkedEmailSteps,
          // sequenceActionPhone: checkedPersSteps,
          sequencePersonalization: checkedPersSteps,
          automations,
          callToAction: cta,
          step: 1,
        })
      );
    }
  };

  useEffect(() => {
    autoSaveInput();
  }, [autoSaveToggle]);

  const handeAutoSettings = (e) => {
    setAutoSend(e.target.checked);
  };
  const handeUseLinkChng = (e) => {
    form.validateFields(["callToAction"]);
    setUseLinks(e.target.checked);
  };

  const onFinishFailed = (errorInfo) => {
    setCheckFailed(true);
    console.log("Failed:", errorInfo);
  };

  const validateAtLeastOne = (_, value) => {
    return !checkErr ? Promise.resolve() : Promise.reject(new Error("At least one outreach step must be selected"));
  };

  const validateAtLeastOneCTA = (_, value) => {
    const oneCustomCta = cta?.some((callToAction) => callToAction?.trim()?.length);
    return oneCustomCta || useLinks ? Promise.resolve() : Promise.reject(new Error("Add at least one CTA"));
  };

  const showSaveValueModal = () => {
    setOpenSaveValueModal(true);
  };

  const showSelectFromSavedModal = () => {
    setOpenSelectFromSavedModal(true);
  };
  const handleCtaChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    form.validateFields(["callToAction"]);
    const newCta = [...cta];
    newCta[index] = event.target.value;
    setCta(newCta);
  };
  const validateSecondInput = async (_, value) => {
    const firstInputValue = form.getFieldValue("testimonials");
    if (firstInputValue && !value) {
      return Promise.reject(new Error("This field is required if the testimonial is provided"));
    }
    return Promise.resolve();
  };
  const handleTestimonialsChange = () => {
    const firstInputValue = form.getFieldValue("testimonials");
    if (checkFailed || !firstInputValue) {
      form.validateFields(["testimonialsWhoSaidIt"]);
    }
  };

  const validateNoSpecialCharsArray = (_, value) => {
    console.log(value, "valeiuafd");
    if (!cta || cta.every((str) => /^[^\{\}\[\]]*$/.test(str))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("No special characters allowed"));
  };

  if (step) {
    return "";
  }

  // TODOFF much later add limits to how many words people enter here

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <ContentCard headerIcon={<SvgIcon type={"companyInfo"} />} cardTitle={"About Your Company"}>
            <CustomLabel
              title={
                <Flex align={"middle"} justify={"space-between"}>
                  <div>
                    Value Proposition
                    <Tooltip
                      placement={"bottom"}
                      title={
                        <>
                          <b>What if I have more than 1 value proposition?</b>
                          <br />
                          <br />
                          There are 2 ways to go about it depending on your service and your prospects.
                          <br />
                          <br />
                          <b>1.</b>
                          <u> If all your value propositions fit the same audience:</u> Then add all of them here and our personalization will automatically use
                          what is relevant to that specific client.
                          <br />
                          <br />
                          <b>2.</b> <u>If your value propositions are relevant to different types of prospects:</u> Then use only the value propostion per
                          campaign and 1 type of prospects per campaign.
                          <br />
                          <br />
                          Tip: List as much details as possible about your service and how it helps your clients.
                        </>
                      }
                    >
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                    </Tooltip>
                  </div>

                  <Row align={"middle"} style={{ marginTop: "5px" }}>
                    <Button className={"link"} onClick={showSaveValueModal}>
                      Save
                    </Button>
                    <Divider type={"vertical"} />
                    <Button className={"link"} onClick={showSelectFromSavedModal}>
                      Select From Saved
                    </Button>
                  </Row>
                </Flex>
              }
              subtitle={
                <>
                  Add The Main Key Benefit Clients Would Get By Using Your Company's Service/Product And The Specific Feature That Delivers It.
                  <br />
                  <br />
                  *Include a complete explanation about your value proposition even if it's long
                </>
              }
            />

            <Form.Item
              name={"valueProposition"}
              rules={[{ required: true, message: `Please enter a value proposition` }, { validator: validateNoSpecialChars }]}
            >
              <Input.TextArea
                placeholder={"Meetz easily optimizes outreach with automated A/B tests and using Ai personalized emails."}
                defaultValue={valueProp}
              />
            </Form.Item>
            <Divider />
            <CustomLabel
              title={"Testimonials To Include"}
              subtitle={"Add A Quote Your Customers Gave About Your Product Or Services, This Helps Raise Interest And Get Your Prospects Attention."}
              // subtitle={
              //   "Add A Quote Your Customers Gave With Or Add The ROI Your Customer Got From Your Product Or Services, include Your Customers Company Name."
              // }
            />
            <Form.Item name={"testimonials"} rules={[{ validator: validateNoSpecialChars }]}>
              <Input.TextArea
                placeholder={`We felt how powerful Meetz is within 5 minutes of signing up! In the first campaign we already got a few great demos.`}
                onChange={handleTestimonialsChange}
              />
            </Form.Item>
            <CustomLabel title={""} subtitle={"Who said the above?"} />
            <Form.Item name={"testimonialsWhoSaidIt"} rules={[{ validator: validateSecondInput }, { validator: validateNoSpecialChars }]}>
              <Input placeholder={`Rob, VP of Sales at Acmef`} />
            </Form.Item>
            <Divider />
            <CustomLabel title={"Your Job Title"} />
            <Form.Item name={"jobTitle"} rules={[{ validator: validateNoSpecialChars }]}>
              <Input placeholder={"SDR/ Account Executive/ VP Of Sales/ Founder"} />
            </Form.Item>
            <Divider />
            <CustomLabel title={"Your Company's Name"} />
            <Form.Item name={"companyName"} rules={[{ required: true, message: `Please enter a company name` }, { validator: validateNoSpecialChars }]}>
              <Input placeholder={"Meetz"} />
            </Form.Item>
          </ContentCard>
        </Col>
        <Col span={12}>
          <ContentCard headerIcon={<SvgIcon type={"fire"} />} cardTitle={"Custom Outreach"}>
            <Form.Item name={"stepTypes"} rules={[{ validator: validateAtLeastOne }]}>
              <CustomLabel title={"Types of steps in sequence"} />
              <StepsTypesCheckboxes
                setCheckErr={setCheckErr}
                checkedEmailSteps={checkedEmailSteps}
                setCheckedEmailSteps={setCheckedEmailSteps}
                setCheckedPersSteps={setCheckedPersSteps}
                automations={automations}
                setAutomations={setAutomations}
                checkedPersSteps={checkedPersSteps}
              />
            </Form.Item>
            <br />
            <Form.Item name={"callToAction"} rules={[{ validator: validateAtLeastOneCTA }, { validator: validateNoSpecialCharsArray }]}>
              <CustomLabel
                title={
                  <>
                    Call to action{" "}
                    <Tooltip
                      placement={"bottom"}
                      title={
                        <>
                          <b>Best practices for call to actions:</b>
                          <br />
                          <br />
                          <b>1.</b> <u>Add several CTA to let Meetz test them all:</u> Every line NEEDS to be a separate CTA.
                          <br />
                          <br />
                          <b>2.</b> <u>Give an easy yes or no question + add value if they say yes:</u> The yes or no question will trigger auto responses for
                          them in Gmail/Outlook making it easy for your prospects to click "Yes, I'm interested" and they can get value if they respond.
                          <br />
                          <br />
                          Example CTA: Is this of interest? I'm happy to send out a quick video showing how we can help your business.
                        </>
                      }
                    >
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                    </Tooltip>
                  </>
                }
              />
              <Flex className={"collapse-card-content"} gap={10} vertical>
                {cta?.map((callToA, index) => {
                  return (
                    <>
                      {" "}
                      <Flex justify="flex-start">
                        {" "}
                        <Input
                          onChange={(event) => handleCtaChange(index, event)}
                          className={"content-input"}
                          placeholder={"Would this be of interest?"}
                          value={callToA}
                        />
                        {cta?.length < 6 && index === cta?.length - 1 && (
                          <SvgIcon
                            type={"plus"}
                            style={{ margin: "auto", cursor: "pointer" }}
                            onClick={() => {
                              addCta();
                            }}
                          />
                        )}
                        {cta?.length > 1 && (
                          <SvgIcon
                            type={"closeTag"}
                            style={{ margin: "auto", cursor: "pointer" }}
                            onClick={() => {
                              remCta(index);
                            }}
                          />
                        )}
                      </Flex>
                    </>
                  );
                })}
                <Text> And </Text>
                <Checkbox checked={useLinks} onChange={handeUseLinkChng}>
                  Book meeting call to action
                </Checkbox>

                {useLinks && (
                  <Form.Item name={"bookMetWithLink"} style={{ marginLeft: "25px" }}>
                    <div style={{ fontWeight: 600 }}>
                      Meetz scheduling links:
                      <Tooltip placement={"topLeft"} title={<>You can edit these links or add more in the "Scheduling Links" tab.</>}>
                        <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                      </Tooltip>
                    </div>
                    <Radio.Group style={{ display: "flex", flexDirection: "column" }} onChange={handleCalChange} value={calToUse}>
                      {sortedMeetingLinks?.length > 1
                        ? sortedMeetingLinks?.map((mt, i) => {
                            // TODON if no selected use the first one as a default.
                            if (!i) {
                              return;
                            }
                            return (
                              <Radio value={mt.id} key={mt.id} style={{ margin: "3px" }}>
                                {mt?.name?.length > 200 ? mt?.name?.substring(0, 200) + "..." : mt?.name} - {`${mt.meetingLength / 60000} min`}
                              </Radio>
                            );
                          })
                        : ""}
                      {/* <Checkbox.Group className={"content-checkbox-group"} options={callToActionsOptions} /> */}
                    </Radio.Group>
                  </Form.Item>
                )}
              </Flex>
            </Form.Item>
            <br />
            <CustomLabel
              title={
                <>
                  Outreach language{" "}
                  <Tooltip
                    placement={"topLeft"}
                    title={<>Regardless of which language you select for the outreach, use English for all the fields in this page.</>}
                  >
                    <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
                  </Tooltip>
                </>
              }
            />
            <Form.Item name={"language"}>
              <CustomSelect options={topLanguages} defaultValue={"English (US)"} />
            </Form.Item>
          </ContentCard>
        </Col>
      </Row>
      <SaveValueModal defaultVal={form.getFieldValue("valueProposition")} open={openSaveValueModal} onCancel={closeModal} />
      <SelectFromSavedModal open={openSelectFromSavedModal} onCancel={closeModal} data={savedValueProposition} setSelectedValProp={setSelectedValProp} />
    </Form>
  );
};
