import { Flex } from "antd"
import { Group } from "./ui/Group"
import { FC, useContext, useEffect, useMemo, useState } from "react"
import { DealsContext } from "../../app/context/DealsContext"
import { generateRandomDigits } from "../../../helpers"
import "./index.less"

interface DealsProps {
  isTourStep?: boolean
}

export const Deals: FC<DealsProps> = ({ isTourStep }) => {
  const { dealsGroups } = useContext(DealsContext)
  const [tourStepData, setTourStepData] = useState(dealsGroups)
  const [data, setData] = useState(dealsGroups)
  const memoizedTourStepData = useMemo(() => tourStepData, [tourStepData]);

  useEffect(() => {
    setData(dealsGroups)
  }, [dealsGroups])

  return (
    <Flex gap={24} className={"deal-groups-wrapper"}>
      {isTourStep ? (
        memoizedTourStepData?.map(group => (
          <Group group={group} key={generateRandomDigits(10)} />
        ))
      ) : (
        data?.map(group => (
          <Group group={group} key={generateRandomDigits(10)} />
        ))
      )}
    </Flex>
  )
}
