import { App } from "../../../../../../../../types"

export const forStepTwoData: App.ForStepTwoProspectEntity[] = [
  {
    id: "1",
    isSequence: true,
    contacts: "Test@Meetz.Ai",
    firstName: "John",
    lastName: "Smith",
    emailed: "Not Sent",
    status: "",
    jobTitle: "Vice President",
    uploadName: "First Test Prospect",
    companyName: "Awesome Co.",
    companyUrl: "",
    linkedin: "",
    country: "Israel",
    localTime: "11:53 AM",
    phoneNumber: "D. +978698604586",
    email: "kan@test.ua",
  },
  {
    id: "2",
    isSequence: true,
    contacts: "Test2@Meetz.Ai",
    firstName: "Myra",
    lastName: "Mcdonald",
    emailed: "Not Sent",
    status: "",
    jobTitle: "Manager",
    uploadName: "First Test Prospect",
    companyName: "Great Co.",
    companyUrl: "",
    linkedin: "",
    country: "Ukraine",
    localTime: "12:53 AM",
    phoneNumber: "D. +978698604586",
    email: "test@test.ua",
  },
]
