import { Flex, Typography } from "antd";
import React, { FC } from "react";
import { manWithLaptop } from "src/shared/images";

export const ActivitiesEmptyData: FC = () => {
  return (
    <Flex vertical align={"center"} gap={18} style={{ padding: "63px 0 46px" }}>
      <img src={manWithLaptop} alt={"man with laptop"} width={244} />
      <Typography.Paragraph className={"dashboard-empty-text"} style={{ maxWidth: 150 }}>
        {/* No activity yet */} Dialer Coming Soon
      </Typography.Paragraph>
    </Flex>
  );
};
