import { Flex } from "antd";
import React, { FC } from "react";
import { Modal } from "src/components/ui";
import { CreateCampaignMethodSelect } from "./ui/CreateCampaignMethodSelect";
import { UpdatePlanBanner } from "../../CreateCampaign/ui";
import "./index.less";
import { outreachCampaignSelector } from "src/store/outreach-campaigns";
import { useSelector } from "react-redux";
import { userIsPayingSelector, userSelector } from "src/store/user";

type CreateCampaignsModalProps = {
  open: boolean;
  onCancel: VoidFunction;
};

export const CreateCampaignsModal: FC<CreateCampaignsModalProps> = ({ open, onCancel }) => {
  const closeMainModal = () => {
    onCancel();
  };

  const { outreachCampaignsList } = useSelector(outreachCampaignSelector);
  const payingClient = useSelector(userIsPayingSelector);
  const canAddMore = payingClient || outreachCampaignsList?.length <= 1;

  return (
    <Modal open={open} title={"Create Campaign"} width={530} onCancel={closeMainModal}>
      <Flex vertical gap={28}>
        {!canAddMore && (
          <UpdatePlanBanner title={"Create Campaign locked!"} closeOriginModal={closeMainModal} subtitle={"To create more than 1 campaign activate account."} />
        )}
        <CreateCampaignMethodSelect onCancel={onCancel} locked={!canAddMore} />
      </Flex>
    </Modal>
  );
};
