import { Button, Flex, Image, Row, Typography } from "antd";
import React from "react";
import runningManWithBox from "src/shared/images/runningManWithBox.webp";
import "./index.less";

export const GoToDesktopModal = () => {
  return (
    <Flex className={"go-to-desktop-modal"} vertical={true} align={"center"}>
      <Row className={"mobile-gradient1"} />
      <Row className={"mobile-gradient2"} />
      <Flex className={"box"} vertical={true} align={"center"}>
        <Image className={"banner"} preview={false} src={runningManWithBox} />
        <Typography.Title className={"main-title"}>
          Go to the <Typography.Text className={"accent-text"}>Desktop App!</Typography.Text>
        </Typography.Title>
        <Typography.Paragraph className={"description"}>
          To see all your new leads and future clients, switch to Meetz.Ai on your computer. See you there!
        </Typography.Paragraph>
      </Flex>
      <Button className={"link meetz-link"}>Meetz.ai</Button>
    </Flex>
  );
};
