import { Row, RowProps, Tooltip, Typography } from "antd"
import React, { FC } from "react"
import { SvgIcon } from "src/shared/icons"

interface RadioContentWithTooltip extends RowProps {
  radioTitle: string
  tooltipTitle: string
}

export const RadioContentWithTooltip: FC<RadioContentWithTooltip> = ({ radioTitle, tooltipTitle }) => {
  return (
    <Row align={"middle"}>
      <Typography.Paragraph>{radioTitle}</Typography.Paragraph>
      <Tooltip placement={"bottomRight"} title={tooltipTitle}>
        <SvgIcon type={"tooltip"} style={{ marginLeft: 8, cursor: "pointer" }} />
      </Tooltip>
    </Row>
  )
}
