/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState } from "react";
import { Input, message } from "antd";
import { ConfirmButtons, Modal } from "src/components/ui";

export const RenameModal = ({ open, onSubmit, idEditing, defaultVal, closeModal }) => {
  const [value, setValue] = useState(defaultVal);

  useEffect(() => {
   setValue(defaultVal)
  }, [defaultVal,idEditing]);

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if(!value?.trim()?.length){
message.error(`Must have a campaign name`);
    }

    if(value?.trim()!==defaultVal){
    onSubmit(idEditing,value);
  }else{
    message.success('Updated Name')
    closeModal()
  }
  }


  return (
    <>
      <Modal width={530} open={open} title={"Rename Campaign"} onCancel={closeModal}>
        <Input value={value} onChange={handleInputChange} /><br /><br />
        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Rename"}
          handleSubmit={handleSubmit}
          onCancel={closeModal}
        />
      </Modal>
    </>
  );
};