export const getHotProspectsTagColor = (item: string) => {
  switch (true) {
    case item === "Open":
      return "cyan"
    case item === "Reply":
      return "blue"
    case item === "Booked":
      return "green"
    case item === "Positively Answered Calls":
      return "purple"
    default:
      return "magenta"
  }
}
