import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import PageLayout from "src/components/layout/PageLayout";
import { ConfirmButtons } from "src/components/ui";
import { CreateCampaignsStepsProgress } from "./ui/StepsProgress";
import { StepTwo, StepThree, StepOne } from "./ui";
import { useNavigate } from "react-router-dom";
import { Row, Typography } from "antd";
import { SvgIcon } from "src/shared/icons";
import { useDispatch, useSelector } from "react-redux";
import { outreachCampaignSelector, togAutoSaveToggle } from "src/store/outreach-campaigns";
import { createCampaignStepsWithAi, getFilteredLeadsRst } from "src/store/outreach-campaigns/asyncRequests";

type Step = {
  key: string;
  step: number;
  component: ReactNode;
};

// const steps: Step[] = [
//   { key: "1", step: 0, component: <StepOne /> },
//   { key: "2", step: 1, component: <StepTwo /> },
//   { key: "3", step: 2, component: <StepThree /> },
// ];
// TODON TODOC change all manual emails to templated emails
export const AIGeneratedSequence: FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(0);
  // console.log(step, "stepstep");
  const [toggleHandleNextStep, setToggleHandleNextStep] = useState(false);
  const formSubmitRef = useRef(null);

  useEffect(() => {
    if (toggleHandleNextStep) {
      setToggleHandleNextStep(false); // making sure it will always reset sometimes it doesnt
    }
  }, [toggleHandleNextStep]);

  // const onNextStep = () => {
  //   if (step !== steps?.length) {
  //     setStep(prevState => prevState + 1)
  //   }
  // }
  const onPreviousStep = () => {
    setStep((prevStep) => (prevStep - 1 >= 0 ? prevStep - 1 : 0));
  };

  const handleNextStep = async () => {
    if (!toggleHandleNextStep) {
      setToggleHandleNextStep(false);
      await setTimeout(() => {
        setToggleHandleNextStep(true);
      }, 300);
      return;
    }

    setToggleHandleNextStep(true);
    // if (formSubmitRef.current) {
    //   formSubmitRef.current();
    // } else {
    //   // nextStep();
    // }
  };

  const onFinish = () => {
    console.log("Campaign activated ");
    navigate("/campaigns");
  };

  const rightHeaderCreateContent = (
    <ConfirmButtons
      width={step !== 0 ? (step === 2 ? "260px" : "233px") : "91px"}
      leftButtonTitle={step !== 0 ? "Back" : null}
      rightButtonTitle={step !== 2 ? "Next step" : "Save & Create Campaign"}
      onCancel={onPreviousStep}
      handleSubmit={handleNextStep}
      // handleSubmit={step!== 2 ? onNextStep : onFinish}
    />
  );
  const dispatch = useDispatch();

  const handleExit = () => {
    dispatch(togAutoSaveToggle());
    dispatch(
      getFilteredLeadsRst({
        uploadName: "All",
        recentlyUpdated: false,
        includeInCampaign: false,
        includeContactedAlready: false,
        page: 1,
        pageChange: false,
        selectedCampaignId: null,
      })
    );
    navigate("/campaigns");
  };

  const pageTitle = (
    <Row align={"middle"} style={{ gap: 16 }}>
      <SvgIcon type={"greyCancel"} onClick={handleExit} style={{ cursor: "pointer", marginTop: "3px" }} />
      <Typography.Title level={2} className={"page-title"}>
        {"Creating AI Generated Sequence"}
      </Typography.Title>
    </Row>
  );

  const { aiGenProps } = useSelector(outreachCampaignSelector);

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const previousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  // const CurrentStepComponent = steps[step].component;

  const [startedASHeartbeat, setStartedASHeartbeat] = useState(false);
  // const activateAutoSave = () => {
  //   if (!startedASHeartbeat) {
  //     setStartedASHeartbeat(true);
  //     // setStep((prevStep) => prevStep - 1);
  //   }
  // };

  useEffect(() => {
    if (!startedASHeartbeat) {
      setStartedASHeartbeat(true);

      const intervalId = setInterval(() => {
        dispatch(togAutoSaveToggle());
      }, 5000);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <PageLayout
      pageTitle={pageTitle}
      bottomHeaderContent={<CreateCampaignsStepsProgress currentStep={step} />}
      rightHeaderContent={rightHeaderCreateContent}
      isTableContent={false}
    >
      {/* not using step===0 && because it resets the form, leave as is */}

      <StepOne onNext={nextStep} step={step} toggleHandleNextStep={toggleHandleNextStep} setToggleHandleNextStep={setToggleHandleNextStep} />
      <StepTwo onNext={nextStep} step={step} toggleHandleNextStep={toggleHandleNextStep} setToggleHandleNextStep={setToggleHandleNextStep} />
      <StepThree step={step} toggleHandleNextStep={toggleHandleNextStep} setToggleHandleNextStep={setToggleHandleNextStep} from={"ai"} />
    </PageLayout>
  );
};
