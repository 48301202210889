import { DatePicker, Flex, Form, Input, InputNumber } from "antd"
import { FC, useContext } from "react"
import { DealsContext } from "../../../../app/context/DealsContext"
import { ConfirmButtons, Modal } from "../../../../ui"
import "./index.less"

interface EditDealModalProps {
  onClose: () => void
}

export const EditDealModal: FC<EditDealModalProps> = ({ onClose }) => {
  const { dealsGroups, selectedDealId, editDeal } = useContext(DealsContext)
  const [form] = Form.useForm()

  const currentDeal = dealsGroups.reduce((foundDeal: any, group) => {
    if (!foundDeal) {
      return group.deals.find(deal => deal.id === selectedDealId)
    }
    return foundDeal
  }, null)

  const handleSubmitBtnClick = () => {
    form
      .validateFields()
      .then(values => {
        const updatedDeal = { ...currentDeal, ...values }
        editDeal(updatedDeal)
        onClose()
      })
      .catch(errorInfo => {
        console.error("Validation failed:", errorInfo)
      })
  }

  return (
    <Modal open={true} title={"Edit Deal"} onCancel={onClose} rootClassName={"edit-deal-modal"}>
      <Form
        form={form}
        layout={"vertical"}
        initialValues={currentDeal}
        requiredMark={false}
        className={"edit-deal-form"}
      >
        <Flex vertical={true} gap={16}>
          <Form.Item
            name={"name"}
            label={"Title"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={"Enter Text"} />
          </Form.Item>
          <Form.Item
            name={"date"}
            label={"Date"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name={"price"}
            label={"Potential deal size"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber placeholder={"Enter Text"} controls={false} />
          </Form.Item>
          <Form.Item
            name={"description"}
            label={"Notes"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={"Enter Text"} />
          </Form.Item>
        </Flex>
      </Form>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Save"}
        className={"confirm-buttons"}
        handleSubmit={handleSubmitBtnClick}
        onCancel={onClose}
      />
    </Modal>
  )
}
