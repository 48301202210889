import { Button, Col, Empty, Image, Row, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { FC, Fragment, useEffect, useState } from "react";
import { PhoneCallsStatus } from "../PhoneCallsStatus";
import { Table, TableHeader } from "src/components/ui";
import { App } from "src/types";
import NoData from "src/shared/images/callingperson.png";
import { phoneCallsData } from "../../info";
import "./index.less";

export const PhoneCallsTable: FC = () => {
  const [loading, setLoading] = useState(false);

  const columns: ColumnsType<App.PhoneCallsEntity> = [
    {
      title: "Contact name",
      render: (record) => `${record.firstName} ${record.lastName}`,
      key: "name",
      align: "center",
    },
    {
      title: "Last time contacted",
      dataIndex: "lastTimeContacted",
      key: "lastTimeContacted",
      align: "center",
    },
    {
      title: "Contents",
      render: (record) => (
        <Col>
          <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ width: 180 }} className={"content"}>
            {record.contents}
          </Typography.Paragraph>
          <Row align={"middle"} style={{ gap: 3 }}>
            <Typography.Paragraph className={"company-step"}>{`Step ${record.company.step} in`}</Typography.Paragraph>
            <Button type={"link"} className={"company-link"}>
              {record.company.name}
            </Button>
          </Row>
        </Col>
      ),
      key: "contents",
      align: "center",
    },
    {
      title: "Total times called",
      dataIndex: "totalTimesCalled",
      key: "totalTimesCalled",
      align: "center",
    },
    {
      title: "Total incoming calls",
      dataIndex: "totalIncomingCalls",
      key: "totalIncomingCalls",
      align: "center",
    },
    {
      title: "Status",
      render: (record) => (
        <Row wrap={false}>
          {record?.status?.map((status: App.PhoneCallsStatus) => (
            <PhoneCallsStatus key={status} status={status} />
          ))}
        </Row>
      ),
      key: "status",
      align: "center",
    },
  ];

  return (
    <Fragment>
      {/* <TableHeader searchPlaceholder={"Search..."} /> */}
      <Table
        customClassName={"phone-calls-table"}
        loading={loading}
        columns={columns}
        //   dataSource={phoneCallsData}
        locale={{
          emptyText: (
            <Empty
              image={<Image preview={false} src={NoData} alt={"No data"} width={173} height={150} />}
              imageStyle={{
                height: 180, // Ensure the image height is consistent with its actual height
                marginTop: "30px",
              }}
              description={<span style={{ marginTop: "10px" }}>Analytics coming soon.</span>} // Add margin to separate text from the image
            />
          ),
        }}
      />
    </Fragment>
  );
};
