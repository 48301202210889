import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  token: {
    fontFamily: "'Outfit', sans-serif",
    colorText: "#464A57",
  },
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      colorPrimaryBg: "unset",
      groupTitleColor: "#919AA7",
      groupTitleFontSize: 12,
      groupTitleLineHeight: 1.26,
      itemColor: "#606B7B",
      itemHoverBg: "transparent",
      itemSelectedBg: "transparent",
      itemSelectedColor: "#01AEEF",
      itemActiveBg: "transparent",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "#FFF",
      bodyBg: "#F7F8FA",
      siderBg: "#FFF",
    },
    Typography: {
      colorText: "#464A57",
      fontSize: 14,
      colorLink: "#01AEEF",
    },
    Segmented: {
      trackBg: "#EDEFF3",
      itemHoverBg: "transparent",
    },
    Table: {
      headerBg: "#FFF",
      stickyScrollBarBorderRadius: 8,
    },
    Input: {
      borderRadius: 36,
    },
    Tabs: {
      inkBarColor: "#01AEEF",
    },
    Cascader: {
      dropdownHeight: 300,
    },
    Radio: {
      colorPrimary: "#01AEEF",
    },
    Checkbox: {
      colorPrimary: "#01AEEF",
      colorBgContainerDisabled: "#67CEF5",
      colorTextDisabled: "#FFF",
    },
    Switch: {
      colorPrimary: "#01AEEF",
    },
    Tooltip: {
      colorBgSpotlight: "#ECFBFC",
      colorTextLightSolid: "#606B7B",
      borderRadius: 8,
    },
    Spin: {
      colorPrimary: "#01AEEF",
    },
  },
}

export default theme
