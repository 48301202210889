/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable import/prefer-default-export */
// import { useSelector } from 'react-redux';

import axios from "axios";
import { API_BASE } from "src/pages/constant";
import { decode as atob, encode as btoa } from "base-64";

// import store from '../index';

// const csrfImp:any = csrf
// const csrfToken =
// const csrfToken='1'
// const csrfToken = (new csrf()).create(import.meta.env.VITE_REACT_APP_CSRF_TOKEN);

// TODOF teams for teams use an apiHandler that updates the state on the real JWT but gets the team members JWT - might have to create a pipeline on the backend. For sure CANT send them to external website. those options will be disabled but anything else can easily be converted. + CHANGE USER SELECTOR to real user selector, but also add a displayed user selector, which will be updated in the initiated function - so will have to change the userSelector everywhere eceot for the dropdown of course.

export const apiHandler = async ({
  url,
  body = null,
  method = "post",
  // contentType = "application/json",
  contentType = "application/json",
  params,
  longTimer,
  downloadFileName = false,
  iframeJwt = null,
}: {
  url?: any;
  longTimer?: any;
  body?: any;
  method?: any;
  contentType?: any;
  params?: any;
  downloadFileName?: any;
  iframeJwt?: any;
}) => {
  // const currentState = store.getState();
  // console.log(currentState, 'currentState');
  // const [iframeJwt] = useSelector(helpersSelector);
  let req_url: string;
  if (params) {
    req_url = `${API_BASE + url}?${new URLSearchParams(params)}`;
  } else {
    req_url = API_BASE + url;
  }
  // console.log('Before to send');
  let token;
  // if (window.location.hostname.endsWith('meetz.ai')) {
  //   console.log('looks like in meetz');
  //   console.log(localStorage, 'localhstiorage', window.location);
  // }

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  setTimeout(
    () => {
      source.cancel("Operation canceled by the user.");
    },
    longTimer ? 300000 : 60000
  );
  // console.log(window.self === window.top, "window.self === window.top");
  if (window.self === window.top) {
    token = localStorage.getItem("token");
    // try {
    //   console.log("beforeqwhat", req_url, {
    //     method,
    //     mode: "cors",
    //     headers: new Headers({
    //       "Content-Type": contentType,
    //       "Authorization": `Bearer ${token}`,
    //       "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN as string),
    //     }),
    //     body: body && JSON.stringify(body),
    //   })
    //   const what = await axios({
    //       method: method,
    //       url: req_url,
    //       headers: {
    //         "Content-Type": contentType,
    //         "Authorization": `Bearer ${token}`,
    //         "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN),
    //       },
    //       data: body,
    //       withCredentials: true,
    //         timeout: longTimer ? 300000 : 60000,
    //     })
    //   console.log(what, "whatwhat")
    // } catch (e) {
    //   console.log(e, "Eror1 in api")
    // }
    // const response = await fetch(req_url, {
    //   method,
    //   mode: "cors",
    //   headers: new Headers({
    //     "Content-Type": contentType,
    //     "Authorization": `Bearer ${token}`,
    //     "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN as string),
    //   }),
    //   body: body && JSON.stringify(body),
    // })
    // console.log("longTimerlongTimer", !!token);
    let response;
    try {
      // console.log(
      //   {
      //     req_url,
      //     token,
      //     csrf: btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN),
      //     body: body && JSON.stringify(body),
      //   },
      //   "axios request"
      // );
      response = !downloadFileName
        ? // ? await fetch(req_url, {
          //     method,
          //     mode: "cors",
          //     headers: new Headers({
          //       "Content-Type": contentType,
          //       Authorization: `Bearer ${token}`,
          //       "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN),
          //     }),
          //     body: body && JSON.stringify(body),
          //   })
          await axios({
            method: method,
            url: req_url,
            headers: {
              "Content-Type": contentType,
              // Accept: "application/json",
              // "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN),
            },
            data: body,
            withCredentials: true,
            timeout: longTimer ? 300000 : 60000,
            // cancelToken: source.token
          })
        : await axios({
            method: method,
            url: req_url,
            headers: {
              "Content-Type": contentType,
              Authorization: `Bearer ${token}`,
              "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN),
            },
            data: body,
            withCredentials: true,
            responseType: "blob",
            timeout: longTimer ? 300000 : 60000,
            // cancelToken: source.token
          });

      // console.log(response, "response from axios");
    } catch (error) {
      console.error("Axios error message:", error.message);
      console.error("Axios error code:", error.code);
      // Log Axios config
      console.error("Axios error config:", error.config);
      console.error("Request error URL:", error.config.url);
      console.error("Request error Method:", error.config.method);
      console.error("Request error Headers:", error.config.headers);
      if (error.config.data) {
        console.error("Request error Data:", error.config.data);
      }
      if (error.response) {
        console.error("Axios error response status:", error.response.status);
        console.error("Axios error response data:", error.response.data);
        console.error("Axios error response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Axios error request:", error.request);
      }

      // NEVER REMOVE THIS PART!!!!!!!!!!!!!!!!!
      throw error;
    }
    // console.log(response, "to send")
    if (downloadFileName) {
      const urlToDown = window.URL.createObjectURL(response?.data);

      // Create a link and set its properties
      const link = document.createElement("a");
      link.href = urlToDown;
      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);
      return "success";
    }
    // console.log(response, "res to send") // dont add .json to console log will make an error
    // return response.json()
    return response?.data;
  } else if (iframeJwt) {
    // In an iframe, listen for the token from the parent
    // window.addEventListener('message', async (event) => {
    // Check the origin if necessary, here assuming it's from a trusted parent
    // console.log(event, 'evvApi', body);
    // if (event?.data?.type === 'TOKEN_RESPONSE') {
    // token = event?.data?.token;
    // console.log(iframeJwt, csrfToken, 'evvApiGotIn');

    const response = !downloadFileName
      ? await axios({
          method: method,
          url: req_url,
          headers: {
            "Content-Type": contentType,
            Authorization: `Bearer ${iframeJwt}`,
            "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN),
          },
          data: body,
          withCredentials: true,
          timeout: longTimer ? 300000 : 60000,
          // cancelToken: source.token
        })
      : await axios({
          method: method,
          url: req_url,
          headers: {
            "Content-Type": contentType,
            Authorization: `Bearer ${iframeJwt}`,
            "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN),
          },
          data: body,
          withCredentials: true,
          timeout: longTimer ? 300000 : 60000,
          responseType: "blob",
          // cancelToken: source.token
        });
    // console.log(response, 'evvApiGotOut');
    if (downloadFileName) {
      const urlToDown = window.URL.createObjectURL(response?.data);

      // Create a link and set its properties
      const link = document.createElement("a");
      link.href = urlToDown;
      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();

      // Remove the link from the DOM
      document.body.removeChild(link);
      return "success";
    }
    // console.log(response.json(), 'json to send');
    return response.data;
    // }
    // });
    //  store the whole request and send it out once the token is also received
    // console.log(token, 'Token');
    // console.log(window.parent.postMessage({ type: 'token' }, '*'), 1);
    // Ask parent for the token
    // window.parent.postMessage({ type: 'REQUEST_TOKEN' }, '*')

    // window.parent.postMessage({ type: 'REQUEST_TOKEN', tokenName: 'token' }, '*');
    // }
    // console.log(token,btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN as string), contentType, 'after to send');
  }
};

export const apiLinkHandler = async ({
  url,
  body = null,
  method = "post",
  contentType = "application/json",
  params,
}: {
  url: any;
  body: any;
  method: any;
  contentType: any;
  params: any;
}) => {
  let req_url: any;
  if (params) {
    req_url = `${API_BASE + url}?${new URLSearchParams(params)}`;
  } else {
    req_url = API_BASE + url;
  }

  const token = localStorage.getItem("guestAvailJwt");

  const response = await axios({
    method: method,
    url: req_url,
    headers: {
      "Content-Type": contentType,
      Authorization: `Bearer ${token}`,
      "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN),
    },
    data: body,
    withCredentials: true,
  });

  return response.data;
};

// export const apiHumanHandler = async ({
//   url,
//   body = null,
//   method = "post",
//   contentType = "application/json",
//   params,
// }: {
//   url: any
//   body: any
//   method: any
//   contentType: any
//   params: any
// }) => {
//   let req_url
//   if (params) {
//     req_url = `${API_BASE + url}?${new URLSearchParams(params)}`
//   } else {
//     req_url = API_BASE + url
//   }

//   const token = localStorage.getItem("tokenhumanchecker")
//   const response = await fetch(req_url, {
//     method,
//     mode: "cors",
//     headers: new Headers({
//       "Content-Type": contentType,
//       "Authorization": `Bearer ${token}`,
//       "X-CSRF-Token": btoa(import.meta.env.VITE_REACT_APP_CSRF_TOKEN as string),
//     }),
//     body: body && JSON.stringify(body),
//   })

//   return response.json()
// }
