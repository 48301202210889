import { SliderSingleProps } from "antd"

export const shortenEmailOptions: SliderSingleProps["marks"] = {
  0:"Brief",
  25: "Short",
  50: "Moderate",
  75: "Long (Best)",
  100:  "Very Long",
}
export const helpingHandOptions: SliderSingleProps["marks"] = {
  0: "No Help",
  25: "Some Aid",
  50: "Helpful",
  75: "Supportive",
  100: "Very Support",
};
export const providingValueOptions: SliderSingleProps["marks"] = {
  0: "Basic",
  25: "Added",
  50: "Valuable",
  75: "High",
  100: "Maximum",
}
export const discussingPainOptions: SliderSingleProps["marks"] = {
  0: "Ignore",
  25: "Hint",
  50: "Mention",
  75: "Emphasize",
  100: "Highlight",
}

export const callToActionsOptions = [
  { label: "Is this of interest?", value: "Is this of interest?" },
  { label: "Book Meeting With Meetz Link", value: "Book Meeting With Meetz Link" },
]

export const reactiveMeetingLinkOptions = [{ label: "Intro Meeting", value: "Intro Meeting" }]

export const salesMethodologyOptions = [
  { label: "Consultative - Recommended", value: "consultative" },
  { label: "Strong Sales - Use proven sales tactics", value: "strong_sales" },
  { label: "Urgency - B2C high volume sales", value: "urgency" },
  { label: "Emotional Intelligence - Soft approach", value: "emotional_intelligence" },
]

export const companyNameOptions = [
  { label: "No Special Characters", value: "No Special Characters" },
  { label: "No Company Initials Ex: Inc..com", value: "No Company Initials Ex: Inc..com" },
  { label: "Urgency", value: "Urgency" },
  { label: "1 Spelling Mistake", value: "1 Spelling Mistake" },
  { label: "1 Lower Case", value: "1 Lower Case" },
]

export const languageOptions = [{ label: "English (Default)", value: "English" }]

export const savedValueProposition  = [
  {
    id: "1",
    name: "Winter Wonderland Discounts",
    description: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
  },
  {
    id: "2",
    name: "Summer Wonderland",
    description: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
  }
]