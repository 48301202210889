import { useContext, useEffect } from "react"
import { CommonContext } from "../../app/context/CommonContext"
import { Button, Flex, Typography } from "antd"
import { SvgIcon } from "../../../shared/icons"
import "./index.less"

export const ViewingProcess = () => {
  const { setCurrentView, setIsViewingProcess } = useContext(CommonContext)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsViewingProcess(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, [])

  const handleBackToMyViewClick = () => {
    setIsViewingProcess(false)
    setCurrentView("Personal")
  }

  return (
    <Flex vertical={true} className={"viewing-process-wrapper"}>
      <Flex className={"viewing-process-box"} gap={16} align={"center"}>
        <Flex gap={8} align={"center"}>
          <Typography.Paragraph className={"viewing-process-title"}>Viewing John Smith's account </Typography.Paragraph>
          <SvgIcon type={"arrowDown"} className={"arrow-down"} width={16} height={16} />
        </Flex>
        <Button className={"back-to-my-view"} onClick={handleBackToMyViewClick}>Back to my View</Button>
      </Flex>
    </Flex>
  )
}