import React, { createContext, useState } from "react"

interface ProspectDataContextProps {
  children: React.ReactNode
}

export const ProspectDataContext = createContext<{
  filterFormData: any
  setFilterFormData: React.Dispatch<any>
}>({
  filterFormData: {},
  setFilterFormData: () => null,
})

export const ProspectDataContextProvider: React.FC<ProspectDataContextProps> = ({ children }) => {
  const [filterFormData, setFilterFormData] = useState<any>({ firstName: [] })

  const contextValue = {
    filterFormData,
    setFilterFormData,
  }

  return <ProspectDataContext.Provider value={contextValue}>{children}</ProspectDataContext.Provider>
}
