import { Flex, Typography } from "antd"
import { FC } from "react"
import { ConfirmButtons, Modal } from "../../../../ui"
import "./index.less"

interface CancelSubscriptionModalProps {
  cancelSubscription: () => void
  onClose: () => void
}

export const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({ cancelSubscription, onClose }) => {
  const handleBookCallBtnClick = () => {
    window.location.href = "/book-meeting"
  }

  return (
    <Modal
      title={"Are you sure you want to cancel your subscription?"}
      rootClassName={"cancel-subscription-modal"}
      open={true}
      onCancel={onClose}
    >
      <Flex vertical={true} gap={16}>
        <Typography.Paragraph className={"description"}>
          All your data, outreach flows, and optimizations the Ai learned from your account will be erased and will need
          to be retrained.{" "}
          <Typography.Text className={"link"} onClick={cancelSubscription}>
            Cancel Subscription Anyways
          </Typography.Text>
        </Typography.Paragraph>
        <Typography.Paragraph className={"description"}>
          We're happy to help get you onboarded and help optimize your account to ensure you're getting solid leads.
        </Typography.Paragraph>
      </Flex>
      <ConfirmButtons
        className={"confirm-buttons"}
        leftButtonTitle={"Back"}
        rightButtonTitle={"Book a strategy call"}
        handleSubmit={handleBookCallBtnClick}
        onCancel={onClose}
      />
    </Modal>
  )
}
