import { Flex, Radio, Typography } from "antd";
import React, { FC, useState } from "react";
import { WeekTimePlanner } from "../WeekTimePlanner";
import "./index.less";

interface WeekTimePlannerWithTabsProps {
  formItemName?: string[];
  className?: string;
  initialAvail: any[];
  scheduling?: boolean;
  setInitialAvail: (value: any) => void;
  // onChange?: (value: any) => void
}

export const WeekTimePlannerWithTabs: FC<WeekTimePlannerWithTabsProps> = ({
  formItemName = [],
  className,
  // onChange,

  setInitialAvail,
  initialAvail,
  scheduling,
  ...props
}) => {
  const [selectedTimeFormat, setSelectedTimeFormat] = useState("AM/PM");
  const timeFormat = selectedTimeFormat === "24h" ? "HH:mm" : "h:mm A";

  return (
    <Flex vertical={true} gap={24} className={className ? `week-time-planner-with-tabs ${className}` : "week-time-planner-with-tabs"} {...props}>
      {/* <Flex vertical={true} gap={8}>
        <Typography.Paragraph className={"label"}>Select Time Format</Typography.Paragraph>
        <Radio.Group defaultValue={"24h"} size={"middle"} onChange={e => setSelectedTimeFormat(e.target.value)}>
        <Radio.Button value={"AM/PM"}>AM/PM</Radio.Button>
          <Radio.Button value={"24h"}>24h</Radio.Button>
        </Radio.Group>
      </Flex> */}
      <Flex vertical={true} gap={8}>
        <Typography.Paragraph className={"label"}>{scheduling ? "This Meetings Availability" : "Sending Times Range (Start ➡️ End)"}</Typography.Paragraph>
        <WeekTimePlanner
          formItemName={formItemName}
          timeFormat={timeFormat}
          initialAvail={initialAvail}
          setInitialAvail={setInitialAvail}
          scheduling={scheduling}
        />
      </Flex>
    </Flex>
  );
};
