import { Space, Typography } from "antd"
import React, { FC } from "react"
import { SvgIcon } from "src/shared/icons"

interface DialerPopupInfoProps {
  iconType: string
  bottomText: string
}
export const DialerPopupInfo: FC<DialerPopupInfoProps> = ({ iconType, bottomText }) => {
  return (
    <Space direction={"vertical"} align={"center"} style={{ marginTop: 16 }}>
      <SvgIcon type={iconType} />
      <Typography.Paragraph style={{ color: "#01AEEF" }}>{bottomText}</Typography.Paragraph>
    </Space>
  )
}
