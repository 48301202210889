import React, { createContext, useState } from "react"

interface CampaignsContextProps {
  children: React.ReactNode
}

export const CampaignsContext = createContext<{
  campaignName: string
  setCampaignName: React.Dispatch<React.SetStateAction<string>>
  selectedStep: string
  setSelectedStep: React.Dispatch<React.SetStateAction<string>>
  isStepsInCampaignPage: boolean
  setIsStepsInCampaignPage: React.Dispatch<React.SetStateAction<boolean>>
  openParallelDialerModal: boolean
  setOpenParallelDialerModal: React.Dispatch<React.SetStateAction<boolean>>
}>({
  openParallelDialerModal: false,
  setOpenParallelDialerModal: () => null,
  isStepsInCampaignPage: false,
  setIsStepsInCampaignPage: () => null,
  campaignName: "",
  setCampaignName: () => null,
  selectedStep: "",
  setSelectedStep: () => null,
})

export const CampaignsContextProvider: React.FC<CampaignsContextProps> = ({ children }) => {
  const [campaignName, setCampaignName] = useState("")
  const [selectedStep, setSelectedStep] = useState("")
  const [isStepsInCampaignPage, setIsStepsInCampaignPage] = useState(false)
  const [openParallelDialerModal, setOpenParallelDialerModal] = useState(false)

  const contextValue = {
    openParallelDialerModal,
    setOpenParallelDialerModal,
    isStepsInCampaignPage,
    setIsStepsInCampaignPage,
    selectedStep,
    setSelectedStep,
    campaignName,
    setCampaignName,
  }

  return <CampaignsContext.Provider value={contextValue}>{children}</CampaignsContext.Provider>
}
