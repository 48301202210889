import { Button, Flex, Row, Typography } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import "./index.less";
import { setSkipTrial } from "src/store/user";
import { useDispatch } from "react-redux";

interface UpdatePlanBannerProps {
  title: string;
  subtitle: string;
  closeOriginModal?: any;
}
export const UpdatePlanBanner: FC<UpdatePlanBannerProps> = ({ title, subtitle, closeOriginModal }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  return (
    <Flex className={"create-campaign-modal-banner"} align={"center"} gap={127}>
      <Flex vertical gap={2} style={{ maxWidth: 191 }}>
        <Typography.Paragraph className={"banner-title"}>{title}</Typography.Paragraph>
        <Typography.Paragraph className={"banner-subtitle"}>{subtitle}</Typography.Paragraph>
      </Flex>
      <Button
        className={"update-btn"}
        onClick={() => {
          closeOriginModal && closeOriginModal();
          dispatch(setSkipTrial(true));
        }}
      >
        Skip Trial
      </Button>
      <Row className={"gradient1"}></Row>
      <Row className={"gradient2"}></Row>
    </Flex>
  );
};
