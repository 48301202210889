/* eslint-disable react/jsx-fragments */
import { Form, FormInstance, FormItemProps, Input, message } from "antd"
import { FC, useContext } from "react"
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext"
import { FilterTagsList } from "src/components/ui/FilterTagsList";
import { specialTextFields } from "../.."
import { prospectDataSelector, setFilterFormData } from "src/store/prospect_data"
import { useDispatch, useSelector } from "react-redux"

interface InputFilterItem extends FormItemProps {
  form: FormInstance
  fieldName: string
enterButton?: boolean
  placeholder?: string
}

const error = () => {
  message.error("This filter is already in the list");
};

export const InputFilterItem: FC<InputFilterItem> = ({ placeholder, form, fieldName,enterButton }) => {
  // const { filterFormData, setFilterFormData } = useContext(ProspectDataContext)

    const { filterFormData } = useSelector(prospectDataSelector);
    const dispatch = useDispatch()
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = e.currentTarget.value.trim()
   if (e.key === "Enter" && trimmedValue !== "") {
    specialTextFields.forEach(field => {
      if(field ===fieldName){
      if (!filterFormData[field]?.includes(trimmedValue)) {
        console.log(field,'her12');
     dispatch   (setFilterFormData({
          ...filterFormData,
          [field]: [...(filterFormData[field] || []), trimmedValue]
        }));
        console.log('fieldfield',field,trimmedValue);
        form.setFieldsValue({ [field]: "" });
      }else {
        error();
      }
    }})
    } 
    
    }

  return (
    <>
   { enterButton?
     <>
      <Form.Item name={fieldName}>
        <Input.Search placeholder={placeholder} onKeyDown={handleKeyPress} allowClear enterButton={'Enter'} onSearch={value => {handleKeyPress({key:'Enter',currentTarget:{value}})}} />
      </Form.Item>
      <FilterTagsList data={filterFormData[fieldName]} form={form} fieldToUpdate={fieldName} />
    </>
    :
    <>
      <Form.Item name={fieldName}>
        <Input placeholder={placeholder} onKeyDown={handleKeyPress}/>
      </Form.Item>
      <FilterTagsList data={filterFormData[fieldName]} form={form} fieldToUpdate={fieldName} />
    </>
    }
    </>
  )
}
