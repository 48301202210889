import { List, Row, Space, Typography } from "antd"
import { FC } from "react"
import { getInteractionsOperation } from "src/helpers/getInteractionsOperation"
import { recentInteractionsData } from "../../dashboardData"
import "./index.less"

export const RecentInteractionTab: FC<{ data: any }> = ({ data }) => {
  return (
    <List
      className={"interactions-list"}
      dataSource={recentInteractionsData}
      renderItem={(item: any) => (
        <List.Item key={item.id} className={"interactions-item"}>
          <Row justify={"space-between"} align={"middle"} wrap={false} style={{ width: "100%" }}>
            <Space direction={"vertical"} size={4}>
              <Typography.Paragraph className={"interactions-title"}>Incoming {item.name}</Typography.Paragraph>
              <Typography.Paragraph className={"interactions-contact"}>
                {item.contact} {getInteractionsOperation(item.name)}
              </Typography.Paragraph>
            </Space>
            <Typography.Paragraph className={"interactions-time"}>{item.time}</Typography.Paragraph>
          </Row>
        </List.Item>
      )}
    />
  )
}
