import { Checkbox, CheckboxProps, GetProp, Space, Tooltip } from "antd";
import React, { FC, useEffect, useState } from "react";
import { SvgIcon } from "src/shared/icons";

type CheckboxValueType = GetProp<typeof Checkbox.Group, "value">[number];

const bareOptions = [
  {
    label: (
      <>
        AI Personalized Email{" "}
        <Tooltip
          placement={"topLeft"}
          title={
            <>
              At the start of the outreach sequence, an AI-personalized email will be crafted specifically for each prospect by analyzing their LinkedIn profile
              to create a tailored message. <br />
              <br />
              If the prospect isn’t active on LinkedIn, or if relevant information isn’t available, Meetz will look at their company or job title to adjust the
              value proposition. This allows the message to resonate with their role, presenting the offer from their unique perspective. <br />
              <br />
              This approach is particularly effective for B2B outreach.
            </>
          }
        >
          <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
        </Tooltip>
      </>
    ),
    value: "AI Personalized Email",
  },
  {
    label: (
      <>
        Templated Emails{" "}
        <Tooltip
          placement={"topLeft"}
          title={
            <>
              The templated emails will also be AI generated so you will not need to write them out one by one, they will utilize variables to customize each
              message. They will either reinforce your value proposition or follow up with prospects to capture their attention.
              <br />
              <br /> Each templated email step will include multiple variations to create effective <b>A/B testing</b>, helping to identify the best-performing
              version for your target prospects
            </>
          }
        >
          <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
        </Tooltip>
      </>
    ),
    value: "Templated Emails",
  },
  {
    label: (
      <>
        Predictive Dialer{" "}
        <Tooltip
          placement={"topLeft"}
          title={
            <>
              This step accelerates outreach by dialing up to 15 prospects at a time until someone answers. When someone picks up, the other calls automatically
              disconnect, making simultaneous answers rare. Once connected, their details appear on your screen, then{" "}
              <b>you will speak with the prospect, not an AI</b>, as most countries made it illegal to use AI to make cold calls (TCPA compliance, Robocalling,
              etc.) + in any case we found human to human outreach is more effective. <br />
              <br />
              Pairing these calls with email outreach increases effectiveness, with follow-up emails noting your recent call attempt to boost engagement.
            </>
          }
        >
          <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
        </Tooltip>
      </>
    ),
    value: "Predictive Dialer",
  },
];

const persOptions = [
  {
    label: (
      <>
        Use prospects LinkedIn{" "}
        <Tooltip
          placement={"topLeft"}
          title={
            <>
              Meetz will use your prospects real time LinkedIn information to personalize the outreach. <br />
              <br />
              If no activity like their posts or comments are relevant Meetz will use other parts of their profile to tailor the message.
            </>
          }
        >
          <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
        </Tooltip>
      </>
    ),
    value: "ScrapeLinkedIn",
  },
  {
    label: (
      <>
        Use prospects company information{" "}
        <Tooltip
          placement={"topLeft"}
          title={
            <>
              Meetz will try to fetch real time information about your prospects company to tie it in to your outreach. Updates, about company, news, and other
              information will try to be used.
            </>
          }
        >
          <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
        </Tooltip>
      </>
    ),
    value: "ScrapeCompany",
  },
]; // TODOF add back in before releasing version with dialer also check on backedn Phone Call
// const bareOptions = ["AI Personalized Email", "Templated Emails", "Phone Call - Power Dialer"];
const persOptionsValue = ["ScrapeLinkedIn", "ScrapeCompany"];
const bareOptionsValue = ["AI Personalized Email", "Templated Emails", "Predictive Dialer"];
const autoOptions = ["Automated Booking", "Auto Send Emails Upon Ai Generation"];
// const plainPhoneOptions = ["Phone Calls", "Voicemail", "SMS"];
const plainPhoneOptions = ["Phone Calls"];
const plainEmailsOptions = ["AI Personalized Email", "Manual Emails"];

export const StepsTypesCheckboxes: FC = ({
  setCheckErr,
  checkedEmailSteps,
  setCheckedPersSteps,
  checkedPersSteps,
  setCheckedEmailSteps,
  automations,
  setAutomations,
}) => {
  const [checkedPhoneList, setCheckedPhoneList] = useState<CheckboxValueType[]>([]);
  const [checkedEmailsList, setCheckedEmailsList] = useState<CheckboxValueType[]>([]);

  useEffect(() => {
    setCheckErr(!checkedEmailSteps?.length);
  }, [checkedEmailSteps]);
  console.log(checkedEmailSteps, "checkedEmailSteps?.length", bareOptions?.length);
  const checkAllBare = bareOptions?.length === checkedEmailSteps?.length;
  const checkAllPers = persOptions?.length === checkedPersSteps?.length;
  const checkAllAuto = autoOptions?.length === automations?.length;
  const checkAllPhone = plainPhoneOptions?.length === checkedPhoneList?.length;
  const bareIndeterminate = checkedEmailSteps && checkedEmailSteps.length > 0 && checkedEmailSteps.length < bareOptions.length;
  const persIndeterminate = checkedPersSteps && checkedPersSteps.length > 0 && checkedPersSteps.length < persOptions.length;
  const autoIndeterminate = automations && automations.length > 0 && automations.length < autoOptions.length;
  console.log(bareIndeterminate, "bareIndeterminate", checkedEmailSteps);
  const phoneIndeterminate = checkedPhoneList && checkedPhoneList.length > 0 && checkedPhoneList.length < plainPhoneOptions.length;

  const checkAllEmails = plainEmailsOptions?.length === checkedEmailsList?.length;
  const emailsIndeterminate = checkedEmailsList && checkedEmailsList.length > 0 && checkedEmailsList.length < plainEmailsOptions.length;

  const onPhoneChange = (list: CheckboxValueType[]) => {
    setCheckedPhoneList(list);
  };

  const onAutoChange = (list: CheckboxValueType[]) => {
    setAutomations(list);
  };
  const onBareChange = (list: CheckboxValueType[]) => {
    setCheckedEmailSteps(list);
  };
  const onPersChange = (list: CheckboxValueType[]) => {
    setCheckedPersSteps(list);
  };
  const onEmailsChange = (list: CheckboxValueType[]) => {
    setCheckedEmailsList(list);
  };

  const onCheckAllBareChange: CheckboxProps["onChange"] = (e) => {
    setCheckedEmailSteps(e.target.checked ? bareOptionsValue : []);
  };
  const onCheckAllPersChange: CheckboxProps["onChange"] = (e) => {
    setCheckedPersSteps(e.target.checked ? persOptionsValue : []);
  };
  const onCheckAllAutoChange: CheckboxProps["onChange"] = (e) => {
    setAutomations(e.target.checked ? autoOptions : []);
  };
  const onCheckAllPhoneChange: CheckboxProps["onChange"] = (e) => {
    setCheckedPhoneList(e.target.checked ? plainPhoneOptions : []);
  };

  const onCheckAllEmailsChange: CheckboxProps["onChange"] = (e) => {
    setCheckedEmailsList(e.target.checked ? plainEmailsOptions : []);
  };
  // console.log(checkedEmailSteps, "checkedEmailSteps");
  return (
    <Space direction={"vertical"}>
      <Checkbox indeterminate={bareIndeterminate} onChange={onCheckAllBareChange} checked={checkAllBare} style={{ fontWeight: 500 }}>
        Outreach steps:
      </Checkbox>
      <Checkbox.Group
        options={bareOptions}
        value={checkedEmailSteps}
        onChange={onBareChange}
        // disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />
      <Checkbox indeterminate={persIndeterminate} onChange={onCheckAllPersChange} checked={checkAllPers} style={{ fontWeight: 500 }}>
        Personalization:
      </Checkbox>
      <Checkbox.Group
        options={persOptions}
        value={checkedPersSteps}
        onChange={onPersChange}
        // disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />
      {/* 
      <Checkbox indeterminate={phoneIndeterminate} onChange={onCheckAllPhoneChange} checked={checkAllPhone} style={{fontWeight:500}}>
        Phone:
      </Checkbox>
      <Checkbox.Group
        options={plainPhoneOptions}
        value={checkedPhoneList}
        onChange={onPhoneChange}
        disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />
      <Checkbox indeterminate={emailsIndeterminate} onChange={onCheckAllEmailsChange} checked={checkAllEmails} style={{fontWeight:500}}>
        Emails:
      </Checkbox>
      <Checkbox.Group
        options={plainEmailsOptions}
        value={checkedEmailsList}
        onChange={onEmailsChange}
        disabled={checkedEmailsList?.length===plainEmailsOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      /> */}

      <Checkbox indeterminate={autoIndeterminate} onChange={onCheckAllAutoChange} checked={checkAllAuto} style={{ fontWeight: 500 }}>
        Automations:
      </Checkbox>
      <Checkbox.Group
        options={autoOptions}
        value={automations}
        onChange={onAutoChange}
        // disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />

      {/* Automated Booking: it actually doesnt do anything now, right now this is about scheduling links/laura. next will need to do interest recognition and automatically try to book the meeting this is a large functionality. */}
    </Space>
  );
};
