import { Button, Form, Input } from "antd"
import React, { FC } from "react"
import { ContentCard, CustomLabel } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"

export const Sms: FC = () => {
  return (
    <ContentCard
      headerIcon={<SvgIcon type={"sms"} />}
      cardTitle={"SMS"}
      headerRightContent={<Button className={"link"}>Add variable</Button>}
    >
      <CustomLabel title={"SMS Body"} />
      <Form.Item
        name={"smsBody"}
        initialValue={
          "Hi Aron, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?\n" +
          "\n" +
          "Warm regards"
        }
      >
        <Input.TextArea autoSize />
      </Form.Item>
      <CustomLabel title={"Example"} />
      <Form.Item
        name={"smsExample"}
        initialValue={
          "Hi Aron, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?\n" +
          "\n" +
          "Warm regards"
        }
      >
        <Input.TextArea autoSize />
      </Form.Item>
    </ContentCard>
  )
}
