/* eslint-disable no-unused-vars */

import { createSelector, createSlice } from "@reduxjs/toolkit";

import _ from "lodash";
import { getUser, gettimezones, initiateAndGetAllUserInfo, patchSettings } from "./asyncRequests";
import { updateMeeting } from "../reactive-links-settings/asyncRequests";
import { getProsFiles } from "../prospect_data/asyncRequests";
import { patchEmailSettings } from "../outreach-emails/asyncRequests";
import { generateOneAiEmail } from "../outreach-campaigns/asyncRequests";
import { skipTrialNow } from "../stripe/asyncRequests";

type ErrorType = null | string;

const INITIAL_STATE = {
  user: {}, // do not reload this, it will put the dashboard in an unclickable loading mode. Only during upgrade/downgrade do that.
  settings: {},
  timezones: [],
  error: null as ErrorType,
  loading: false,
  settingsLoading: false,
  account_status: null,
  show_softdeleted_popup: false,
  is_notifications_insist: false,
  payingClient: false,
  trialClient: false,
  tutorialRun: false,
  openActivateModalTrialSkip: false,
  sdBarCollapsed: false, // ui menucollapsed
  showTutorial: false, // not used
};

function loadingFailed(state: { error: any }, action: { payload: any }) {
  state.error = action.payload;
}
interface LogoutAction {
  type: string;
  payload: {
    error: string | null; // Adjust the type of error property here
  };
}
const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logout: (state, action) => {
      console.log(action, "logout");
      // console.log('islogout');
      const isIframe = window.self !== window.top;
      if (isIframe) {
        window.parent.postMessage({ type: "REMOVE_TOKEN", tokenName: "token" }, "*");
      } else {
        localStorage.clear();
      }
      state.user = {};
      state.error = action?.payload?.error || null;
      state.account_status = null;
      state.show_softdeleted_popup = false;
      state.loading = false;
    },
    setNotificationsInsist: (state) => {
      state.is_notifications_insist = true;
    },
    SetColapsedSdBar: (state, action) => {
      state.sdBarCollapsed = action.payload;
    },
    setSkipTrial: (state, action) => {
      state.openActivateModalTrialSkip = action.payload;
    },
    ShowTutorialNow: (state) => {
      state.showTutorial = true; // this works for the video not for the passing through one
    },

    setTutorialRun: (state, action) => {
      state.tutorialRun = action.payload;
    },
    hideTutorialNow: (state) => {
      state.showTutorial = false;
    },
    setError: loadingFailed,
    // toggleTutorialStatus: (state) => {
    //   state.user.is_show_tutorial = !state.user.is_show_tutorial;
    //   state.user.new_account = !state.user.new_account;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {
        console.log(action.payload, "settings");
        state.settings = action.payload.settings;
      })
      .addCase(getUser.pending, (state) => {
        if (!state.loading) {
          state.loading = true;
        }
      })
      .addCase(getUser.fulfilled, (state, action: any) => {
        if (state.loading) {
          state.loading = false;
        }

        console.log("USERREQ=SUCCESS");
        const client = action.payload;
        state.payingClient = client?.new_payment_status === "successfully_on" && client?.new_subscription_plans;
        state.trialClient = client?.new_payment_status === "trial" && client?.new_subscription_plans;
        // console.log(client?.new_payment_status, client?.new_subscription_plans, "client?.new_subscription_plans");
        // console.log(client, "clientclient");
        state.user = client;
      })
      .addCase(getUser.rejected, (state, action: any) => {
        if (state.loading) {
          state.loading = false;
        }
        console.log("USERREQ=REJECTED");
        console.log("Unhandled promise rejection3:", action.error);
        state.error = "Issue getting account";
      })
      .addCase(getProsFiles.fulfilled, (state, action: any) => {
        if (state.user?.id) {
          const deepCopiedUser = _.cloneDeep(state.user);
          deepCopiedUser.prospect_data_credits = action.payload.credits;
          state.payingClient = deepCopiedUser?.new_payment_status === "successfully_on" && deepCopiedUser?.new_subscription_plans;
          state.trialClient = deepCopiedUser?.new_payment_status === "trial" && deepCopiedUser?.new_subscription_plans;
          state.user = deepCopiedUser;
        }
        // console.log(action.payload, "action.payload3")
      })
      .addCase(patchEmailSettings.fulfilled, (state, action: any) => {
        if (state.user?.id) {
          const deepCopiedUser = _.cloneDeep(state.user);
          state.payingClient = deepCopiedUser?.new_payment_status === "successfully_on" && deepCopiedUser?.new_subscription_plans;
          state.trialClient = deepCopiedUser?.new_payment_status === "trial" && deepCopiedUser?.new_subscription_plans;
          deepCopiedUser.first_name = action.payload.firstName;
          state.user = deepCopiedUser;
        }
      })
      .addCase(skipTrialNow.fulfilled, (state, action: any) => {
        const client = action.payload?.user || {};
        state.payingClient = client?.new_payment_status === "successfully_on" && client?.new_subscription_plans;
        state.trialClient = client?.new_payment_status === "trial" && client?.new_subscription_plans;
        console.log(action.payload, "skipTrialNowskipTrialNow");
        state.user = client;
      })
      .addCase(generateOneAiEmail.fulfilled, (state, action: any) => {
        const deepCopiedUser = _.cloneDeep(state.user);
        const newCredAmnt = deepCopiedUser.ai_personalized_emails_left - 1 < 0 ? 0 : deepCopiedUser.ai_personalized_emails_left - 1;
        deepCopiedUser.ai_personalized_emails_left = newCredAmnt;
        state.user = deepCopiedUser;

        // console.log(action.payload, "action.payload3")
      })

      .addCase(patchSettings.pending, (state) => {
        if (!state.settingsLoading) {
          state.settingsLoading = true;
        }
      })
      .addCase(updateMeeting.fulfilled, (state, action) => {
        const client = action.payload?.client;
        state.payingClient = client?.new_payment_status === "successfully_on" && client?.new_subscription_plans;
        state.trialClient = client?.new_payment_status === "trial" && client?.new_subscription_plans;
        state.user = client;
        state.settings = action.payload?.settings;
      })
      .addCase(patchSettings.fulfilled, (state, action: any) => {
        state.settings = action.payload?.settings;
      })
      .addCase(patchSettings.rejected, (state, action: any) => {
        if (state.settingsLoading) {
          state.settingsLoading = false;
          console.log(action.error, "error2");
          state.error = action.error;
        }
      })
      .addCase(gettimezones.fulfilled, (state, action: any) => {
        state.timezones = action.payload;
      })
      .addCase(gettimezones.rejected, (state, action: any) => {
        console.log(action, "timezones err");
      });
  },
});

const { actions, reducer } = userSlice;

export const {
  logout,
  hideTutorialNow,
  ShowTutorialNow,
  setUser,
  setError,
  setNotificationsInsist,
  // toggleTutorialStatus,
  // setStepOfTutorial,
  setTutorialRun,
  SetColapsedSdBar,
  setSkipTrial,
} = actions;

export default reducer;

export const fullUserSelector = createSelector(
  (state) => state.user,
  (user) => user
);

export const userSelector = createSelector(
  (state) => state.user,
  (user) => user.user
);
export const userIsPayingSelector = createSelector(
  (state) => state.user,
  (user) => user.payingClient
);
export const userIsTrialSelector = createSelector(
  (state) => state.user,
  (user) => user.trialClient
);

export const settingsSelector = createSelector(
  (state) => state.user,
  (user) => user.settings
);

export const tokenSelector = createSelector(
  (state) => state.user,
  (user) => user.token
);

export const errorSelector = createSelector(
  (state) => state.user,
  (user) => user.error
);
