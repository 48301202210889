import { Tag, Typography } from "antd";
import { ConfirmButtons, Modal } from "src/components/ui";

export const AreYouSureDeleteModal = ({ onCancel, onSubmit }) => {
  return (
    <Modal
      // width={530}
      // customClassName={"sending-limits-modal"}
      open
      title={"Are you sure you want to delete this email?"}
      onCancel={onCancel}
    >
      <Typography.Paragraph className={"description"}>
        Any prospects that were getting emails with this address will stop in the campaign until you reconnect this email address again.
      </Typography.Paragraph>{" "}
      <br />
      <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Delete"} handleSubmit={onSubmit} onCancel={onCancel} />
    </Modal>
  );
};
