import dayjs, { Dayjs } from "dayjs"

interface Deal {
  id: string
  name: string
  description: string
  date: Dayjs
  price: number
}

interface DealsGroup {
  id: string
  name: string
  totalPrice: number
  deals: Deal[]
}

export const dealsGroupsData: DealsGroup[] = [
  {
    id: "1",
    name: "Interested",
    totalPrice: 1100,
    deals: [
      {
        id: "1.1",
        name: "NY 2.0",
        description: "Notes: About Leads from NY",
        date: dayjs("2024-03-25"),
        price: 650,
      },
      {
        id: "1.2",
        name: "Spain 2.0",
        description: "Notes: About Leads from Spain",
        date: dayjs("2024-03-19"),
        price: 450,
      },
    ],
  },
  {
    id: "2",
    name: "Meeting Completed",
    totalPrice: 1100,
    deals: [
      {
        id: "2.1",
        name: "Technological industry",
        description: "Notes: About Leads",
        date: dayjs("2024-03-25"),
        price: 750,
      },
      {
        id: "2.2",
        name: "Real estate",
        description: "Notes: About Leads",
        date: dayjs("2024-03-19"),
        price: 350,
      },
    ],
  },
  {
    id: "3",
    name: "Closed",
    totalPrice: 6570,
    deals: [
      {
        id: "3.1",
        name: "NY",
        description: "Notes: About Leads from NY",
        date: dayjs("2024-03-25"),
        price: 1650,
      },
      {
        id: "3.2",
        name: "Spain",
        description: "Notes: About Leads from Spain",
        date: dayjs("2024-03-20"),
        price: 1510,
      },
      {
        id: "3.3",
        name: "UAE",
        description: "Notes: About Leads from United Arab Emirates",
        date: dayjs("2024-03-19"),
        price: 3410,
      },
    ],
  },
  // {
  //   id: "4",
  //   name: "Interested",
  //   totalPrice: 1100,
  //   deals: [
  //     {
  //       id: "4.1",
  //       name: "NY 2.0",
  //       description: "Notes: About Leads from NY",
  //       date: dayjs("2024-03-25"),
  //       price: 650,
  //     },
  //     {
  //       id: "4.2",
  //       name: "Spain 2.0",
  //       description: "Notes: About Leads from Spain",
  //       date: dayjs("2024-03-19"),
  //       price: 450,
  //     },
  //   ],
  // },
  // {
  //   id: "5",
  //   name: "Meeting Completed",
  //   totalPrice: 1100,
  //   deals: [
  //     {
  //       id: "5.1",
  //       name: "Technological industry",
  //       description: "Notes: About Leads",
  //       date: dayjs("2024-03-25"),
  //       price: 750,
  //     },
  //     {
  //       id: "5.2",
  //       name: "Real estate",
  //       description: "Notes: About Leads",
  //       date: dayjs("2024-03-19"),
  //       price: 350,
  //     },
  //   ],
  // },
  // {
  //   id: "6",
  //   name: "Closed",
  //   totalPrice: 6570,
  //   deals: [
  //     {
  //       id: "6.1",
  //       name: "NY",
  //       description: "Notes: About Leads from NY",
  //       date: dayjs("2024-03-25"),
  //       price: 1650,
  //     },
  //     {
  //       id: "6.2",
  //       name: "Spain",
  //       description: "Notes: About Leads from Spain",
  //       date: dayjs("2024-03-20"),
  //       price: 1510,
  //     },
  //     {
  //       id: "6.3",
  //       name: "UAE",
  //       description: "Notes: About Leads from United Arab Emirates",
  //       date: dayjs("2024-03-19"),
  //       price: 3410,
  //     },
  //   ],
  // },
]
