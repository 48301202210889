import { Tag } from "antd"
import { CSSProperties, FC } from "react"

interface CustomTagProps {
  text: string
  color: string
  backgroundColor: string
  style?: CSSProperties
}

export const CustomTag: FC<CustomTagProps> = ({ text, color, backgroundColor, style, ...props }) => {
  return (
    <Tag
      className={"custom-tag"}
      bordered={false}
      style={{
        padding: "4px 14px",
        borderRadius: 30,
        textAlign: "center",
        color: color,
        backgroundColor: backgroundColor,
        marginInlineEnd: 0,
        ...style,
      }}
      {...props}
    >
      {text}
    </Tag>
  )
}
