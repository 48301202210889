/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-key */
import { Checkbox, Flex, Input, Radio, RadioChangeEvent, Space, Tooltip, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons, Modal } from "src/components/ui";
import { UpdatePlanBanner } from "../../../../../UpdatePlanBanner";
import { useDispatch, useSelector } from "react-redux";
import { prospectDataSelector } from "src/store/prospect_data";
import tz from "moment-timezone";
import { userSelector } from "src/store/user";
import { uploadLeadFileAndColumnInd } from "src/store/outreach-campaigns/asyncRequests";

const { Text } = Typography;

type ExtractionFileModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  setProspectAmountExtracting: (a: any) => void;
};

export const ExtractionFileModal: FC<ExtractionFileModalProps> = ({ setProspectAmountExtracting, open, onCancel }) => {
  const filtOptions = [
    {
      value: "remove_prev_upload",
      label: "Remove previously uploaded prospects",
    },
    {
      value: "remove_prev_contacted",
      label: "Remove prospects already contacted",
    },
  ];
  const [checkedFilters, setCheckedFilters] = useState(["remove_prev_upload", "remove_prev_contacted"]);
  const { exportFiles } = useSelector(prospectDataSelector);

  const [value, setValue] = useState(null);
  const [exportFilesClean, setExportFilesClean] = useState([]);

  const closeModal = () => {
    onCancel();
  };
  const client = useSelector(userSelector);

  const onChangeFilt: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues) => {
    setCheckedFilters(checkedValues);
  };

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const formatUnixTimestamp = (unixTimestamp) => {
    const date = tz.unix(unixTimestamp).tz(tz.tz.guess());
    return date.format("MMMM Do 'YY [at] h:mm A");
  };

  const dispatch = useDispatch();
  const selectedExp =
    value !== null ? (typeof value === "string" ? true : exportFilesClean?.length && exportFilesClean.find((exp) => exp?.id === value)) : false;

  console.log(selectedExp, "selectedExp", value);
  useEffect(() => {
    setExportFilesClean(exportFiles?.filter((exp) => exp?.id !== "pending")?.sort((a, b) => b.pendingUnix - a.pendingUnix));
  }, [exportFiles]);

  const handleSubmit = () => {
    const totalLeads = selectedExp ? selectedExp?.goodProspAmnt : 0;
    setProspectAmountExtracting(totalLeads);

    const removeContactedPros = checkedFilters?.includes("remove_prev_contacted");
    const removeLeadsAlreadyUpl = checkedFilters?.includes("remove_prev_upload");

    dispatch(uploadLeadFileAndColumnInd({ selExtFile: value, totalLeads, removeContactedPros, removeLeadsAlreadyUpl }));
    // setOpenUploadFormModal(true)
    onCancel();
  };

  return (
    <Modal centered open={open} title={"Select the Meetz prospect data export"} onCancel={closeModal}>
      <Flex gap={16} vertical>
        {/*    <Space direction={"vertical"}>
        <Text>Files from Meetz database</Text>
          <Input.Search className={"search-input"} placeholder={"Search..."} allowClear enterButton={false} /> TODOFF quickly add this  
        </Space> */}
        <Flex gap={28} vertical>
          <Radio.Group buttonStyle={"solid"} onChange={onChange} value={value}>
            {/* <Space direction={"vertical"}> */}
            {exportFilesClean?.length ? (
              exportFilesClean?.map((exp) => (
                <Space direction={"vertical"} style={{ marginTop: "10px" }}>
                  <Radio value={exp?.id}>
                    {exp?.name?.length > 25 ? (
                      <>
                        <Tooltip placement={"right"} title={exp?.name}>
                          <b>Export Name:</b> {exp?.name?.substring(0, 25) + "..."}
                          {!!exp?.uploadedToMeetz && <span style={{ color: "lightblue" }}> - Uploaded</span>}
                          <br />
                          <b>Date Saved:</b> {formatUnixTimestamp(exp?.pendingUnix)}
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <b>Export Name:</b> {exp?.name}
                        {!!exp?.uploadedToMeetz && <span style={{ color: "lightblue" }}> - Uploaded</span>}
                        <br />
                        <b>Date Saved:</b> {formatUnixTimestamp(exp?.pendingUnix)}
                      </>
                    )}{" "}
                    <br />
                    <b>Prospects amount:</b> {exp?.goodProspAmnt || 0}
                  </Radio>{" "}
                </Space>
              ))
            ) : (
              <Radio value={"testPros"}>Example Prospect Data</Radio>
            )}
            {/* </Space> */}
          </Radio.Group>
          {!client?.new_payment_status ||
            (client?.new_payment_status === "churned" && (
              <UpdatePlanBanner
                // this is marketing wording so they upgrade to extract  TODON check link works
                title={`Get${exportFiles?.length ? " real" : " more"} prospect data!`}
                subtitle={`Update your account now to access ${
                  exportFiles?.length ? " real" : " more"
                } prospect data and generate new clients for your business.`}
              />
            ))}
          <Checkbox.Group style={{ width: "100%", margin: "5px 0 28px 0" }} onChange={onChangeFilt} value={checkedFilters}>
            {exportFilesClean?.length && (
              <Flex vertical gap={10}>
                {filtOptions.map((item) => (
                  <Checkbox key={item.value} value={item.value}>
                    {item.label}
                  </Checkbox>
                ))}
              </Flex>
            )}
          </Checkbox.Group>
          <ConfirmButtons onCancel={closeModal} leftButtonTitle={"Back"} handleSubmit={handleSubmit} rightButtonTitle={"Upload"} disabled={!selectedExp} />
        </Flex>
      </Flex>
    </Modal>
  );
};
