/* eslint-disable react/jsx-fragments */
import React, { FC, useEffect, useState } from "react";
import { CustomLabel, Modal, StatusTag, TextEditor } from "../../../../../../ui";
import { Checkbox, Flex, Input, Row, Table, Typography } from "antd";
import { statusColors } from "../../index";
import "./index.less";

type ViewAnalyticsModalProps = {
  onClose: VoidFunction;
};

export const ViewAnalyticsModal: FC<ViewAnalyticsModalProps> = ({ item, onClose }) => {
  const [bestScore, setBestScore] = useState(0);
  const [bestVariantNum, setBestVariantNum] = useState(0);
  //   let explanation = '';
  // let reason = 'booked';

  useEffect(() => {
    let curBestScroe = 0;
    let curBestSttpNum = 0;
    if (item?.emailVariantsArray?.length) {
      for (let i = 0; i < item?.emailVariantsArray?.length; i++) {
        const variant = item.emailVariantsArray[i];
        const score =
          variant.email_analytic_opened * 0.2 +
          variant.email_analytic_clicked * 0.4 +
          variant.email_analytic_forwarded * 1.5 +
          variant.email_analytic_replied * 2.5 +
          variant.email_analytic_booked * 20;

        if (score > curBestScroe) {
          curBestScroe = score;
          curBestSttpNum = variant?.variantNum;
        }
      }
    }
    setBestVariantNum(curBestSttpNum);
    setBestScore(curBestScroe);
  }, [item]);

  // TODOF add buton later to optimize the campaign

  // TODON check that the numbers work well and are correct here

  // const bestVariant = item.emailVariantsArray[bestVariantIndex];
  // let num
  //   if (bestVariant.email_analytic_booked > 0) {
  //       num = bestVariant.email_analytic_booked;
  //       reason = 'book';
  //     } else if (bestVariant.email_analytic_replied > 0) {
  //       num = bestVariant.email_analytic_replied;
  //       reason = 'reply';
  //     } else if (bestVariant.email_analytic_forwarded > 0) {
  //     num = bestVariant.email_analytic_forwarded;
  //     reason = 'forward';
  //   } else if (bestVariant.email_analytic_clicked > 0) {
  //     num = bestVariant.email_analytic_clicked;
  //     reason = 'click';
  //   } else if (bestVariant.email_analytic_opened > 0) {
  //     num = bestVariant.email_analytic_opened;
  //       reason = 'open';
  //   }

  //   explanation = `Best email variant found: Variant ${String.fromCharCode(65 + item?.emailVariantsArray[bestVariantIndex]?.variantNum)}, that has ${num?.toLocaleString()} ${reason}${num>1? "'s":''}.`;

  //  const [variants, setVariants] = useState([
  //   {
  //     id: "1",
  //     name: "Variant A",
  //     description: {
  //       title: "Maximize Your Design Power 🚀",
  //       text: "Hi, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?",
  //     },
  //     status: {
  //       success: 2,
  //       finished: 3,
  //       completed: 2,
  //       skipped: 2,
  //     },
  //     isBestSoFar: true,
  //   },
  //   {
  //     id: "2",
  //     name: "Variant B",
  //     description: {
  //       title: "Maximize Your Design Power 🚀",
  //       text: "Hi, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?",
  //     },
  //     status: {
  //       success: 2,
  //       finished: 3,
  //       completed: 2,
  //       skipped: 2,
  //     },
  //     isBestSoFar: false,
  //   }
  // ])
  // console.log(item,'itemitem');
  const columns = [
    {
      title: "Variations",
      // dataIndex: "name",
      key: "name",
      render: (_, record) => <>Variation {String.fromCharCode(64 + record?.variantNum)}</>,
    },
    {
      title: "Email",
      // dataIndex: "description",
      key: "description",
      render: (_, record) => {
        console.log("valval", record);
        return (
          <div className={"description-wrapper"}>
            <div className={"description-size-controller"}>
              {/* <Checkbox checked={record?.subject?.trim()==='ReplyTo'} style={{pointerEvents:'none'}} >Reply to previous steps email</Checkbox><br /><br />
      {record?.subject?.trim()!=='ReplyTo' && (<>
        <React.Fragment>
          <CustomLabel title={"Subject Line"} />
            <Input  style={{pointerEvents:'none'}}  value={record?.subject} />
        </React.Fragment><br /><br /></>
      )} */}
              {record?.subject?.trim() === "ReplyTo" ? (
                <div style={{ marginTop: "3px", fontWeight: 500 }}>Replies to previous steps email</div>
              ) : (
                <>
                  <div style={{ marginTop: "3px" }}>
                    <CustomLabel title={"Subject Line"} />
                    <Input value={record?.subject} style={{ color: "#757a89", pointerEvents: "none", backgroundColor: "#f7f7f7 !important" }} />
                  </div>
                </>
              )}

              <div style={{ marginTop: "20px" }}>
                <CustomLabel title={"Email Body"} />
                <TextEditor content={record.body} readOnly isMaxHeight />
              </div>
              {/* <Typography.Paragraph className={"description-title"}>{record.subject}</Typography.Paragraph>
            <Typography.Paragraph className={"description-text"}>{record.body}</Typography.Paragraph> */}
            </div>
          </div>
        );
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      key: "status",
      render: (value: string, record: any) => (
        <>
          <Flex style={{ marginBottom: 20 }} align="center">
            <div> Total sends: {record?.email_analytic_sent || 0}</div>{" "}
            {!!bestScore && bestVariantNum === record?.variantNum && (
              <StatusTag value={"Best So Far"} color={"rgba(43, 143, 130, 0.10)"} style={{ padding: "7px 14px", color: "#2B8F82", marginLeft: "10px" }} />
            )}
          </Flex>
          <Flex gap={8}>
            <StatusTag
              // key={key}
              value={`Opened: ${(record?.email_analytic_opened || 0)?.toLocaleString()}`}
              color={"processing"}
              style={{ padding: "7px 14px" }}
              // onClick={() => showStatusModal(key)} TODOF later add
            />
            <StatusTag
              // key={key}
              value={`Clicked: ${(record?.email_analytic_clicked || 0)?.toLocaleString()}`}
              color={"cyan"}
              style={{ padding: "7px 14px" }}
              // onClick={() => showStatusModal(key)} TODOF later add
            />
            <StatusTag
              // key={key}
              value={`Forwarded: ${(record?.email_analytic_forwarded || 0)?.toLocaleString()}`}
              color={"purple"}
              style={{ padding: "7px 14px" }}
              // onClick={() => showStatusModal(key)} TODOF later add
            />
            <StatusTag
              // key={key}
              value={`Replied: ${(record?.email_analytic_replied || 0)?.toLocaleString()}`}
              color={"success"}
              style={{ padding: "7px 14px" }}
              // onClick={() => showStatusModal(key)} TODOF later add
            />
            <StatusTag
              // key={key}
              value={`Booked: ${(record?.email_analytic_replied || 0)?.toLocaleString()}`}
              color={"gold"}
              style={{ padding: "7px 14px" }}
              // onClick={() => showStatusModal(key)} TODOF later add
            />
          </Flex>
        </>
      ),
    },
  ];

  return (
    <Modal
      width={"fit-content"}
      rootClassName={"view-analytics-modal"}
      open={true}
      title={`Step ${item?.stepNum} Analytics: Email Variations Overview`}
      onCancel={onClose}
    >
      <Typography.Paragraph>
        Variations are automatically sent to different prospects in this step to test the best performing template and optimize your lead generation.
      </Typography.Paragraph>
      <br />
      <Table dataSource={item?.emailVariantsArray} columns={columns} pagination={false} style={{ maxHeight: "60vh", overflowY: "scroll" }} />
      <br />
      <div>
        * To optimize the email template based on the best performing one or edit them by exiting this window and clicking above the sequece on "Edit Sequence"
      </div>
    </Modal>
  );
};
