/* eslint-disable no-unused-vars */

import { createSelector, createSlice } from "@reduxjs/toolkit";
import { initiateAndGetAllUserInfo } from "../user/asyncRequests";

const INITIAL_STATE = {
  invoiceData: [], // invoices of customer
};

const billingSlice = createSlice({
  name: "billing",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initiateAndGetAllUserInfo.fulfilled, (state, action) => {
      state.invoiceData = action.payload.invoiceData;
    });
  },
});

const { reducer } = billingSlice;

export default reducer;

export const invoiceDataSelector = createSelector(
  (state) => state.billing,
  (billing) => billing.invoiceData
);
