import React, { FC } from "react"
import { SvgIcon } from "src/shared/icons"

const Logo: FC<{ isLogoLetter?: boolean }> = ({ isLogoLetter }) => {
  return (
    <SvgIcon
      type={!isLogoLetter ? "logo" : "logoLetter"}
      onClick={() => window.location.assign("/")}
      style={{
        width: !isLogoLetter ? 94 : 22,
        height: !isLogoLetter ? 16 : 24,
        textAlign: isLogoLetter ? "center" : "unset",
      }}
    />
  )
}

export { Logo }
