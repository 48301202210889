/* eslint-disable react/jsx-fragments */
import { Button, Flex, Form, List, MenuProps, Radio, Tooltip, Typography, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Drawer } from "src/components/ui";
import { SignInWithButtons } from "src/components/ui/SignInWithButtons";
import { CustomTag } from "src/components/ui/CustomTag";
import { DotsMenuDropdown } from "src/components/ui/DotsMenuDropdown";
import { CustomSelect } from "../../../../ui";
import "./index.less";
import { SvgIcon } from "src/shared/icons";
import { useDispatch, useSelector } from "react-redux";
import { reactiveLinksSelector } from "src/store/reactive-links-settings";
import { userSelector } from "src/store/user";
import { AppDispatch } from "src/store";
import { removeCal } from "src/store/reactive-links-settings/asyncRequests";
import axios from "axios";
import { API_BASE } from "src/pages/constant";
import { patchSettings } from "src/store/user/asyncRequests";

interface AdditionalAvailabilityDrawerProps {
  onClose: () => void;
}

const communicationMethods = [
  { value: "Meet", label: "Meet" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
];

const bufferTime = [
  { value: "0 min", label: "0 min" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
];

const minTimeToBook = [
  { value: "0 min", label: "0 min" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
];

export const AdditionalAvailabilityDrawer: FC<AdditionalAvailabilityDrawerProps> = ({ onClose }) => {
  const [mainCalendarData, setMainCalendarData] = useState<any[]>([]);
  const [additionalCalendarData, setAdditionalCalendarData] = useState<any[]>([]);

  const { additionalAvailabilityFormated, integrations } = useSelector(reactiveLinksSelector);
  // const [firstLoad, setFirstLoad] = useState(false)
  useEffect(() => {
    if (additionalAvailabilityFormated?.length) {
      // setFirstLoad(true) should update as soon as backend gives back updated list. I need the id in order for it to work properly - cant optimistically update
      setMainCalendarData([{ id: "0", email: additionalAvailabilityFormated[0]?.mainCalendar }]);

      // eslint-disable-next-line no-unsafe-optional-chaining
      setAdditionalCalendarData(
        [
          ...additionalAvailabilityFormated?.map((mul, i) => {
            if (!i) {
              return null;
            } else {
              return { id: mul?.id, email: mul.extraCal };
            }
          }),
        ]?.filter(Boolean)
      );
    }
  }, [additionalAvailabilityFormated]);

  const [signedInData, setSignedInData] = useState({ status: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [meetingToolsData, setMeetingToolsData] = useState([
    {
      id: "00",
      name: "Google Meet",
      default: true,
      integration: {
        status: "enabled",
      },
    },
    {
      id: "01",
      name: "Teams",
      default: false,
      integration: {
        status: "disabled",
      },
    },
    {
      id: "02",
      name: "Zoom",
      default: false,
      integration: {
        status: "disabled",
      },
    },
  ]);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      mainCalendarData: mainCalendarData,
      additionalCalendarData: additionalCalendarData,
      signedInData: signedInData,
    });
  }, [form, mainCalendarData, additionalCalendarData, signedInData]);

  const handleResetBtnClick = () => {
    form.resetFields();
  };

  const handleDropdownClick = (id: string) => {
    console.log(id, "additionalCalendarData");
    setSelectedItemId(id);
  };

  const handleDisconnectClick = (id: string) => {
    const updatedTools = meetingToolsData.map((el) => (el.id === id ? { ...el, integration: { ...el.integration, status: "disabled" } } : el));
    setMeetingToolsData(updatedTools);
  };

  const setToMainCalendar = (id: string | null) => {
    if (id) {
      const itemToMove = additionalCalendarData.find((item) => item.id === id);

      if (itemToMove) {
        const updatedAdditionalCalendarData = additionalCalendarData.filter((item) => item.id !== id);
        setAdditionalCalendarData(updatedAdditionalCalendarData);
        setMainCalendarData((prevData) => [...prevData, itemToMove]);
      }
    }
  };
  const dispatch = useDispatch<AppDispatch>();
  const removeFromAdditionalCalendar = (id: string | null) => {
    if (id) {
      dispatch(removeCal({ id }));
      console.log(additionalCalendarData, "additionalCalendarData", id);
      const updatedAdditionalCalendarData = additionalCalendarData.filter((item) => item.id !== id);
      setAdditionalCalendarData(updatedAdditionalCalendarData);
    }
  };
  const { calendarType } = useSelector(userSelector);

  const handleIntegrateBtnClick = async (tool: any) => {
    if (tool === "zoom") {
      if (window.self === window.top) {
        window.location.href = `${API_BASE}/login/zoom`;
      } else {
        window.parent.postMessage({ type: "REDIRECT_REQUEST", url: `${API_BASE}/login/zoom` }, "*");
      }
    } else if (tool === "teams") {
      const token = localStorage.getItem("token");
      const response = await axios(`${API_BASE}/integrate-teams/login`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (window.self === window.top) {
        window.location.href = response.data;
      } else {
        window.parent.postMessage({ type: "REDIRECT_REQUEST", url: response.data }, "*");
      }
    }
  };

  console.log(integrations, "integrations");

  //  const [selectedDefault, setSelectedDefault] = useState<any>({})
  const [integratedPlatforms, setIntegratedPlatforms] = useState<any>({});
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  useEffect(() => {
    if (!firstLoad && integrations) {
      // setFirstLoad(true)
      setIntegratedPlatforms(integrations?.integratedServices);
      // setSelectedDefault(integrations?.defaultMeeting)
    }
  }, [integrations]);

  const [selectedMeetingTool, setSelectedMeetingTool] = useState<any>(null);

  const handleFormSubmit = () => {
    dispatch(
      patchSettings({
        default_meeting_method: selectedMeetingTool,
      })
    );
    onClose();
  };

  const handleTakeOffIntegration = (e, platformToRemove) => {
    e?.preventDefault();
    e?.stopPropagation();
    let newPlatformDefault;
    const copiedPlat = { ...integratedPlatforms };
    copiedPlat[platformToRemove] = false;

    if (selectedMeetingTool === platformToRemove || integrations?.defaultMeeting === platformToRemove) {
      if (calendarType !== "microsoft") {
        newPlatformDefault = "google_meet"; // always integrated
      } else if (platformToRemove === "teams") {
        if (integratedPlatforms?.zoom) {
          newPlatformDefault = "zoom";
        } else {
          newPlatformDefault = ""; // on purpose not null for logic
        }
      }
    } else {
      // for zoom
      if (integratedPlatforms?.teams) {
        newPlatformDefault = "teams";
      } else {
        newPlatformDefault = ""; // on purpose not null for logic
      }
    }
    setSelectedMeetingTool(newPlatformDefault);

    if (newPlatformDefault?.length) {
      dispatch(
        patchSettings({
          is_integrated: copiedPlat,
          default_meeting_method: newPlatformDefault || "",
        })
      );
    } else {
      dispatch(
        patchSettings({
          is_integrated: copiedPlat,
        })
      );
    }
    setIntegratedPlatforms(copiedPlat);
  };

  const meetingToolsOptions = [
    calendarType !== "microsoft" && {
      value: "google_meet",
      label: (
        <Flex justify={"space-between"} align={"center"}>
          <Typography.Paragraph>Google Meets</Typography.Paragraph>
          <CustomTag
            text={"Connected"}
            color={"#0FCA7A"}
            backgroundColor={"rgba(15, 202, 122, 0.10)"}
            style={{ width: "fit-content", padding: "6px 16px", lineHeight: 1, cursor: "default" }}
          />
        </Flex>
      ),
      default: integrations?.defaultMeeting === "google_meet",
    },
    {
      value: "zoom",
      label: (
        <Flex justify={"space-between"} align={"center"}>
          <Typography.Paragraph>Zoom</Typography.Paragraph>
          {integratedPlatforms?.zoom ? (
            <Flex>
              {" "}
              <CustomTag
                text={"Connected"}
                color={"#0FCA7A"}
                backgroundColor={"rgba(15, 202, 122, 0.10)"}
                style={{ width: "fit-content", padding: "6px 16px", lineHeight: 1, cursor: "default" }}
              />
              <div>
                <SvgIcon
                  style={{ height: "100%", marginTop: "auto" }}
                  type={"closeTag"}
                  onClick={(e) => {
                    handleTakeOffIntegration(e, "zoom");
                  }}
                />
              </div>
            </Flex>
          ) : (
            <Button className={"link"} onClick={() => handleIntegrateBtnClick("zoom")}>
              Integrate
            </Button>
          )}
        </Flex>
      ),
      default: integrations?.defaultMeeting === "zoom",
      disabled: !integratedPlatforms?.zoom,
    },
    {
      value: "teams",
      label: (
        <Flex justify={"space-between"} align={"center"}>
          <Typography.Paragraph>Microsoft Teams</Typography.Paragraph>
          {integratedPlatforms?.teams ? (
            <Flex>
              <CustomTag
                text={"Connected"}
                color={"#0FCA7A"}
                backgroundColor={"rgba(15, 202, 122, 0.10)"}
                style={{ width: "fit-content", padding: "6px 16px", lineHeight: 1, cursor: "default" }}
              />
              <div>
                <SvgIcon
                  style={{ height: "100%", marginTop: "auto" }}
                  type={"closeTag"}
                  onClick={(e) => {
                    handleTakeOffIntegration(e, "teams");
                  }}
                />
              </div>
            </Flex>
          ) : (
            <Button className={"link"} onClick={() => handleIntegrateBtnClick("teams")}>
              Integrate
            </Button>
          )}
        </Flex>
      ),
      default: integrations?.defaultMeeting === "teams",
      disabled: !integratedPlatforms?.teams,
    },
  ]?.filter(Boolean);
  // in X do optimistic update
  const dropdownMenuItems: MenuProps["items"] = [
    // { TODOF
    //   key: "1",
    //   label: "Set as Booked-Meeting Calendar",
    //   onClick: () => setToMainCalendar(selectedItemId),
    // },
    {
      key: "2",
      label: "Remove",
      onClick: () => removeFromAdditionalCalendar(selectedItemId),
    },
  ];

  const iframe = window.self !== window.top;

  const onGoogleClick = () => {
    if (iframe) {
      window.parent.postMessage({ type: "REDIRECT_REQUEST", url: `${import.meta.env.VITE_REACT_APP_SERVER_API}/calendar/addCalgoogle/dealai` }, "*");
    } else {
      window.location.href = `${import.meta.env.VITE_REACT_APP_SERVER_API}/calendar/addCalgoogle`;
    }
  };
  const onMicrosoftClick = () => {
    if (iframe) {
      window.parent.postMessage({ type: "REDIRECT_REQUEST", url: `${import.meta.env.VITE_REACT_APP_SERVER_API}/calendar/addCalmicrosoft/dealai` }, "*");
    } else {
      window.location.href = `${import.meta.env.VITE_REACT_APP_SERVER_API}/calendar/addCalmicrosoft`;
    }
  };
  // console.log(additionalCalendarData,'additionalCalendarData');

  return (
    <Drawer
      open={true}
      title={"Settings"}
      leftButtonTitle={"Close"}
      rightButtonTitle={"Save"}
      onBtnPrimaryClick={(e) => (!selectedMeetingTool ? message.success("Saved") && onClose(e) : handleFormSubmit(e))}
      // rightButtonDis={!selectedMeetingTool}
      onBtnSecondaryClick={onClose}
      onCancel={onClose}
      className={"additional-availability-drawer"}
    >
      <Form
        form={form}
        layout={"vertical"}
        initialValues={{
          defaultCommunicationMethod: communicationMethods[0].value,
          bufferTimeBetweenMeetings: bufferTime[0].value,
          minimumTimeToBookInAdvance: minTimeToBook[0].value,
          meetingTools: meetingToolsOptions.find((meet) => meet.default)?.value,
        }}
        className={"additional-availability-form"}
      >
        <Flex vertical={true} gap={22} style={{ paddingTop: 22 }}>
          <Flex vertical={true} gap={16}>
            <Typography.Title level={3} className={"subtitle"}>
              Main Calendar{" "}
              <Tooltip
                placement={"bottom"}
                title={
                  <>
                    Your meetings will be booked on your main calendar. <br />
                    <br />
                    *If you need to change this calendar with a different one, simply ping support and we will change it for you.
                    <br /> The update can take up to 24 hours, alternatively you can log out and create a new Meetz account with that email.
                  </>
                }
              >
                <SvgIcon type={"tooltip"} width={20} height={20} />
              </Tooltip>
            </Typography.Title>
            <List
              itemLayout={"horizontal"}
              dataSource={mainCalendarData}
              renderItem={(item) => (
                <List.Item>
                  <Flex justify={"space-between"} align={"center"} style={{ width: "100%" }}>
                    <Typography.Paragraph className={"text"}>{item.email}</Typography.Paragraph>
                    <CustomTag
                      text={"Booked-Meeting Calendar"}
                      color={"#01AEEF"}
                      backgroundColor={"rgba(1, 174, 239, 0.10)"}
                      style={{ width: "fit-content", padding: "6px 16px", lineHeight: 1 }}
                    />
                  </Flex>
                </List.Item>
              )}
            />
          </Flex>
          {!!additionalCalendarData?.length && (
            <Flex vertical={true} gap={16}>
              <Typography.Title level={3} className={"subtitle"}>
                Additional Calendars{" "}
              </Typography.Title>
              <List
                itemLayout="horizontal"
                dataSource={additionalCalendarData}
                renderItem={(item) => (
                  <List.Item>
                    <Flex justify={"space-between"} align={"center"} style={{ width: "100%" }}>
                      <Typography.Paragraph className={"text"}>{item.email}</Typography.Paragraph>
                      <DotsMenuDropdown menuItems={dropdownMenuItems} onDropdownClick={() => handleDropdownClick(item.id)} />
                    </Flex>
                  </List.Item>
                )}
              />
            </Flex>
          )}
          <Flex vertical={true} gap={16}>
            <SignInWithButtons
              title={
                <>
                  Add Additional Calendars{" "}
                  <Tooltip placement={"bottom"} title={"Cross checked calendars for your availability."}>
                    <SvgIcon type={"tooltip"} width={20} height={20} />
                  </Tooltip>
                </>
              }
              onGoogleClick={onGoogleClick}
              onMicrosoftClick={onMicrosoftClick}
            />
          </Flex>
          {/* <Flex vertical={true} gap={16}>
            <Typography.Title level={3} className={"subtitle"}>
              General Settings
            </Typography.Title>
            <Flex vertical={true} gap={8}>
              <Typography.Paragraph className={"label"}>Default communication method</Typography.Paragraph>
              <Form.Item name={"defaultCommunicationMethod"}>
                <CustomSelect options={communicationMethods} />
              </Form.Item>
            </Flex>
            <Flex vertical={true} gap={8}>
              <Typography.Paragraph className={"label"}>Buffer Times Between Meetings</Typography.Paragraph>
              <Form.Item name={"bufferTimeBetweenMeetings"}>
                <CustomSelect options={bufferTime} />
              </Form.Item>
            </Flex>
            <Flex vertical={true} gap={8}>
              <Typography.Paragraph className={"label"}>Minimum Time To Book In Advance</Typography.Paragraph>
              <Form.Item name={"minimumTimeToBookInAdvance"}>
                <CustomSelect options={minTimeToBook} />
              </Form.Item>
            </Flex>
          </Flex> */}
          <Flex vertical={true} gap={16}>
            <Typography.Title level={3} className={"subtitle"}>
              Default Meeting Tool
            </Typography.Title>
            <Form.Item name={"meetingTools"}>
              <Radio.Group
                onChange={(e) => setSelectedMeetingTool(e.target.value)}
                value={selectedMeetingTool}
                options={meetingToolsOptions}
                className={"meeting-tools-radio-group"}
              />
            </Form.Item>
          </Flex>
        </Flex>
      </Form>
    </Drawer>
  );
};
