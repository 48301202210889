/* eslint-disable react/jsx-key */
import { Typography } from "antd";
import { Fragment } from "react";

export const plansData = [
  {
    id: "1",
    name: "Starter",
    planBeName: "starter",
    iconType: "starterIcon",
    description: "Starter package for individuals to start exploring the platform.",
    previousPrice: 49,
    currentPrice: 39,
    discountPercent: 20,
    advantages: [
      // <Fragment key='1'>

      // <Typography.Paragraph>
      //   <Typography.Text style={{ fontWeight: 600 }}>150</Typography.Text> Prospects Data
      // </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>150</Typography.Text> Al Personalized Emails
      </Typography.Paragraph>,
      // <Typography.Paragraph>
      //   <Typography.Text style={{ fontWeight: 600 }}>2,000</Typography.Text> Templated Email Sends
      // </Typography.Paragraph>,
      <Typography.Paragraph>
        {" "}
        <Typography.Text style={{ fontWeight: 600 }}>500</Typography.Text> Prospects You Can Upload
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>1,000</Typography.Text> Email Sends
      </Typography.Paragraph>,
      <Typography.Paragraph>Calendar Scheduling Links</Typography.Paragraph>,
      <Typography.Paragraph>Connect Up To 2 Calendars For Availability</Typography.Paragraph>,
      <Typography.Paragraph>Email Inbox Management + Responses + Analytics All In The Platform</Typography.Paragraph>,
      <Typography.Paragraph>Parallel Dialer (Up To 15 Calls At A Time)</Typography.Paragraph>,
      <Typography.Paragraph>
        Dialer & <Typography.Text style={{ color: "#0FCA7A" }}> Spotify Integration </Typography.Text> - Stops Music/Podcast Once Someone Answers
      </Typography.Paragraph>,
      // </Fragment>,
    ],
    extraAdvantages: [
      // <Typography.Paragraph>
      //   <Typography.Text className={"previous-amount"}>150</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>200</Typography.Text> Prospects Data
      // </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text className={"previous-amount"}>150</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>200</Typography.Text> Al Personalized
        Emails
      </Typography.Paragraph>,
      // <Typography.Paragraph>
      //   {" "}
      //   <Typography.Text className={"previous-amount"}>2,000</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>2,400</Typography.Text> Templated
      //   Email Sends
      // </Typography.Paragraph>,
      <Typography.Paragraph>
        {" "}
        <Typography.Text className={"previous-amount"}>500</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>650</Typography.Text> Prospects You
        Can Upload
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text className={"previous-amount"}>1,000</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>1,200</Typography.Text> Email Sends
      </Typography.Paragraph>,
      <Typography.Paragraph>Calendar Scheduling Links</Typography.Paragraph>,
      <Typography.Paragraph>Connect Up To 2 Calendars For Availability</Typography.Paragraph>,
      <Typography.Paragraph>Email Inbox Management + Responses + Analytics All In The Platform</Typography.Paragraph>,
      <Typography.Paragraph>Parallel Dialer (Up To 15 Calls At A Time)</Typography.Paragraph>,
      <Typography.Paragraph>
        Dialer & <Typography.Text style={{ color: "#0FCA7A" }}> Spotify Integration </Typography.Text> - Stops Music/Podcast Once Someone Answers
      </Typography.Paragraph>,
    ],
    priority: 0,
  },
  {
    id: "2",
    name: "Growth",
    planBeName: "growth",
    iconType: "growthIcon",
    description: "The full power of Meetz to generate leads with ease by using Ai.",
    previousPrice: 99,
    currentPrice: 79,
    discountPercent: 20,
    advantages: [
      // <Fragment key='1'>
      // <Typography.Paragraph style={{ color: "white" }}>
      //   <Typography.Text style={{ fontWeight: 600, color: "white" }}>Features In Starter Plan, Plus</Typography.Text>
      // </Typography.Paragraph>,

      // <Typography.Paragraph>👉 Ai Email Scheduler Plan Included</Typography.Paragraph>,

      // <Typography.Paragraph style={{ color: "white" }}>⭐ Ai Email Scheduler Plan Included ⭐</Typography.Paragraph>,

      // <Typography.Paragraph style={{ color: "white" }}>
      //   <Typography.Text style={{ color: "white", fontWeight: 600 }}>500</Typography.Text> Prospects Data
      // </Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>
        <Typography.Text style={{ fontWeight: 600, color: "white" }}>500</Typography.Text> Al Personalized Emails
      </Typography.Paragraph>,

      <Typography.Paragraph style={{ color: "white" }}>
        <Typography.Text style={{ fontWeight: 600, color: "white" }}>5,000</Typography.Text> Prospects You Can Upload
      </Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>
        <Typography.Text style={{ color: "white", fontWeight: 600 }}>7,500</Typography.Text> Email Sends
      </Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>
        <Typography.Text style={{ fontWeight: 600, color: "white" }}>Unlimited</Typography.Text> Email Warm Ups 🔥
      </Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Robust auto phone dialing and SMS system</Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Connect Up To 6 Email Addresses</Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Auto-Rotates Email Addresses In Campaigns</Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Dialer &lt;&gt; Ai Personalized Email Follow Up (Missed Call Feature)</Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Dialer Real Time Prospect Information</Typography.Paragraph>,
      // </Fragment>,
    ],
    extraAdvantages: [
      // <Typography.Paragraph style={{ color: "white" }}>
      //   <Typography.Text style={{ fontWeight: 600, color: "white" }}>Features In Starter Plus</Typography.Text>
      // </Typography.Paragraph>,

      // <Typography.Paragraph style={{ color: "white" }}>👉 Ai Email Scheduler Plan Included</Typography.Paragraph>,
      // <Typography.Paragraph style={{ color: "white" }}>
      //   <Typography.Text style={{ color: "white", fontWeight: 600 }}>Unlimited</Typography.Text>
      // </Typography.Paragraph>,

      // <Typography.Paragraph style={{ color: "white" }}>
      //   <Typography.Text className={"previous-amount"} style={{ color: "white" }}>
      //     500
      //   </Typography.Text>{" "}
      //   <Typography.Text style={{ fontWeight: 600, color: "white" }}>650</Typography.Text> Prospects Data
      // </Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>
        <Typography.Text className={"previous-amount"} style={{ color: "white" }}>
          500
        </Typography.Text>{" "}
        <Typography.Text style={{ fontWeight: 600, color: "white" }}>650</Typography.Text> Al Personalized Emails
      </Typography.Paragraph>,
      // <Typography.Paragraph style={{ color: "white" }}>
      //   <Typography.Text className={"previous-amount"} style={{ color: "white" }}>
      //     5,000
      //   </Typography.Text>{" "}
      //   <Typography.Text style={{ fontWeight: 600, color: "white" }}>6,000</Typography.Text> Templated Email Sends
      // </Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>
        <Typography.Text className={"previous-amount"} style={{ color: "white" }}>
          5,000
        </Typography.Text>{" "}
        <Typography.Text style={{ fontWeight: 600, color: "white" }}>6,000</Typography.Text> Prospects You Can Upload
      </Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>
        <Typography.Text className={"previous-amount"} style={{ color: "white" }}>
          4,000
        </Typography.Text>{" "}
        <Typography.Text style={{ fontWeight: 600, color: "white" }}>7,500</Typography.Text> Email Sends
      </Typography.Paragraph>,
      // <Typography.Paragraph style={{ color: "white" }}>⭐ Ai Email Scheduler Plan Included ⭐</Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>
        <Typography.Text style={{ fontWeight: 600, color: "white" }}>Unlimited</Typography.Text> Email Warm Ups 🔥
      </Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Robust auto phone dialing and SMS system</Typography.Paragraph>,

      <Typography.Paragraph style={{ color: "white" }}>Connect Up To 6 Email Addresses</Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Auto-Rotates Email Addresses In Campaigns</Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Dialer &lt;&gt; Ai Personalized Email Follow Up (Missed Call Feature)</Typography.Paragraph>,
      <Typography.Paragraph style={{ color: "white" }}>Dialer Real Time Prospect Information</Typography.Paragraph>,
    ],
    priority: 1,
  },
  {
    id: "3",
    name: "Ultimate",
    planBeName: "ultimate",
    iconType: "ultimateIcon",
    description: "If you're ready to scale quickly and get a significant amount of leads.",
    previousPrice: 199,
    currentPrice: 159,
    discountPercent: 20,
    advantages: [
      // <Fragment key='1'>
      // <Typography.Paragraph>
      //   <Typography.Text style={{ fontWeight: 600 }}>Features In Growth Plus</Typography.Text>
      // </Typography.Paragraph>,
      // <Typography.Paragraph>
      //   <Typography.Text style={{ fontWeight: 600 }}>1,500</Typography.Text> Prospects Data
      // </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>1,500</Typography.Text> Al Personalized Emails
      </Typography.Paragraph>,
      // <Typography.Paragraph>
      //   <Typography.Text style={{ fontWeight: 600 }}>20,000</Typography.Text> Templated Email Sends
      // </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>15,000</Typography.Text> Prospects You Can Upload
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>30,000</Typography.Text> Email Sends
      </Typography.Paragraph>,
      <Typography.Paragraph>
        Connect Up To <Typography.Text>10</Typography.Text> Email Addresses
      </Typography.Paragraph>,
      // </Fragment>,
    ],
    extraAdvantages: [
      // <Typography.Paragraph>
      //   <Typography.Text style={{ fontWeight: 600 }}>Features In Growth Plus</Typography.Text>
      // </Typography.Paragraph>,
      // <Typography.Paragraph>
      //   <Typography.Text style={{ fontWeight: 600 }}>Unlimited</Typography.Text> Email Sends
      // </Typography.Paragraph>,

      // <Typography.Paragraph>
      //   <Typography.Text className={"previous-amount"}>1,500</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>1,800</Typography.Text> Prospects
      //   Data
      // </Typography.Paragraph>,
      <Typography.Paragraph>
        {" "}
        <Typography.Text className={"previous-amount"}>1,500</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>1,800</Typography.Text> Al
        Personalized Emails
      </Typography.Paragraph>,
      // <Typography.Paragraph>
      //   {" "}
      //   <Typography.Text className={"previous-amount"}>20,000</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>22,000</Typography.Text> Manual
      //   Email Sends
      // </Typography.Paragraph>,

      <Typography.Paragraph>
        <Typography.Text className={"previous-amount"}>10,000</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>15,000</Typography.Text> Prospects
        You Can Upload
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text className={"previous-amount"}>20,000</Typography.Text> <Typography.Text style={{ fontWeight: 600 }}>30,000</Typography.Text> Email
        Sends
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Advanced A/B</Typography.Text> Testing You Can Upload
      </Typography.Paragraph>,
      <Typography.Paragraph>
        Connect Up To <Typography.Text>10</Typography.Text> Email Addresses
      </Typography.Paragraph>,
    ],
    priority: 2,
  },
  {
    id: "4",
    name: "Ai Email Scheduler Plan",
    planBeName: "scheduler",
    iconType: "scheduler",
    description: " Unique And Simple Scheduling Experience Simply CC Your Ai Assistant In The Email Thread To Start Booking The Meeting.",
    previousPrice: 49,
    currentPrice: 39,
    discountPercent: 20,
    advantages: [
      // <Fragment key='1'>
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Patented Meetz Ai Technology That Handles Complex Scheduling Threads</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Connects To Your Calendar(s) To Find Availability For You And Your Guest</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Customize The Assistants Email To Name@YourCompany.Com</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Completely Customize The Assistants Email Signature!</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Automated Reminders For Your Guest If They Don't Respond</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Schedules Follow Ups In Future Dates If Needed. </Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Versatile And Integrates With Your Video Platform</Typography.Text>
      </Typography.Paragraph>,

      // </Fragment>,
    ],
    extraAdvantages: [
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Patented Meetz Ai Technology That Handles Complex Scheduling Threads</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Connects To Your Calendar(s) To Find Availability For You And Your Guest</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Customize The Assistants Email To Name@YourCompany.Com</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Completely Customize The Assistants Email Signature!</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Automated Reminders For Your Guest If They Don't Respond</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Schedules Follow Ups In Future Dates If Needed. </Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Versatile And Integrates With Your Video Platform</Typography.Text>
      </Typography.Paragraph>,
    ],
    priority: 2,
  },
];

export const enterprisePlan = {
  id: "0",
  name: "Enterprise",
  iconType: "people",
  description: "Have a team that needs more robust lead generation and cross team scheduling functionality?",
  advantages: [
    <Typography.Paragraph>Lead Generation Synchronizing</Typography.Paragraph>,
    <Typography.Paragraph>AI Deep Learning Optimization Across Team</Typography.Paragraph>,
    <Typography.Paragraph>Higher Limits</Typography.Paragraph>,
    <Typography.Paragraph>Customer Success To Advise</Typography.Paragraph>,
    <Typography.Paragraph>Cross Team Scheduling</Typography.Paragraph>,
    <Typography.Paragraph>More Customizations</Typography.Paragraph>,
  ],
};
