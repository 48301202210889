import React, { FC } from "react"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

interface MenuItemProps {
  icon: any
  title: any
  action: VoidFunction
  isActive: any
}

export const MenuItem: FC<MenuItemProps> = ({ icon, title, action, isActive = null }) => (
  <button className={`menu-item${isActive && isActive() ? " is-active" : ""}`} onClick={action} title={title}>
    <SvgIcon type={icon} />
  </button>
)