import { CheckOutlined, MinusOutlined, MoreOutlined } from "@ant-design/icons"
import { Button, Dropdown, Flex, Input, MenuProps, Space, Table, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC, useState } from "react"
import { ContentCard, CustomLabel, TableHeader } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { App } from "../../../../../../../../types"
import { forStepTwoData } from "../../../AIGenerated/ui/StepTwo/stepTwoData"
import { ExtractionFileModal } from "../../../AIGenerated/ui/StepTwo/ui/ExtractionFileModal"
import { UploadProspectsModal } from "../../../AIGenerated/ui/StepTwo/ui/UploadProspectsModal"
import "./index.less"

const { Text } = Typography

const items: MenuProps["items"] = [
  {
    key: "1",
    label: "Add To Sequence",
  },
  {
    key: "2",
    label: "Take Off Of Sequence",
  },
]

export const EditProspects: FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [openExtractionFileModal, setOpenExtractionFileModal] = useState(false)
  const [openUploadProspectsModal, setOpenUploadProspectsModal] = useState(false)
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const showExtractionFileModal = () => {
    setOpenExtractionFileModal(true)
  }

  const closeExtractionFileModal = () => {
    setOpenExtractionFileModal(false)
  }

  const showUploadProspectsModal = () => {
    setOpenUploadProspectsModal(true)
  }

  const closeUploadProspectsModal = () => {
    setOpenUploadProspectsModal(false)
  }

  const columns: ColumnsType<App.ForStepTwoProspectEntity> = [
    {
      title: "Seq.",
      dataIndex: "isSequence",
      key: "isSequence",
      render: record => (record ? <CheckOutlined /> : <MinusOutlined />),
    },
    {
      title: "Contacts",
      dataIndex: "contacts",
      key: "contacts",
      align: "right",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      align: "right",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      align: "right",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Emailed Already",
      dataIndex: "emailed",
      key: "emailed",
      align: "right",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Upload Name",
      dataIndex: "uploadName",
      key: "uploadName",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Company Url",
      dataIndex: "companyUrl",
      key: "companyUrl",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "LinkedIn Url",
      dataIndex: "linkedin",
      key: "linkedin",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Dropdown placement={"bottomRight"} menu={{ items: items }} trigger={["click"]}>
          <Space style={{ cursor: "pointer" }} onClick={e => e.preventDefault()}>
            <MoreOutlined />
          </Space>
        </Dropdown>
      ),
    },
  ]

  return (
    <Flex vertical gap={25}>
      <ExtractionFileModal open={openExtractionFileModal} onCancel={closeExtractionFileModal} />
      <UploadProspectsModal onCancel={closeUploadProspectsModal} open={openUploadProspectsModal} />
      <Flex className={"card-container"} gap={30}>
        <ContentCard headerIcon={<SvgIcon type={"fire"} />} cardTitle={"Prospects"}>
          <Flex className={"step-two-card"} align={"flex-start"} vertical gap={15}>
            <Flex vertical>
              <Text>Upload prospects to campaign</Text>
              <small>Add the relevant prospects inside CSV</small>
            </Flex>
            <Button onClick={showUploadProspectsModal} className={"btn-link"} type={"link"}>
              Upload prospects
            </Button>
            <Text>or</Text>
            <Text>Add prospects from Meetz database</Text>
            <Button onClick={showExtractionFileModal} className={"btn-link"} type={"link"}>
              Select extraction file
            </Button>
          </Flex>
        </ContentCard>
        <ContentCard headerIcon={<SvgIcon type={"fire"} />} cardTitle={"Offer"}>
          <Flex className={"step-two-card"} vertical gap={20}>
            <Flex vertical gap={5}>
              <CustomLabel
                title={"Value Proposition"}
                subtitle={"Describe why these prospects would take interest in your value proposition."}
              />
              <Input.Search className={"search-input"} placeholder={"Keep It Brief"} allowClear enterButton={false} />
            </Flex>
            <Flex vertical gap={5}>
              <CustomLabel
                title={"Unique benefits"}
                subtitle={"Expand on what makes your offer valuable to the typical prospect uploaded."}
              />
              <Input.Search className={"search-input"} placeholder={"Keep It Brief"} allowClear enterButton={false} />
            </Flex>
          </Flex>
        </ContentCard>
      </Flex>
      <Flex vertical className={"explore-wrapper step-two-table-container"}>
        <TableHeader searchPlaceholder={"Search Prospect data..."} />
        <Table
          columns={columns}
          dataSource={forStepTwoData}
          rowSelection={{ ...rowSelection }}
          rowKey={"id"}
          pagination={{ position: ["bottomLeft"] }}
        />
      </Flex>
    </Flex>
  )
}
