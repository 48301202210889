import { Flex, Pagination, Table as AntTable, TableProps, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useState } from "react";
import SkeletonTable, { SkeletonTableColumnsType } from "./SkeletonWrapper";
import { SvgIcon } from "../../../shared/icons";
import { CustomSelect } from "../CustomSelect";
import "./index.less";

interface CustomTableProps extends TableProps {
  dataSource: any;
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  // handleCustomPageChange;
  quickDropdownSkip?: boolean;
  quickSkip?: boolean;
  skeletonSelection?: boolean;
  customClassName?: string;
  loading?: boolean;
  columns: ColumnsType<any>;
  totalAmnt: number;
  pageSize?: number;
  divHeight?: string;
  divWidth?: string;
  keyCustom?: string;
}

export const Table: FC<CustomTableProps> = ({
  dataSource,
  customClassName,
  columns,
  skeletonSelection,
  loading,
  pageSize: customPageSize,
  totalPages,
  quickSkip,
  quickDropdownSkip,
  // handleCustomPageChange,
  currentPage,
  totalAmnt,
  setCurrentPage,
  keyCustom = "id",
  divHeight = "530px",
  divWidth = "fit-content",
  ...props
}) => {
  // console.log('key,',keyCustom);
  // const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(customPageSize || 10);
  // const [totalPages, setTotalPages] = useState((dataSource?.length <= pageSize ? 1 : Math.ceil(dataSource?.length / pageSize)) || 1)
  // const [paginatedData, setPaginatedData] = useState<any[]>([])

  // useEffect(() => {
  //   const startIndex = (currentPage - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   setPaginatedData(dataSource?.slice(startIndex, endIndex) || []);
  // }, [currentPage, pageSize, dataSource]);

  // useEffect(() => {
  //   setTotalPages((dataSource.length <= 10 ? 1 : Math.ceil(dataSource.length / pageSize)) || 1)
  // }, [dataSource, pageSize])

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const handleSuperPrevBtnClick = () => {
    setCurrentPage(1);
  };

  const handleSuperNextBtnClick = () => {
    setCurrentPage(totalPages);
  };

  const pagesToJumpOptions = quickDropdownSkip
    ? Array.from({ length: totalPages }, (_, index) => ({
        label: `${index + 1}`,
        value: `${index + 1}`,
      }))
    : [];

  const itemRender = (page, type, originalElement) => {
    if (type === "page" && page === totalPages && page > 99) {
      // Hide the last page number
      return null;
    }
    return originalElement;
  };

  return (
    <SkeletonTable rowSelection={skeletonSelection} loading={loading} columns={columns as SkeletonTableColumnsType[]}>
      {/* <div style={{width:'100%',height:divHeight,overflowY:'scroll'}}> */}
      <div style={{ width: divWidth, height: divHeight, overflowY: "scroll" }}>
        {/* <div style={{flexGrow:1,flexShrink:0,height:divHeight,overflowY:'scroll'}}> */}
        <AntTable
          className={`custom-table ${customClassName}`}
          rowKey={keyCustom}
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          sticky
          {...props}
        />
      </div>
      {totalPages > 0 && (
        <Flex justify={"space-between"} align={"center"} className={"table-footer-wrapper"}>
          <Flex gap={5} align={"center"}>
            {!!quickSkip && (
              <Flex
                className={`pagination-super-arrow-wrapper ${currentPage === 1 && "disabled"}`}
                justify={"center"}
                align={"center"}
                onClick={handleSuperPrevBtnClick}
              >
                <SvgIcon type={"paginationSuperArrowLeft"} width={16} height={16} />
              </Flex>
            )}
            <Pagination
              showLessItems={true}
              showSizeChanger={false} // TODO
              onChange={handlePageChange}
              // onChange={handleCustomPageChange || handlePageChange}
              current={currentPage}
              pageSize={pageSize}
              total={totalAmnt}
              prevIcon={
                <Flex className={"pagination-arrow-wrapper"} justify={"center"} align={"center"}>
                  <SvgIcon type={"paginationArrowLeft"} width={16} height={16} />
                </Flex>
              }
              nextIcon={
                <Flex className={"pagination-arrow-wrapper"} justify={"center"} align={"center"}>
                  <SvgIcon type={"paginationArrowRight"} width={16} height={16} />
                </Flex>
              }
              itemRender={itemRender}
              className={"pagination"}
            />
            {!!quickSkip && (
              <Flex
                className={`pagination-super-arrow-wrapper ${currentPage === totalPages && "disabled"}`}
                justify={"center"}
                align={"center"}
                onClick={handleSuperNextBtnClick}
              >
                <SvgIcon type={"paginationSuperArrowRight"} width={16} height={16} />
              </Flex>
            )}
          </Flex>
          <Flex className={"jump-to-page-wrapper"} align={"center"} gap={10}>
            <Typography.Paragraph className={"jump-to-page-text"}>
              Page {currentPage.toString()?.toLocaleString()} of {totalPages?.toLocaleString()}
            </Typography.Paragraph>
            {quickDropdownSkip && (
              <CustomSelect
                className={"jump-to-page-select"}
                options={pagesToJumpOptions || []}
                value={currentPage.toString()}
                onChange={(value) => setCurrentPage(Number(value))}
                isSearchSelect={false}
              />
            )}

            {/* <Typography.Paragraph className={"jump-to-page-text"}></Typography.Paragraph> */}
          </Flex>
        </Flex>
      )}
    </SkeletonTable>
  );
};
