import { Button, Flex, Input, Menu, message, Row, Tooltip, Typography } from "antd";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import "./index.less";
import { CustomSelect, Loader, Modal } from "src/components/ui";
import { BranchesOutlined, RiseOutlined } from "@ant-design/icons";
import { UpdatePlanBanner } from "src/components/pages/campaigns/CreateCampaign/ui";
import { userIsPayingSelector } from "src/store/user";
import { useDispatch, useSelector } from "react-redux";
import { getRealTimePrices, purchaseBalance } from "src/store/dialer/asyncRequests";
import { dialerSelector, resetPaybBill } from "src/store/dialer";
import { PaymentMethodModal } from "src/components/pages/billing/ui/PaymentMethodModal";
import { COUNTRIES_EXAMPLE_NUM_TWILIO, COUNTRIES_TWILIO, COUNTRIES_WITH_AVAILABLE_PHONE_EXNUMBER_TWILIO } from "src/pages/constant";
import { SvgIcon } from "src/shared/icons";

export const ManageCallBalanceModal: FC<{
  open;
  close;
  fromDialerPopup;
}> = ({ open, close, fromDialerPopup }) => {
  // const showAddNumberModal = () => {
  //   setIsAddNumberModalOpen(true);
  // };
  const dispatch = useDispatch();
  const { statusOfPaymentBilling, sentPaymentRequest, newDialerAmntAdd, amountPPM } = useSelector(dialerSelector);
  // const [amountPPM, setAmountPPM] = useState("Add to and from countries to check");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const value = e.target.value;

    // Check for invalid input (not allowing periods and non-digit characters)
    if (/^\d*$/.test(value)) {
      // If within valid range, update state and clear error
      if (Number(value) <= 500 && Number(value) > 9) {
        setAmount(value);
        setError("");
      } else if (Number(value) < 10) {
        setAmount(value);
        setError("Cannot add less than $10");
      } else {
        setError("Amount cannot add more than $500 (Contact support for this)");
      }
    }
  };
  const addBalance = () => {
    dispatch(purchaseBalance({ amount: Number(amount || 0) }));
  };
  const payingClient = useSelector(userIsPayingSelector);
  const [selSettingtab, setSelSettingtab] = useState("balance");

  const handleMenuClick = (e) => {
    setSelSettingtab(e.key);
  };

  const [paymentIntentToFix, setPaymentIntentToFix] = useState("");

  useEffect(() => {
    if (statusOfPaymentBilling === "no_activity_now") {
      return;
    }
    if (statusOfPaymentBilling === "successfully_added") {
      close();
      message.success(`Successfully added $${newDialerAmntAdd} to your balance`);
      dispatch(resetPaybBill());

      if (fromDialerPopup) {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      return;
    }
    close();

    setPaymentIntentToFix(statusOfPaymentBilling);
    dispatch(resetPaybBill());
  }, [statusOfPaymentBilling]);

  useEffect(() => {
    if (amountPPM === "Error") {
      message.error("Error in getting prices, reach out to support if this continues");
    }
  }, [amountPPM]);

  const closeBillingModal = () => {
    setPaymentIntentToFix("");
  };
  const succBillingModal = () => {
    message.success(`Successfully added $${newDialerAmntAdd} to your balance - Refreshing now`);
    closeBillingModal();
    close();
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  // rates
  const [countrySelected, setCountrySelected] = useState("");
  const [countryToSelected, setCountryToSelected] = useState("");
  const handleCountryChange = (value: string) => {
    setCountrySelected(value);
  };
  const handleCountryToChange = (value: string) => {
    setCountryToSelected(value);
  };

  useEffect(() => {
    if (countrySelected?.length && countryToSelected?.length) {
      dispatch(getRealTimePrices({ originCountry: countrySelected, destinationCountry: countryToSelected }));
    }
  }, [countrySelected, countryToSelected]);

  return (
    <>
      {!!paymentIntentToFix?.length && (
        <PaymentMethodModal
          isGenericBillingFailed
          successfulPayment={succBillingModal}
          onClose={closeBillingModal}
          paymentIntentToUse={paymentIntentToFix}
          forceTitle={`Add Dialer Balance - Meetz`}
          forceSubtitle={`Adding to balance - $${newDialerAmntAdd}`}
        />
      )}
      <Modal open={open} onCancel={close} title={"Dialer Balance"} width="800px">
        <Flex style={{ width: "100%" }}>
          <div style={{ height: "100%", marginRight: "20px" }}>
            <Menu
              mode="inline"
              selectedKeys={[selSettingtab]}
              onClick={handleMenuClick}
              style={{
                height: "100%",
                borderRight: 0,
                alignSelf: "center",
                padding: 25,
                width: "fit-content",
                marginRight: "20px",
                border: "1px solid #edeff3",
                borderRadius: "16px",
              }}
            >
              <Menu.Item key="balance" icon={<RiseOutlined />} style={{ padding: "0", paddingBottom: "20px" }}>
                Add balance
              </Menu.Item>
              <Menu.Item
                style={{ borderTop: "1px solid #eaeaea", padding: "0", paddingBottom: "10px", borderRadius: 0, paddingTop: "32px" }}
                key="rates"
                icon={<BranchesOutlined />}
              >
                Call rates
              </Menu.Item>
            </Menu>
          </div>

          {selSettingtab === "balance" ? (
            <div>
              {!payingClient && (
                <div style={{ height: "100px", width: "450px" }}>
                  <UpdatePlanBanner title={"Trial mode"} closeOriginModal={close} subtitle={"Skip trial now to get an extra $10 to use in your call balance"} />
                </div>
              )}
              <Flex align="center" style={{ marginBottom: "5px" }}>
                Amount to add to balance (In USD)
                <Input placeholder={"Add amount"} style={{ width: "150px", marginLeft: "20px" }} value={amount} onChange={handleChange} />
              </Flex>
              <div style={{ marginTop: "3px", color: error ? "red" : "black", marginBottom: "20px" }}>{error ? error : `Balance to add: $${amount || 0}`}</div>
              <div>
                {!sentPaymentRequest ? (
                  <Button className="primary" disabled={!amount?.length || !!error} onClick={addBalance}>
                    Add balance
                  </Button>
                ) : (
                  <Tooltip placement={"top"} title={<>Adding dialer balance...</>}>
                    <Button className="primary" disabled style={{ width: "150px" }}>
                      <Loader size="small" />
                      <span style={{ marginLeft: "10px" }}> Add balance</span>
                    </Button>
                  </Tooltip>
                )}
              </div>
              <div style={{ fontSize: "12px", color: "grey", marginTop: "35px" }}>
                By clicking "Add balance" the payment method on file will be charged. To get details on your call balance usage, check the billing section in
                your settings.
              </div>
            </div>
          ) : (
            ""
          )}
          {selSettingtab === "rates" ? (
            <div>
              <Typography.Title level={3} style={{ marginBottom: "18px" }}>
                Check call rates in Meetz
              </Typography.Title>
              <Flex justify="center">
                <div style={{ width: "180px" }}>
                  <Typography.Paragraph style={{ marginBottom: "20px" }}>
                    1. Select the country of your Meetz number{" "}
                    <Tooltip
                      placement={"top"}
                      title={
                        <>
                          This is the country of your Meetz phone number. Not where you physically call from.
                          <br />
                          <br />
                          Only displays countries that Meetz offers phone numbers to call with, this does not limit what countries you can call.
                        </>
                      }
                    >
                      {" "}
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
                    </Tooltip>
                  </Typography.Paragraph>
                  {/* <CustomSelect options={COUNTRIES_TWILIO} onChange={handleCountryChange} value={countrySelected} /> */}
                  <CustomSelect options={COUNTRIES_WITH_AVAILABLE_PHONE_EXNUMBER_TWILIO} onChange={handleCountryChange} value={countrySelected} />
                </div>{" "}
                <SvgIcon type={"rightArrow"} style={{ margin: "auto 70 auto 70", cursor: "pointer", verticalAlign: "middle" }} />
                <div style={{ width: "180px" }}>
                  <Typography.Paragraph style={{ marginBottom: "40px" }}>
                    2. Country calling
                    <Tooltip placement={"top"} title={<>Where your prospects phone number is from.</>}>
                      {" "}
                      <SvgIcon type={"tooltip"} style={{ marginLeft: 5, cursor: "pointer", verticalAlign: "middle" }} />
                    </Tooltip>
                  </Typography.Paragraph>
                  <CustomSelect options={COUNTRIES_EXAMPLE_NUM_TWILIO} onChange={handleCountryToChange} value={countryToSelected} />
                </div>{" "}
              </Flex>
              <Flex style={{ margin: "30px auto 0 auto", fontSize: "15px", fontWeight: 400 }}>
                Price per minute:{" "}
                <span style={{ color: "grey", fontSize: "15px", fontWeight: 400, marginLeft: "10px" }}>
                  {amountPPM === "loading" ? (
                    <Flex>
                      <div>Loading</div>
                      <Loader style={{ marginLeft: "30px" }} />
                    </Flex>
                  ) : amountPPM === "Error" ? (
                    "Add to and from countries to check"
                  ) : (
                    amountPPM
                  )}
                </span>
              </Flex>{" "}
              <div style={{ fontSize: "12px", color: "grey", marginTop: "35px" }}>
                These prices are subject to changes and updates in real time based on Meetz's phone provider.
                <br />
                When answered information that will be displayed is your prospects activity, prospects LinkedIn if available, company information, and Ai
                recommended intro sentences.
              </div>
            </div>
          ) : (
            ""
          )}
        </Flex>
      </Modal>
    </>
  );
};
