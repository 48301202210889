import { Divider, Flex } from "antd";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TabsSwitch } from "src/components/ui";
import { InfoItem } from "../InfoItem";
import tz from "moment-timezone";

export const CompanyCardContent: FC<{ data: any }> = ({ data }) => {
  const [activeTab, setActiveTab] = useState("About Company");
  const [aboutCo, setAboutCo] = useState(null);
  const [coUpdate, setCoUpdate] = useState(null);
  const localTimezone = tz?.tz?.guess();

  useEffect(() => {
    let setActTab = `About Company`;

    const companyLIUpdatesArr = data?.companyLIUpdatesArr;
    if (companyLIUpdatesArr?.length) {
      setCoUpdate(companyLIUpdatesArr);
      setActTab = `Updates`;
    } else {
      setCoUpdate(null);
    }
    const companyLICoInfo = data?.companyLICoInfo;
    if (companyLICoInfo?.name?.length || companyLICoInfo?.domain?.length) {
      setAboutCo(companyLICoInfo);
      setActTab = `About Company`;
    } else {
      setAboutCo(null);
    }
    setActiveTab(setActTab);
  }, [data]);

  return (
    <Flex vertical>
      {aboutCo && coUpdate ? (
        <TabsSwitch
          firstTab={"About Company"}
          secondTab={"Updates"}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isMediumSize
          style={{ width: "260px" }}
        />
      ) : (
        ""
      )}
      {activeTab === "About Company" ? (
        <Flex vertical style={{ height: 328, overflowY: "scroll", marginTop: 16 }}>
          {/* {data.companyPost &&
            data.companyPost.map((item: any, index: number) => {
              return (
                <> */}
          <Flex vertical gap={8}>
            <InfoItem label={"Company Name"} value={aboutCo?.name} />
            <InfoItem label={"Company Summary"} value={aboutCo?.description} />
            <InfoItem label={"Revenue"} value={aboutCo?.revenue} />
            <InfoItem label={"Head Count"} value={aboutCo?.staff_range} />
            {!!aboutCo?.technologies?.length && (
              <>
                <Divider style={{ margin: "16px 0" }} />

                <InfoItem
                  label={"Technologies used"}
                  value={aboutCo?.technologies?.map((sr) => {
                    return (
                      <>
                        {sr}
                        <br />
                      </>
                    );
                  })}
                />
              </>
            )}
          </Flex>
          {/* {index !== data.length - 1 && <Divider style={{ margin: "16px 0" }} />} */}
          {/* </>
              );
            })} */}
        </Flex>
      ) : (
        <Flex vertical style={{ height: 328, overflowY: "scroll", marginTop: 16 }}>
          {coUpdate?.length &&
            coUpdate?.map((item: any, index: number) => {
              return (
                <>
                  <Flex vertical gap={8} key={item.urn}>
                    <InfoItem label={"How Long Ago"} value={item?.timeDescription} />
                    <InfoItem label={"Post Text"} value={item?.text} />
                    {/* <InfoItem label={"Article In Post"} value={item.article} />
                    <InfoItem label={"Article Subtitle"} value={item.articleSubtitle} />
                    <InfoItem label={"Article Link"} value={<Link to={item.articleLink}>Link</Link>} /> */}
                  </Flex>
                  {index !== data.length - 1 && <Divider style={{ margin: "16px 0" }} />}
                </>
              );
            })}
        </Flex>
      )}
    </Flex>
  );
};
