import { App } from "../../../types";
import { StatisticItem } from "../../ui/StatisticsBar";

export const statisticEmailsData: StatisticItem[] = [
  { id: 6, title: "Total Sends", value: 31 },
  { id: 7, title: "Total Opens", value: 3 },
  { id: 8, title: "Total Clicks", value: 12232 },
  { id: 9, title: "Likely Forwarded", value: 2 },
  { id: 10, title: "Total Replies", value: 1 },
  { id: 11, title: "Booked Meetings", value: 4 },
];

export const emailsAddressesData: {
  id: string;
  email: string;
  warmUpMode: boolean;
  connectStatus: boolean;
  connected?: boolean;
}[] = [
  {
    id: "1",
    email: "tom@meetz.ai",
    warmUpMode: true,
    connectStatus: true,
    connected: false,
  },
  {
    id: "2",
    email: "gal.stuman@meetz.ai",
    warmUpMode: false,
    connectStatus: false,
    connected: false,
  },
];

// export const emailsData: App.EmailsEntity[] = [
export const emailsData = [
  {
    id: "1",
    firstName: "John",
    lastName: "Doe",

    contents: {
      title: "Personalized Emails with AI 📈 ",
      content:
        "Hi John, I noticed as a designer, you strive for efficient processes and creativity. Our AI can help you personalize emails fast, maximizing your valuable time. Imagine the impact on your design projects if you could automate personalized sales emails in less than a minute. Streamline your workflow and focus on what you love. Would this be of interest?",
    },
    fixedFrontEndAnalytic: "OPENED",
    count: 2,
    dateSent: "Dec 3, 2024",
    company: {
      id: "1",
      name: "ATB",
      step: 1,
    },
    from: [
      {
        type: "Direct phone",
        source: "(+1) 647-234-1245",
      },
    ],
    to: [
      {
        type: "Company phone",
        source: "(+1) 647-234-1243",
      },
    ],
    type: "Email",
    traffic: "Incoming",
  },
  {
    id: "2",
    firstName: "Pavlo",
    lastName: "Zibrov",
    contents: {
      title: "Revolutionize Your Outreach 🚀",
      content:
        "Hi Pavlo, I noticed your role as a designer at bn and thought our AI email personalization could be a game-changer for you. Without it, keeping up with personalized outreach can be time-consuming. With our solution, you can create perfect outbound emails in seconds, saving you valuable time. Would this be of interest?",
    },
    fixedFrontEndAnalytic: "LIKELY FORWARDED",
    count: 0,
    dateSent: "Jun 1, 2024",
    company: {
      id: "2",
      name: "FOXTROT",
      step: 4,
    },
    from: [
      {
        type: "Direct phone",
        source: "(+1) 647-234-1245",
      },
    ],
    to: [
      {
        type: "Company phone",
        source: "(+1) 647-234-1243",
      },
    ],
    type: "Email",
    traffic: "Incoming",
  },
  {
    id: "3",
    firstName: "Petro",
    lastName: "Poroshenko",
    contents: {
      title: "Automate Personalized Emails with AI 💌 ",
      content:
        "Hi Petro, I noticed as a designer, you strive for efficient processes and creativity. Our AI can help you personalize emails fast, maximizing your valuable time. Imagine the impact on your design projects if you could automate personalized sales emails in less than a minute. Streamline your workflow and focus on what you love. Would this be of interest?",
    },
    fixedFrontEndAnalytic: "REPLIED",
    count: 2,
    dateSent: "Oct 3, 2024",
    company: {
      id: "4",
      name: "Roshen",
      step: 2,
    },
    from: [
      {
        type: "Email",
        source: "john@gmail.com",
      },
    ],
    to: [
      {
        type: "Email",
        source: "test@Meetz.Ai",
      },
      {
        type: "Email",
        source: "test2@Meetz.Ai",
      },
    ],
    type: "Email",
    traffic: "Incoming",
  },
  {
    id: "4",
    firstName: "Volodymyr",
    lastName: "Klychko",
    contents: {
      title: "Automate Personalized Emails with AI",
      content:
        "Hi Volodymyr, I noticed as a designer, you strive for efficient processes and creativity. Our AI can help you personalize emails fast, maximizing your valuable time. Imagine the impact on your design projects if you could automate personalized sales emails in less than a minute. Streamline your workflow and focus on what you love. Would this be of interest?",
    },
    fixedFrontEndAnalytic: "BOOKED",
    count: 1,

    dateSent: "Oct 3, 2024",
    company: {
      id: "5",
      name: "Roshen",
      step: 2,
    },
    from: [
      {
        type: "Direct phone",
        source: "(+1) 647-234-1245",
      },
    ],
    to: [
      {
        type: "Company phone",
        source: "(+1) 647-234-1243",
      },
    ],
    type: "Email",
    traffic: "Incoming",
  },
  {
    id: "5",
    firstName: "John",
    lastName: "Wick",
    contents: {
      title: "Automate Personalized Emails with AI",
      content:
        "Hi John, I noticed as a designer, you strive for efficient processes and creativity. Our AI can help you personalize emails fast, maximizing your valuable time. Imagine the impact on your design projects if you could automate personalized sales emails in less than a minute. Streamline your workflow and focus on what you love. Would this be of interest?",
    },
    fixedFrontEndAnalytic: "CLICKED",
    count: 3,

    dateSent: "Oct 3, 2024",
    company: {
      id: "5",
      name: "Gildiia",
      step: 2,
    },
    from: [
      {
        type: "Direct phone",
        source: "(+1) 647-234-1245",
      },
    ],
    to: [
      {
        type: "Company phone",
        source: "(+1) 647-234-1243",
      },
    ],
    type: "Email",
    traffic: "Incoming",
  },
];
