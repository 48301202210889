import { Button, Form, Radio, Row, Typography } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, CustomLabel, Modal } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { CreateVoicemailModal } from "../CreateVoiceMailModal"

type AddVoicemailModalProps = {
  open: boolean
  onCancel: VoidFunction
}

const voicemailData: any[] = [
  {
    id: "1",
    title: "Winter Wonderland Discounts",
    duration: "03:37",
  },
  {
    id: "2",
    title: "Summer Sunshine Deals",
    duration: "02:07",
  },
]

export const AddVoicemailModal: FC<AddVoicemailModalProps> = ({ open, onCancel }) => {
  const [openModal, setOpenModal] = useState(false)

  const showModal = () => {
    setOpenModal(true)
    onCancel()
  }
  const onCreateModalCancel = () => {
    setOpenModal(false)
  }

  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    console.log("Added")
    onCancel()
  }

  const isVoicemailData = !!(voicemailData && voicemailData.length)

  return (
    <React.Fragment>
      <Modal width={530} open={open} title={"Add Voicemail"} onCancel={closeModal}>
        <CustomLabel
          title={"Choose from created voicemails"}
          subtitle={isVoicemailData ? "Select how to naturalize the variable to make it more personalized:" : undefined}
          isFontBold={true}
        />
        {isVoicemailData ? (
          <Form.Item name={"voicemail"}>
            <Radio.Group style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%" }}>
              {voicemailData.map(item => {
                return (
                  <Row justify={"space-between"} align={"middle"}>
                    <Radio key={item.id} value={item.id}>
                      {`${item.title} (${item.duration})`}
                    </Radio>
                    <SvgIcon type={"voicemailPlay"} />
                  </Row>
                )
              })}
            </Radio.Group>
          </Form.Item>
        ) : (
          <Typography.Paragraph className={"empty-data"}>
            No voicemail is currently created. Create voicemail below.
          </Typography.Paragraph>
        )}
        <Typography.Paragraph className={"empty-data"} style={{ margin: "16px 0" }}>
          Or
        </Typography.Paragraph>
        <CustomLabel title={"Create new one"} isFontBold={true} />
        <Button className={"link"} style={{ marginBottom: 28, padding: "5px 0" }} onClick={showModal}>
          Create
        </Button>
        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Add Voicemail"}
          onCancel={closeModal}
          handleSubmit={handleFormSubmit}
          disabled={!isVoicemailData}
        />
      </Modal>
      <CreateVoicemailModal open={openModal} onCancel={onCreateModalCancel} />
    </React.Fragment>
  )
}
