import { Flex, Typography } from "antd"
import { FC } from "react"
import { SvgIcon } from "../../../../../shared/icons"
import { ConfirmButtons, Modal } from "../../../../ui"
import "./index.less"

interface LowerPriorityPlanModalProps {
  handleChangePlan: () => void
  onClose: () => void
}

export const LowerPriorityPlanModal: FC<LowerPriorityPlanModalProps> = ({ handleChangePlan, onClose }) => {
  return (
    <Modal rootClassName={"lower-priority-modal"} open={true} closeIcon={false} onCancel={onClose}>
      <Flex vertical={true} align={"center"}>
        <SvgIcon type={"alarm"} />
        <Typography.Title className={"title"} level={2}>
          Downgrading May Affect Your Outreach Flows
        </Typography.Title>
        <Typography.Paragraph className={"description"}>
          Be aware that reducing your Meetz limitations could potentially disrupt your outreach flows if you don't have
          enough emails.
        </Typography.Paragraph>
        <ConfirmButtons
          className={"confirm-buttons"}
          leftButtonTitle={"Proceed with Downgrade"}
          rightButtonTitle={"Stay on Current Plan"}
          onCancel={() => {
            handleChangePlan()
            onClose()
          }}
          handleSubmit={onClose}
        />
      </Flex>
    </Modal>
  )
}
