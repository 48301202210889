import React, { useContext } from "react";
import { Flex, MenuProps, Typography } from "antd";
import { DealsContext } from "../../../../app/context/DealsContext";
import { CustomCard } from "../../../../ui/CustomCard";
import { CustomTag } from "../../../../ui/CustomTag";
import { getTagStyles } from "../../../../../helpers";
import { useDrag } from 'react-dnd';
import { ItemTypes } from "../Group"
import "./index.less"

export const DealCard = ({ deal, index, groupId }: any) => {
  const { setSelectedDealId, openEditDealModal, openDeleteDealModal, setDealsGroups } = useContext(DealsContext);

  const [{ isDragging, opacity }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { id: deal.id, index, groupId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.8 : 1
    }),
  });

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Edit",
      onClick: openEditDealModal,
    },
    {
      key: "2",
      label: "Delete",
      onClick: openDeleteDealModal,
    },
  ];

  const cardFooter = () => {
    const tagStyles = getTagStyles("Green");

    return <Flex justify={"space-between"} align={"center"} className={"deal-card-footer-wrapper"}>
      <Typography.Paragraph className={"date"}>{deal?.date?.format('DD MMM YYYY')}</Typography.Paragraph>
      <CustomTag text={`$${deal?.price}`} color={tagStyles.color} backgroundColor={tagStyles.backgroundColor} />
    </Flex>;
  };

  return (
    <div ref={drag} style={{ width: "100%", cursor: "pointer", opacity }}>
      <CustomCard
        id={deal?.id}
        title={deal?.name}
        description={deal?.description}
        dropdownMenuItems={items}
        onDropdownClick={() => setSelectedDealId(deal.id)}
        footerContent={cardFooter()}
      />
    </div>
  );
};
