/* eslint-disable react/jsx-fragments */
import { Button, Flex } from "antd"
import { SvgIcon } from "../../../../../../shared/icons"
import "./index.less"

import { useState } from "react"
import { updateSpotify } from "src/store/dialer/asyncRequests"
import { useDispatch } from "react-redux"
import { AppDispatch } from "src/store"


export const Player = () => {
const dispatch = useDispatch<AppDispatch>()  

  const handleMenuClick = (e: any,buttonName) => {
    e.stopPropagation()

    dispatch(updateSpotify({ buttonName }))
  }



  return (
     <div onClick={(e)=>{e.stopPropagation()}}>  
   
      <Flex justify={"space-between"} align={"center"} className={"player"} onClick={handleMenuClick}>
      <Button className={"player-btn"} onClick={(e) => handleMenuClick(e,'shuffle')}>
        <SvgIcon type={"shuffle"} width={20} height={20} />
      </Button>
      <Flex align={"center"} gap={24} className={"controls"} onClick={(e) => handleMenuClick(e,'back')}>
        <Button className={"player-btn"}>
          <SvgIcon type={"rewindBack"} width={14} height={14} />
        </Button>
        <Button className={"player-btn"} onClick={(e) => handleMenuClick(e,'play')}>
          <SvgIcon type={"play"} width={40} height={40} />
        </Button>
        <Button className={"player-btn"} onClick={(e) => handleMenuClick(e,'next')}>
          <SvgIcon type={"rewindForward"} width={14} height={14} />
        </Button>
      </Flex>
      <Button className={"player-btn"} onClick={(e) => handleMenuClick(e,'replay')}>
        <SvgIcon type={"undo"} width={20} height={20} />
      </Button>
    
      
    </Flex>
    </div>
  )
}
