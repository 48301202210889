import React, { createContext, useState } from "react"
import { dealsGroupsData } from "../../pages/deals/dealsGroupsData"
import { Dayjs } from "dayjs"

interface MeetingLinksContextProps {
  children: React.ReactNode
}

interface Deal {
  id: string
  name: string
  description: string
  date: Dayjs
  price: number
}

interface DealsGroup {
  id: string
  name: string
  totalPrice: number
  deals: Deal[]
}

export const DealsContext = createContext<{
  dealsGroups: DealsGroup[]
  setDealsGroups: React.Dispatch<React.SetStateAction<DealsGroup[]>>
  selectedGroupId: string | null
  setSelectedGroupId: React.Dispatch<React.SetStateAction<string | null>>
  selectedDealId: string | null
  setSelectedDealId: React.Dispatch<React.SetStateAction<string | null>>
  addColumn: (newGroup: DealsGroup) => void
  editColumn: (updatedGroup: DealsGroup) => void
  deleteColumn: () => void
  addDeal: (newDeal: Deal) => void
  editDeal: (updatedDeal: Deal) => void
  deleteDeal: () => void
  isAddColumnModalOpened: boolean
  openAddColumnModal: () => void
  closeAddColumnModal: () => void
  isEditColumnModalOpened: boolean
  openEditColumnModal: () => void
  closeEditColumnModal: () => void
  isDeleteColumnModalOpened: boolean
  openDeleteColumnModal: () => void
  closeDeleteColumnModal: () => void
  isAddDealModalOpened: boolean
  openAddDealModal: () => void
  closeAddDealModal: () => void
  isEditDealModalOpened: boolean
  openEditDealModal: () => void
  closeEditDealModal: () => void
  isDeleteDealModalOpened: boolean
  openDeleteDealModal: () => void
  closeDeleteDealModal: () => void
}>({
  dealsGroups: [],
  setDealsGroups: () => {},
  selectedGroupId: null,
  setSelectedGroupId: () => {},
  selectedDealId: null,
  setSelectedDealId: () => {},
  addColumn: () => {},
  editColumn: () => {},
  deleteColumn: () => {},
  addDeal: () => {},
  editDeal: () => {},
  deleteDeal: () => {},
  isAddColumnModalOpened: false,
  openAddColumnModal: () => {},
  closeAddColumnModal: () => {},
  isEditColumnModalOpened: false,
  openEditColumnModal: () => {},
  closeEditColumnModal: () => {},
  isDeleteColumnModalOpened: false,
  openDeleteColumnModal: () => {},
  closeDeleteColumnModal: () => {},
  isAddDealModalOpened: false,
  openAddDealModal: () => {},
  closeAddDealModal: () => {},
  isEditDealModalOpened: false,
  openEditDealModal: () => {},
  closeEditDealModal: () => {},
  isDeleteDealModalOpened: false,
  openDeleteDealModal: () => {},
  closeDeleteDealModal: () => {},
})

export const DealsContextProvider: React.FC<MeetingLinksContextProps> = ({ children }) => {
  const [dealsGroups, setDealsGroups] = useState<DealsGroup[]>(dealsGroupsData)
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null)
  const [selectedDealId, setSelectedDealId] = useState<string | null>(null)
  const [isAddColumnModalOpened, setIsAddColumnModalOpened] = React.useState(false)
  const [isEditColumnModalOpened, setIsEditColumnModalOpened] = useState(false)
  const [isDeleteColumnModalOpened, setIsDeleteColumnModalOpened] = useState(false)
  const [isAddDealModalOpened, setIsAddDealModalOpened] = useState(false)
  const [isEditDealModalOpened, setIsEditDealModalOpened] = useState(false)
  const [isDeleteDealModalOpened, setIsDeleteDealModalOpened] = useState(false)

  const addColumn = (newGroup: DealsGroup) => {
    setDealsGroups([...dealsGroups, newGroup])
  }

  const editColumn = (updatedGroup: DealsGroup) => {
    setDealsGroups(dealsGroups.map(group => group.id === updatedGroup.id ? updatedGroup : group))
  }

  const deleteColumn = () => {
    setDealsGroups(dealsGroups.filter(group => group?.id !== selectedGroupId))
  }

  const addDeal = (newDeal: Deal) => {
    const currentGroup = dealsGroups.find(group => group.id === selectedGroupId)

    if (currentGroup) {
      const updatedDeals = [...currentGroup.deals, newDeal]
      const updatedGroup = {
        ...currentGroup,
        deals: updatedDeals,
        totalPrice: updatedDeals.reduce((total, deal) => total + deal.price, 0)
      };

      setDealsGroups(dealsGroups.map(group => group.id === updatedGroup.id ? updatedGroup : group))
    }
  }

  const editDeal = (updatedDeal: Deal) => {
    const updatedGroups = dealsGroups.map(group => {
      const updatedDeals = group.deals.map(deal => deal.id === updatedDeal.id ? updatedDeal : deal);
      const totalPrice = updatedDeals.reduce((total, deal) => total + deal.price, 0);
      return { ...group, deals: updatedDeals, totalPrice };
    });
    setDealsGroups(updatedGroups);
  };

  const deleteDeal = () => {
    const updatedGroups = dealsGroups.map(group => {
      const updatedDeals = group.deals.filter(deal => deal.id !== selectedDealId);
      const totalPrice = updatedDeals.reduce((total, deal) => total + deal.price, 0);
      return { ...group, deals: updatedDeals, totalPrice };
    });
    setDealsGroups(updatedGroups);
  };

  const openAddColumnModal = () => {
    setIsAddColumnModalOpened(true)
  }

  const closeAddColumnModal = () => {
    setIsAddColumnModalOpened(false)
  }

  const openEditColumnModal = () => {
    setIsEditColumnModalOpened(true)
  }

  const closeEditColumnModal = () => {
    setIsEditColumnModalOpened(false)
  }

  const openDeleteColumnModal = () => {
    setIsDeleteColumnModalOpened(true)
  }

  const closeDeleteColumnModal = () => {
    setIsDeleteColumnModalOpened(false)
  }

  const openAddDealModal = () => {
    setIsAddDealModalOpened(true)
  }

  const closeAddDealModal = () => {
    setIsAddDealModalOpened(false)
  }

  const openEditDealModal = () => {
    setIsEditDealModalOpened(true)
  }

  const closeEditDealModal = () => {
    setIsEditDealModalOpened(false)
  }

  const openDeleteDealModal = () => {
    setIsDeleteDealModalOpened(true)
  }

  const closeDeleteDealModal = () => {
    setIsDeleteDealModalOpened(false)
  }

  const contextValue = {
    dealsGroups,
    setDealsGroups,
    selectedGroupId,
    setSelectedGroupId,
    selectedDealId,
    setSelectedDealId,
    addColumn,
    editColumn,
    deleteColumn,
    addDeal,
    editDeal,
    deleteDeal,
    isAddColumnModalOpened,
    openAddColumnModal,
    closeAddColumnModal,
    isEditColumnModalOpened,
    openEditColumnModal,
    closeEditColumnModal,
    isDeleteColumnModalOpened,
    openDeleteColumnModal,
    closeDeleteColumnModal,
    isAddDealModalOpened,
    openAddDealModal,
    closeAddDealModal,
    isEditDealModalOpened,
    openEditDealModal,
    closeEditDealModal,
    isDeleteDealModalOpened,
    openDeleteDealModal,
    closeDeleteDealModal,
  }

  return <DealsContext.Provider value={contextValue}>{children}</DealsContext.Provider>
}
