import { Flex, Form, Input } from "antd"
import { FC, useContext } from "react"
import { TeamMembersContext } from "../../../../app/context/TeamMembersContext"
import { ConfirmButtons, Modal } from "../../../../ui"
import { generateRandomDigits } from "../../../../../helpers"
import "./index.less"

interface AddMembersManuallyModalProps {
  isStepAfterCreateTeam?: boolean
  isAddContact?:boolean
  onClose: () => void
}

export const AddMembersManuallyModal: FC<AddMembersManuallyModalProps> = ({ isStepAfterCreateTeam, isAddContact, onClose }) => {
  const { teams, setTeams, selectedTeamId, tempTeamData, setTempTeamData, openAddTeamMembersModal } =
    useContext(TeamMembersContext)
  const [form] = Form.useForm()

  const handleSubmitBtnClick = () => {
    form
      .validateFields()
      .then(values => {
        if (isStepAfterCreateTeam) {
          const newTeam: any = {
            ...tempTeamData,
            members: tempTeamData?.members
              ? [...tempTeamData.members, { ...values, id: generateRandomDigits(10) }]
              : [{ ...values }]
          };
          setTempTeamData(newTeam)
          onClose()
          openAddTeamMembersModal()
        } else {
          const updatedTeam: any = teams.find(team => team.id === selectedTeamId);
          setTempTeamData({...updatedTeam, members: [{ ...values, id: generateRandomDigits(10) }] })
          onClose()
          openAddTeamMembersModal()
        }

        onClose()
      })
      .catch(errorInfo => {
        console.error("Validation failed:", errorInfo)
      })
  }

  return (
    <Modal
      open={true}
      title={isAddContact ? "Add Contact" : "Add Member Manually"}
      rootClassName={"add-team-members-manually-modal"}
      onCancel={onClose}
    >
      <Form form={form} layout={"vertical"} requiredMark={false} className={"add-team-members-form"}>
        <Flex vertical={true} gap={16}>
          <Form.Item
            name={"email"}
            label={"Email*"}
            rules={[
              {
                required: true,
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input type={"email"} placeholder={"Enter text"} />
          </Form.Item>
          <Form.Item name={"uploadName"} label={"Upload Name"}>
            <Input placeholder={"Enter text"} />
          </Form.Item>
          <Form.Item
            name={"firstName"}
            label={"First Name*"}
            rules={[
              {
                required: true,
                message: "Please enter the first name",
              },
            ]}
          >
            <Input placeholder={"Enter text"} />
          </Form.Item>
          <Form.Item name={"lastName"} label={"Last Name"}>
            <Input placeholder={"Enter text"} />
          </Form.Item>
          <Form.Item
            name={"position"}
            label={"Position*"}
            rules={[
              {
                required: true,
                message: "Please enter the position",
              },
            ]}
          >
            <Input placeholder={"Enter text"} />
          </Form.Item>
          <Form.Item name={"linkedInUrl"} label={"Leads LinkedIn Url"}>
            <Input placeholder={"Enter text"} />
          </Form.Item>
          <Form.Item
            name={"leadsCompanyName"}
            label={"Leads Company Name*"}
            rules={[
              {
                required: true,
                message: "Please enter the leads company name",
              },
            ]}
          >
            <Input placeholder={"Enter text"} />
          </Form.Item>
          <Form.Item name={"leadsCompanyMainUrl"} label={"Leads Company's Main Url"}>
            <Input placeholder={"Enter text"} />
          </Form.Item>
        </Flex>
      </Form>
      <ConfirmButtons
        rightButtonTitle={"Add"}
        leftButtonTitle={"Back"}
        className={"confirm-buttons"}
        handleSubmit={handleSubmitBtnClick}
        onCancel={onClose}
      />
    </Modal>
  )
}
