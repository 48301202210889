import { useMediaQuery } from "react-responsive";

enum Breakpoints {
  XS = 480,
  SM = 576,
  MD = 768,
  LG = 1024,
  XL = 1200,
  XXL = 1600,
}

type SizeMap = Readonly<{ [key in keyof typeof Breakpoints]: Breakpoints }>;

const sizes: SizeMap = {
  XS: Breakpoints.XS,
  SM: Breakpoints.SM,
  MD: Breakpoints.MD,
  LG: Breakpoints.LG,
  XL: Breakpoints.XL,
  XXL: Breakpoints.XXL,
} as const;

type DeviceType = "mobile" | "desktop" | "tablet" | keyof SizeMap;

type BreakpointHook = { [key in `is${Capitalize<DeviceType>}`]: boolean } & { sizes: SizeMap };

function useBreakpoints(): BreakpointHook {
  return {
    isMobile: useMediaQuery({ maxWidth: Breakpoints.MD - 1 }),
    isTablet: useMediaQuery({ minWidth: Breakpoints.MD, maxWidth: Breakpoints.XL - 1 }),
    isDesktop: useMediaQuery({ minWidth: Breakpoints.MD }),
    // isDesktop: useMediaQuery({ minWidth: Breakpoints.XL }),
    isXS: useMediaQuery({ maxWidth: Breakpoints.XS }),
    isSM: useMediaQuery({ minWidth: Breakpoints.XS }),
    isMD: useMediaQuery({ minWidth: Breakpoints.SM }),
    isLG: useMediaQuery({ minWidth: Breakpoints.MD }),
    isXL: useMediaQuery({ minWidth: Breakpoints.LG }),
    isXXL: useMediaQuery({ minWidth: Breakpoints.XL }),
    sizes,
  };
}

export { Breakpoints, useBreakpoints };
