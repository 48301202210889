// export const updateObject = (oldObject, updatedProperties) => ({
//   ...oldObject,
//   ...updatedProperties,
// });

// export const updateArray = (oldArray, updatedProperties) => [...oldArray, ...updatedProperties];

// export const REDUCER_STATUS = {
//   initial: 'initial',
//   success: 'success',
//   error: 'error',
//   loading: 'loading',
// };

// export function createState({ state, status = REDUCER_STATUS.initial, error = null }) {
//   return {
//     status,
//     error,
//     data: state,
//   };
// }

/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable func-names */
/* eslint-disable no-restricted-syntax */
// import { TIMEZONE_ABBR, LANDING_TO_CHECKOUT, SUBSCRIPTION_TYPES, DAYLIGHT_DATES } from 'config/constants';

import tz from "moment-timezone"
import { DAYLIGHT_DATES } from "src/pages/constant"
// import ct from 'countries-and-timezones';

// export const ct_timezones = ct.getAllTimezones();
// export const timezones = Object.entries(ct_timezones)
//   .map(([key, value]) => {
//     if (!TIMEZONE_ABBR[value.dstOffsetStr]) {
//       return null;
//     }

//     return {
//       name: key,
//       offset: TIMEZONE_ABBR[value.dstOffsetStr],
//     };
//   })
//   .filter(Boolean);

// export const hexToRgb = (input: string | any[]) => {
//   input += '';
//   input = input.replace('#', '');
//   const hexRegex = /[0-9A-Fa-f]/g;
//   if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
//     throw new Error('input is not a valid hex color.');
//   }
//   if (input.length === 3) {
//     const first = input[0];
//     const second = input[1];
//     const last = input[2];
//     input = first + first + second + second + last + last;
//   }
//   input = input.toUpperCase();
//   const first = input[0] + input[1];
//   const second = input[2] + input[3];
//   const last = input[4] + input[5];
//   return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(last, 16)}`;
// };

// export const capitalizeSnakeCase = (string: string) =>
//   string
//     .split('_')
//     .map((str: string) => str.charAt(0).toUpperCase() + str.slice(1))
//     .join(' ');

// export const sortWeekdays = (schedule: { [x: string]: any; }) => {
//   const sorter = {
//     monday: 1,
//     tuesday: 2,
//     wednesday: 3,
//     thursday: 4,
//     friday: 5,
//     saturday: 6,
//     sunday: 7,
//   };

//   const tmp: any[] = [];
//   Object.keys(schedule).forEach(function (key) {
//     const value = schedule[key];
//     const index = sorter[key.toLowerCase()];
//     tmp[index] = {
//       key,
//       value,
//     };
//   });

//   const ordered_data = {};
//   tmp.forEach(function (obj) {
//     ordered_data[obj.key] = obj.value;
//   });

//   return ordered_data;
// };

export const stringifyResults = (arr: any[]) => {
  const res = []
  for (const a of arr) {
    if (typeof a === "string") {
      res.push(JSON.parse(a))
    } else {
      res.push(a)
    }
  }

  return res
}

// export const capitalizeWord = (str: { match: (arg0: string) => any; charAt: (arg0: number) => string; slice: (arg0: number) => string; map: (arg0: (str: any) => any) => any[]; }) => {
//   if (str.match('_')) {
//     return capitalizeSnakeCase(str);
//   }else if (str && typeof str === 'string') {
//     return str.charAt(0).toUpperCase() + str.slice(1);
//   }

//   if (Array.isArray(str)) {
//     return str.map((str) => str.charAt(0).toUpperCase() + str.slice(1)).join(' ');
//   }
// };

// export const SuspenseImg = ({ src, ...rest }) => {
//   imgCache.read(src);
//   return <img alt="" src={src} {...rest} />;
// };

// export const ErrorBoundaryLogger = (error: any, info: any) => {
//   // eslint-disable-next-line no-console
//   console.log('Error logging: \n', error, info);
// };

// export function negativeDiscountChecker({ amount, coupon_value }) {
//   const val = +amount / 100 - +coupon_value;

//   if (val <= 0) {
//     return Number(0).toFixed(2);
//   }

//   return val.toFixed(2);
// }

// export const removeArrayFromArray = ({ arr, to_remove }) => {
//   arr.filter(function (x: any) {
//     return to_remove.indexOf(x) < 0;
//   });
// };

// export function devProdUrlFidner(url: any) {
//   return process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_CLIENT}/${url}` : `http://localhost:3000/${url}`;
// }

// export function isUStime({ timezone }) {
//   const selected = timezones.find((t) => t.name === timezone);
//   let time = Number(selected.offset.match(/[-|+]\d+/));
//   if (Number.isNaN(time)) {
//     time = 0;
//   }

//   return time >= -10 && time <= -4;
// }

// export function getOffesetFormTimezoneName({ datetime, timezone }) {
//   const exist = tz.tz.zone(timezone);
//   let offset;
//   if (!exist) {
//     offset = '+00:00';
//   } else {
//     const timeslot = tz.tz(`${datetime}`, `${timezone}`).format();

//     offset = timeslot.match(/[+|-][\d+:]+$/);
//     offset = offset ? offset[0] : '+00:00';
//   }
//   return offset;
// }

// export function extractDataFromSignature(text: string) {
//   const first_split = text.split('|');
//   let name;
//   let job_title;
//   let email;

//   if (first_split.length === 2) {
//     name = first_split[0].trim();
//   } else {
//     return { name, job_title, email, error: 'name' };
//   }

//   const second_split = first_split[1].split('\n');
//   if (second_split.length === 2) {
//     job_title = second_split[0].trim();
//     email = second_split[1].replace('e:', '').trim();
//   } else {
//     return { name, job_title, email, error: 'email' };
//   }

//   return { name, job_title, email, error: null };
// }

// export function toCheckoutChecker(query: { entries: () => any; has: (arg0: any) => any; }) {
//   let is_checkout = false;
//   const entries = query?.entries();
//   if ([...entries].length > 0) {
//     // eslint-disable-next-line no-restricted-syntax
//     for (const param of LANDING_TO_CHECKOUT) {
//       if (!query.has(param)) {
//         is_checkout = false;
//         break;
//       }
//       is_checkout = true;
//     }
//   }
//   // ---DEV
//   // make sure we got valid amount -> find price_id
//   // valid sub -> SUB_TYPE
//   // valid interval
//   return is_checkout;
// }

// export function findDowngradeUpgradeStatus({ subscription, old_type, new_interval, new_amount, type }) {
//   let is_downgrade = false;

//   const old_amount = subscription?.plan?.amount / 100;
//   const old_interval = subscription?.plan?.interval;

//   if (old_interval === new_interval) {
//     if (old_amount > new_amount) {
//       is_downgrade = true;
//     }
//   } else {
//     if (
//       (old_type === SUBSCRIPTION_TYPES.PREMIUM && type !== SUBSCRIPTION_TYPES.PREMIUM) ||
//       (old_type === SUBSCRIPTION_TYPES.STANDARD && type === SUBSCRIPTION_TYPES.FREE)
//     ) {
//       is_downgrade = true;
//     }

//     if (old_type === type && old_amount > new_amount) {
//       is_downgrade = true;
//     }
//   }
//   return is_downgrade;
// }

// export function getSubscriptionPriceIdFromAmount({ from_landing, plans }) {
//   if (from_landing) {
//     const { amount, interval } = from_landing;

//     let id;
//     const plan = plans[interval];

//     if (plan) {
//       // eslint-disable-next-line eqeqeq
//       id = plan.find((pl: { amount: any; }) => pl.amount == amount)?.id;
//     }
//     return id;
//   }
// }

// export function getCheckoutDefaultValues({ names, data }) {
//   return names.reduce((acc: { [x: string]: any; }, curr: string | number) => {
//     acc[curr] = data[curr];
//     return acc;
//   }, {});
// }

// rough estimate of sumet time for display purposes not for booking
export function isSummerTimeChecker(timeslot = Date.now()) {
  const this_year:any = tz(timeslot).year()
  const start = `${DAYLIGHT_DATES[this_year].start} 00:00:00`
  const end = `${DAYLIGHT_DATES[this_year].end} 00:00:00`
  const start_unix = +tz(start).startOf("day").format("x")
  const end_unix = +tz(end).startOf("day").format("x")

  const now = timeslot
  if (now >= start_unix && now <= end_unix) {
    return true
  }
  return false
}
