import { Flex} from "antd"
import  { FC, useEffect, useState,  } from "react"
import { Loader, Modal } from "src/components/ui"
import Confetti from 'react-confetti';

type SaveValueModalProps = {
  editId?: number;
  open: boolean;
  onCancel: VoidFunction;
defaultVal  :string;
defaultTitle  :string;
}


export const CreatingCampaignModal: FC<SaveValueModalProps> = ({ open,isFirstCamp }) => {
  const [isFirstCampaign, setIsFirstCampaign] = useState(false);
  useEffect(() => {
   if(isFirstCamp && open){
setIsFirstCampaign(true)
   }
  }, [isFirstCamp,open]);


  return <Modal width={530} open={open}hasCloseIcon={false}  title={ `Meetz is creating your ${isFirstCampaign? 'first ':''}campaign now...` } > 
    <Flex justify='center' align='center' vertical><br /><Loader size='large'/><br /><br />
<div>{isFirstCampaign? 'Welcome to Meetz and get':'Get'} ready for leads!</div>
</Flex>
  </Modal>
}
