import { Button, Flex, Row, Typography } from "antd"
import { FC, useState } from "react"
import { SvgIcon } from "src/shared/icons"
import { GuidModal } from "../GuidModal"
import "./index.less"

export const HowToUseCard: FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
  const [isGuidModalOpened, setIsGuidModalOpened] = useState(false)

  const openGuidModal = () => {
    setIsGuidModalOpened(true)
  }

  const closeGuidModal = () => {
    setIsGuidModalOpened(false)
  }

  return (
    <>
      {/* <Flex className={"how-to-use-card"} vertical> TODOF
        {isCollapsed ? (
          <Row align={"middle"} justify={"center"}>
            <SvgIcon type={"question"} />
          </Row>
        ) : (
          <Row align={"middle"} style={{ marginBottom: 12, gap: 8 }}>
            <SvgIcon type={"question"} />
            <Typography.Title level={4} className={"how-to-use-title"}>
              How to use Meetz?
            </Typography.Title>
            <Typography.Paragraph className={"how-to-use-description"}>
              Get started using our quick instructions!
            </Typography.Paragraph>
            <Button className={"primary"} onClick={openGuidModal}>Get Started</Button>
          </Row>
        )}
      </Flex>
      {isGuidModalOpened && <GuidModal open={isGuidModalOpened} onCancel={closeGuidModal} />} */}
    </>
  )
}
