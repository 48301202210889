import { Card, CardProps, Row, Typography } from "antd"
import { FC, PropsWithChildren, ReactNode } from "react"
import "./index.less"

interface ContentCardProps extends CardProps {
  headerIcon: ReactNode
  cardTitle: string
  headerRightContent?: ReactNode
  customClassName?: string
}

export const ContentCard: FC<PropsWithChildren<ContentCardProps>> = ({
  headerIcon,
  cardTitle,
  children,
  headerRightContent,
  customClassName,
  ...props
}) => {
  return (
    <Card
      className={`content-card-wrapper ${customClassName}`}
      title={
        <Row align={"middle"} justify={"space-between"}>
          <Row align={"middle"} style={{ gap: 8 }}>
            {headerIcon}
            <Typography.Paragraph className={"card-title"}>{cardTitle}</Typography.Paragraph>
          </Row>
          {headerRightContent ? headerRightContent : null}
        </Row>
      }
      bordered={false}
      {...props}
    >
      {children}
    </Card>
  )
}
