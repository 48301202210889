export const recentInteractionsData = [
  {
    id: "11",
    name: "phone",
    time: "now",
    contact: "(345) 333-3333",
  },
  {
    id: "1",
    name: "email",
    time: "1 min ago",
    contact: "tom@meetz.ai",
  },
  {
    id: "2",
    name: "phone",
    time: "1 h ago",
    contact: "(345) 345-5453",
  },
  {
    id: "3",
    name: "SMS",
    time: "1 day ago",
    contact: "(345) 345-5555",
  },
  {
    id: "4",
    name: "phone",
    time: "1 month ago",
    contact: "(555) 345-5555",
  },
  {
    id: "5",
    name: "email",
    time: "29 Dec 2023",
    contact: "tolya@bn.ua",
  },
]

export const hotProspectsData = [
  {
    id: "1",
    contact: "tom@meetz.ai",
    status: [
      {
        title: "Open",
        value: 10,
      },
      {
        title: "Reply",
        value: 10,
      },
      {
        title: "Booked",
        value: "Yes",
      },
    ],
  },
  {
    id: "2",
    contact: "kamila@meetz.ai",
    status: [
      {
        title: "Positively Answered Calls",
        value: "Yes",
      },
      {
        title: "Ai-email-scheduler-flow",
        value: "No",
      },
    ],
  },
  {
    id: "3",
    contact: "tolya@bn.ai",
    status: [
      {
        title: "Positively Answered Calls",
        value: "Yes",
      },
      {
        title: "Booked",
        value: "Yes",
      },
    ],
  },
  {
    id: "4",
    contact: "olya@bn.ai",
    status: [
      {
        title: "Open",
        value: 10,
      },
      {
        title: "Reply",
        value: 10,
      },
      {
        title: "Booked",
        value: "Yes",
      },
    ],
  },
  {
    id: "5",
    contact: "kolya@bn.ai",
    status: [
      {
        title: "Open",
        value: 10,
      },
      {
        title: "Reply",
        value: 10,
      },
      {
        title: "Booked",
        value: "Yes",
      },
    ],
  },
]

export const totalAmountEmailSchedulingData = [
  {
    id: "1",
    name: "Only You",
    subject: "Me",
    lastEngaged: "1 Days Ago",
    nextFollowUp: true,
    meetingStatus: true,
  },
  {
    id: "2",
    name: "Tom Smith",
    subject: "Diares",
    lastEngaged: "2 Days Ago",
    nextFollowUp: true,
    meetingStatus: false,
  },
  {
    id: "3",
    name: "Gal Stuman",
    subject: "1989",
    lastEngaged: "30 Days Ago",
    nextFollowUp: true,
    meetingStatus: false,
  },
  {
    id: "4",
    name: "Tony Stark",
    subject: "Company",
    lastEngaged: "1 Month Ago",
    nextFollowUp: true,
    meetingStatus: true,
  },
  {
    id: "5",
    name: "Tolya Strong",
    subject: "Comp Ltd",
    lastEngaged: "1 Month Ago",
    nextFollowUp: true,
    meetingStatus: true,
  },
  {
    id: "6",
    name: "Timmy Shine",
    subject: "Any ltd",
    lastEngaged: "1 Days Ago",
    nextFollowUp: true,
    meetingStatus: true,
  },
]
