/* eslint-disable react/jsx-fragments */
// MenuBar.js
import React, { FC, useState, useCallback, Fragment } from 'react';
import { Input, message, Upload } from 'antd';
import type { UploadProps } from 'antd';
import { Flex } from 'antd';
import { SvgIcon } from 'src/shared/icons';
import { MenuItem } from '../MenuItem';
import './index.less';
import {ConfirmButtons} from '../../ConfirmButtons';
import { useEditor } from '@tiptap/react';
import LinkWithTooltip from './LinkWithTooltip';
import { Modal } from '../../Modal';

const props: UploadProps = {
  name: 'file',
  action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const MenuBar: FC<{ editor: any,unsubSentence }> = ({ editor,unsubSentence }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [urlError, setUrlError] = useState('');

  const toggleLink = useCallback(() => {
    if(!unsubSentence){
    const isActive = editor?.isActive('link');
    if (isActive) {
      editor?.chain().focus().unsetLink().run();
    } else {
      setIsModalVisible(true);
    }}else{
       const isActive = editor?.isActive('link');
    if (isActive) {
      editor?.chain().focus().unsetLink().run();
    } else {
    editor?.chain().focus().extendMarkRange('link').setLink({ href: linkUrl }).run()
    }
    }
  }, [editor]);

  const validateUrl = (url) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!urlPattern.test(url);
  };

  const handleOk = () => {
    if (!validateUrl(linkUrl)) {
      setUrlError('Please enter a valid URL.');
      return;
    }
    if (linkUrl) {
      editor?.chain().focus().extendMarkRange('link').setLink({ href: linkUrl }).run();
    }
    setIsModalVisible(false);
    setLinkUrl('');
    setUrlError('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setLinkUrl('');
    setUrlError('');
  };

  if (!editor) {
    return null;
  }

  const unsub = [   {
      icon: 'link',
      title: 'Link',
      action: () => toggleLink(),
      isActive: () => editor.isActive('link'),
    }]
  const items = [
    {
      icon: 'bold',
      title: 'Bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      icon: 'underline',
      title: 'Underline',
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive('underline'),
    },
    {
      icon: 'italic',
      title: 'Italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      icon: 'link',
      title: 'Link',
      action: () => toggleLink(),
      isActive: () => editor.isActive('link'),
    },
    {
      icon: 'alignLeft',
      title: 'Align Left',
      action: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: () => editor.isActive({ textAlign: 'left' }),
    },
    {
      icon: 'alignCenter',
      title: 'Align Center',
      action: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: () => editor.isActive({ textAlign: 'center' }),
    },
    {
      icon: 'alignRight',
      title: 'Align Right',
      action: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: () => editor.isActive({ textAlign: 'right' }),
    },
    {
      icon: 'unorderedList',
      title: 'Unordered List',
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive('bulletList'),
    },
    {
      icon: 'orderedList',
      title: 'Ordered List',
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive('orderedList'),
    },
  ];

  return (
    <>
      <Flex className="editor-menubar" align="center" justify="space-between">
        {/* <Upload {...props}>
          <SvgIcon type="addFile" style={{ cursor: 'pointer' }} />
        </Upload> */}
        {unsubSentence ? unsub.map((item, index) => (
          <Fragment key={index}><Flex> 
            <MenuItem {...item} /><div> - Highlight where you want the unsubscribe link</div></Flex>
          </Fragment>
        )) :items.map((item, index) => (
          <Fragment key={index}>
            <MenuItem {...item} />
          </Fragment>
        ))}
      </Flex>
      <Modal
        title="Insert Link"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          placeholder="Enter URL"
          value={linkUrl}
          onChange={(e) => {
            setLinkUrl(e.target.value);
            if (urlError) {
              setUrlError('');
            } // Clear error on input change
          }}
        />
        {urlError? <p style={{ color: 'red' }}>{urlError}</p>:<p/>}
        <br />
        <ConfirmButtons className="confirm-buttons" leftButtonTitle="Back" rightButtonTitle="Select" handleSubmit={handleOk} onCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default MenuBar;
