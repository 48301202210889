import { Column } from "@ant-design/plots"
import { FC } from "react"
import { aiInitiatedData } from "./AiInitiatedEmail"

export const AiInitiatedEmailChart: FC = () => {
  const config = {
    height: 236,
    forceFit: true,
    data: aiInitiatedData,
    xField: "date",
    yField: "value",
    colorField: "type",
    groupField: "type",
    group: true,
    scale: { color: { range: ["#6048F399", "#01AEEF99", "#37B9A899", "#AF1EAA99"] } },
    style: {
      width: 10,
      radiusTopLeft: 6,
      radiusTopRight: 6,
    },
  }

  return <Column {...config} />
}
