import { message, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentMethodModal } from "src/components/pages/billing/ui/PaymentMethodModal";
import { Modal, TableHeader } from "src/components/ui";
import { createSubIntentToPayForCli } from "src/store/stripe/asyncRequests";
import { userSelector } from "src/store/user";
import { Loader } from "../Loader";

export const Failedpayment = () => {
  const [checkoutPlan, setCheckoutPlan] = useState();
  const [plansAndPricingOpen, setPlansAndPricingOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(true);
  // const [planName, setPlanName] = useState("");

  const { new_subscription_plans, new_payment_status, discount_got } = useSelector(userSelector);
  const successfulPayment = () => {
    message.success(`Updated successfully!`);
    setIsSuccess(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    // message.success()
  };
  const dispatch = useDispatch();
  useEffect(() => {
    // let nameOfPlan;
    let checkoutPlan;
    switch (new_subscription_plans) {
      case "scheduler":
        // nameOfPlan = Scheduler;
        checkoutPlan = "scheduler";
        break;
      case "starter":
        checkoutPlan = "starter";
        break;
      case "growth":
        checkoutPlan = "growth";
        break;
      case "enterprise":
      case "ultimate":
      default:
        checkoutPlan = "ultimate";
        break;
    }
    dispatch(createSubIntentToPayForCli({ plan: checkoutPlan, priceDiscount: discount_got === "price" }));
    setCheckoutPlan(checkoutPlan);
    // setPlanName("");
  }, []);
  // priceDiscount

  //  | 'scheduler'
  // | 'starter'
  // | 'growth'
  // | 'ultimate'
  // | 'enterprise';
  return (
    <>
      {checkoutPlan && !plansAndPricingOpen ? (
        isSuccess ? (
          <Modal open>
            Success! Updating your account now... <Loader />
          </Modal>
        ) : (
          <PaymentMethodModal
            checkout={checkoutPlan}
            payment
            failedPayment
            failedPaymentSkipTrial={new_payment_status === "error_in_charging_temp_skip"}
            discountType={discount_got === "price" ? "price" : discount_got ? "extrCreds" : null}
            successfulPayment={successfulPayment}
          />
        )
      ) : (
        <></>
        // <Modal>Loading</Modal>
      )}
      {plansAndPricingOpen}
    </>
  );
};
