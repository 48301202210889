import { Tag, TagProps } from "antd";
import { CSSProperties, FC } from "react";
import { SvgIcon } from "src/shared/icons";

interface CustomTagProps extends TagProps {
  value: string;
  color: string;
  removeAction?: () => void;
  showRemActionIcon?: boolean;
  count?: number;
  onClick?: VoidFunction;
  style?: CSSProperties;
}

export const StatusTag: FC<CustomTagProps> = ({ color, value, count, style, onClick, removeAction, showRemActionIcon }) => {
  return (
    <Tag
      className={"table-tag"}
      bordered={false}
      color={color}
      onClick={onClick}
      style={{
        lineHeight: 1,
        textAlign: "center",
        // padding: "4px 10px",
        borderRadius: 79,

        cursor: onClick ? "pointer" : "unset",
        ...style,
      }}
    >
      {typeof value === "number" ? value.toLocaleString("en-US") : value}
      {count ? `: ${count?.toLocaleString()}` : null}
      {showRemActionIcon && removeAction ? (
        <div>
          {" "}
          <SvgIcon type={"closeTag"} style={{ margin: "auto", marginLeft: "3px", marginTop: "2px", cursor: "pointer" }} onClick={removeAction} />{" "}
        </div>
      ) : (
        ""
      )}
    </Tag>
  );
};
