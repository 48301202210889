import { Tag, TagProps } from "antd"
import { FC } from "react"

interface CardTagProps extends TagProps {
  value: string
  color: string
  backgroundColor: string
}

export const CardTag: FC<CardTagProps> = ({ color, backgroundColor, value }) => {
  return (
    <Tag
      className={"CardTag"}
      bordered={false}
      color={backgroundColor}
      style={{ padding: "4px 14px", borderRadius: 30, textAlign: "center", color: color }}
    >
      {value}
    </Tag>
  )
}
