import { FormInstance, Row, Tag, Typography } from "antd"
import React, { FC, PropsWithChildren, useContext, useEffect, useState } from "react"
import { SvgIcon } from "src/shared/icons"
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext"
import "./index.less"
import { specialTextFields } from "src/components/pages/prospect-data/ui/ProspectFilterCollapse"
import { prospectDataSelector, setFilterFormData } from "src/store/prospect_data"
import { useDispatch, useSelector } from "react-redux"

const FilterTag: FC<PropsWithChildren<{ onClose: VoidFunction,canClose?:boolean }>> = ({ onClose,canClose=true, children }) => {
  return (
    <Tag closable={canClose} bordered={false} closeIcon={<SvgIcon type={"closeTag"} />} onClose={onClose}>
      {children}
    </Tag>
  )
}

interface FilterTagsListProps {
  data: string[] | undefined
  fieldToUpdate?: any
  form?: FormInstance
  onTagRemove?: (tag: string) => void
  onChange?: (newTagsList: string[]) => void
}

export const FilterTagsList: FC<FilterTagsListProps> = ({ onTagRemove, data, form, fieldToUpdate, onChange }) => {
  // const { setFilterFormData} = useContext(ProspectDataContext)
  const [tags, setTags] = useState<string[]>([])
    const { filterFormData } = useSelector(prospectDataSelector);
  const [deletedTags, setDeletedTags] = useState<string[]>([]);
  const dispatch = useDispatch()
  const handleClose = (removedTag: string) => {

    console.log(removedTag,'onTagRemove');
    if (onTagRemove) {
      //  prospect data action
      onTagRemove(removedTag);
      // console.log('field1',specialTextFields);
     
    } else {
      const newTags = tags.filter(tag => tag !== removedTag);
      setDeletedTags(prevDeletedTags => [...prevDeletedTags, removedTag]);
      form?.setFieldValue(fieldToUpdate, newTags);
       console.log(newTags,'her13',fieldToUpdate);
      // setFilterFormData && dispatch( setFilterFormData(form?.getFieldsValue()));
      setFilterFormData && dispatch( setFilterFormData({...(filterFormData||{}),[fieldToUpdate]:newTags }));
      // setFilterFormData && setFilterFormData(form?.getFieldsValue());

      if (onChange) {
        onChange(newTags)
      }
    }
      specialTextFields.forEach(field => {
    // if (removedTag === field) {
      // console.log('field',field);
      form?.setFieldsValue({ [field]: "" });
    // }
  });
  };

  useEffect(() => {
    console.log(data,'datadata');
    // const updatedTags = data ? data.filter(tag => !deletedTags.includes(tag)) : []; seems maybe redundant
    const updatedTags = data || [];
    setTags(updatedTags);
  }, [data, deletedTags]);
  
  return (
    <Row className={"filter-tags-list"}>
      {data && tags.map((tag, index) => {
        return !deletedTags.includes(tag) ? (
          tag ? (
            <FilterTag key={`${tag}-${index}`} onClose={() => handleClose(tag)} canClose={tag!=='Business Email Address'}>
              <Typography.Paragraph ellipsis style={{maxWidth: "233px"}}>{tag}</Typography.Paragraph>
            </FilterTag>
          ) : null
        ) : null;
      })}
    </Row>
  );
}
