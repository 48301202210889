import { Button, Flex, Row, Space, Typography } from "antd"
import React, { FC, useState } from "react"
import { ContentCard, CustomLabel } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { CreateTaskModal } from "../CreateTaskModal"

const tasks = [
  {
    id: "1",
    title: "Google Meet Appointment",
    date: "March 15, 2024, at 10:00 AM (GMT)",
    info: {
      name: "Google Meet Link",
      reference: "meet.google.com/serendipitous-meeting",
    },
  },
  {
    id: "2",
    title: "Phone Call",
    date: "March 30, 2024, at 12:00 AM (GMT)",
    info: {
      name: "Phone Number",
      reference: "(345) 567-8960",
    },
  },
]

export const Task: FC = () => {
  const [openModal, setOpenModal] = useState(false)

  const showModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <ContentCard
      headerIcon={<SvgIcon type={"task"} />}
      cardTitle={"Task"}
      headerRightContent={
        <Button className={"link"} onClick={showModal}>
          Create task
        </Button>
      }
    >
      <CustomLabel title={"List of Created tasks"} />
      {tasks && tasks.length ? (
        <Flex vertical gap={8}>
          {tasks.map((task, index) => {
            return (
              <Space key={`${index}-${task.id}`} direction={"vertical"} size={2}>
                <Row align={"middle"}>
                  <SvgIcon type={"dividerDot"} style={{ margin: "0 8px" }} />
                  <Typography.Paragraph className={"task-title"}>{`${task.title}:`}</Typography.Paragraph>
                </Row>
                <Typography.Paragraph className={"task-title"}>{`Date and Time: ${task.date}`}</Typography.Paragraph>
                <Typography.Paragraph className={"task-title"}>
                  {`${task.info.name}: ${task.info.reference}`}
                </Typography.Paragraph>
              </Space>
            )
          })}
        </Flex>
      ) : (
        <Typography.Paragraph className={"empty-data"}>
          No task is currently created. Create task above
        </Typography.Paragraph>
      )}
      <CreateTaskModal open={openModal} onCancel={closeModal} />
    </ContentCard>
  )
}
