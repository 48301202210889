/* eslint-disable react/jsx-fragments */
import { Button, Select, Table, Typography, message } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useState } from "react";
import { ConfirmButtons, Modal, StatusTag } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import "./index.less";
import { deleteOptimisticEmail, outreachEmailsSelector, updateOptimisticEmails } from "src/store/outreach-emails";
import { useDispatch, useSelector } from "react-redux";
import { updateEmailSettings } from "src/store/outreach-emails/asyncRequests";
import { AppDispatch } from "src/store";

type ConnectedEmailsProps = {
  open: boolean;
  // showWarmUpModal: (item: any) => void
  showModal: (item: any) => void;
  onCancel: VoidFunction;
  data: any;
};

export const ConnectedEmailsModal: FC<ConnectedEmailsProps> = ({ data, open, onCancel, showModal }) => {
  const [emailsData, setEmailsData] = useState<any>();

  // useEffect(() => {
  //   if(data) setEmailsData(data)
  // }, [data, !open])
  const closeModal = () => {
    onCancel();
  };

  const { additionalEmails, loading } = useSelector(outreachEmailsSelector);

  console.log(additionalEmails, "additionalEmailsadditionalEmails");
  useEffect(() => {
    setEmailsData(
      [...additionalEmails]?.sort((a, b) => {
        return a?.id - b?.id;
      })
    );
  }, [additionalEmails, !open]);

  const [newTimesBetweenSends, setNewTimesBetweenSends] = useState<any[]>([]);

  const handleChange = (timeBetweenSends, emailId) => {
    const indexOfEmailId = newTimesBetweenSends.findIndex((times) => times.emailId === emailId);
    if (indexOfEmailId === -1) {
      setNewTimesBetweenSends([...newTimesBetweenSends, { timeBetweenSends, emailId }]);
    } else {
      const copArr = [...newTimesBetweenSends];
      copArr[indexOfEmailId] = { timeBetweenSends, emailId };
      setNewTimesBetweenSends(copArr);
    }
  };
  const dispatch = useDispatch<AppDispatch>();
  const handleSubmit = () => {
    if (!newTimesBetweenSends?.length) {
      message.destroy();
      message.success("Updated email settings");
      onCancel();
      return;
    } else {
      message.destroy();
      message.info("Updating email settings");
    }
    dispatch(
      updateOptimisticEmails(
        newTimesBetweenSends
          ?.map((emailObj) => {
            if (emailObj?.timeBetweenSends) {
              return { value: { minimum_time_between_sends: emailObj?.timeBetweenSends }, id: emailObj?.emailId };
            } else {
              return null;
            }
          })
          ?.filter(Boolean)
      )
    );
    dispatch(updateEmailSettings({ newTimesBetweenSends }));
    onCancel();
  };

  const timePeriod = [
    { label: "10 Seconds", value: 10 },
    { label: "30 Seconds", value: 30 },
    { label: "1 Minute", value: 60 },
    { label: "1.5 Minutes", value: 90 },
    { label: "2 Minutes", value: 120 },
    { label: "5 Minutes", value: 300 },
    { label: "10 Minutes", value: 600 },
    { label: "20 Minutes", value: 1200 },
  ];

  const timeBetweenSendsOptions = timePeriod.map((time) => {
    return {
      label: time.label,
      value: time.value,
    };
  });

  const handleDelete = (itemId: string) => {
    dispatch(deleteOptimisticEmail(itemId));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Email",
      dataIndex: "email_alias_emailofcal",
      key: "email_alias_emailofcal",
    },
    // {
    //   title: "First Name", add later but will need to change logic in manual emails + ai emails since the name is dynamic. this can also change the business since 1 acount can handle multiple users reducing the cost?
    //   dataIndex: "connected",
    //   render: value =>
    //     value ? <StatusTag value={"Enable"} color={"success"} /> : <StatusTag value={"None"} color={"cyan"} />,
    //   key: "connected",
    // },
    {
      title: "Campaigns Connected to",
      dataIndex: "connected",
      render: (value) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {
              value ? <StatusTag value={"1"} color={"success"} /> : <StatusTag value={"None"} color={"cyan"} /> // TODON add campagins connected too
            }
          </div>
        </>
      ),
      key: "connected",
      align: "center",
    },
    {
      title: "Email Sends Per Day Limit",
      dataIndex: "sends_per_day",
      render: (value, record) => (
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {value?.length === 1 ? (
              <Button className={"link"} style={{ padding: 0 }} onClick={() => showModal(record)}>
                {value[0]} Emails
              </Button>
            ) : (
              <Button className={"link"} style={{ padding: 0 }} onClick={() => showModal(record)}>
                Warm Up Mode 🔥
              </Button>
            )}
          </div>
        </>
      ),
      key: "warmUpMode",
      align: "center",
    },
    {
      title: "Time Between Sends",
      render: (value, all) => {
        return <Select options={timeBetweenSendsOptions} style={{ width: 92 }} defaultValue={value} onChange={(val) => handleChange(val, all.id)} />;
      },
      key: "minimum_time_between_sends",
      dataIndex: "minimum_time_between_sends",
    },
    {
      title: "Status",
      dataIndex: "connected",
      render: (value) => (
        <>
          <div style={{ display: "flex", justifyContent: "center", cursor: value ? "default" : "pointer" }}>
            {value ? <StatusTag value={"Connected"} color={"success"} /> : <StatusTag value={<u>Reconnect</u>} color={"error"} />}
          </div>
        </>
      ),
      key: "status",
      align: "center",
    },
    {
      title: "Remove",
      render: (render) => <SvgIcon type={"closeTag"} style={{ cursor: "pointer" }} onClick={() => handleDelete(render.id)} />,
      key: "remove",
      align: "right",
    },
  ];

  return (
    <Modal customClassName={"emails-connected-modal"} width={"1000px"} open={open} title={"Your Connected Emails"} onCancel={closeModal}>
      <Typography.Paragraph className={"tag-counter"} style={{ marginBottom: 28 }}>{`${emailsData?.length} Emails`}</Typography.Paragraph>
      <Table rowKey={"id"} columns={columns} dataSource={emailsData} pagination={false} />
      <div style={{ width: "50%", margin: " 28px 0 0 auto" }}>
        <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Save"} onCancel={closeModal} handleSubmit={handleSubmit} />
      </div>
    </Modal>
  );
};
