import { Typography } from "antd"
import { FC, useContext } from "react"
import { MeetingLinksContext } from "../../../../app/context/MeetingLinksContext"
import { ConfirmButtons, Modal } from "../../../../ui"
import "./index.less"

interface DeleteMeetingLinkModalProps {
  onClose: () => void
}

export const DeleteMeetingLinkModal: FC<DeleteMeetingLinkModalProps> = ({ onClose }) => {
  const { deleteMeetingLink } = useContext(MeetingLinksContext)

  const handleDeleteBtnClick = () => {
    deleteMeetingLink()
    onClose()
  }

  return (
    <Modal
      open={true}
      title={"Are you sure you want to delete this meeting link?"}
      className={"delete-meeting-link-modal-root"}
      onCancel={onClose}
    >
      {/* <Typography.Paragraph className={"description"}>
        This will remove all associated data, and the process cannot be undone.
      </Typography.Paragraph> */}
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Delete"}
        handleSubmit={handleDeleteBtnClick}
        onCancel={onClose}
      />
    </Modal>
  )
}
