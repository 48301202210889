import { Typography } from "antd"
import { FC, useContext } from "react"
import { DealsContext } from "../../../../app/context/DealsContext"
import { ConfirmButtons, Modal } from "../../../../ui"
import "./index.less"

interface DeleteColumnModalProps {
  onClose: () => void
}

export const DeleteColumnModal: FC<DeleteColumnModalProps> = ({ onClose }) => {
  const { deleteColumn } = useContext(DealsContext)

  const handleSubmitBtnClick = () => {
    deleteColumn()
    onClose()
  }

  return (
    <Modal
      open={true}
      title={"Are you sure you want to delete this column?"}
      onCancel={onClose}
      rootClassName={"delete-column-modal"}
    >
      <Typography.Paragraph className={"description"}>
        This action will permanently remove all associated data, and the process cannot be undone.
      </Typography.Paragraph>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Delete"}
        className={"confirm-buttons"}
        handleSubmit={handleSubmitBtnClick}
        onCancel={onClose}
      />
    </Modal>
  )
}
