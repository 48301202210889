import { Button, Flex, Space } from "antd";
import React, { FC } from "react";
import { cloud } from "src/shared/images";

export const OverviewEmptyData: FC = () => {
  return (
    <Flex vertical align={"center"} style={{ padding: "20px 0 30px" }}>
      <img src={cloud} alt={"woman with phone"} width={194} />
      <Space direction={"vertical"} align={"center"} style={{ marginTop: 22 }}>
        No analytics yet
        {/* <Button className={"primary"} style={{ maxWidth: 136, height: 24, padding: "0 10px", fontSize: 12 }}>
          Add Email Addresses
        </Button>
        <Button className={"link"} style={{ maxWidth: 131, height: 24, padding: "0 10px", fontSize: 12 }}>
          Add Phone Number
        </Button> */}
      </Space>
    </Flex>
  );
};
