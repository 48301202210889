import { List, Row, Typography } from "antd"
import { FC } from "react"
import { StatusTag } from "src/components/ui"
import { getHotProspectsTagColor } from "src/helpers/getHotProspectsTagColor"
import { hotProspectsData } from "../../dashboardData"
import "./index.less"

export const HotProspectsTab: FC<{ data: any }> = ({ data }) => {
  return (
    <List
      className={"hot-prospects-list"}
      dataSource={hotProspectsData}
      renderItem={(item: any) => (
        <List.Item key={item.id} className={"prospects-item"}>
          <Typography.Paragraph className={"prospects-email"}>{item.contact}</Typography.Paragraph>
          <Row justify={"start"} style={{ gap: 8 }}>
            {item.status.map((status: any, index: number) => {
              return (
                <StatusTag
                  key={`${index}-${status.title}`}
                  value={`${status.title}: ${status.value}`}
                  color={getHotProspectsTagColor(status.title)}
                />
              )
            })}
          </Row>
        </List.Item>
      )}
    />
  )
}
