import { Button, Form, Input, Row } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, CustomLabel, Modal } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

type CreateVoicemailModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const CreateVoicemailModal: FC<CreateVoicemailModalProps> = ({ open, onCancel }) => {
  const [isRecording, setIsRecording] = useState(false)

  const toggleRecord = () => {
    setIsRecording(prevState => !prevState)
  }

  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    console.log("Added")
    onCancel()
  }

  return (
    <Modal width={530} open={open} title={"Create Voicemail"} onCancel={closeModal}>
      <Form.Item name={"voicemailName"} label={"Name"}>
        <Input placeholder={"Enter text"} />
      </Form.Item>
      <CustomLabel title={"Voicemail body"} isFontBold={true} />
      <Row
        className={"record-btn-wrapper"}
        align={"middle"}
        justify={"space-between"}
        style={{ marginBottom: 28, backgroundColor: isRecording ? "#01AEEF14" : undefined }}
        wrap={false}
      >
        <Button className={"link"} style={{ paddingLeft: 16 }} onClick={toggleRecord}>
          {isRecording ? "Stop " : "Start "}recording
        </Button>
        <SvgIcon type={isRecording ? "pause" : "microphone"} style={{ cursor: "pointer" }} onClick={toggleRecord} />
      </Row>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Create Voicemail"}
        onCancel={closeModal}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  )
}
