import React, { useState, useEffect, ReactNode } from "react";
import Error from "src/shared/images/error.webp";
import { Flex, message, Typography } from "antd";
import "./index.less";
import { findLocation } from "src/components/app/AuthRoutes";

function ErrorBoundary({ children }: { children?: ReactNode }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = () => setHasError(true);
    window.addEventListener("error", errorHandler);
    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  const loc = findLocation()?.toLowerCase();
  const dontShowErrorIfNoInternet = ["login", "signup"];

  useEffect(() => {
    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      if (!event?.reason?.errorFields?.length && !dontShowErrorIfNoInternet.includes(loc)) {
        // this is stopping a regular form fail and the application handles it. if not its not good
        setHasError(true);
        console.log("Unhandled promise rejection:", event.reason);
      } else if (!event?.reason?.errorFields?.length && dontShowErrorIfNoInternet.includes(loc)) {
        // message.warning(`You need internet to use Meetz 🧑🏻‍💻`);
      }
    };
    window.addEventListener("unhandledrejection", unhandledRejectionHandler);
    return () => {
      window.removeEventListener("unhandledrejection", unhandledRejectionHandler);
    };
  }, []);

  // useEffect(() => {
  //   console.log("Error in errorboundary", error);
  // }, [hasError]);

  if (hasError && import.meta.env.VITE_NODE_ENV !== "production") {
    message.error(`DEV: NOTICE ERROR HERE THAT MUST BE FIXED!!`); // error will disrupt production

    return children;
  }

  if (hasError) {
    // if (hasError && import.meta.env.VITE_NODE_ENV === "production") {
    // page-wrapper causing strange css issues
    return (
      <Flex className={"custom-page-wrapper"}>
        <Flex className={"error-wrapper"} justify={"center"} style={{ padding: "40px 48px" }}>
          <Flex vertical={true} justify={"center"} align={"center"} className={"error-img-wrapper"}>
            <img className={"img-err"} src={Error} alt={"Error"} />
            <Typography.Title className={"img-err-title"}>Something Went Wrong!</Typography.Title>
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return children;
}

export default ErrorBoundary;
