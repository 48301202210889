import { createSlice, createSelector } from '@reduxjs/toolkit';
import { postContactSupport } from './asyncRequests';

const helpersSlice = createSlice({
  name: 'helpers',
  initialState: {
    dashPageValue: 0,
    loading: false,
    error: null,
    from_landing: null,
    iframeJwt: '',
    support_success: null,
    tutorial: null,
  },
  reducers: {
  
    setJwtIframeInHelper: (state, action) => {
      state.iframeJwt = action.payload;
    },
  },
 
});

const { actions, reducer } = helpersSlice;

export const {  setJwtIframeInHelper } =
  actions;

export default reducer;

export const helpersSelector = createSelector(
  (state) => state.helpers,
  (helpers) => helpers
);
