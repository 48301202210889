import { Input, Row } from "antd"
import React, { FC } from "react"
import { SvgIcon } from "src/shared/icons"

interface TableHeaderProps {
  handleChange?: (func:any)=>void
  hasMaxWidth?: boolean
  marginBottom?: number
  searchPlaceholder?: string
  leftHeaderContent?: React.ReactNode | null
  rightHeaderContent?: React.ReactNode | null
}

export const TableHeader: FC<TableHeaderProps> = ({
  leftHeaderContent,
  rightHeaderContent,
  searchPlaceholder,
  hasMaxWidth = true,
  handleChange,
  marginBottom = 24
}) => {
  return (
    <Row className={"table-header-row"} justify={"space-between"} align={"middle"} style={{ marginBottom }}>
      {leftHeaderContent && leftHeaderContent}
      {searchPlaceholder && (
        <Input.Search
          className={"search-input"}
          placeholder={searchPlaceholder}
          allowClear
          // prefix={<SvgIcon type={"search"} />}
          onChange={handleChange}
          enterButton={false}
          style={{ maxWidth: hasMaxWidth ? 376 : "unset" }}
        />
      )}
      {rightHeaderContent}
    </Row>
  )
}
