import React, { FC, ForwardedRef, useContext, useEffect, useState } from "react";
/* eslint-disable react/jsx-fragments */
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext";
import { ApplyFromSavedModal, CheckboxGroupFilterItem, InputFilterItem, ProspectFilterHeader, SaveFiltersModal, SelectFilterItem } from "./ui";
import {
  accuracyTypeList,
  contactTypeList,
  countriesList,
  departmentOptions,
  headcountList,
  industryOptions,
  informationOptions,
  informationOptionsArr,
  revenueList,
  seniorityLevelList,
  sicCodeList,
  stateList,
} from "../../info";
import { ConfirmButtons } from "src/components/ui";
import { FilterTagsList } from "src/components/ui/FilterTagsList";
import { SvgIcon } from "src/shared/icons";
import { Button, Collapse, Divider, Form, FormInstance, Row, Typography, Cascader, Checkbox, Space, Flex, CollapseProps } from "antd";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import {
  prospectDataSelector,
  resetCurPagePros,
  resetSelectedRows,
  setAppliedFilters,
  setFilterFormData,
  setPageNum,
  setPageNumSync,
} from "src/store/prospect_data";
import { getFilterProspects, getProspectsDb } from "src/store/prospect_data/asyncRequests";
import { AppDispatch } from "src/store";

interface ProspectFilterProps {
  form: FormInstance;
  ref?: any;
  isTourStep?: boolean;
}

// special text fields, have custom way to work with filters in case they type up their own searches.
export const specialTextFields = ["jobTitle", "company", "companyUrl", "skills", "city"];

export const savedFiltersData = [
  {
    id: 1,
    name: "Finance and C-level",
    filterTags: ["Adams", "C-level", "Staff", "$0-1M"],
  },
  {
    id: 2,
    name: "Winter Wonderland Discounts",
    filterTags: ["Crane", "Staff", "$10-50M"],
  },
];

export const totalTagsList = (filterFormData: any) =>
  filterFormData &&
  (Object.values(filterFormData)
    .flat()
    .filter((value) => value && value !== undefined) as string[]);

export const ProspectFilterCollapse: FC<ProspectFilterProps> = React.forwardRef<HTMLDivElement, ProspectFilterProps>(({ form, isTourStep }, ref) => {
  const [openSaveFilterModal, setOpenSaveFilterModal] = useState(false);
  const [openFromSavedModal, setOpenFromSavedModal] = useState(false);
  // const { filterFormData, setFilterFormData } = useState({});
  // const { filterFormData, setFilterFormData } = useContext(ProspectDataContext);

  const { filterFormData, loading, syncedPageWithData, curProspPages } = useSelector(prospectDataSelector);

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!syncedPageWithData) {
      dispatch(getFilterProspects({ ...filterFormData, page: curProspPages }));
      dispatch(setPageNumSync());
    }
  }, [syncedPageWithData]);

  const totalTagsLength = totalTagsList(filterFormData)?.length || 0;
  const [selectedTags, setSelectedTags] = useState<string[]>(totalTagsList(filterFormData) ?? []);
  const getCount = (data: any) => (data?.length ? `(${data?.length})` : "");
  const onInformationChange = (value: any) => {
    const informationTag = value.flatMap((item: string[]) => item[1]);
    dispatch(setFilterFormData({ ...filterFormData, information: informationTag }));
  };

  const onIndustryChange = (value: any) => {
    const industryTag = value.flatMap((item: string[]) => item[1] || item[0]);

    dispatch(setFilterFormData({ ...filterFormData, industry: industryTag }));
  };

  const findField = (propertyName: string, object: any): string | null => {
    for (const field in object) {
      if (Object.prototype.hasOwnProperty.call(object, field)) {
        if (object[field]?.includes(propertyName)) {
          return field;
        }
      }
    }
    return null;
  };

  // typeduptext is special here
  const handleTagRemove = (removedTag: string) => {
    const fieldToUpdate = findField(removedTag, filterFormData);
    console.log(removedTag, "typedUpText", fieldToUpdate);
    const newSelectedTags = selectedTags.filter((tag) => tag !== removedTag);
    setSelectedTags(newSelectedTags);

    const updatedFieldValues = fieldToUpdate && specialTextFields.includes(fieldToUpdate) ? filterFormData[fieldToUpdate] : form.getFieldValue(fieldToUpdate);

    const newFieldValues = updatedFieldValues.filter((value: any) => value !== removedTag);
    if (fieldToUpdate) {
      form.setFieldsValue({ [fieldToUpdate]: newFieldValues });

      dispatch(setFilterFormData({ ...(filterFormData || {}), [fieldToUpdate]: newFieldValues }));
    }
    // dispatch(  setFilterFormData(form.getFieldsValue()))
  };

  const handleClearAll = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    form.resetFields();
    dispatch(resetSelectedRows());
    dispatch(resetCurPagePros());
    const specialTextFieldsBlnk = {};
    specialTextFields.forEach((field) => {
      specialTextFieldsBlnk[field] = "";
    });
    // form.setFieldsValue({ information: ["Validated Email", "Business Email Address", "Exclude My Saved Contacts"] });
    form.setFieldsValue({ information: ["Validated Email", "Business Email Address", "Exclude My Saved Contacts", "Recently Updated"] });
    dispatch(
      setFilterFormData({
        ...specialTextFieldsBlnk,
        // information: ["Validated Email", "Business Email Address", "Exclude My Saved Contacts"],
        information: ["Validated Email", "Business Email Address", "Exclude My Saved Contacts", "Recently Updated"],
      })
    );
  };

  const handleClearField = (e, fieldName: string) => {
    console.log(e, "eeee");
    e?.preventDefault();
    e?.stopPropagation();
    form.setFieldsValue({ [fieldName]: undefined });
    dispatch(setFilterFormData({ ...filterFormData, [fieldName]: undefined }));
  };

  const [industryInputValue, setIndInputValue] = useState("");
  const onIndustrySearch = (value) => {
    setIndInputValue(value);
  };

  const items: CollapseProps["items"] = [
    ...(totalTagsLength !== 0
      ? [
          {
            key: "0",
            label: `All Selected Filters (${totalTagsLength})`,
            extra: (
              <Button className={"link"} onClick={handleClearAll}>
                Reset All
              </Button>
            ),
            children: (
              <Space direction={"vertical"}>
                <Row justify={"space-between"} align={"middle"}>
                  <Typography.Paragraph>{`All Selected filters `}</Typography.Paragraph>
                </Row>
                <FilterTagsList data={totalTagsList(filterFormData) ?? []} form={form} onTagRemove={handleTagRemove} />
              </Space>
            ),
          },
        ]
      : []),
    {
      key: "1", // contactTitles
      label: `Job Title ${getCount(filterFormData?.jobTitle)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "jobTitle")}>
          Clear
        </Button>
      ),
      children: <InputFilterItem form={form} fieldName={"jobTitle"} placeholder={"Example: VP Sales"} enterButton />,
    },
    {
      key: "2", // contactJobFunctions
      label: `Job Function ${getCount(filterFormData?.function)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "function")}>
          Clear
        </Button>
      ),
      children: <SelectFilterItem data={filterFormData?.function} form={form} fieldName={"function"} staticOptions={departmentOptions} />,
    },
    {
      key: "3", // contactLevels
      label: `Seniority level ${getCount(filterFormData?.seniorityLevel)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "seniorityLevel")}>
          Clear
        </Button>
      ),
      children: <CheckboxGroupFilterItem data={filterFormData?.seniorityLevel} form={form} fieldName={"seniorityLevel"} checkboxList={seniorityLevelList} />,
    },
    {
      key: "4", // companyIndustries
      label: `Company Industry ${getCount(filterFormData?.industry)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "industry")}>
          Clear
        </Button>
      ),
      children: (
        <>
          <Form.Item name={"industry"}>
            <Cascader
              style={{ width: "100%" }}
              options={industryOptions}
              onChange={onIndustryChange}
              placement={"bottomRight"}
              tagRender={() => <></>}
              searchValue={industryInputValue}
              onSearch={onIndustrySearch}
              multiple
              showSearch
            />
          </Form.Item>
          <FilterTagsList data={filterFormData?.industry} form={form} fieldToUpdate={"industry"} />
        </>
      ),
    },
    {
      key: "5", // companySizeIds
      label: `Company Headcount ${getCount(filterFormData?.headcount)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "headcount")}>
          Clear
        </Button>
      ),
      children: <CheckboxGroupFilterItem data={filterFormData?.headcount} form={form} fieldName={"headcount"} checkboxList={headcountList} />,
    },
    {
      key: "6", // companyRevenueIds
      label: `Company Revenue ${getCount(filterFormData?.revenue)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "revenue")}>
          Clear
        </Button>
      ),
      children: <CheckboxGroupFilterItem data={filterFormData?.revenue} form={form} fieldName={"revenue"} checkboxList={revenueList} />,
    },
    {
      key: "7", // companyNames
      label: `Company Name ${getCount(filterFormData?.company)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "company")}>
          Clear
        </Button>
      ),
      children: <InputFilterItem form={form} fieldName={"company"} placeholder={"Example: Facebook"} enterButton />,
    },
    {
      key: "8",
      label: `Company URL ${getCount(filterFormData?.companyUrl)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "companyUrl")}>
          Clear
        </Button>
      ),
      children: <InputFilterItem form={form} fieldName={"companyUrl"} enterButton placeholder={"Example: www.facebook.com"} />,
    },

    {
      key: "9", // companySicCodes
      label: `SIC Codes ${getCount(filterFormData?.companySicCodes)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "companySicCodes")}>
          Clear
        </Button>
      ),
      children: <SelectFilterItem data={filterFormData?.companySicCodes} form={form} fieldName={"companySicCodes"} staticOptions={sicCodeList} />,
      // sicCodeList
    },

    {
      key: "10", // contactSkills
      label: `Skills Prospect Has ${getCount(filterFormData?.skills)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "skills")}>
          Clear
        </Button>
      ),
      children: <InputFilterItem form={form} fieldName={"skills"} placeholder={"Example: PPC ads"} enterButton />,
    },
    // {
    //   key: "11",
    //   label: `Technologies used ${getCount(filterFormData?.technologies)}`,
    //   extra: <Button className={"link"} onClick={(e)=>handleClearField(e,"technologies")}>Clear</Button>,
    //   children: <SelectFilterItem data={filterFormData?.technologies} form={form} fieldName={"technologies"} placeholder={"Example: AWS Lambda"} staticOptions={technologyList}/>
    // },
    {
      key: "11", // countries
      label: `Countries ${getCount(filterFormData?.country)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "country")}>
          Clear
        </Button>
      ),
      children: (
        <SelectFilterItem
          data={filterFormData?.country}
          form={form}
          fieldName={"country"}
          placeholder={"Example: United States"}
          staticOptions={countriesList}
        />
      ),
    },
    {
      key: "12", // states
      label: `State ${getCount(filterFormData?.state)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "state")}>
          Clear
        </Button>
      ),
      children: <SelectFilterItem data={filterFormData?.state} form={form} fieldName={"state"} placeholder={"Example: New York"} staticOptions={stateList} />,
    },
    {
      key: "13", // cities
      label: `City ${getCount(filterFormData?.city)}`,
      extra: (
        <Button className={"link"} onClick={(e) => handleClearField(e, "city")}>
          Clear
        </Button>
      ),
      children: <InputFilterItem form={form} fieldName={"city"} placeholder={"Example: Los Angeles"} enterButton />,
    },
    {
      key: "14",
      label: `Prospect Info ${getCount(filterFormData?.information)}`,
      // extra: <Button className={"link"} onClick={(e)=>handleClearField(e,"information")}>Clear</Button>,
      children: <CheckboxGroupFilterItem data={filterFormData?.information} form={form} fieldName={"information"} checkboxList={informationOptionsArr} />,
    },
    // {
    //   key: "17",
    //   label: `Prospect Info ${getCount(filterFormData?.information)}`,
    //   extra: <Button className={"link"} onClick={(e)=>handleClearField(e,"information")}>Clear</Button>,
    //   children:
    //     <>
    //       <Form.Item name={"information"}>
    //         <Cascader
    //           style={{ width: "100%" }}
    //           options={informationOptions}
    //           onChange={onInformationChange}
    //           placement={"bottomRight"}
    //           tagRender={() => <></>}
    //           multiple
    //         />
    //       </Form.Item>
    //       <FilterTagsList data={filterFormData?.information} form={form} fieldToUpdate={"information"}/>
    //     </>
    // },
    // {
    //   key: "18",
    //   label: `Prospect Information Accuracy ${getCount(filterFormData?.accuracy)}`,
    //   extra: <Button className={"link"} onClick={(e)=>handleClearField(e,"accuracy")}>Clear</Button>,
    //   children:
    //     <CheckboxGroupFilterItem
    //       className={"large-label-item"}
    //       data={filterFormData?.accuracy}
    //       form={form}
    //       fieldName={"accuracy"}
    //       checkboxList={accuracyTypeList}
    //       style={{ marginBottom: 0 }}
    //     />
    // },
  ].filter(Boolean);

  const showSaveFilterModal = () => {
    setOpenSaveFilterModal(true);
  };

  const showFromSavedModal = () => {
    setOpenFromSavedModal(true);
  };
  const onCloseModal = () => {
    setOpenSaveFilterModal(false);
    setOpenFromSavedModal(false);
  };
  const onValueChange = (changedValues: any, allValues: any) => {
    const hasSpecialTextField = Object?.keys(changedValues)?.some((key) => specialTextFields?.includes(key));
    if (!hasSpecialTextField) {
      // if (!changedValues.hasOwnProperty('firstName')) {

      // ...filterFormData
      dispatch(setFilterFormData({ ...(filterFormData || {}), ...(changedValues || {}) }));
    }
  };

  const getProspects = (e) => {
    if (e.type === "keydown" && e.key === "Enter") {
      return;
    }
    dispatch(setAppliedFilters());
    dispatch(resetSelectedRows());
    dispatch(getFilterProspects({ ...filterFormData, page: 1 }));
    dispatch(setPageNum({ isSynced: true, pageNum: 1 }));
  };

  const handleFormSubmit = () => {
    console.log("Export test");
  };
  // later can do deep copy of tags to see if changed and only then let them click it

  // TODO after finishing with it organize from first with contact to company to data + connect the exclude with data? prob good + if dirty form = deepcopy tags check if there is any tag + hover over if not + default validated emails/exclude shit.

  useEffect(() => {
    // on load show the filtres previously slelected
    form.setFieldsValue({ ...filterFormData });
  }, []);
  <ConfirmButtons
    disabled={!totalTagsLength || JSON.stringify(filterFormData) === JSON.stringify({ information: ["Exclude My Saved Contacts"] })}
    rightButtonTitle={"Apply now"}
    leftButtonTitle={"Reset all"}
    onCancel={handleClearAll}
    noEnterKeyboardSubmit
    handleSubmit={getProspects}
    loading={loading}
  />;
  return (
    <>
      <div ref={ref}>
        <Form
          form={form}
          className={"prospect-filter-wrapper"}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onValuesChange={onValueChange}
          // onFinish={handleFormSubmit}
        >
          <ProspectFilterHeader
            totalTagsLength={totalTagsLength}
            openSaveModal={showSaveFilterModal}
            openFromSavedModal={showFromSavedModal}
            filterFormData={filterFormData}
            handleClearAll={handleClearAll}
            getProspects={getProspects}
            loading={loading}
          />
          <Divider style={{ margin: 0 }} />
          <Collapse
            className={"prospects-filter-collapse"}
            items={items}
            expandIcon={({ isActive }) => <SvgIcon type={"arrowCollapse"} style={{ transform: isActive ? "rotate(0deg)" : "rotate(180deg)" }} />}
            bordered={false}
            // collapsible={"header"}
          />

          {/* <Divider style={{ margin: 0 }} />
            <Flex style={{ padding: 24 }}>
             
            </Flex> */}
        </Form>
      </div>
      {!isTourStep && (
        <>
          <ApplyFromSavedModal data={savedFiltersData} open={openFromSavedModal} onCancel={onCloseModal} />
          <SaveFiltersModal data={filterFormData} open={openSaveFilterModal} onCancel={onCloseModal} />
        </>
      )}
    </>
  );
});
