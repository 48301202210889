import { Layout, Row, Typography } from "antd"
import React from "react"
const { Content } = Layout

interface ContentLayoutProps {
  pageTitle: string | React.ReactNode
  rightHeaderContent?: React.ReactNode | null
  bottomHeaderContent?: React.ReactNode | null
  statistic?: React.ReactNode | null
  children: React.ReactNode
  isTableContent?: boolean
}

const PageLayout: React.FC<ContentLayoutProps> = ({
  pageTitle,
  rightHeaderContent,
  children,
  bottomHeaderContent,
  isTableContent = true,
  statistic,
}) => {
  return (
    <Layout
      style={{
        padding: "18px 40px",
      }}
    >
      <Row align={"middle"} justify={"space-between"} style={{ marginBottom: 24 }} wrap={false}>
        <Typography.Title level={2} className={"page-title"}>
          {pageTitle}
        </Typography.Title>
        {rightHeaderContent}
      </Row>
      {bottomHeaderContent && (
        <Row justify={"space-between"} style={{ marginBottom: 24 }}>
          {bottomHeaderContent}
        </Row>
      )}
      {statistic && <div style={{ marginBottom: 24 }}>{statistic}</div>}
      <Content
        style={
          isTableContent
            ? {
                padding: "24px",
                borderRadius: 16,
                border: "1px solid #EDEFF3",
                backgroundColor: "#FFF",
              }
            : undefined
        }
      >
        {children}
      </Content>
    </Layout>
  )
}

export default PageLayout
