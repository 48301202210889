import { Form, Input } from "antd"
import { FC, useContext } from "react"
import { generateRandomDigits } from "../../../../../helpers"
import { DealsContext } from "../../../../app/context/DealsContext"
import { ConfirmButtons, Modal } from "../../../../ui"
import "./index.less"

interface AddColumnModalProps {
  onClose: () => void
}

export const AddColumnModal: FC<AddColumnModalProps> = ({ onClose }) => {
  const { addColumn } = useContext(DealsContext)
  const [form] = Form.useForm()

  const handleSubmitBtnClick = () => {
    form
      .validateFields()
      .then(values => {
        const newGroup = {
          id: generateRandomDigits(10),
          name: values.name,
          totalPrice: 0,
          deals: [],
        }
        addColumn(newGroup)
        onClose()
      })
      .catch(errorInfo => {
        console.error("Validation failed:", errorInfo)
      })
  }

  return (
    <Modal open={true} title={"Add Column"} onCancel={onClose} rootClassName={"add-column-modal"}>
      <Form form={form} layout={"vertical"} requiredMark={false} className={"add-column-form"}>
        <Form.Item
          name={"name"}
          label={"Name"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={"Enter Text"} />
        </Form.Item>
      </Form>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Add"}
        className={"confirm-buttons"}
        handleSubmit={handleSubmitBtnClick}
        onCancel={onClose}
      />
    </Modal>
  )
}
