/* eslint-disable react/jsx-fragments */
import { Typography } from "antd";
import React, { FC } from "react";
import { ConfirmButtons, Modal } from "src/components/ui";

type SaveBeforeChangeProps = {
  open: boolean;
  onCancel: VoidFunction;
};

export const SaveBeforeChangeModal: FC<SaveBeforeChangeProps> = ({ onSubmit, onCancel }) => {
  return (
    <Modal width={530} open={!!open} title={"Do you want to save the template changes you made?"} onCancel={onSubmit}>
      {/* on purpose onsubmit */}

      {/* {isphone ?<> 
        <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginBottom: 26 }}>
  *Make sure that if you delete this phone step you adjust the email templates after it if they talk about "I tried calling you earlier today".
        </Typography.Paragraph></>
        :''} */}
      <ConfirmButtons leftButtonTitle={"Discard Changes"} rightButtonTitle={"Save Changes"} onCancel={onCancel} handleSubmit={onSubmit} />
    </Modal>
  );
};
