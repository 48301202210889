import { Modal, Typography } from "antd"
import { FC, useContext } from "react"

import "./index.less"
import { ConfirmButtons } from "../ConfirmButtons"

interface DeleteWarningModalProps {
  onClose: () => void
  handleDeleteBtnClick: () => void
  id:any
  title:any
  paragraph?:any
}

export const DeleteWarningModal: FC<DeleteWarningModalProps> = ({ handleDeleteBtnClick,onClose ,id,title,paragraph}) => {
 

  return (
    <Modal
      open={true}
      title={title} className={"delete-warning-modal-root"}
   footer={null}
      onCancel={onClose}
       centered={true}
    >
      {paragraph && <Typography.Paragraph className={"description"}>
       {paragraph}
      </Typography.Paragraph> }
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Delete"}
        handleSubmit={()=>handleDeleteBtnClick(id)}
        onCancel={onClose}
      />
    </Modal>
  )
}
