import { Form, Input } from "antd"
import { FC, useContext } from "react"
import { DealsContext } from "../../../../app/context/DealsContext"
import { ConfirmButtons, Modal } from "../../../../ui"
import "./index.less"

interface EditColumnModalProps {
  onClose: () => void
}

export const EditColumnModal: FC<EditColumnModalProps> = ({ onClose }) => {
  const { dealsGroups, selectedGroupId, editColumn } = useContext(DealsContext)
  const [form] = Form.useForm()
  const currentGroup = dealsGroups?.find(group => group.id === selectedGroupId);

  const handleSubmitBtnClick = () => {
    form
      .validateFields()
      .then(values => {
        const updatedGroup: any = {...currentGroup, name: values.name}
        editColumn(updatedGroup)
        onClose()
      })
      .catch(errorInfo => {
        console.error("Validation failed:", errorInfo)
      })
  }

  return (
    <Modal open={true} title={"Edit Column"} onCancel={onClose} rootClassName={"edit-column-modal"}>
      <Form form={form} layout={"vertical"} requiredMark={false} className={"edit-column-form"}>
        <Form.Item
          name={"name"}
          label={"Name"}
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={currentGroup?.name}
        >
          <Input placeholder={"Enter Text"} />
        </Form.Item>
      </Form>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Save"}
        className={"confirm-buttons"}
        handleSubmit={handleSubmitBtnClick}
        onCancel={onClose}
      />
    </Modal>
  )
}
