import { Dropdown, MenuProps } from "antd"
import React, {Dispatch, FC, SetStateAction, useState } from "react"
import { SvgIcon } from "src/shared/icons"
import { Player } from "./ui/Player"
import { ConnectWithSpotifyButton } from "src/components/pages/phone-calls/ui/ConnectWithSpotifyButton"

export const PlayerDropdown: React.FC = ({ ...props }) => {
  const [menuVisible, setMenuVisible] = useState(false)

  const handleVisibleChange = (visible: boolean) => {
    setMenuVisible(visible)
  }

  const handleMenuClick = (e: any) => {
    e.stopPropagation()
  }

 


  const items: MenuProps["items"] = [
    {
      label: <ConnectWithSpotifyButton />,
      key: "0",
    },
    {
      label: <Player />,
      key: "1",
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      rootClassName={"player-dropdown"}
      open={menuVisible}
      onOpenChange={handleVisibleChange}
      {...props}
    >
     
      <SvgIcon type={"playHeader"} style={{ cursor: "pointer" }} />
    </Dropdown>
  )
}
