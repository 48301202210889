import { Button, Flex, Form, Input, message, Row, Typography } from "antd"
import React, { FC } from "react"
import { ConfirmButtons, CustomSelect, Modal } from "src/components/ui"
import { saveToClipboard } from "src/helpers/saveToClipboard"
import { SvgIcon } from "src/shared/icons"
import { getDialerPhoneOptions } from "../../index"
import "../../index.less"

type SendSmsModalProps = {
  open: boolean
  onCancel: VoidFunction
  data: any
}

const calendarLink = "https://www.google.com"

export const SendSmsModal: FC<SendSmsModalProps> = ({ open, onCancel, data }) => {
  const [form] = Form.useForm()
  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    console.log("Send SMS")
    closeModal()
  }

  const handleSaveLink = () => {
    saveToClipboard(calendarLink)
    message.success("Copied!")
  }

  return (
    <Modal width={544} open={open} title={"Send SMS"} onCancel={closeModal}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Flex vertical>
          {data ? (
            <Row align={"middle"} wrap={false} style={{ marginBottom: 8 }}>
              <SvgIcon type={"dividerDot"} style={{ marginRight: 8 }} />
              <Typography.Paragraph style={{ marginRight: 20 }}>{data.name}</Typography.Paragraph>
              <CustomSelect
                defaultValue={data.phones.d}
                className={"phone-select"}
                options={getDialerPhoneOptions(data.phones)}
                popupClassName={"phone-select-dropdown"}
              />
            </Row>
          ) : (
            <Typography.Paragraph>+(1) 647-234-1245</Typography.Paragraph>
          )}
          <Row justify={"space-between"} style={{ marginBottom: 16 }}>
            <Button className={"link"} onClick={handleSaveLink}>
              Copy Calendar Link
            </Button>
          </Row>
          <Form.Item
            name={"smsBody"}
            label={"SMS Body"}
            initialValue={
              "Hi Tom, \n" +
              "I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?\n" +
              "\n" +
              "Warm regards"
            }
          >
            <Input.TextArea autoSize />
          </Form.Item>
          <ConfirmButtons
            leftButtonTitle={"Back"}
            rightButtonTitle={"Send SMS"}
            onCancel={closeModal}
            handleSubmit={handleFormSubmit}
          />
        </Flex>
      </Form>
    </Modal>
  )
}
