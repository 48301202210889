import { Space, Table, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC } from "react"
import { Modal } from "src/components/ui"
import { App } from "src/types"
import { EmailsStatus } from "../EmailsStatus"

type EmailDetailsProps = {
  open: boolean
  onCancel: VoidFunction
  selectedContact: App.EmailsEntity
}

export const EmailDetailsModal: FC<EmailDetailsProps> = ({ open, onCancel, selectedContact }) => {
  const closeModal = () => {
    onCancel()
  }

  const columns: ColumnsType<App.EmailsEntity> = [
    {
      title: "Date",
      dataIndex: "dateSent",
      key: "dateSent",
    },
    {
      title: "Traffic",
      dataIndex: "traffic",
      key: "traffic",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Content",
      render: record => (
        <Space direction={"vertical"} key={record.id}>
          <Typography.Paragraph ellipsis={{ rows: 2 }} style={{ width: 206 }}>
            {record.contents.content}
          </Typography.Paragraph>
        </Space>
      ),
      key: "contents",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: value =>
        value.map((item: App.EmailStatus, index: number) => {
          return <EmailsStatus key={`${index}-${item.title}`} status={item.title} count={item.count} />
        }),
      key: "status",
    },
  ]

  return (
    <Modal customClassName={"table-modal"} width={"1024px"} open={open} title={"Details"} onCancel={closeModal}>
      <Table rowKey={"id"} columns={columns} dataSource={[selectedContact]} pagination={false} />
    </Modal>
  )
}
