import { Spin, SpinProps } from "antd";
import { FC } from "react";
import "./index.less";

export const Loader: FC<SpinProps> = ({ className, ...props }) => {
  return (
    <Spin className={className ? `spinner ${className}` : "spinner"} {...props}>
      <div className={"content"} />
    </Spin>
  );
};

export default Loader;
