export const aiInitiatedData = [
  {
    date: "17 Dec",
    type: "Total",
    value: 230,
  },
  {
    date: "17 Dec",
    type: "Booked",
    value: 100,
  },
  {
    date: "17 Dec",
    type: "In progress",
    value: 50,
  },
  {
    date: "17 Dec",
    type: "Not Scheduled",
    value: 60,
  },
  {
    date: "18 Dec",
    type: "Total",
    value: 260,
  },
  {
    date: "18 Dec",
    type: "Booked",
    value: 30,
  },
  {
    date: "18 Dec",
    type: "In progress",
    value: 150,
  },
  {
    date: "18 Dec",
    type: "Not Scheduled",
    value: 80,
  },
  {
    date: "19 Dec",
    type: "Total",
    value: 280,
  },
  {
    date: "19 Dec",
    type: "Booked",
    value: 100,
  },
  {
    date: "19 Dec",
    type: "In progress",
    value: 100,
  },
  {
    date: "19 Dec",
    type: "Not Scheduled",
    value: 80,
  },
  {
    date: "20 Dec",
    type: "Total",
    value: 60,
  },
  {
    date: "20 Dec",
    type: "Booked",
    value: 130,
  },
  {
    date: "20 Dec",
    type: "In progress",
    value: 150,
  },
  {
    date: "20 Dec",
    type: "Not Scheduled",
    value: 160,
  },
  {
    date: "22 Dec",
    type: "Total",
    value: 60,
  },
  {
    date: "22 Dec",
    type: "Booked",
    value: 130,
  },
  {
    date: "22 Dec",
    type: "In progress",
    value: 150,
  },
  {
    date: "22 Dec",
    type: "Not Scheduled",
    value: 160,
  },
  {
    date: "23 Dec",
    type: "Total",
    value: 25,
  },
  {
    date: "23 Dec",
    type: "Booked",
    value: 100,
  },
  {
    date: "23 Dec",
    type: "In progress",
    value: 170,
  },
  {
    date: "23 Dec",
    type: "Not Scheduled",
    value: 135,
  },
  {
    date: "24 Dec",
    type: "Total",
    value: 70,
  },
  {
    date: "24 Dec",
    type: "Booked",
    value: 150,
  },
  {
    date: "24 Dec",
    type: "In progress",
    value: 110,
  },
  {
    date: "24 Dec",
    type: "Not Scheduled",
    value: 100,
  },
  {
    date: "25 Dec",
    type: "Total",
    value: 350,
  },
  {
    date: "25 Dec",
    type: "Booked",
    value: 160,
  },
  {
    date: "25 Dec",
    type: "In progress",
    value: 140,
  },
  {
    date: "25 Dec",
    type: "Not Scheduled",
    value: 80,
  },
  {
    date: "26 Dec",
    type: "Total",
    value: 260,
  },
  {
    date: "26 Dec",
    type: "Booked",
    value: 160,
  },
  {
    date: "26 Dec",
    type: "In progress",
    value: 150,
  },
  {
    date: "26 Dec",
    type: "Not Scheduled",
    value: 180,
  },
  {
    date: "27 Dec",
    type: "Total",
    value: 230,
  },
  {
    date: "27 Dec",
    type: "Booked",
    value: 170,
  },
  {
    date: "27 Dec",
    type: "In progress",
    value: 180,
  },
  {
    date: "27 Dec",
    type: "Not Scheduled",
    value: 190,
  },
  {
    date: "28 Dec",
    type: "Total",
    value: 130,
  },
  {
    date: "28 Dec",
    type: "Booked",
    value: 110,
  },
  {
    date: "28 Dec",
    type: "In progress",
    value: 70,
  },
  {
    date: "28 Dec",
    type: "Not Scheduled",
    value: 180,
  },
]
